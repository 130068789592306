import { FC } from 'react'

import classnames from 'classnames'

import {
  ComponentMetadata,
  ComponentParameter
} from 'api/hooks/useGetComponentMetadata/types'
import { ComponentSummaryId } from 'api/hooks/useGetComponentTree'
import { ProblemDetails } from 'api/types/http-problem-details'

import { DynamicDropdown } from 'components/DynamicDropdown/DynamicDropdown'

import classes from './Dropdown.module.scss'

interface DropdownProps {
  inputId: string
  resultsDataBoundary?: string
  componentMetaData: ComponentMetadata
  parameter?: ComponentParameter
  parameterName: string
  value: string
  hasError?: boolean
  componentSummaryId: ComponentSummaryId
  onEdit: (s: string) => unknown
  onEditorError: (error?: ProblemDetails) => void
}

export const Dropdown: FC<DropdownProps> = ({
  inputId,
  resultsDataBoundary,
  componentMetaData,
  parameter,
  parameterName,
  value,
  hasError,
  componentSummaryId,
  onEdit,
  onEditorError
}) => (
  <DynamicDropdown
    inputId={inputId}
    inputClassName={classnames(classes.Dropdown__Input)}
    className={classnames(classes.Dropdown, {
      [classes.Dropdown__Error]: hasError
    })}
    value={value}
    componentMetadata={componentMetaData}
    componentSummaryId={componentSummaryId}
    parameter={parameter}
    parameterName={parameterName}
    resultsDataBoundary={resultsDataBoundary}
    onEdit={onEdit}
    onEditorError={onEditorError}
  />
)
