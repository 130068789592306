import { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  AutoCompleteItem,
  AutoCompleteItemId
} from '@matillion/component-library'
import classnames from 'classnames'
import { debounce } from 'lodash'

import { ComponentMetadata } from 'api/hooks/useGetComponentMetadata/types'
import { ProblemDetails } from 'api/types/http-problem-details'

import { AutoComplete } from 'components/AutoComplete'

import {
  Parameter,
  useParameterOptions
} from 'hooks/useParameterOptions/useParameterOptions'

import classes from './DynamicDropdown.module.scss'

export interface DynamicDropdownProps {
  inputId: string
  value: string
  componentMetadata: ComponentMetadata
  componentSummaryId: string
  parameter?: Parameter
  parameterName: string
  placeholder?: string
  className?: string
  inputClassName?: string
  resultsDataBoundary?: string
  onEdit: (selectedValue: string) => unknown
  onEditorError?: (error?: ProblemDetails) => void
}

export const DynamicDropdown: FunctionComponent<DynamicDropdownProps> = ({
  inputId,
  value,
  componentMetadata,
  componentSummaryId,
  parameter,
  parameterName,
  placeholder,
  className,
  inputClassName,
  resultsDataBoundary,
  onEdit,
  onEditorError
}) => {
  const { t } = useTranslation()
  const [dropdownValue, setDropdownValue] = useState<string>(value)
  const [isQueryEnabled, setIsQueryEnabled] = useState<boolean>(false)

  const {
    isLoading,
    isRefetching,
    data: items,
    isError,
    error
  } = useParameterOptions({
    componentSummaryId,
    componentMetaData: componentMetadata,
    parameter,
    isEnabled: isQueryEnabled
  })

  const selectedValue = {
    id: dropdownValue,
    name: dropdownValue
  }

  const isLoadingOptions = isQueryEnabled && (isLoading || isRefetching)

  const getAvailableItems = (): AutoCompleteItemId[] => {
    if (isLoadingOptions) {
      return []
    }

    if (items.length > 0 && items[0].options && items[0].options.length > 0) {
      return items[0].options.map((option) => ({
        id: option.toString(),
        name: option.toString()
      }))
    }

    return [
      {
        id: 'no_options_available',
        name: t('parameterEditor.DROPDOWN.noItemsFound', {
          parameter: parameterName
        }),
        disabled: true
      }
    ]
  }

  const handleEdit = useMemo(
    () =>
      debounce((updatedValue: string) => {
        onEdit(updatedValue)
      }, 500),
    [onEdit]
  )

  const handleChange = (selected: { target: AutoCompleteItem }) => {
    const selectedVal = selected.target.value?.name ?? ''

    setDropdownValue(selectedVal)
    handleEdit(selectedVal)
  }

  const handleClick = () => {
    setIsQueryEnabled(true)
  }

  const handleBlur = () => {
    setIsQueryEnabled(false)
  }

  useEffect(() => {
    onEditorError?.(isError && error ? error : undefined)
  }, [isError, error, onEditorError])

  return (
    <AutoComplete
      allowFreetext
      className={classnames(className, {
        [classes['DynamicDropdown--isLoading']]: isLoadingOptions
      })}
      data-testid={`dynamic-dropdown-${inputId}`}
      placeholder={placeholder}
      availableItems={getAvailableItems()}
      inputId={inputId}
      inputClassName={inputClassName}
      loading={isLoadingOptions}
      value={selectedValue}
      resultsDataBoundary={resultsDataBoundary}
      onClick={handleClick}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  )
}
