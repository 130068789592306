import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { HorizontalStack } from 'components/HorizontalStack/HorizontalStack'

import { ProjectInfo } from '../../hooks/useProjectInfo/useProjectInfo'
import BranchSelector from './components/BranchSelector'
import EnvironmentSelector from './components/EnvironmentSelector'
import ProjectExplorerLink from './components/ProjectExplorerLink'
import ProjectSelector from './components/ProjectSelector'
import classes from './ProjectToolbar.module.scss'

const ProjectToolbar: FC<{ projectInfo: ProjectInfo }> = ({ projectInfo }) => {
  const { t } = useTranslation()

  return (
    <div className={classes.ProjectToolbar}>
      <HorizontalStack withSpacer aria-label={t('projectToolbox.toolbarLabel')}>
        <ProjectExplorerLink projectInfo={projectInfo} />
        <ProjectSelector />
        <BranchSelector projectInfo={projectInfo} />
        <EnvironmentSelector projectInfo={projectInfo} />
      </HorizontalStack>
    </div>
  )
}

export default ProjectToolbar
