import { ComponentInstance } from 'job-lib/types/Job'

const updateComponentName = (value: string, componentName: string) => {
  const parsedValue = JSON.parse(value)
  parsedValue.componentName = componentName

  return JSON.stringify(parsedValue)
}

export const setDPLComponentNameOverride = (
  component: ComponentInstance,
  componentName: string
) => {
  const { elements } = component.parameters[2]
  const valueToModify = elements[1].values[1].value

  const updatedValue = updateComponentName(valueToModify, componentName)

  return {
    ...component.parameters[2],
    elements: {
      1: {
        ...elements[1],
        values: {
          1: {
            ...elements[1].values[1],
            value: updatedValue
          }
        }
      }
    }
  }
}
