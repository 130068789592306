import { FC, PropsWithChildren, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon, Typography } from '@matillion/component-library'
import classnames from 'classnames'

import { useComponentSummaries } from 'api/hooks/useGetComponentTree'

import { Loading } from 'components/Loading/Loading'

import { useWorkingCopy } from 'modules/EtlDesigner/hooks/useWorkingCopy'

import classes from './Canvas.module.scss'
import { CanvasProvider } from './CanvasProvider'
import { FlowCanvas } from './components/FlowCanvas/FlowCanvas'
import { Toolbox } from './components/Toolbox/Toolbox'
import { ZoomToolbar } from './components/ZoomToolbar/ZoomToolbar'

const CanvasOverlay: FC<PropsWithChildren> = ({ children }) => (
  <div className={classes.Canvas} data-testid="canvas-container">
    <div
      data-testid="canvas-overlay-container"
      className={classnames(classes.Canvas__Overlay, classes.Overlay)}
    >
      {children}
    </div>
  </div>
)

export const Canvas: FC = () => {
  const { t } = useTranslation()
  const [snapEnabled, setSnapEnabled] = useState(true)

  const {
    summary,
    job,
    isLoading: isWorkingCopyLoading,
    isError: isWorkingCopyError
  } = useWorkingCopy()
  const {
    isLoading: isComponentSummariesLoading,
    isError: isComponentSummariesError
  } = useComponentSummaries()

  if (isWorkingCopyError || isComponentSummariesError) {
    return (
      <CanvasOverlay>
        <Icon.Error className={classes.Overlay__Icon} />

        <Typography className={classes.Overlay__Text}>
          {t('translation:canvas.noJobInfo')}
        </Typography>
      </CanvasOverlay>
    )
  }

  if (isWorkingCopyLoading || isComponentSummariesLoading || !job || !summary) {
    return (
      <CanvasOverlay>
        <Loading />
      </CanvasOverlay>
    )
  }

  return (
    <CanvasProvider job={job} jobSummary={summary}>
      <div data-testid="canvas-toolbox" className={classes.Canvas__Toolbox}>
        <Toolbox />
      </div>

      <FlowCanvas job={job} snapEnabled={snapEnabled} />

      <div
        data-testid="canvas-zoom-controls"
        className={classes.Canvas__ZoomToolbar}
      >
        <ZoomToolbar
          snapEnabled={snapEnabled}
          setSnapEnabled={setSnapEnabled}
        />
      </div>
    </CanvasProvider>
  )
}
