import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Icon, Typography } from '@matillion/component-library'

import { useProjectPermission } from 'api/external/usePermission/useProjectPermission'
import { JobSummary } from 'api/hooks/useGetJobSummaries'

import { ReactComponent as OrchestrationSvg } from 'assets/OrchestrationJob.svg'
import { ReactComponent as TransformationSvg } from 'assets/TransformationJob.svg'

import { JobType } from 'job-lib/types/JobType'

import { PipelineBrowserCommand } from '../../context/types'
import { usePipelineBrowserCommandProvider } from '../../effects/usePipelineBrowserCommand'
import classes from '../../PipelineBrowser.module.scss'

export interface EmptyPipelineBrowserListCTAProps {
  onClick?: () => void
  type: JobType
}

const EmptyJobBrowserListCTA: FC<EmptyPipelineBrowserListCTAProps> = ({
  onClick,
  type
}) => {
  const { t } = useTranslation()

  const buttonText = t('sideBar.jobPanel.createNewJob', {
    context: type
  })

  return (
    <Button
      alt="text"
      aria-label={buttonText}
      type="button"
      className={classes.EmptyPipelineBrowserList__Button}
      onClick={onClick}
    >
      <div className={classes.EmptyPipelineBrowserList__Button__Content}>
        {type === JobType.Orchestration ? (
          <OrchestrationSvg />
        ) : (
          <TransformationSvg />
        )}
        <Typography
          className={classes.EmptyPipelineBrowserList__Button__Text}
          as={'span'}
        >
          {buttonText}
        </Typography>
      </div>
    </Button>
  )
}

export interface Props {
  jobs: JobSummary[]
}

export const EmptyPipelineBrowserList: FC = () => {
  const { t } = useTranslation()
  const { onCommand } = usePipelineBrowserCommandProvider()
  const { hasPermission, isLoading } = useProjectPermission('create_pipelines')

  const hasPermissionNotLoading = hasPermission && !isLoading

  return (
    <div className={classes.EmptyPipelineBrowserList}>
      <Icon.File />
      <Typography className={classes.EmptyPipelineBrowserList__Text}>
        {t(
          hasPermissionNotLoading
            ? 'sideBar.jobPanel.emptyJobList'
            : 'sideBar.jobPanel.emptyJobListUnauthorised'
        )}
      </Typography>

      {hasPermissionNotLoading && (
        <div className={classes.EmptyPipelineBrowserList__CTAContainer}>
          <EmptyJobBrowserListCTA
            type={JobType.Orchestration}
            onClick={() => {
              onCommand({
                type: PipelineBrowserCommand.CREATE_PIPELINE,
                payload: JobType.Orchestration
              })
            }}
          />
          <EmptyJobBrowserListCTA
            type={JobType.Transformation}
            onClick={() => {
              onCommand({
                type: PipelineBrowserCommand.CREATE_PIPELINE,
                payload: JobType.Transformation
              })
            }}
          />
        </div>
      )}
    </div>
  )
}
