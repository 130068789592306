import { FunctionComponent, useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Route, Routes } from 'react-router-dom'

import { datadogLogs } from '@datadog/browser-logs'
import { Layout } from '@matillion/component-library'
import {
  gitComponentLibraryMswHandlers,
  useDifferentGetWorkingTreeBranchHandlers
} from '@matillion/git-component-library'
import { HubHeader, RoleGuard, useAuth, useUser } from '@matillion/hub-client'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { setupWorker } from 'msw'

import {
  AGENT_ID,
  BRANCH_ID,
  ENVIRONMENT_ID,
  PROJECT_ID
} from '__test-utils__/ids'

import { useAgentDetailsHandlers } from 'api/hooks/useAgentDetails/__handlers__/useAgentDetails.handlers'
import { useCreateJobHandlers } from 'api/hooks/useCreateJob/__handlers__/useCreateJob.handlers'
import { useDeleteBranchHandlers } from 'api/hooks/useDeleteBranch/__handlers__/useDeleteBranch.handlers'
import { useGetComponentMetadataHandlers } from 'api/hooks/useGetComponentMetadata/__handlers__/useGetComponentMetadata.handlers'
import { useGetComponentTreeHandlers } from 'api/hooks/useGetComponentTree/__handlers__/useGetComponentTree.handlers'
import { useGetJobHandlers } from 'api/hooks/useGetJob/__handlers__/useGetJob.handlers'
import { useGetJobSummariesHandlers } from 'api/hooks/useGetJobSummaries/__handlers__/useGetJobSummaries.handlers'
import { useGetParameterOptionsHandlers } from 'api/hooks/useGetParameterOptions/__handlers__/useGetParameterOptions.handlers'
import { useGetProjectHandlers } from 'api/hooks/useGetProject/__handlers__/useGetProject.handlers'
import { useGetRunTasksHandlers } from 'api/hooks/useGetRunTasks/__handlers__/useGetRunTasks.handlers'
import { useListBranchesHandlers } from 'api/hooks/useListBranches/__handlers__/useListBranches.handlers'
import { useListEnvironmentsHandlers } from 'api/hooks/useListEnvironments/__handlers__/useListEnvironments.handlers'
import { useListProjectsHandlers } from 'api/hooks/useListProjects/__handlers__/useListProjects.handlers'
import { useListProjectVariablesHandler } from 'api/hooks/useListProjectVariables/__handlers__/useProjectVariables.handlers'
import { usePublishJobsHandlers } from 'api/hooks/usePublishJobs/__handlers__/usePublishJobs.handlers'
import { useRunJobHandlers } from 'api/hooks/useRunJob/__handlers__/useRunJob.handlers'
import { useSampleComponentHandlers } from 'api/hooks/useSampleComponent/__handlers__/useSampleComponent.handlers'
import { useSaveJobHandlers } from 'api/hooks/useSaveJob/__handlers__/useSaveJob.handlers'
import { useValidateComponentHandlers } from 'api/hooks/useValidateComponent/__handlers__/useValidateComponent.handlers'

import config from 'config'

import { Login, Logout } from 'modules/Auth'
import { Canvas } from 'modules/Canvas'
import ErrorBoundaryFallback from 'modules/ErrorBoundaryFallback'
import EtlDesigner from 'modules/EtlDesigner'
import FlowStatsExplorer from 'modules/FlowStatsExplorer/FlowStatsExplorer'
import { GettingStarted } from 'modules/GettingStarted'
import { ProjectGuard } from 'modules/ProjectGuard/ProjectGuard'

import { identify } from 'utils/heap'

export const allHandlers = [
  ...useGetComponentTreeHandlers,
  ...useGetJobSummariesHandlers,
  ...useGetJobHandlers,
  ...useRunJobHandlers,
  ...useGetRunTasksHandlers,
  ...useGetComponentMetadataHandlers,
  ...useGetParameterOptionsHandlers,
  ...useListBranchesHandlers,
  ...useListEnvironmentsHandlers,
  ...useSaveJobHandlers,
  ...useCreateJobHandlers,
  ...useValidateComponentHandlers,
  ...usePublishJobsHandlers,
  ...useDeleteBranchHandlers,
  ...useSampleComponentHandlers,
  ...useDifferentGetWorkingTreeBranchHandlers,
  ...useListProjectVariablesHandler,
  ...useListProjectsHandlers,
  ...useGetProjectHandlers,
  ...useAgentDetailsHandlers,
  ...gitComponentLibraryMswHandlers({
    designerBaseUrl: `${config.getApiBaseUrl()}/v1`
  })
]

/* istanbul ignore next */
if (process.env.REACT_APP_USE_MOCKS === 'true' && !('Cypress' in window)) {
  const worker = setupWorker(...allHandlers)
  worker.start({
    quiet: Boolean(process.env.REACT_APP_MSW_LOG_OUTPUT) ?? true
  })
  worker.printHandlers()
}

/* istanbul ignore next */
const DevRoutes = () => (
  <ul data-testid="test-routes" style={{ margin: '24px' }}>
    <li>
      <a
        href={`/project/${PROJECT_ID}/branch/${BRANCH_ID}?agentId=${AGENT_ID}&environmentId=${ENVIRONMENT_ID}`}
      >
        DEV THING
      </a>
    </li>
    <li>
      <a href="/empty/empty">EMPTY</a>
    </li>
    <li>
      <a href="/500/500">Error response</a>
    </li>
  </ul>
)

const App: FunctionComponent = () => {
  const { isLoggedIn } = useAuth()
  const { user } = useUser()

  useEffect(() => {
    identify(user.email)
  }, [user])

  if (!isLoggedIn) {
    return <Login />
  }

  return (
    <RoleGuard role="saas-etl:user">
      <Layout header={<HubHeader />}>
        <ErrorBoundary
          onError={(err) => {
            datadogLogs.logger.error(
              `Error caught by react error boundary (${err.message})`,
              {
                message: err.message,
                stack: err.stack,
                name: err.name
              }
            )
          }}
          fallback={<ErrorBoundaryFallback />}
        >
          <Routes>
            <Route path="/logout" element={<Logout />} />

            {process.env.REACT_APP_USE_MOCKS === 'true' &&
              !('Cypress' in window) && (
                <Route path="/" element={<DevRoutes />} />
              )}

            <Route path="project/:projectId" element={<ProjectGuard />}>
              <Route path="branch/:branchId" element={<EtlDesigner />}>
                <Route index element={<GettingStarted />} />

                <Route path="task/:taskId" element={<FlowStatsExplorer />} />

                <Route path="job/:jobSummaryId" element={<Canvas />}>
                  <Route path="component/:componentId" element={null} />
                </Route>
              </Route>
            </Route>

            <Route path="*" element={<ProjectGuard />} />
          </Routes>

          {process.env.REACT_APP_REACT_QUERY_DEVTOOL === 'true' && (
            <div role="region" aria-label={'React Query Devtools'}>
              <ReactQueryDevtools />
            </div>
          )}
        </ErrorBoundary>
      </Layout>
    </RoleGuard>
  )
}

export default App
