import { BRANCH_ID, BRANCH_NAME } from '__test-utils__/ids'

import { ListBranchesResponse } from '../types'

export const MockedBranches: ListBranchesResponse[] = [
  {
    id: 'df2bcad0-c892-4a05-9a53-b61614aa42ad',
    name: 'main',
    defaultEnvironmentName: 'Snowflake',
    defaultEnvironmentId: 'ee3d109e-26a6-4c94-a22e-ee3b2601d19c'
  },
  {
    id: '6e3403aa-ed89-4a9b-b2c1-fc219e2a4be8',
    name: BRANCH_NAME,
    defaultEnvironmentName: 'Snowflake',
    defaultEnvironmentId: 'ee3d109e-26a6-4c94-a22e-ee3b2601d19c'
  },
  {
    id: 'ebcccbc8-18d2-4110-bfb4-29eae7df5f2e',
    name: 'BranchNoConflicts',
    defaultEnvironmentName: 'Snowflake',
    defaultEnvironmentId: 'ee3d109e-26a6-4c94-a22e-ee3b2601d19c'
  },
  {
    id: '65c3d4d4-9787-47df-88a5-6a82c9cdd088',
    name: 'BranchWithConflicts',
    defaultEnvironmentName: 'Snowflake',
    defaultEnvironmentId: 'ee3d109e-26a6-4c94-a22e-ee3b2601d19c'
  },
  {
    id: 'a4e5af0f-b403-4cf8-841e-0fb296e6ac70',
    name: 'BranchManyConflicts',
    defaultEnvironmentName: 'Snowflake',
    defaultEnvironmentId: 'ee3d109e-26a6-4c94-a22e-ee3b2601d19c'
  },
  {
    id: '2682b37c-c24f-4e6b-9582-6b63a7366f96',
    name: 'BranchFullOffset',
    defaultEnvironmentName: 'Snowflake',
    defaultEnvironmentId: 'ee3d109e-26a6-4c94-a22e-ee3b2601d19c'
  },
  {
    id: '53cb804f-7036-4a5a-a63e-191b306d3a55',
    name: 'BranchPartialOffset',
    defaultEnvironmentName: 'Snowflake',
    defaultEnvironmentId: 'ee3d109e-26a6-4c94-a22e-ee3b2601d19c'
  },
  {
    id: BRANCH_ID,
    name: 'secondary-test-branch',
    defaultEnvironmentName: 'Snowflake',
    defaultEnvironmentId: 'ee3d109e-26a6-4c94-a22e-ee3b2601d19c'
  },
  {
    id: BRANCH_ID,
    name: BRANCH_ID,
    defaultEnvironmentName: 'Snowflake',
    defaultEnvironmentId: 'ee3d109e-26a6-4c94-a22e-ee3b2601d19c'
  }
]
