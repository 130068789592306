import { Tooltip, TreeItem, Typography } from '@matillion/component-library'

import { ComponentTree } from 'api/hooks/useGetComponentTree'

import Folder from 'components/Folder/Folder'

import classes from '../ComponentBrowser.module.scss'
import { ComponentTreeItem } from './ComponentTreeItem'

interface RenderTreeOptions {
  expanded?: boolean
}

const renderTree = (
  folder: ComponentTree,
  { expanded }: RenderTreeOptions = {}
) => {
  return Object.keys(folder)
    .sort()
    .map((key) => {
      const child = folder[key]

      if (child.type === 'component') {
        return (
          <ComponentTreeItem
            key={child.component.componentId}
            component={child.component}
            isAvailableForAgent={child.isAvailableForAgent}
          />
        )
      }

      return (
        <TreeItem
          key={key}
          nodeId={key}
          label={key}
          leaf={
            <div className={classes.Folder} data-testid={`tree-folder-${key}`}>
              <Folder />
              <Tooltip content={key} onlyShowOnOverflow>
                <div>
                  <Typography
                    format="bcs"
                    className={classes.Folder__Text}
                    as="span"
                  >
                    {key}
                  </Typography>
                </div>
              </Tooltip>
            </div>
          }
        >
          {renderTree(child.children)}
        </TreeItem>
      )
    })
}

export { renderTree }
