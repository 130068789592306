import useUser from '../effects/useUser'
import { useEffect, useMemo } from 'react'
import getConfig from '../config'
import useEnvironment from '../effects/useEnvironment'

/**
 *  Provides a series of Intercom API methods.
 *
 * @category Hooks
 */

declare global {
  type UserAttributesOp = 'boot' | 'update'
  type NoPayloadOp = 'shutdown'
  type ResourceActionOp = 'startTour'

  interface Window {
    Intercom?: {
      (operation: UserAttributesOp, payload: UserAttributes): void
      (operation: NoPayloadOp): void
      (operation: ResourceActionOp, resourceId: number): void
    }
  }
}

interface UserAttributes {
  app_id?: string
  user_id?: string | null
  email?: string | null
  created_at?: string | null
  last_request_at?: number | null
  name?: string | null
  country?: string | null
  'First name'?: string | null
  'Last name'?: string | null
  Nickname?: string | null
  'Job title'?: string | null
  'Email verified'?: boolean | null
  'Marketing consent'?: boolean | null
  'Invited by teammate'?: boolean | null
  'Recently used account id'?: string | null
  'Recently used account name'?: string | null
  'Recently used subdomain'?: string | null
  [key: string]: string | boolean | number | null | undefined
}

const useIntercom = () => {
  const { user, organisation, profile } = useUser()
  const { environment, offline } = useEnvironment()

  const appId = useMemo(
    () => getConfig(environment).intercom.app_id,
    [environment]
  )

  useEffect(() => {
    boot()
  }, [])

  const boot = () => {
    if (window.Intercom) {
      const attributes: UserAttributes = {
        app_id: appId,
        user_id: user.sub,
        email: user.email,
        created_at: user['https://www.matillion.com/created_at'],
        name: profile.name,
        country: user.country,
        'First name': user.given_name,
        'Last name': user.family_name,
        Nickname: user.nickname,
        'Job title': user['https://hub.matillion.com/job_title'],
        'Email verified': user.email_verified === 'true',
        'Marketing consent':
          user['https://hub.matillion.com/marketing_consent'] === 'true',
        'Invited by teammate':
          user['https://hub.matillion.com/invited'] === 'true',
        'Recently used account id': organisation.id,
        'Recently used account name': organisation.name,
        'Recently used subdomain': organisation.subdomain
      }

      window.Intercom('boot', attributes)

      return true
    }
    if (!offline) {
      console.error(
        "Intercom's boot operation did not run. Check that Intercom has loaded and window.Intercom is available."
      )
    }

    return false
  }

  const update = (additionalUserAttributes = {}) => {
    if (window.Intercom) {
      const attributes: UserAttributes = {
        user_id: user.sub,
        last_request_at: new Date().getTime() / 1000,
        ...additionalUserAttributes
      }

      window.Intercom('update', attributes)

      return true
    }
    if (!offline) {
      console.error(
        "Intercom's update operation did not run. Check that Intercom has loaded and window.Intercom is available."
      )
    }
    return false
  }

  const shutdown = () => {
    if (window.Intercom) {
      window.Intercom('shutdown')

      return true
    }
    if (!offline) {
      console.error(
        "Intercom's shutdown operation did not run. Check that Intercom has loaded and window.Intercom is available."
      )
    }
    return false
  }

  const startTour = (tourId: number) => {
    if (window.Intercom) {
      window.Intercom('startTour', tourId)

      return true
    }
    if (!offline) {
      console.error(
        "Intercom's startTour operation did not run. Check that Intercom has loaded and window.Intercom is available."
      )
    }
    return false
  }

  return { update, shutdown, startTour }
}

export default useIntercom
