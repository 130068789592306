import { FC, ReactNode } from 'react'

import classNames from 'classnames'

import classes from '../ParameterOverlay.module.scss'

interface FooterProps {
  children: ReactNode
  className?: string
}

export const Footer: FC<FooterProps> = ({ children, className }) => (
  <div
    className={classNames(classes.ParameterOverlay__FooterContainer, className)}
  >
    {children}
  </div>
)
