import { FC, lazy, Suspense, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Icon,
  MicroCta,
  Modal,
  Typography
} from '@matillion/component-library'
import classNames from 'classnames'

import { Loading } from 'components/Loading/Loading'

import { useFlags } from 'hooks/useFlags'

import classes from '../FlowStatsExplorer.module.scss'
import { AgentStackTrace } from './AgentStackTrace'

const CodeEditor = lazy(async () => import('components/CodeEditor'))
export interface CellMessageProps {
  value?: string
  workerRequests?: string[]
}

const CellMessage: FC<CellMessageProps> = ({ value, workerRequests }) => {
  const [messageModalOpen, setMessageModalOpen] = useState(false)
  const { t } = useTranslation()
  const { enableAgentStackTrace } = useFlags()

  return (
    <div className={classes.CellMessage}>
      <div
        className={classNames(
          classes.RowTypography,
          classes.CellMessage__Text,
          'u-truncate-text'
        )}
      >
        <Typography format="bcs" as="span">
          {value}
        </Typography>
      </div>
      {value && (
        <MicroCta
          aria-label={t('taskExplorer.cellMessage.cta')}
          alt="transparent"
          onClick={() => setMessageModalOpen(true)}
          className={classNames(classes.CellMessage__CTA)}
          data-testid="cell-message-cta"
        >
          <Icon.ArrowRightSmall />
        </MicroCta>
      )}
      {messageModalOpen && value && (
        <Modal
          onCancel={() => setMessageModalOpen(false)}
          ariaLabelledBy="modal-title"
          size="large"
        >
          <Typography
            as="h2"
            format="tm"
            id="modal-title"
            className={classes.CellMessageModal__Title}
          >
            {t('taskExplorer.cellMessage.modalTitle')}
          </Typography>
          <Suspense fallback={<Loading />}>
            <CodeEditor
              value={value}
              readOnly
              className={classes.CodeMirrorCodeEditor}
            />
          </Suspense>
          {/* istanbul ignore next */}
          {enableAgentStackTrace && workerRequests?.length && (
            <AgentStackTrace workerRequests={workerRequests} />
          )}
          <div className={classes.CellMessageModal__Controls}>
            <Button
              onClick={() => setMessageModalOpen(false)}
              text={t('common.close')}
            />
          </div>
        </Modal>
      )}
    </div>
  )
}

export default CellMessage
