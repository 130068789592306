import { FunctionComponent } from 'react'

/* react-flow uses svg path markers to render arrowheads on edges.
 * due to browser support for markers defined in external files being
 * pretty spotty in 2023, we need to inline these definitions somewhere
 * in the app so that our paths can reference them later on. the downside
 * of this is that we need to make any changes to the svgs here manually
 * in future... */
export const FlowMarkers: FunctionComponent = () => (
  <svg
    width="9"
    height="14"
    viewBox="0 0 9 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <marker
        id="marker-fail"
        viewBox="0 0 9 14"
        refX="7"
        refY="7"
        markerUnits="userSpaceOnUse"
        markerWidth="9"
        markerHeight="14"
        orient="auto-start-reverse"
      >
        <path d="M1 1L7 7L1 13" stroke="#C2292B" strokeWidth="2" />
      </marker>

      <marker
        id="marker-success"
        viewBox="0 0 9 14"
        refX="7"
        refY="7"
        markerUnits="userSpaceOnUse"
        markerWidth="9"
        markerHeight="14"
        orient="auto-start-reverse"
      >
        <path d="M1 1L7 7L1 13" stroke="#2D8825" strokeWidth="2" />
      </marker>

      <marker
        id="marker-unconditional"
        viewBox="0 0 9 14"
        refX="7"
        refY="7"
        markerUnits="userSpaceOnUse"
        markerWidth="9"
        markerHeight="14"
        orient="auto-start-reverse"
      >
        <path d="M1 1L7 7L1 13" stroke="#757575" strokeWidth="2" />
      </marker>

      <marker
        id="marker-true"
        viewBox="0 0 9 14"
        refX="7"
        refY="7"
        markerUnits="userSpaceOnUse"
        markerWidth="9"
        markerHeight="14"
        orient="auto-start-reverse"
      >
        <path d="M1 1L7 7L1 13" stroke="#366fe5" strokeWidth="2" />
      </marker>

      <marker
        id="marker-false"
        viewBox="0 0 9 14"
        refX="7"
        refY="7"
        markerUnits="userSpaceOnUse"
        markerWidth="9"
        markerHeight="14"
        orient="auto-start-reverse"
      >
        <path d="M1 1L7 7L1 13" stroke="#ea6d39" strokeWidth="2" />
      </marker>
    </defs>
  </svg>
)
