import {
  GIT_OAUTH_REDIRECT,
  type InitOAuthActionProps,
  type InitOAuthActionResponse
} from './types'
import useInitOAuth from '../../api/hooks/useInitOAuth'
import { useTranslation } from 'react-i18next'
import { useCallback, useEffect, useState } from 'react'
import { useUser } from '@matillion/hub-client'

const useInitOAuthAction = (
  args: InitOAuthActionProps
): InitOAuthActionResponse => {
  const { provider, onSuccess, onFailure } = args
  const [oauthURL, setOAuthURL] = useState<string>()
  const { mutateAsync: initOAuth, isLoading } = useInitOAuth({ provider })
  const { t } = useTranslation('translation', {
    keyPrefix: 'git.oauth.initialise'
  })
  const { organisation } = useUser()

  const doInitOAuth = useCallback(async () => {
    const subdomain = organisation.subdomain
    const originUrl = window.location.origin
    const originNoSubdomain = originUrl.replace(`${subdomain}.`, '')
    const redirectUrl = `${originNoSubdomain}/${GIT_OAUTH_REDIRECT}`

    try {
      const { authorizationUrl } = await initOAuth({
        originCallbackUrl: redirectUrl,
        stateSuffix: subdomain
      })

      onSuccess?.()
      return authorizationUrl
    } catch (e) {
      onFailure?.()
    }
  }, [initOAuth, t, onSuccess, onFailure, organisation])

  useEffect(() => {
    doInitOAuth().then((authorisationURL) => {
      if (authorisationURL) {
        setOAuthURL(authorisationURL)
      }
    })
  }, [])

  return {
    loading: isLoading,
    authorizationURL: oauthURL
  }
}

export default useInitOAuthAction
