import { ChangeEvent, Dispatch } from 'react'
import { useTranslation } from 'react-i18next'

import { Field, Typography } from '@matillion/component-library'
import classNames from 'classnames'

import { useListEnvironments } from 'api/hooks/useListEnvironments'

import {
  Fields,
  FormState,
  ReducerActions
} from 'modules/ManageVariables/types'

import classes from '../Form.module.scss'
import { ReducerAction } from '../formReducer/formReducer'

export const ProjectSpecificFields = ({
  state,
  dispatch
}: {
  state: FormState
  dispatch: Dispatch<ReducerAction>
}) => {
  const { t } = useTranslation()
  const { data: environments } = useListEnvironments()

  return (
    <>
      <Field
        className={classes.Field}
        title={t('manageVariables.jobVariables.fields.defaultValue')}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          dispatch({
            type: ReducerActions.UPDATE_DEFAULT_VALUE,
            field: Fields.DEFAULT_VALUE,
            value: e.target.value
          })
        }}
        value={state.DEFAULT_VALUE.value}
        hasError={state[Fields.DEFAULT_VALUE].isValid === false}
        errorText={
          state[Fields.DEFAULT_VALUE].isValid === false &&
          t(state[Fields.DEFAULT_VALUE].error, {
            field: t('manageVariables.jobVariables.fields.defaultValue')
          })
        }
      />

      <fieldset className={classes.Form__ScopeFieldset}>
        <legend className={classes.Form__Legend}>
          <Typography format="ts">
            {t(
              'manageVariables.jobVariables.fields.environmentDefaultOverride'
            )}
          </Typography>
        </legend>
        <div className={classes.Form__ProjectOverrideField}>
          <Typography
            aria-hidden
            format="bcs"
            weight="bold"
            className={classes.Form__CustomLabel}
          >
            <span>{t('manageVariables.jobVariables.fields.environment')}</span>
          </Typography>
          <Typography
            aria-hidden
            format="bcs"
            weight="bold"
            className={classes.Form__CustomLabel}
          >
            <span>{t('common.value')}</span>
          </Typography>
        </div>

        {environments?.results.map((environment) => {
          const field = state.PROJECT_DEFAULT_OVERRIDES.value?.[environment.id]

          return (
            <Field
              key={environment.id}
              className={classNames(classes.Field, classes.Form__FieldList)}
              onBlur={() => dispatch({ type: ReducerActions.VALIDATE_FORM })}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                dispatch({
                  type: ReducerActions.UPDATE_PROJECT_OVERRIDE,
                  field: Fields.PROJECT_DEFAULT_OVERRIDES,
                  environmentId: environment.id,
                  value: e.target.value
                })
              }}
              title={environment.name}
              value={field?.value ?? ''}
              hasError={field && !field?.isValid}
              errorText={!field?.isValid && t(field?.error ?? '')}
            />
          )
        })}
      </fieldset>
    </>
  )
}
