import { BRANCH_ID } from '__test-utils__/ids'

import { type ProblemDetails } from 'types/ProblemDetails'
import { type CommitResponse } from 'api/hooks/useCommit/types'

const problemDetailBody = (type: string, status: number): ProblemDetails => {
  return {
    type,
    title: 'Some title',
    detail: 'Some message',
    status
  }
}

export const MockCommitResponses: Record<string, CommitResponse> = {
  [BRANCH_ID]: {
    commitId: 'YourCommitID'
  },
  main: {
    commitId: '3cf22a06f0fa3dc6a1304b02485c1c8501e3aba7'
  },
  BranchNoConflicts: {
    commitId: '3cf22a06f0fa3dc6a1304b02485c1c8501e3aba7'
  },
  BranchWithConflicts: {
    commitId: '3cf22a06f0fa3dc6a1304b02485c1c8501e3aba7'
  }
}

export const MockCommitErrorResponse: Record<string, ProblemDetails> =
  {
    '400-commit-message-length': problemDetailBody(
      'working-tree/commit_message_too_long',
      400
    ),
    '400-no-changes': problemDetailBody(
      'working-tree/no_changes_to_commit',
      400
    ),
    '409-branch-behind': problemDetailBody(
      'working-tree/local_branch_behind_remote',
      409
    )
  }
