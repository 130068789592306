import { Cardinality, ConnectionPortType } from 'job-lib/types/Components'
import { JobType } from 'job-lib/types/JobType'

import { ColumnEditorType } from '../../../useGetParameterOptions/types'
import {
  ComponentEditorTabType,
  ComponentMetadataResponse,
  EditorType,
  ExportVariableType,
  LookUpType,
  ParameterDataType,
  UseVariable,
  VisibleWhenOperator
} from '../../types'

export const mockMarketoQueryMetadata: ComponentMetadataResponse = {
  componentId: 'marketo-query',
  metadata: {
    schemaVersion: 1,
    emeraldId: '-1608038798',
    componentType: JobType.Orchestration,
    inputPorts: [
      {
        portId: ConnectionPortType.INPUT,
        cardinality: Cardinality.ONE
      }
    ],
    outputPorts: [
      {
        portId: ConnectionPortType.SUCCESS,
        cardinality: Cardinality.MANY
      },
      {
        portId: ConnectionPortType.UNCONDITIONAL,
        cardinality: Cardinality.MANY
      },
      {
        portId: ConnectionPortType.FAILURE,
        cardinality: Cardinality.MANY
      }
    ],
    deprecated: false,
    exports: [
      ExportVariableType.COMPLETED_AT,
      ExportVariableType.COMPONENT_NAME,
      ExportVariableType.DURATION,
      ExportVariableType.FILENAME,
      ExportVariableType.MESSAGE,
      ExportVariableType.ROW_COUNT,
      ExportVariableType.STARTED_AT,
      ExportVariableType.STATUS,
      ExportVariableType.TIME_TAKEN_TO_LOAD,
      ExportVariableType.TIME_TAKEN_TO_STAGE
    ],
    editorTabs: [
      ComponentEditorTabType.PROPERTIES,
      ComponentEditorTabType.SAMPLE,
      ComponentEditorTabType.SQL,
      ComponentEditorTabType.EXPORT,
      ComponentEditorTabType.HELP
    ],
    parameters: [
      {
        id: 'component-name',
        dplID: 'componentName',
        resourceID: 'componentName',
        metlSlot: 1,
        useVariable: UseVariable.NO,
        dataType: ParameterDataType.TEXT,
        authProvider: null,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        validationDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null,
        secretTypes: [],
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        id: 'basic-advanced-mode',
        dplID: 'basicAdvancedMode',
        resourceID: 'basicAdvancedMode',
        metlSlot: 2,
        useVariable: UseVariable.NO,
        dataType: ParameterDataType.TEXT,
        authProvider: null,
        editorType: EditorType.DROPDOWN,
        lookupType: null,
        lookupDependencies: null,
        validationDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: [
          {
            name: null,
            resourceID: null,
            type: 'TEXT',
            options: ['Advanced', 'Basic'],
            columnEditorType: ColumnEditorType.Dropdown,
            defaultValue: 'Basic',
            lookupType: null,
            lookupDependencies: null
          }
        ],
        defaultValue: null,
        secretTypes: [],
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        id: 'authentication',
        dplID: 'authentication',
        resourceID: 'authentication',
        metlSlot: 3,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT_SECRET_REF,
        authProvider: 'marketo',
        editorType: EditorType.OAUTH,
        lookupType: LookUpType.DESIGNER_OAUTH,
        lookupDependencies: ['this.secretTypes:renamedKey'],
        validationDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null,
        secretTypes: ['OAUTH_CLIENT_CRED'],
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        id: 'rest-endpoint',
        dplID: 'restEndpoint',
        resourceID: 'restEndpoint',
        metlSlot: 500,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        authProvider: null,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        validationDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null,
        secretTypes: [],
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        id: 'connection-options',
        dplID: 'connectionOptions',
        resourceID: 'connectionOptions',
        metlSlot: 12,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.GRID,
        authProvider: null,
        editorType: EditorType.GRID_EDITOR,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        validationDependencies: null,
        visibleWhen: null,
        optional: true,
        staticOptions: null,
        defaultValue: null,
        secretTypes: [],
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        id: 'data-schema',
        dplID: 'dataSchema',
        resourceID: 'dataSchema',
        metlSlot: 20,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        authProvider: null,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        validationDependencies: null,
        visibleWhen: [
          {
            param: 'basicAdvancedMode',
            value: 'Basic',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null,
        secretTypes: [],
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        id: 'data-source',
        dplID: 'dataSource',
        resourceID: 'dataSource',
        metlSlot: 6,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        authProvider: null,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        validationDependencies: null,
        visibleWhen: [
          {
            param: 'basicAdvancedMode',
            value: 'Basic',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null,
        secretTypes: [],
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        id: 'data-selection',
        dplID: 'dataSelection',
        resourceID: 'dataSelection',
        metlSlot: 7,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.LIST,
        authProvider: null,
        editorType: EditorType.MULTI_OPTION_SELECTOR,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        validationDependencies: null,
        visibleWhen: [
          {
            param: 'basicAdvancedMode',
            value: 'Basic',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null,
        secretTypes: [],
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        id: 'data-source-filter',
        dplID: 'dataSourceFilter',
        resourceID: 'dataSourceFilter',
        metlSlot: 8,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.GRID,
        authProvider: null,
        editorType: EditorType.GRID_EDITOR,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        validationDependencies: null,
        visibleWhen: [
          {
            param: 'basicAdvancedMode',
            value: 'Basic',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: true,
        staticOptions: null,
        defaultValue: null,
        secretTypes: [],
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        id: 'combine-filters',
        dplID: 'combineFilters',
        resourceID: 'combineFilters',
        metlSlot: 9,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        authProvider: null,
        editorType: EditorType.DROPDOWN,
        lookupType: null,
        lookupDependencies: null,
        validationDependencies: null,
        visibleWhen: [
          {
            param: 'basicAdvancedMode',
            value: 'Basic',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: [
          {
            name: null,
            resourceID: null,
            type: 'TEXT',
            options: ['And', 'Or'],
            columnEditorType: ColumnEditorType.Dropdown,
            defaultValue: 'And',
            lookupType: null,
            lookupDependencies: null
          }
        ],
        defaultValue: null,
        secretTypes: [],
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        id: 'sql-query',
        dplID: 'sqlQuery',
        resourceID: 'sqlQuery',
        metlSlot: 10,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT_SQL,
        authProvider: null,
        editorType: EditorType.SQL_EDITOR_ADVANCED,
        lookupType: null,
        lookupDependencies: null,
        validationDependencies: null,
        visibleWhen: [
          {
            param: 'basicAdvancedMode',
            value: 'Advanced',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: 'SELECT ...',
        secretTypes: [],
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        id: 'limit',
        dplID: 'limit',
        resourceID: 'limit',
        metlSlot: 11,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        authProvider: null,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        validationDependencies: null,
        visibleWhen: [
          {
            param: 'basicAdvancedMode',
            value: 'Basic',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: true,
        staticOptions: null,
        defaultValue: '100',
        secretTypes: [],
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        id: 'type',
        dplID: 'type',
        resourceID: 'type',
        metlSlot: 2000,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        authProvider: null,
        editorType: EditorType.DROPDOWN,
        lookupType: null,
        lookupDependencies: null,
        validationDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: [
          {
            name: '',
            resourceID: '',
            type: 'TEXT',
            options: ['Standard'],
            columnEditorType: ColumnEditorType.Dropdown,
            defaultValue: 'Standard',
            lookupType: null,
            lookupDependencies: null
          }
        ],
        defaultValue: 'Standard',
        secretTypes: [],
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        id: 'primary-keys',
        dplID: 'primaryKeys',
        resourceID: 'primaryKeys',
        metlSlot: 18,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.LIST,
        authProvider: null,
        editorType: EditorType.MULTI_OPTION_SELECTOR,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        validationDependencies: null,
        visibleWhen: [
          {
            param: 'type',
            value: 'Standard',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: true,
        staticOptions: null,
        defaultValue: null,
        secretTypes: [],
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        id: 'warehouse-1',
        dplID: 'warehouse1',
        resourceID: 'warehouse',
        metlSlot: 16,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        authProvider: null,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        validationDependencies: null,
        visibleWhen: [
          {
            param: 'type',
            value: 'Standard',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: '[Environment Default]',
        secretTypes: [],
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        id: 'database',
        dplID: 'database',
        resourceID: 'database',
        metlSlot: 13,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        authProvider: null,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        validationDependencies: null,
        visibleWhen: [
          {
            param: 'type',
            value: 'Standard',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: '[Environment Default]',
        secretTypes: [],
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        id: 'schema',
        dplID: 'schema',
        resourceID: 'schema',
        metlSlot: 14,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        authProvider: null,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        validationDependencies: null,
        visibleWhen: [
          {
            param: 'type',
            value: 'Standard',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: '[Environment Default]',
        secretTypes: [],
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        id: 'target-table',
        dplID: 'targetTable',
        resourceID: 'targetTable',
        metlSlot: 15,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        authProvider: null,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        validationDependencies: null,
        visibleWhen: [
          {
            param: 'type',
            value: 'Standard',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null,
        secretTypes: [],
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        id: 'stage-1',
        dplID: 'stage1',
        resourceID: 'stage',
        metlSlot: 88345,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        authProvider: null,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        validationDependencies: null,
        visibleWhen: [
          {
            param: 'type',
            value: 'Standard',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: '[Custom]',
        secretTypes: [],
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        id: 'stage-platform',
        dplID: 'stagePlatform',
        resourceID: 'stagePlatform',
        metlSlot: 88341,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        authProvider: null,
        editorType: EditorType.DROPDOWN,
        lookupType: null,
        lookupDependencies: null,
        validationDependencies: null,
        visibleWhen: [
          {
            param: 'stage1',
            value: '[Custom]',
            operator: VisibleWhenOperator.EQUALS
          },
          {
            param: 'type',
            value: 'Standard',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: [
          {
            name: null,
            resourceID: null,
            type: 'TEXT',
            options: ['Existing Amazon S3 Location', 'Snowflake Managed'],
            columnEditorType: ColumnEditorType.Dropdown,
            defaultValue: 'Existing Amazon S3 Location',
            lookupType: null,
            lookupDependencies: null
          }
        ],
        defaultValue: null,
        secretTypes: [],
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        id: 'stage-authentication',
        dplID: 'stageAuthentication',
        resourceID: 'stageAuthentication',
        metlSlot: 88342,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        authProvider: null,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        validationDependencies: null,
        visibleWhen: [
          {
            param: 'stagePlatform',
            value: 'Existing Amazon S3 Location',
            operator: VisibleWhenOperator.EQUALS
          },
          {
            param: 'stage1',
            value: '[Custom]',
            operator: VisibleWhenOperator.EQUALS
          },
          {
            param: 'type',
            value: 'Standard',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: 'Credentials',
        secretTypes: [],
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        id: 'storage-integration',
        dplID: 'storageIntegration',
        resourceID: 'storageIntegration',
        metlSlot: 88343,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        authProvider: null,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        validationDependencies: null,
        visibleWhen: [
          {
            param: 'stagePlatform',
            value: 'Existing Amazon S3 Location',
            operator: VisibleWhenOperator.EQUALS
          },
          {
            param: 'stage1',
            value: '[Custom]',
            operator: VisibleWhenOperator.EQUALS
          },
          {
            param: 'stageAuthentication',
            value: 'Storage Integration',
            operator: VisibleWhenOperator.EQUALS
          },
          {
            param: 'type',
            value: 'Standard',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null,
        secretTypes: [],
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        id: 's3-staging-area',
        dplID: 's3StagingArea',
        resourceID: 's3StagingArea',
        metlSlot: 17,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        authProvider: null,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        validationDependencies: null,
        visibleWhen: [
          {
            param: 'stagePlatform',
            value: 'Existing Amazon S3 Location',
            operator: VisibleWhenOperator.EQUALS
          },
          {
            param: 'stage1',
            value: '[Custom]',
            operator: VisibleWhenOperator.EQUALS
          },
          {
            param: 'type',
            value: 'Standard',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null,
        secretTypes: [],
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        id: 'use-accelerated-endpoint-1',
        dplID: 'useAcceleratedEndpoint1',
        resourceID: 'useAcceleratedEndpoint',
        metlSlot: 88344,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        authProvider: null,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        validationDependencies: null,
        visibleWhen: [
          {
            param: 'stagePlatform',
            value: 'Existing Amazon S3 Location',
            operator: VisibleWhenOperator.EQUALS
          },
          {
            param: 'stage1',
            value: '[Custom]',
            operator: VisibleWhenOperator.EQUALS
          },
          {
            param: null,
            value: null,
            operator: VisibleWhenOperator.DYNAMIC_VISIBILITY_LOOKUP
          },
          {
            param: 'type',
            value: 'Standard',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: 'False',
        secretTypes: [],
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        id: 'encryption',
        dplID: 'encryption',
        resourceID: 'encryption',
        metlSlot: 40000,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        authProvider: null,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        validationDependencies: null,
        visibleWhen: [
          {
            param: 'stagePlatform',
            value: 'Existing Amazon S3 Location',
            operator: VisibleWhenOperator.EQUALS
          },
          {
            param: 'stage1',
            value: '[Custom]',
            operator: VisibleWhenOperator.EQUALS
          },
          {
            param: 'type',
            value: 'Standard',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: 'None',
        secretTypes: [],
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        id: 'kms-key-id',
        dplID: 'kmsKeyId',
        resourceID: 'kmsKeyId',
        metlSlot: 40001,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        authProvider: null,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        validationDependencies: null,
        visibleWhen: [
          {
            param: 'stagePlatform',
            value: 'Existing Amazon S3 Location',
            operator: VisibleWhenOperator.EQUALS
          },
          {
            param: 'stage1',
            value: '[Custom]',
            operator: VisibleWhenOperator.EQUALS
          },
          {
            param: 'encryption',
            value: 'SSE KMS',
            operator: VisibleWhenOperator.EQUALS
          },
          {
            param: 'type',
            value: 'Standard',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null,
        secretTypes: [],
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        id: 'stage-authentication-1',
        dplID: 'stageAuthentication1',
        resourceID: 'stageAuthentication',
        metlSlot: 63319,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        authProvider: null,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        validationDependencies: null,
        visibleWhen: [
          {
            param: 'stagePlatform',
            value: 'Existing Azure Blob Storage Location',
            operator: VisibleWhenOperator.EQUALS
          },
          {
            param: 'stage1',
            value: '[Custom]',
            operator: VisibleWhenOperator.EQUALS
          },
          {
            param: 'type',
            value: 'Standard',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: 'Credentials',
        secretTypes: [],
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        id: 'storage-integration-1',
        dplID: 'storageIntegration1',
        resourceID: 'storageIntegration',
        metlSlot: 63322,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        authProvider: null,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        validationDependencies: null,
        visibleWhen: [
          {
            param: 'stagePlatform',
            value: 'Existing Azure Blob Storage Location',
            operator: VisibleWhenOperator.EQUALS
          },
          {
            param: 'stage1',
            value: '[Custom]',
            operator: VisibleWhenOperator.EQUALS
          },
          {
            param: 'stageAuthentication1',
            value: 'Storage Integration',
            operator: VisibleWhenOperator.EQUALS
          },
          {
            param: 'type',
            value: 'Standard',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null,
        secretTypes: [],
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        id: 'storage-account',
        dplID: 'storageAccount',
        resourceID: 'storageAccount',
        metlSlot: 63320,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        authProvider: null,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        validationDependencies: null,
        visibleWhen: [
          {
            param: 'stagePlatform',
            value: 'Existing Azure Blob Storage Location',
            operator: VisibleWhenOperator.EQUALS
          },
          {
            param: 'stage1',
            value: '[Custom]',
            operator: VisibleWhenOperator.EQUALS
          },
          {
            param: 'type',
            value: 'Standard',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null,
        secretTypes: [],
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        id: 'blob-container',
        dplID: 'blobContainer',
        resourceID: 'blobContainer',
        metlSlot: 63321,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        authProvider: null,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        validationDependencies: null,
        visibleWhen: [
          {
            param: 'stagePlatform',
            value: 'Existing Azure Blob Storage Location',
            operator: VisibleWhenOperator.EQUALS
          },
          {
            param: 'stage1',
            value: '[Custom]',
            operator: VisibleWhenOperator.EQUALS
          },
          {
            param: 'type',
            value: 'Standard',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null,
        secretTypes: [],
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        id: 'storage-integration-2',
        dplID: 'storageIntegration2',
        resourceID: 'storageIntegration',
        metlSlot: 84533,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        authProvider: null,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        validationDependencies: null,
        visibleWhen: [
          {
            param: 'stagePlatform',
            value: 'Existing Google Cloud Storage Location',
            operator: VisibleWhenOperator.EQUALS
          },
          {
            param: 'stage1',
            value: '[Custom]',
            operator: VisibleWhenOperator.EQUALS
          },
          {
            param: 'type',
            value: 'Standard',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null,
        secretTypes: [],
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        id: 'gcs-staging-area',
        dplID: 'gcsStagingArea',
        resourceID: 'gcsStagingArea',
        metlSlot: 98776,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        authProvider: null,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        validationDependencies: null,
        visibleWhen: [
          {
            param: 'stagePlatform',
            value: 'Existing Google Cloud Storage Location',
            operator: VisibleWhenOperator.EQUALS
          },
          {
            param: 'stage1',
            value: '[Custom]',
            operator: VisibleWhenOperator.EQUALS
          },
          {
            param: 'type',
            value: 'Standard',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null,
        secretTypes: [],
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        id: '-2',
        dplID: '2',
        resourceID: '',
        metlSlot: 40501,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        authProvider: null,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        validationDependencies: null,
        visibleWhen: [
          {
            param: null,
            value: null,
            operator: VisibleWhenOperator.NEVER
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null,
        secretTypes: [],
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        id: 'load-options',
        dplID: 'loadOptions',
        resourceID: 'loadOptions',
        metlSlot: 40502,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.GRID,
        authProvider: null,
        editorType: EditorType.MULTI_PROPERTIES_EDITOR,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        validationDependencies: null,
        visibleWhen: [
          {
            param: 'type',
            value: 'Standard',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: true,
        staticOptions: null,
        defaultValue: null,
        secretTypes: [],
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        id: 'warehouse',
        dplID: 'warehouse',
        resourceID: 'warehouse',
        metlSlot: 1992,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        authProvider: null,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        validationDependencies: null,
        visibleWhen: [
          {
            param: 'type',
            value: 'External',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: '[Environment Default]',
        secretTypes: [],
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        id: 'database-1',
        dplID: 'database1',
        resourceID: 'database',
        metlSlot: 1999,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        authProvider: null,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        validationDependencies: null,
        visibleWhen: [
          {
            param: 'type',
            value: 'External',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: '[Environment Default]',
        secretTypes: [],
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        id: 'schema-1',
        dplID: 'schema1',
        resourceID: 'schema',
        metlSlot: 1998,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        authProvider: null,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        validationDependencies: null,
        visibleWhen: [
          {
            param: 'type',
            value: 'External',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: '[Environment Default]',
        secretTypes: [],
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        id: 'new-table-name',
        dplID: 'newTableName',
        resourceID: 'newTableName',
        metlSlot: 1997,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        authProvider: null,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        validationDependencies: null,
        visibleWhen: [
          {
            param: 'type',
            value: 'External',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null,
        secretTypes: [],
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        id: 'stage-database',
        dplID: 'stageDatabase',
        resourceID: 'stageDatabase',
        metlSlot: 1996,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        authProvider: null,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        validationDependencies: null,
        visibleWhen: [
          {
            param: 'type',
            value: 'External',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: '[Environment Default]',
        secretTypes: [],
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        id: 'stage-schema',
        dplID: 'stageSchema',
        resourceID: 'stageSchema',
        metlSlot: 1995,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        authProvider: null,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        validationDependencies: null,
        visibleWhen: [
          {
            param: 'type',
            value: 'External',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: '[Environment Default]',
        secretTypes: [],
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        id: 'stage',
        dplID: 'stage',
        resourceID: 'stage',
        metlSlot: 1994,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        authProvider: null,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        validationDependencies: null,
        visibleWhen: [
          {
            param: 'type',
            value: 'External',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null,
        secretTypes: [],
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        id: 'use-accelerated-endpoint',
        dplID: 'useAcceleratedEndpoint',
        resourceID: 'useAcceleratedEndpoint',
        metlSlot: 1993,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        authProvider: null,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        validationDependencies: null,
        visibleWhen: [
          {
            param: null,
            value: null,
            operator: VisibleWhenOperator.DYNAMIC_VISIBILITY_LOOKUP
          },
          {
            param: 'type',
            value: 'External',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: 'False',
        secretTypes: [],
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        id: 'auto-debug',
        dplID: 'autoDebug',
        resourceID: 'autoDebug',
        metlSlot: 1013,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        authProvider: null,
        editorType: EditorType.DROPDOWN,
        lookupType: null,
        lookupDependencies: null,
        validationDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: [
          {
            name: null,
            resourceID: null,
            type: 'TEXT',
            options: ['Off', 'On'],
            columnEditorType: ColumnEditorType.Dropdown,
            defaultValue: 'Off',
            lookupType: null,
            lookupDependencies: null
          }
        ],
        defaultValue: null,
        secretTypes: [],
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        id: 'debug-level',
        dplID: 'debugLevel',
        resourceID: 'debugLevel',
        metlSlot: 1014,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        authProvider: null,
        editorType: EditorType.DROPDOWN,
        lookupType: null,
        lookupDependencies: null,
        validationDependencies: null,
        visibleWhen: [
          {
            param: 'autoDebug',
            value: 'On',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: [
          {
            name: null,
            resourceID: null,
            type: 'TEXT',
            options: ['1', '2', '3', '4', '5'],
            columnEditorType: ColumnEditorType.Dropdown,
            defaultValue: '3',
            lookupType: null,
            lookupDependencies: null
          }
        ],
        defaultValue: null,
        secretTypes: [],
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        id: '-1',
        dplID: '1',
        resourceID: '',
        metlSlot: 1001,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        authProvider: null,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        validationDependencies: null,
        visibleWhen: [
          {
            param: null,
            value: null,
            operator: VisibleWhenOperator.NEVER
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null,
        secretTypes: [],
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      }
    ]
  }
}
