import { FunctionComponent, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Panel, PanelGroup } from 'react-resizable-panels'

import { useFlags } from 'hooks/useFlags'

import PipelineBrowser from 'modules/PipelineBrowser/PipelineBrowser'

import { ComponentBrowser } from './components/ComponentBrowser/ComponentBrowser'
import { ComponentsList } from './components/ComponentsList'
import { PanelWrapper } from './components/PanelWrapper'
import SchemaBrowser from './components/SchemaBrowser'
import SchemaMenu from './components/SchemaMenu/SchemaMenu'
import { SimpleRefetchRef } from './types'

const SideBar: FunctionComponent = () => {
  const { enableNewAddComponentsExperience } = useFlags()
  const { t } = useTranslation()
  const schemaMenuRef = useRef<SimpleRefetchRef>(null)

  return (
    <PanelGroup direction="vertical" autoSaveId="sidebar">
      <Panel order={1} id="pipelines">
        <PipelineBrowser data-testid="pipeline-browser" />
      </Panel>

      <PanelWrapper
        id="components"
        header={
          enableNewAddComponentsExperience
            ? t('sideBar.componentPanel.addComponents')
            : t('sideBar.componentPanel.components')
        }
        order={2}
        searchEnabled={!enableNewAddComponentsExperience}
      >
        {enableNewAddComponentsExperience ? (
          <ComponentsList />
        ) : (
          <ComponentBrowser />
        )}
      </PanelWrapper>

      <PanelWrapper
        id="schemas"
        header={t('sideBar.schemaPanel.title')}
        order={3}
        contextMenu={
          <SchemaMenu refetch={() => schemaMenuRef.current?.refetch()} />
        }
        collapsed
      >
        <SchemaBrowser ref={schemaMenuRef} />
      </PanelWrapper>
    </PanelGroup>
  )
}

export default SideBar
