import { Parameter } from './useParameterOptions'

interface DestructuredLookupDependencies {
  paramName?: string
  rename?: string
}

export type ParameterMetadataDependencies = Record<
  string,
  Parameter[keyof Parameter]
> | null

export const destructedParameterMetadataDependencies = (
  dependency: string
): DestructuredLookupDependencies => {
  const regex = /^this\.([^.:]+)(?:[.:](.+))?$/
  const match = regex.exec(dependency)

  if (!match) {
    // If the string doesn't match the expected format, in this case "this" only
    // In the future we might add more patterns
    return {}
  }

  const [, paramName, rename] = match
  return { paramName, rename }
}

export const getParameterMetadataDependencies = (
  parameter: Parameter | undefined
): ParameterMetadataDependencies | null => {
  if (!parameter) {
    return null
  }

  const lookupDependencies = parameter.lookupDependencies
  if (!lookupDependencies) {
    return null
  }

  return lookupDependencies.reduce<Record<string, Parameter[keyof Parameter]>>(
    (output, dep) => {
      const { paramName, rename } = destructedParameterMetadataDependencies(dep)
      const key = paramName as keyof typeof parameter

      if (paramName && parameter[key]) {
        /* istanbul ignore next */
        output[rename ?? paramName] = parameter[key]
      }

      return output
    },
    {}
  )
}
