import { useStubDescribeMerge } from 'api/hooks/useDescribeMerge/useDescribeMerge.handlers'
import { useStubMerge } from 'api/hooks/useMerge/useMerge.handlers'
import { useStubGetBranch } from 'api/hooks/useGetBranch/useGetBranch.handlers'
import { type GitMswHandlerConfig } from './types'
import { PROJECT_ID } from '__test-utils__/ids'
import { type RequestHandler } from 'msw'
import { useStubDescribePullChanges } from 'api/hooks/useDescribePullChanges/useDescribePullChanges.handlers'
import { useStubPullChanges } from 'api/hooks/usePullChanges/usePullChanges.handlers'
import { useStubGetWorkingTreeBranch } from 'api/hooks/useGetWorkingTreeBranch/useGetWorkingTreeBranch.handlers'
import { useStubHardResetBranch } from 'api/hooks/useDeleteBranch/useDeleteBranch.handlers'
import { useStubInitOAuth } from 'api/hooks/useInitOAuth/useInitOAuth.handlers'
import { useStubCaptureOAuthToken } from 'api/hooks/useCaptureOAuthToken/useCaptureOAuthToken.handlers'
import { useStubGetRepositories } from 'api/hooks/useGetRepositories/useGetRepositories.handlers'

const gitComponentLibraryMswHandlers = (
  config?: Partial<GitMswHandlerConfig>
): RequestHandler[] => {
  const projectId = config?.project ?? PROJECT_ID

  const designerEndpoints = [
    useStubDescribeMerge,
    useStubGetBranch,
    useStubMerge
  ].flatMap((func) => {
    return func(config?.designerBaseUrl ?? '', projectId)
  })

  const workingTreeEndpoints = [
    useStubGetWorkingTreeBranch,
    useStubDescribePullChanges,
    useStubPullChanges,
    useStubHardResetBranch
  ].flatMap((func) => {
    return func(config?.workingTreeBaseUrl ?? '', projectId)
  })

  const gitServiceEndpoints = [
    useStubInitOAuth,
    useStubCaptureOAuthToken,
    useStubGetRepositories
  ].flatMap((func) => {
    return func(config?.gitServiceBaseUrl ?? '')
  })

  return [...designerEndpoints, ...workingTreeEndpoints, ...gitServiceEndpoints]
}

export default gitComponentLibraryMswHandlers
