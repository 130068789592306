import { FC } from 'react'

export const RemoveIcon: FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="12" fill="#2D8825" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 13H7V11H17V13Z"
      fill="white"
    />
  </svg>
)
