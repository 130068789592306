import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Modal, Toaster } from '@matillion/component-library'

import classes from './CommitModal.module.scss'

import BehindRemoteForm from '../BehindRemoteForm/BehindRemoteForm'
import CommitForm from './CommitForm'
import useGitContext from 'provider/GitProvider/useGitContext'
import { type DescribeMergeConflict } from 'api/hooks/useDescribeMerge/types'
import { type ConflictResolutionSelection } from '../ConflictResolutionTable/types'
import Loading from '../Loading'
import ConflictResolutionForm from '../ConflictsModal/ConflictResolutionForm'
import { type CommitModalProps } from './types'
import useIsBehindRemote from 'hooks/useIsBehindRemote/useIsBehindRemote'
import usePullAction from 'hooks/usePullAction/usePullAction'

const ModalContent = ({
  environments,
  onPublishPipelines,
  onPullChanges,
  onClose
}: CommitModalProps) => {
  const { branch, featureFlags, isNativeGit } = useGitContext()
  const { makeToast } = Toaster.useToaster()
  const { t } = useTranslation('translation', { keyPrefix: 'git.commit' })

  const handleIsBehindFailure = () => {
    makeToast({
      type: 'error',
      title: t('toast.is-behind.title'),
      message: t('toast.is-behind.message')
    })

    onClose?.()
  }

  const { isBehindRemote, isLoading: isBehindLoading } = useIsBehindRemote({
    branchName: branch,
    enabled: !isNativeGit,
    onFailure: handleIsBehindFailure
  })
  const [isBehind, setIsBehind] = useState(isBehindRemote)
  const [conflicts, setConflicts] = useState<DescribeMergeConflict[]>([])

  useEffect(() => {
    setIsBehind(isBehindRemote)
  }, [isBehindRemote])

  const handlePullSuccess = useCallback(() => {
    setConflicts([])
    onPullChanges?.()
    setIsBehind(false)
  }, [])

  const handlePullConflict = useCallback(
    (pullConflicts: DescribeMergeConflict[]) => {
      setConflicts(pullConflicts)
    },
    []
  )

  const {
    pullRemoteChanges,
    resolvePullConflicts,
    isLoading: isPullLoading
  } = usePullAction({
    onSuccess: handlePullSuccess,
    onConflict: handlePullConflict
  })

  const handleResolvedConflicts = (
    resolutions: ConflictResolutionSelection[]
  ) => {
    setConflicts([])
    resolvePullConflicts(resolutions)
  }

  const handleCancelConflictResolution = () => {
    makeToast({
      type: 'info',
      title: t('toast.dismiss.title'),
      message: t('toast.dismiss.message')
    })
    onClose?.()
  }

  const handleClose = () => {
    onClose?.()
  }

  if (!featureFlags.pullChangesV2) {
    return (
      <CommitForm
        branchId={branch}
        onClose={handleClose}
        environments={environments}
        onPublishPipelines={onPublishPipelines}
      />
    )
  }

  if (isBehindLoading) {
    return <Loading />
  }

  const hasConflicts = conflicts.length > 0

  if (isBehind && !hasConflicts && !isNativeGit) {
    return (
      <BehindRemoteForm
        onCancel={handleClose}
        loading={isPullLoading}
        onPull={pullRemoteChanges}
      />
    )
  }

  if (hasConflicts) {
    return (
      <ConflictResolutionForm
        submit={t('resolve')}
        conflicts={conflicts}
        onResolve={handleResolvedConflicts}
        onCancel={handleCancelConflictResolution}
      />
    )
  }

  return (
    <CommitForm
      branchId={branch}
      onClose={handleClose}
      environments={environments}
      onPublishPipelines={onPublishPipelines}
    />
  )
}

const CommitModal = ({
  environments,
  onPublishPipelines,
  onPullChanges,
  onClose
}: CommitModalProps) => {
  const handleClose = () => {
    onClose?.()
  }

  return (
    <Modal
      size="mid"
      id="commit-dialog"
      disableBackdropClick
      onCancel={handleClose}
      ariaLabelledBy="commit-title"
      className={classes.CommitModal}
    >
      <ModalContent
        onClose={onClose}
        environments={environments}
        onPullChanges={onPullChanges}
        onPublishPipelines={onPublishPipelines}
      />
    </Modal>
  )
}

export default CommitModal
