import { UseQueryResult } from '@tanstack/react-query'

import { State, ValidationTaskStatus } from 'api/hooks/useGetRunTasks/types'
import { ValidateComponentResponse } from 'api/hooks/useValidateComponent/types'

import { MAX_HISTORY_PERSISTENCE } from 'modules/TaskHistoryPanel/components/TaskHistory/history.utilities'

type DataFromValidateComponentResponse = Pick<
  UseQueryResult<ValidateComponentResponse | undefined>,
  'data'
>

export const updateValidationStateFrom =
  (validationResults: DataFromValidateComponentResponse[]) =>
  (currentState: ValidationTaskStatus[]) => {
    const newState = [...currentState]
    const currentValidation = newState.pop()
    const now = Date.now()

    // remove validations that are too old
    const filteredState = newState.filter(
      (e) => now - e.enqueuedTime < MAX_HISTORY_PERSISTENCE
    )

    if (currentValidation) {
      const isValid = validationResults.every(
        (result) => result.data?.status === 'VALID'
      )

      currentValidation.state = isValid ? State.Success : State.Failed
      currentValidation.endTime = now
      filteredState.push(currentValidation)
    }

    return filteredState
  }

export const newValidationStateWith =
  (now: number, jobName: string, environmentName: string) =>
  (currentState: ValidationTaskStatus[]) =>
    [
      ...currentState,
      {
        id: now,
        jobName: jobName,
        environmentName: environmentName,
        enqueuedTime: now,
        startTime: now,
        endTime: null,
        state: State.Running
      }
    ]
