import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { PopOverItem, PopOverSection } from 'components/PopOverMenu'

import { useFlags } from 'hooks/useFlags'

import { usePipelineBrowserCommandProvider } from 'modules/PipelineBrowser/effects/usePipelineBrowserCommand'

import { PipelineTreeReducerType } from '../../reducers/pipelineTreeReducer/types'
import CreatePipelineContextMenuEntries from '../CreatePipelineContextMenuEntries/CreatePipelineContextMenuEntries'

const PipelinePanelContextMenu: FC = () => {
  const { t } = useTranslation()
  const { pipelineFolders } = useFlags()
  const { onPipelineTreeCommand } = usePipelineBrowserCommandProvider()

  const handleCreateFolder = () => {
    onPipelineTreeCommand({
      path: '',
      type: PipelineTreeReducerType.CREATE_FOLDER
    })
  }

  return (
    <PopOverSection>
      <CreatePipelineContextMenuEntries />

      {pipelineFolders && (
        <PopOverItem
          onClick={handleCreateFolder}
          data-testid="job-action-create-folder"
        >
          {t('jobContextMenu.createFolder')}
        </PopOverItem>
      )}
    </PopOverSection>
  )
}

export default PipelinePanelContextMenu
