import { FunctionComponent, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Alert, Button, DataGridRow } from '@matillion/component-library'
import classNames from 'classnames'
import { cloneDeep, mapValues } from 'lodash'

import {
  ComponentMetadata,
  ComponentParameter,
  ParameterDataType
} from 'api/hooks/useGetComponentMetadata/types'
import { ComponentSummaryId } from 'api/hooks/useGetComponentTree'
import { ProblemDetails } from 'api/types/http-problem-details'

import { ReactComponent as AddIcon } from 'assets/add-plus-icon.svg'
import { ReactComponent as DeleteIcon } from 'assets/delete-minus-icon.svg'
import { ReactComponent as DisabledAddIcon } from 'assets/disabled-add-plus-icon.svg'
import { ReactComponent as DisabledDeleteIcon } from 'assets/disabled-delete-minus-icon.svg'

import {
  ParameterOverlayButton,
  ParameterOverlayErrors,
  ParameterOverlayFooter,
  ParameterOverlayHeader
} from 'components/ParameterOverlay'

import { ComponentInstanceId } from 'job-lib/types/Job'
import { ElementCollection } from 'job-lib/types/Parameters'

import { ParameterOptions } from '../../types'
import classes from '../MultiOptionSelector/MultiOptionSelector.module.scss'
import { Grid } from './components/Grid/Grid'
import { GridCell, UpdateRows } from './components/Grid/types'
import {
  generateBlobRow,
  getHighestRowSlot,
  init
} from './components/Grid/utils'
import gridEditorClasses from './GridEditor.module.scss'

export interface GridEditorProps extends ParameterOptions {
  parameterName: string
  parameter: ComponentParameter
  componentMetaData: ComponentMetadata
  componentInstanceId: ComponentInstanceId
  componentSummaryId: ComponentSummaryId
  elements: ElementCollection
  problemDetail?: ProblemDetails | null
  onDone: (editedValue: ElementCollection) => void
}

export const GridEditor: FunctionComponent<GridEditorProps> = ({
  componentMetaData,
  componentInstanceId,
  componentSummaryId,
  editorColumns,
  elements,
  parameter,
  parameterName,
  problemDetail,
  onDone
}) => {
  const { t } = useTranslation()
  const [rows, setRows] = useState(() => init(elements, editorColumns))
  const [selectedRows, setSelectedRows] = useState<string[]>([])

  const hasColumns = editorColumns.length > 0

  const handleSubmit = useCallback(() => {
    const newElements: ElementCollection = {}

    rows.forEach(({ cells }, index) => {
      // paramSlot needs to be index based on submission
      // to account for the removal of rows in the job object
      const paramSlot = index + 1

      newElements[paramSlot] = {
        slot: paramSlot,
        values: {}
      }
      Object.entries(cells).forEach(([columnId, cell]) => {
        const { type, value, slot, dataType } = cell

        newElements[paramSlot].values[slot] = {
          slot,
          value: String(value),
          type,
          dataType: dataType as ParameterDataType
        }
      })
    })

    onDone(newElements)
  }, [onDone, rows])

  const updateRows: UpdateRows = useCallback(
    ({ rowSlot, slot, value, type, dataType }: GridCell) => {
      setRows((prevRows) => {
        const newRows = cloneDeep(prevRows)
        newRows[rowSlot - 1].cells[slot] = {
          type,
          value,
          slot,
          rowSlot,
          dataType
        }

        return newRows
      })
    },
    []
  )

  const deleteRows = () => {
    setRows((previousRows) => {
      const updatedRows = previousRows.filter(
        (row) => !selectedRows.includes(row.id)
      )

      const reindexedRows = updatedRows.map((row, index) => ({
        ...row,
        id: `${index + 1}-${Date.now().toString()}`,
        cells: mapValues(row.cells, (cell: GridCell) => ({
          ...cell,
          rowSlot: index + 1
        }))
      }))

      return reindexedRows
    })

    setSelectedRows([])
  }

  const addRow = useCallback(() => {
    const newRowSlot = getHighestRowSlot(rows)
    setRows((prevState) => [
      ...prevState,
      generateBlobRow(newRowSlot, editorColumns)
    ])
  }, [setRows, editorColumns, rows])

  return (
    <>
      <ParameterOverlayHeader title={parameterName} />
      {problemDetail?.detail && (
        <Alert
          aria-label={problemDetail.detail}
          className={gridEditorClasses.Alert}
          type="error"
          message={problemDetail.detail}
        />
      )}
      <ParameterOverlayErrors />
      <div
        className={classNames([
          classes.MultiOptionSelector__Container,
          gridEditorClasses.GridContainer
        ])}
      >
        <form
          className={classes.MultiOptionSelector__Content}
          onSubmit={(evt) => evt.preventDefault()}
        >
          <Grid
            componentMetaData={componentMetaData}
            componentInstanceId={componentInstanceId}
            componentSummaryId={componentSummaryId}
            elements={elements}
            parameter={parameter}
            rows={hasColumns ? rows : []}
            updateRows={updateRows}
            columns={editorColumns}
            isSelectable={true}
            selectedRows={selectedRows}
            onSelectedChange={(id: DataGridRow['id'], isSelected: boolean) => {
              if (isSelected) {
                setSelectedRows((tmpRows) => [...tmpRows, id.toString()])
              } else {
                setSelectedRows((tmpRows) =>
                  tmpRows.filter((_id) => id !== _id)
                )
              }
            }}
          />
        </form>
      </div>
      <div
        className={classNames([
          classes.MultiOptionSelector__ButtonContainer,
          gridEditorClasses.FooterContainer
        ])}
      >
        <div className={classes.MultiOptionSelector__AddRow}>
          <Button
            alt="text"
            type="submit"
            disabled={!hasColumns}
            onClick={addRow}
            data-testid="add-row"
            aria-label={t('parameterEditor.GRID_EDITOR.addEntry')}
          >
            {!hasColumns ? <DisabledAddIcon /> : <AddIcon />}
          </Button>
        </div>
        <div className={classes.MultiOptionSelector__DeleteRow}>
          <Button
            alt="text"
            type="submit"
            onClick={deleteRows}
            data-testid="delete-rows"
            aria-label={t('parameterEditor.GRID_EDITOR.deleteEntry')}
            disabled={selectedRows.length === 0 || !hasColumns}
          >
            {selectedRows.length !== 0 ? (
              <DeleteIcon />
            ) : (
              <DisabledDeleteIcon />
            )}
          </Button>
        </div>
      </div>
      <ParameterOverlayFooter>
        <ParameterOverlayButton
          disabled={!hasColumns}
          data-testid="grid-editor-done-button"
          onClick={handleSubmit}
          text={t('common.save')}
        />
      </ParameterOverlayFooter>
    </>
  )
}
