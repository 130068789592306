import { rest } from 'msw'

import config from 'config'

import { mockComponentInfo } from './mockComponentInfo'
import { mockCustomComponentInfo } from './mockCustomComponentInfo'

export const useGetComponentTreeHandlers = [
  rest.get(`${config.getApiBaseUrl()}/v1/components`, (_req, res, ctx) =>
    res(ctx.json(mockComponentInfo))
  ),
  rest.get(
    `${config.getApiBaseUrl()}/v1/connector-components`,
    (_req, res, ctx) => res(ctx.json(mockCustomComponentInfo))
  )
]
