import { MouseEvent, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import classnames from 'classnames'

import { JobSummary, useGetJobSummaries } from 'api/hooks/useGetJobSummaries'
import { RunTaskStatus } from 'api/hooks/useGetRunTasks/types'

import { InteractiveTab, InteractiveTabs } from 'components/InteractiveTabs'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'
import { useSelectedJobs } from 'hooks/useSelectedJobs'

import { JobType } from 'job-lib/types/JobType'

import { useTaskStatus } from 'modules/FlowStatsExplorer/hooks/useTaskStatus'

import { EtlDocumentTabIcon } from './components/EtlDocumentTabIcon'
import classes from './JobTabs.module.scss'
import { EtlDocument, TaskType } from './types'

export const formatSelectedDocuments = <DocType,>(
  selectedValues: string[],
  mapper: (e: string) => DocType | undefined,
  formatter: (e: DocType) => EtlDocument
): EtlDocument[] => {
  return selectedValues.reduce<EtlDocument[]>((acc, currentValue) => {
    const documentType = mapper(currentValue)
    if (!documentType) return acc

    const document: EtlDocument = formatter(documentType)
    acc.push(document)

    return acc
  }, [])
}

export const JobTabs = () => {
  const { data: summaries, isError } = useGetJobSummaries()
  const { tasks } = useTaskStatus()
  const { t } = useTranslation()

  const { jobSummaryId: activeJobId, flowInstanceId: activeTaskId } =
    useProjectInfo()
  const {
    selectedJobs,
    selectedTasks,
    navigateToJob,
    navigateToTask,
    closeJob,
    closeTask,
    closeAll
  } = useSelectedJobs()

  const selectedDocuments = useMemo(() => {
    const allJobs = formatSelectedDocuments<JobSummary>(
      selectedJobs,
      (job) => summaries?.results.find((e) => e.jobId === job),
      (summary) => ({
        key: `${summary.type}-${summary.jobId}`,
        id: summary.jobId,
        document: summary,
        title: summary.name,
        type: summary.type,
        isActive: summary.jobId === activeJobId,
        closeDocument: () => closeJob(summary.jobId),
        openDocument: () => navigateToJob(summary.jobId)
      })
    )

    const allTasks = formatSelectedDocuments<RunTaskStatus>(
      selectedTasks,
      (id) => tasks.find((task) => task.id.toString() === id),
      (e) => ({
        key: `${TaskType.FlowSummary}-${e.id}`,
        id: e.id.toString(),
        document: e,
        title: `${t('taskHistory.tabNamePrefix')} ${e.jobName}`,
        type: TaskType.FlowSummary,
        isActive: e.id.toString() === activeTaskId,
        closeDocument: () => closeTask(e.id.toString()),
        openDocument: () => navigateToTask(e.id.toString())
      })
    )

    return [...allJobs, ...allTasks]
  }, [
    summaries,
    tasks,
    selectedJobs,
    selectedTasks,
    activeJobId,
    activeTaskId,
    closeJob,
    closeTask,
    navigateToJob,
    navigateToTask,
    t
  ])

  const activeTabIndex = useMemo(
    () => selectedDocuments.findIndex((document) => document.isActive),
    [selectedDocuments]
  )

  const onTabChange = useCallback(
    (tab: { value: number }) => {
      const document = selectedDocuments[tab.value]
      document.openDocument()
    },
    [selectedDocuments]
  )

  const onTabClose = useCallback((event: MouseEvent, document: EtlDocument) => {
    event.preventDefault()
    event.stopPropagation()
    document.closeDocument()
  }, [])

  const onCloseAllTabs = useCallback(
    (event: MouseEvent) => {
      event.preventDefault()
      event.stopPropagation()

      closeAll()
    },
    [closeAll]
  )

  if (isError) {
    return null
  }

  if (selectedDocuments.length <= 0) {
    // in future, should this be replaced with an empty tab row, rather than hiding it entirely?
    return null
  }

  return (
    <InteractiveTabs
      className={classes.JobTabs__Tabs}
      contentClassName={classes.JobTabs__TabContent}
      listClassName={classes.JobTabs__TabList}
      activeIndex={activeTabIndex}
      onChange={onTabChange}
      onCloseAll={onCloseAllTabs}
    >
      {selectedDocuments.map((doc: EtlDocument, index) => (
        <InteractiveTab
          key={doc.key}
          data-testid={`tab-${doc.key}`}
          className={classnames(classes.JobTabs__Tab, {
            [classes['JobTabs__Tab--Orchestration']]:
              doc.type === JobType.Orchestration,
            [classes['JobTabs__Tab--TaskDetails']]:
              doc.type === TaskType.FlowSummary,
            [classes['JobTabs__Tab--ActiveTab']]: index === activeTabIndex
          })}
          internalKey={doc.key}
          title={doc.title}
          tooltip={doc.title}
          iconBefore={<EtlDocumentTabIcon documentType={doc.type} />}
          onTabClose={(event: MouseEvent) => onTabClose(event, doc)}
        />
      ))}
    </InteractiveTabs>
  )
}
