import { Dispatch } from 'react'

import {
  JobVariableType,
  JobVariableVisibility,
  VariableScope
} from 'job-lib/types/Variables'

export enum Stages {
  MANAGE = 'MANAGE',
  CREATE = 'CREATE',
  DELETE = 'DELETE',
  EDIT = 'EDIT'
}

export enum ReducerActions {
  UPDATE_FIELD = 'UPDATE_FIELD',
  VALIDATE_FORM = 'VALIDATE_FORM',
  UPDATE_NAME = 'UPDATE_NAME',
  UPDATE_PROJECT_OVERRIDE = 'UPDATE_PROJECT_OVERRIDE',
  UPDATE_DEFAULT_VALUE = 'UPDATE_DEFAULT_VALUE',
  UPDATE_VARIABLE_TYPE = 'UPDATE_VARIABLE_TYPE'
}

export enum Fields {
  VARIABLE_SCOPE = 'VARIABLE_SCOPE',
  NAME = 'NAME',
  DESCRIPTION = 'DESCRIPTION',
  VARIABLE_TYPE = 'VARIABLE_TYPE',
  VISIBILITY = 'VISIBILITY',
  DEFAULT_VALUE = 'DEFAULT_VALUE',
  PROJECT_DEFAULT_OVERRIDES = 'PROJECT_DEFAULT_OVERRIDES'
}

export enum ErrorStatuses {
  'RESERVED_WORD' = 'RESERVED_WORD',
  'DUPLICATE' = 'DUPLICATE',
  'EMPTY' = 'EMPTY',
  'NO_ERROR' = 'NO_ERROR',
  'SELECT_OPTION' = 'SELECT_OPTION',
  'INCORRECT_NAME_FORMAT' = 'INCORRECT_NAME_FORMAT',
  'INCORRECT_NUMBER_FORMAT' = 'INCORRECT_NUMBER_FORMAT'
}
export interface SelectedJobVariable {
  type: VariableScope.JOB_VARIABLE
  name: string
}

export interface SelectedProjectVariable {
  type: VariableScope.PROJECT_VARIABLE
  name: string
  id: string
}

export type SelectedVariable = SelectedJobVariable | SelectedProjectVariable

export enum ProjectVariableType {
  NUMBER = 'NUMBER',
  TEXT = 'TEXT'
}

export enum ProjectVariableBehaviour {
  COPIED = 'COPIED',
  SHARED = 'SHARED'
}

export interface ManageVariableStageProps {
  onCancel: () => void
  setStage: Dispatch<Stages>
  setSelectedVariable?: Dispatch<SelectedVariable>
  setSelectedVariableScope?: Dispatch<VariableScope>
  selectedVariable?: SelectedVariable
  selectedVariableScope: VariableScope
}

export interface FieldStatus<T> {
  value: T
  isValid: null | boolean
  error: string
}

export interface FieldState {
  [Fields.DESCRIPTION]: FieldStatus<string>
  [Fields.NAME]: FieldStatus<string>
  [Fields.VARIABLE_TYPE]: FieldStatus<
    JobVariableType | ProjectVariableType | null
  >
  [Fields.VARIABLE_SCOPE]: FieldStatus<VariableScope | null>
  [Fields.DEFAULT_VALUE]: FieldStatus<string>
  [Fields.VISIBILITY]: FieldStatus<JobVariableVisibility | null>
  [Fields.PROJECT_DEFAULT_OVERRIDES]: FieldStatus<ProjectDefaultOverrides>
}
export interface FormState extends FieldState {
  isFormValid: boolean
}

export type ProjectDefaultOverrides = Record<
  ProjectVariableOverride['environmentId'],
  ProjectVariableOverride
>

export interface ProjectVariableOverride {
  value: string | number | null
  environmentId: string
  isValid?: boolean
  error?: string
}

export interface ProjectVariable {
  id: string
  name: string
  type: ProjectVariableType
  behaviour: ProjectVariableBehaviour
  description: string
  value: string | number | null
  overrides: ProjectVariableOverride[]
}
