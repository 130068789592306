import { FunctionComponent } from 'react'

import GitButton from 'components/Git/components/GitButton/GitButton'

import { useFlags } from 'hooks/useFlags'
import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import ScheduleButton from 'modules/EtlDesigner/components/HeaderBar/components/ScheduleButton'
import ProjectToolbar from 'modules/ProjectToolbar/ProjectToolbar'

import { BannerContent } from './components/BannerContent'
import classes from './HeaderBar.module.scss'

const HeaderBar: FunctionComponent = () => {
  const projectInfo = useProjectInfo()
  const { enableAddSchedule } = useFlags()

  return (
    <div className={classes.HeaderBar}>
      <ProjectToolbar projectInfo={projectInfo} />

      <BannerContent>
        {enableAddSchedule && (
          <div className={classes.HeaderBar__InlineButton}>
            <ScheduleButton />
          </div>
        )}
        <div className={classes.HeaderBar__InlineButton}>
          <GitButton />
        </div>
      </BannerContent>
    </div>
  )
}

export default HeaderBar
