import { ReactElement } from 'react'

import { DraggableLocation } from '@hello-pangea/dnd'

import { ColumnLabel } from '../components/Column'

interface MultiDragArgs {
  sourceData: Array<string | null>
  leftColumn: Array<string | null>
  rightColumn: Array<string | null>
  allItems: string[]
  selectedItems: string[]
  source: DraggableLocation
  destination: DraggableLocation
  moveAll?: boolean
}

interface ProcessedColumns {
  [k: string]: Array<string | null>
}

const getColumnClones = (
  leftColumn: Array<string | null>,
  rightColumn: Array<string | null>,
  source: DraggableLocation,
  destination: DraggableLocation
) => {
  const sourceColumn =
    source.droppableId === ColumnLabel.leftColumn
      ? [...leftColumn]
      : [...rightColumn]

  const destinationColumn =
    destination.droppableId === ColumnLabel.leftColumn
      ? [...leftColumn]
      : [...rightColumn]

  return { sourceColumn, destinationColumn }
}

const moveAll = ({
  sourceData,
  leftColumn,
  rightColumn,
  allItems,
  source,
  destination
}: MultiDragArgs): ProcessedColumns => {
  const { sourceColumn, destinationColumn } = getColumnClones(
    leftColumn,
    rightColumn,
    source,
    destination
  )

  const sourceDataClone = [...sourceData]

  const indices = sourceDataClone
    .filter(Boolean)
    .map((item) => allItems.indexOf(item as string))

  indices.forEach((itemIndex) => {
    destinationColumn[itemIndex] = sourceColumn[itemIndex]
    sourceColumn[itemIndex] = null
  })

  return {
    [source.droppableId]: sourceColumn,
    [destination.droppableId]: destinationColumn
  }
}

const singleMove = ({
  sourceData,
  leftColumn,
  rightColumn,
  allItems,
  source,
  destination
}: MultiDragArgs): ProcessedColumns => {
  const { sourceColumn, destinationColumn } = getColumnClones(
    leftColumn,
    rightColumn,
    source,
    destination
  )

  const sourceCloneFiltered = sourceData.filter(Boolean)

  const allItemsIndex = allItems.indexOf(
    sourceCloneFiltered[source.index] as string
  )

  const removed = sourceCloneFiltered[source.index]

  destinationColumn[allItemsIndex] = removed
  sourceColumn[allItemsIndex] = null

  return {
    [source.droppableId]: sourceColumn,
    [destination.droppableId]: destinationColumn
  }
}

const multiMove = ({
  leftColumn,
  rightColumn,
  allItems,
  selectedItems,
  source,
  destination
}: MultiDragArgs): ProcessedColumns => {
  const { sourceColumn, destinationColumn } = getColumnClones(
    leftColumn,
    rightColumn,
    source,
    destination
  )

  const indices = selectedItems
    .filter(Boolean)
    .map((item) => allItems.indexOf(item))

  indices.forEach((itemIndex) => {
    destinationColumn[itemIndex] = sourceColumn[itemIndex]
    sourceColumn[itemIndex] = null
  })

  return {
    [source.droppableId]: sourceColumn,
    [destination.droppableId]: destinationColumn
  }
}

export const moveItems = (args: MultiDragArgs): ProcessedColumns => {
  if (args.moveAll) {
    return moveAll(args)
  }
  if (args.selectedItems.length > 0) {
    return multiMove(args)
  }

  return singleMove(args)
}

export const multiSelect = (
  allItems: string[],
  selectedItems: string[],
  newItem: string
) => {
  if (!selectedItems.length) {
    return [newItem]
  }

  const indexOfNew = allItems.indexOf(newItem)

  const lastSelected = selectedItems[selectedItems.length - 1]
  const indexOfLast = allItems.indexOf(lastSelected)

  const isSelectingForwards = indexOfNew > indexOfLast
  const start = isSelectingForwards ? indexOfLast : indexOfNew
  const end = isSelectingForwards ? indexOfNew : indexOfLast

  const inBetween = allItems.slice(start, end + 1)

  const toAdd = inBetween.filter((item) => !selectedItems.includes(item))

  return [...selectedItems, ...toAdd]
}

export const highlightText = (
  text: string,
  textToHighlight: string
): string | ReactElement => {
  if (
    text.toLowerCase().includes(textToHighlight.toLowerCase()) &&
    textToHighlight
  ) {
    /* istanbul ignore next */
    const substringToHighlight =
      text.match(new RegExp(textToHighlight, 'gi'))?.[0] ?? ''

    const [before, ...after] = text.split(substringToHighlight)

    return (
      <>
        {before}
        <span style={{ fontWeight: 700 }}>{substringToHighlight}</span>
        {after.join(substringToHighlight)}
      </>
    )
  }

  return text
}

export const calculateColumns = (options: string[], selected: string[]) => {
  const result = {
    leftColumn: [] as string[],
    rightColumn: [] as string[]
  }

  result.rightColumn = selected
  result.leftColumn = options.filter((item) => !selected.includes(item))

  return result
}

export const fillColumns = (items: string[], selected: string[]) => {
  const validItems = Array(items.length).fill(null)
  const invalidItems: string[] = []

  const allItemsColumn = items.map((item) =>
    selected.includes(item) ? null : item
  )

  selected.forEach((item) => {
    const index = items.indexOf(item)

    if (index === -1) {
      return invalidItems.push(item)
    }

    validItems[index] = item
  })

  return {
    allItemsColumn,
    selectedItemsColumn: [...invalidItems, ...validItems]
  }
}
