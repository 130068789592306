import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon } from '@matillion/component-library'

import { useListEnvironments } from 'api/hooks/useListEnvironments'

import { ProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import ToolbarSelector, { ToolbarSelectorType } from './ToolbarSelector'

const EnvironmentSelector: FC<{ projectInfo: ProjectInfo }> = ({
  projectInfo
}) => {
  const { data, isLoading, isError } = useListEnvironments()
  const { environmentId } = projectInfo
  const { t } = useTranslation()

  const env = data?.results.find((e) => e.id === environmentId)

  return (
    <ToolbarSelector
      data-testid="project-toolbar-environmentSelector"
      text={env?.name ?? t('projectToolbox.errorLoading.environment')}
      type={ToolbarSelectorType.ENVIRONMENT}
      icon={Icon.Container}
      isError={isError}
      isLoading={isLoading}
    />
  )
}

export default EnvironmentSelector
