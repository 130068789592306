import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as VariableSvg } from 'assets/variable-icon.svg'

import { IconButton } from 'components/IconButton/IconButton'

import { ManageVariables } from 'modules/ManageVariables/ManageVariables'

export const VariablesButton = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const { t } = useTranslation()

  return (
    <>
      <IconButton
        label={t('canvasToolbox.manageVariables')}
        data-testid="canvas-toolbox-manage-variables"
        onClick={() => setModalIsOpen(true)}
      >
        <VariableSvg />
      </IconButton>
      {modalIsOpen && (
        <ManageVariables
          onCancel={() => setModalIsOpen(false)}
        ></ManageVariables>
      )}
    </>
  )
}
