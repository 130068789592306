import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { Modal, Typography } from '@matillion/component-library'

import { ComponentSummaryId } from 'api/hooks/useGetComponentTree'

import { ReactComponent as PlusInCircle } from 'assets/plus-in-circle.svg'

import { useMakeComponent } from 'job-lib/hooks/useMakeComponent/useMakeComponent'
import { jobActions } from 'job-lib/store'
import {
  ComponentInstanceId,
  OrchestrationJob,
  TransformationJob
} from 'job-lib/types/Job'

import { useWorkingCopy } from 'modules/EtlDesigner/hooks/useWorkingCopy'

import classes from './AddNextComponent.module.scss'
import { AddComponentList } from './components/AddComponentList'

interface Props {
  sourceComponentId?: ComponentInstanceId
}

export const AddNextComponent = ({ sourceComponentId }: Props) => {
  const { t } = useTranslation()
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const dispatch = useDispatch()
  const [makeComponent] = useMakeComponent()
  const workingCopy = useWorkingCopy()
  const job = workingCopy.job as TransformationJob | OrchestrationJob

  const onSelectComponent = useCallback(
    async (id: ComponentSummaryId) => {
      const position = {
        x: 0,
        y: 0
      }
      // This line is covered by a Cypress test but coverage is not working correctly
      /* istanbul ignore next */
      if (sourceComponentId) {
        position.x = job?.components[sourceComponentId].x + 120
        position.y = job?.components[sourceComponentId].y
      }

      setIsDialogOpen(false)

      dispatch(
        jobActions.addComponent(
          await makeComponent({
            id,
            ...position
          })
        )
      )
    },
    [sourceComponentId, job, dispatch, makeComponent]
  )

  return (
    <>
      <button
        type="button"
        className={classes.AddNextComponentCTA}
        onClick={() => setIsDialogOpen(true)}
        data-testid="add-next-component"
        aria-label={t('canvas.addComponent')}
      >
        <PlusInCircle />
      </button>

      {isDialogOpen && (
        <Modal
          ariaLabelledBy="modal-title"
          onCancel={() => setIsDialogOpen(false)}
          className={classes.ModalContainer}
        >
          <div className={classes.AddNextComponent}>
            <Typography as="h2" format="tm" id="modal-title">
              {t('sideBar.componentPanel.addComponents')}
            </Typography>
          </div>
          <AddComponentList onSelectComponent={onSelectComponent} />
        </Modal>
      )}
    </>
  )
}
