import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { SearchBar, Typography } from '@matillion/component-library'

import {
  SourceColumn,
  SourceColumns
} from 'modules/ParameterEditors/components/MultiExpressionsEditor'

import classes from './Fields.module.scss'
import FieldsTableRow from './FieldsTableRow'

interface Props {
  sourceColumns: SourceColumns
  onColumnClick: (column: SourceColumn) => unknown
}

const Fields: FC<Props> = ({ sourceColumns, onColumnClick }) => {
  const [filter, setFilter] = useState('')
  const { t } = useTranslation()

  const filteredColumns = useMemo(() => {
    return sourceColumns.filter((col) => {
      const column = col.column.toLowerCase()
      return column.includes(filter.toLowerCase())
    })
  }, [filter, sourceColumns])

  return (
    <div className={classes.Wrapper}>
      <table className={classes.FieldsTable}>
        <thead>
          <tr className={classes.FieldsTableHeaders}>
            <th className={classes.FieldsTableHeaderLeft}>
              <Typography format="mc" weight="bold">
                {t('common.name')}
              </Typography>
            </th>
            <th className={classes.FieldsTableHeader}>
              <Typography format="mc" weight="bold">
                {t('common.type')}
              </Typography>
            </th>
          </tr>
        </thead>
        <tbody className={classes.FieldsTable__Body}>
          <tr>
            <td colSpan={2} className={classes.SearchRow}>
              <SearchBar
                placeholder={t('itemSelector.searchLabel')}
                aria-label={t('itemSelector.searchLabel')}
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                className={classes.FieldsSearch}
              />
            </td>
          </tr>

          {filteredColumns.map((col, index) => (
            <FieldsTableRow
              // Updated to use index as column order shouldn't change.
              // We also don't have control over the uniqueness of the key as it's driven
              // off of customer data.
              key={`${col.alias}.${col.column}.${index}`}
              column={col}
              onClick={() => onColumnClick(col)}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default Fields
