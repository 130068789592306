import { type ChangeEvent, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Field,
  Textarea,
  Typography
} from '@matillion/component-library'

import BranchIcon from 'icons/BranchIcon.svg'
import classes from './CommitModal.module.scss'
import { type Environment } from 'types/Environment'
import useGitContext from 'provider/GitProvider/useGitContext'
import useCommitAction from 'hooks/useCommitAction/useCommitAction'
import PublishJobsForm from '../PublishJobsForm'
import { type PublishToggleEvent } from '../PublishJobsForm/types'
import { type PublishPipelinesCallback } from 'types/Events'

export interface CommitFormProps extends PublishPipelinesCallback {
  branchId?: string
  onClose: () => void
  environments: Environment[]
}

const CommitForm = ({
  branchId,
  environments,
  onPublishPipelines,
  onClose
}: CommitFormProps) => {
  const { t } = useTranslation()

  const [publish, setPublish] = useState(false)
  const [commitMessage, setCommitMessage] = useState('')
  const [envsUnavailable, setEnvsUnavailable] = useState(false)
  const { refreshWorkingTreeStatus, isNativeGit } = useGitContext()
  const [environment, setEnvironment] = useState<string | undefined>(undefined)

  const handleCommitSuccess = useCallback(
    async (commitId: string) => {
      refreshWorkingTreeStatus?.({
        refreshFileSummaries: true,
        refreshPipelines: true
      })

      if (!isNativeGit && environment && branchId) {
        await onPublishPipelines({
          commitId,
          branch: branchId,
          environment
        })
      }

      onClose()
    },
    [
      environment,
      branchId,
      onClose,
      onPublishPipelines,
      isNativeGit,
      refreshWorkingTreeStatus
    ]
  )

  const { isLoading: commitLoading, commit } = useCommitAction({
    commitMessage,
    onSuccess: handleCommitSuccess,
    onFailure: onClose
  })

  const onCommitMessageChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCommitMessage(e.target.value)
  }

  const handleEnvsUnavailable = useCallback(() => {
    setEnvsUnavailable(true)
  }, [setEnvsUnavailable])

  const handlePublishFormChange = useCallback(
    ({ enabled, environment }: PublishToggleEvent) => {
      setPublish(enabled)
      setEnvironment(environment)
    },
    [setPublish, setEnvironment]
  )

  const isValidCommitMessage = commitMessage.trim() !== ''
  const isPublishValid = publish && !environment && !envsUnavailable

  return (
    <div data-testid="commit-form">
      <div
        className={classes.CommitModal__title}
        data-testid="modal-commit-title-block"
      >
        <Typography format="tm" id="commit-title" data-testid="commit-title">
          {isNativeGit
            ? t('git.commit.modal.titleNativeGit')
            : t('git.commit.modal.title')}
        </Typography>
        <Typography
          data-testid="commit-subtitle"
          className={classes.CommitModal__description}
        >
          {isNativeGit
            ? t('git.commit.modal.subtitleNativeGit')
            : t('git.commit.modal.subtitle')}
        </Typography>
      </div>

      <div className={classes.CommitModal__container}>
        <Typography format="bcs" weight="bold" data-testid="modal-branch-label">
          {t('git.commit.branch')}
        </Typography>
        <div
          data-testid="modal-branch-name"
          className={classes.CommitModal__branchName}
        >
          <img src={BranchIcon} alt={'Branch Icon'} />
          <span>{branchId}</span>
        </div>
      </div>

      <div className={classes.CommitModal__container}>
        <Field
          id="commit-message"
          name="commit-message"
          value={commitMessage}
          disabled={commitLoading}
          inputComponent={Textarea}
          title={t('git.commit.message')}
          onChange={onCommitMessageChange}
          aria-label={t('git.commit.message')}
          data-testid="modal-commit-message-input"
        />
      </div>

      {!isNativeGit && (
        <div className={classes.CommitModal__publishContainer}>
          <PublishJobsForm
            environments={environments}
            unavailable={branchId !== 'main'}
            onChange={handlePublishFormChange}
            onUnavailable={handleEnvsUnavailable}
            warningKey={'git.publish.message.not-default-branch'}
          />
        </div>
      )}

      <div className={classes.CommitModal__buttons}>
        <Button
          id="cancel"
          alt="secondary"
          onClick={onClose}
          text={t('git.cancel')}
          disabled={commitLoading}
          data-testid="commit-modal-cancel"
        />

        <Button
          id="commit"
          alt="positive"
          onClick={commit}
          waiting={commitLoading}
          text={t('git.commit.commit')}
          data-testid="commit-modal-submit"
          disabled={!isValidCommitMessage || isPublishValid}
        />
      </div>
    </div>
  )
}

export default CommitForm
