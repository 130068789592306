import useGitContext from 'provider/GitProvider/useGitContext'
import { type GitFeatureFlags } from './types'

export const defaultFeatureFlags: GitFeatureFlags = {
  pullChangesV2: true
}

const useFeatureFlags = () => {
  const { featureFlags } = useGitContext()

  return { ...featureFlags }
}

export default useFeatureFlags
