import { RequestHandler, rest } from 'msw'

import config from 'config'

import {
  mockDeletedJobSummaryResponse,
  mockJobSummaryResponse
} from './mockJobSummaryResponse'

const getJobSummariesUri = `${config.getApiBaseUrl()}/v1/projects/*/branches/*/jobs`

export const useGetJobSummariesHandlers: RequestHandler[] = [
  rest.get(getJobSummariesUri, async (_req, res, ctx) => {
    return res(ctx.json(mockJobSummaryResponse))
  })
]

export const useGetDeletedJobSummariesHandlers: RequestHandler[] = [
  rest.get(getJobSummariesUri, async (_req, res, ctx) => {
    return res(ctx.json(mockDeletedJobSummaryResponse))
  })
]

export const useGetJGitJobSummariesHandlers: RequestHandler[] = [
  rest.get(getJobSummariesUri, async (_req, res, ctx) => {
    return res(
      ctx.json(mockJobSummaryResponse),
      // In reality, this header returns with no value, but it seems
      // the MSW strips this from the response when you give it a falsy value.
      ctx.set('matillion.wts.jgit', 'value')
    )
  })
]
