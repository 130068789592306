import { type InitOAuthResponse } from './types'
import { type ExternalGitProvider } from 'types/ExternalGitProvider'

export const useInitOAuthResponses: Record<
  ExternalGitProvider,
  InitOAuthResponse
> = {
  github: {
    authorizationUrl:
      'https://github.com/login/oauth/authorize?client_id=Iv1.60685b233a97aec0&response_type=code&redirect_uri=https://project-explorer-dev/git-oauth-redirect&state=0d8985cd0a4f4c82ac35c378c1f36625'
  }
}
