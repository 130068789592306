import { FunctionComponent, useLayoutEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Icon,
  Input,
  LoadingSpinner,
  Typography
} from '@matillion/component-library'
import classnames from 'classnames'
import { useCombobox } from 'downshift'

import classes from './AutoComplete.module.scss'
import { displayDropdownAboveInput } from './displayDropdownAboveInput'
import { highlightText } from './higlightText'
import {
  AutoCompleteActionProps,
  AutoCompleteItemId,
  AutoCompleteProps
} from './types'

function resolveItem(
  value: AutoCompleteItemId | string | number | null,
  availableItems: AutoCompleteItemId[]
) {
  if (typeof value === 'string' || typeof value === 'number') {
    return availableItems.find((item) => item.id === value)
  }
  return value
}

export const AutoComplete: FunctionComponent<AutoCompleteProps> = ({
  name: fieldName,
  placeholder = '',
  className = '',
  error = false,
  loading = false,
  disabled = false,
  availableItems,
  value,
  onChange,
  onBlur,
  onClick,
  action,
  allowFreetext,
  colorTheme,
  inputClassName,
  inputId,
  optionClassName,
  resultsClassName,
  resultsDataBoundary,
  ...otherProps
}) => {
  const { t } = useTranslation()
  const [text, setText] = useState<string>('')
  const [isDropdownAbove, setIsDropdownAbove] = useState<boolean>(false)
  const [internalValue, setInternalValue] = useState<AutoCompleteItemId | null>(
    null
  )

  const componentRef = useRef<HTMLDivElement>(null)
  const dropdownRef = useRef<HTMLDivElement>(null)

  const selectedItem =
    typeof value !== 'undefined'
      ? resolveItem(value, availableItems)
      : internalValue
  const inputValue = selectedItem?.name ?? text

  const filteredItems = availableItems.filter((item) =>
    item.name.toLowerCase().includes(text.toLowerCase())
  )

  const changeSelection = (
    item: AutoCompleteItemId | null,
    isFreetext = false
  ): void => {
    if (item?.id !== selectedItem?.id) {
      setInternalValue(item)
      onChange({
        target: {
          name: fieldName,
          value: item,
          isFreetext
        }
      })
    }
  }

  const onInputChange = (changedInputValue: string) => {
    setText(changedInputValue)

    if (!changedInputValue) {
      return changeSelection(null)
    }

    const item =
      availableItems.find(
        ({ name }) => name.toLowerCase() === changedInputValue.toLowerCase()
      ) ?? null

    if (item?.disabled) {
      return
    }

    if (item || !allowFreetext) {
      return changeSelection(item)
    }

    changeSelection(
      {
        id: changedInputValue,
        name: changedInputValue
      },
      true
    )
  }

  const {
    getComboboxProps,
    getInputProps,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
    highlightedIndex,
    isOpen,
    openMenu
  } = useCombobox({
    items: filteredItems,
    inputValue: text || (internalValue?.name ?? ''),
    selectedItem,
    itemToString: (item) => item?.name ?? '',
    onStateChange: ({ type, ...changes }) => {
      const {
        InputChange,
        InputBlur,
        InputKeyDownEscape,
        ItemClick,
        InputKeyDownEnter,
        FunctionOpenMenu
      } = useCombobox.stateChangeTypes
      const changedInputValue = changes.inputValue ?? ''
      switch (type) {
        case InputChange:
          onInputChange(changedInputValue)
          break
        case FunctionOpenMenu:
          setText('')
          break
        case InputBlur:
        case InputKeyDownEscape:
          if (inputValue && !selectedItem) {
            setText('')
          }
          break
        case ItemClick:
        case InputKeyDownEnter:
          if (changes.selectedItem) {
            changeSelection(changes.selectedItem)
          }
          break
        default:
          break
      }
    }
  })

  useLayoutEffect(() => {
    const displayDropdownAbove = displayDropdownAboveInput({
      resultsDataBoundary,
      componentRef: componentRef.current,
      dropdownRef: dropdownRef.current,
      isOpen
    })

    setIsDropdownAbove(displayDropdownAbove)
  }, [resultsDataBoundary, componentRef, dropdownRef, isOpen])

  const inputProps = getInputProps({
    onFocus: openMenu,
    onBlur,
    onClick
  })

  return (
    <div
      ref={componentRef}
      className={classnames(
        classes.AutoComplete,
        { [classes['AutoComplete--Open']]: isOpen },
        className
      )}
    >
      <div
        {...getComboboxProps()}
        onFocus={() => {
          if (inputProps.onClick) {
            inputProps.onClick()
          }
        }}
      >
        <Input
          data-testid="autocomplete-input"
          autoComplete="off"
          colorTheme={colorTheme}
          {...otherProps}
          {...inputProps}
          id={inputId ?? inputProps.id}
          value={isOpen ? text : selectedItem?.name ?? ''}
          error={error}
          disabled={disabled}
          placeholder={isOpen ? 'Start typing' : placeholder}
          className={classnames(classes.AutoComplete__Input, inputClassName)}
          name={fieldName}
          iconAfter={{
            icon: (
              <button
                aria-label={isOpen ? 'Collapse dropdown' : 'Expand dropdown'}
                data-testid="autocomplete-toggle"
                type="button"
                className={classnames(classes.AutoComplete__Toggle, {
                  [classes['AutoComplete--Disabled']]: disabled
                })}
                {...getToggleButtonProps({
                  onClick
                })}
              >
                <Icon.ChevronDown />
              </button>
            ),
            clickable: !disabled
          }}
        />
      </div>
      <div
        className={classnames(classes.AutoComplete__Results, resultsClassName, {
          [classes['AutoComplete__Results--Above-Input']]: isDropdownAbove
        })}
        style={
          isDropdownAbove
            ? {
                bottom: `${
                  componentRef.current?.getBoundingClientRect().height ??
                  // This should never be reachable as isDropdownAbove cannot be true withou a componentRef
                  /* istanbul ignore next */ 0
                }px`
              }
            : {}
        }
        ref={dropdownRef}
      >
        <ul {...getMenuProps()} className={classes.AutoComplete__List}>
          {isOpen &&
            filteredItems.map((item, index) => (
              <li
                data-testid="autocomplete-item"
                key={item.id}
                className={classnames(
                  classes.AutoComplete__Item,
                  {
                    [classes['AutoComplete__Item--Highlight']]:
                      highlightedIndex === index,
                    [classes['AutoComplete__Item--Current']]:
                      selectedItem?.id === item.id,
                    [classes['AutoComplete__Item--Disabled']]: item.disabled
                  },
                  optionClassName
                )}
                {...getItemProps({
                  item,
                  index,
                  onClick: (e) => e.currentTarget.click(),
                  disabled: !!item.disabled
                })}
              >
                {highlightText(item.name, inputValue)}
                {typeof item.disabled === 'string' && (
                  <span className={classes.AutoComplete__DisabledHint}>
                    <Icon.Error />
                    <Typography as="span" format="mc">
                      {item.disabled}
                    </Typography>
                  </span>
                )}
              </li>
            ))}
          {isOpen && loading && (
            <div
              className={classnames(
                classes.AutoComplete__Item,
                optionClassName
              )}
            >
              <div
                className={classnames(
                  classes.AutoComplete,
                  classes['AutoComplete--Loading'],
                  className
                )}
                data-testid="autocomplete-loading"
              >
                <LoadingSpinner />
              </div>
            </div>
          )}
          {isOpen && filteredItems.length === 0 && !loading && (
            <div
              className={classnames(
                classes.AutoComplete__Item,
                classes['AutoComplete__Item--Disabled'],
                optionClassName
              )}
            >
              <Typography as="span" format="bcs">
                {t('common.noItemsMatchSearch')}
              </Typography>
            </div>
          )}
        </ul>
        {action && (
          <div className={classes.AutoComplete__ActionBox}>{action}</div>
        )}
      </div>
    </div>
  )
}

export const AutoCompleteAction: FunctionComponent<
  React.PropsWithChildren<AutoCompleteActionProps>
> = ({ icon, onClick, className = '', children, ...otherProps }) => {
  return (
    <button
      data-testid="autocomplete-action"
      type="button"
      className={classnames(classes.AutoCompleteAction, className)}
      onClick={onClick}
      {...otherProps}
    >
      {icon}
      <Typography
        className={classes.AutoCompleteAction__Text}
        as="span"
        weight="normal"
        format="bcs"
      >
        {children}
      </Typography>
    </button>
  )
}
