import { ParameterDataType } from 'api/hooks/useGetComponentMetadata/types'

import { ElementCollection } from 'job-lib/types/Parameters'

import {
  ChildDataStructure,
  DataStructure,
  DataStructureType,
  SharedDataStructure
} from './types'

const COLUMN_NAME_SLOT = 5

export function convertFromRootValueToDataStructure(
  elements: ElementCollection,
  columns: string[]
): DataStructure[] {
  return columns.map((column) => {
    const elementsByColumn = Object.entries(elements).reduce(
      (columnElements, [slot, element]) => {
        if (element.values?.[COLUMN_NAME_SLOT].value === column) {
          return {
            ...columnElements,
            [slot]: element
          }
        }
        return columnElements
      },
      {}
    )

    return {
      key: column,
      type: DataStructureType.VARIANT,
      children: convertElementsToDataStructures(elementsByColumn)
    }
  })
}

function convertElementsToDataStructures(
  elements: ElementCollection
): ChildDataStructure[] {
  return Object.values(elements).map((element) => {
    const {
      1: name,
      2: type,
      3: size,
      4: decimalPlaces,
      5: column,
      6: alias,
      7: isArray,
      8: isSelected
    } = element.values

    return {
      key: name.value,
      type: type.value as DataStructureType,
      size: parseInt(size.value),
      decimalPlaces: parseInt(decimalPlaces.value),
      column: column.value,
      alias: alias.value,
      array: isArray.value === 'true',
      selected: isSelected.value === 'true',
      children: convertElementsToDataStructures(element.elements ?? {})
    }
  })
}

export function convertFromDataStructureToRootValue(
  dataStructure: DataStructure[]
): ElementCollection {
  return dataStructure.reduce((rootValue, structure) => {
    return {
      ...rootValue,
      ...convertFromDataStructuresToElements(
        structure.children,
        Object.keys(rootValue).length,
        structure.key
      )
    }
  }, {})
}

function convertFromDataStructuresToElements(
  dataStructures: ChildDataStructure[],
  startIndex: number,
  columnName: string
): ElementCollection {
  return dataStructures
    .map((dataStructure, index) => {
      return {
        slot: startIndex + index + 1,
        values: {
          1: {
            slot: 1,
            type: 'STRING',
            dataType: ParameterDataType.TEXT,
            value: dataStructure.key
          },
          2: {
            slot: 2,
            type: 'STRING',
            dataType: ParameterDataType.TEXT,
            value: dataStructure.type
          },
          3: {
            slot: 3,
            type: 'STRING',
            dataType: ParameterDataType.TEXT,
            value: dataStructure.size.toString()
          },
          4: {
            slot: 4,
            type: 'STRING',
            dataType: ParameterDataType.TEXT,
            value: dataStructure.decimalPlaces.toString()
          },
          5: {
            slot: 5,
            type: 'STRING',
            dataType: ParameterDataType.TEXT,
            value: columnName
          },
          6: {
            slot: 6,
            type: 'STRING',
            dataType: ParameterDataType.TEXT,
            value: dataStructure.alias
          },
          7: {
            slot: 7,
            type: 'STRING',
            dataType: ParameterDataType.TEXT,
            value: dataStructure.array.toString()
          },
          8: {
            slot: 8,
            type: 'STRING',
            dataType: ParameterDataType.TEXT,
            value: dataStructure.selected.toString()
          }
        },
        elements:
          dataStructure.children.length > 0
            ? convertFromDataStructuresToElements(
                dataStructure.children,
                0,
                columnName
              )
            : undefined
      }
    })
    .reduce((previous, current) => {
      return {
        ...previous,
        [current.slot]: current
      }
    }, {})
}

export function flattenDataStructure(
  dataStructure: DataStructure | ChildDataStructure,
  basePath: string[] = []
): Record<string, ChildDataStructure> {
  return {
    [composeJsonPath([...basePath, dataStructure.key])]: dataStructure,
    ...dataStructure.children.reduce((previous, current) => {
      return {
        ...previous,
        ...flattenDataStructure(current, [...basePath, dataStructure.key])
      }
    }, {})
  }
}

export function composeJsonPath(parts: string[]): string {
  const [root, ...otherParts] = parts
  const wrappedParts = otherParts.map((part) => `["${part}"]`)
  return `${root}${wrappedParts.join('')}`
}

export function doPathsMatch(value1: string[], value2: string[]) {
  if (value1.length !== value2.length) {
    return false
  }
  return value1.every((part, index) => part === value2[index])
}

export function omitElement<T extends SharedDataStructure>(
  dataStructure: T,
  path: string[],
  base: string[]
): T {
  return {
    ...dataStructure,
    children: dataStructure.children
      .filter(
        (childElement) => !doPathsMatch([...base, childElement.key], path)
      )
      .map((childElement) =>
        omitElement(childElement, path, [...base, childElement.key])
      )
  }
}

export function addElement<T extends SharedDataStructure>(
  dataStructure: T,
  targetPath: string[],
  newElement: ChildDataStructure,
  basePath: string[]
): T {
  if (doPathsMatch(basePath, targetPath)) {
    return {
      ...dataStructure,
      children: [...dataStructure.children, newElement]
    }
  }
  return {
    ...dataStructure,
    children: dataStructure.children.map((childElement) =>
      addElement(childElement, targetPath, newElement, [
        ...basePath,
        childElement.key
      ])
    )
  }
}

export function modifyElement<T extends SharedDataStructure>(
  dataStructure: T,
  targetPath: string[],
  modifications: Partial<ChildDataStructure>,
  basePath: string[]
): T {
  if (doPathsMatch(basePath, targetPath)) {
    return {
      ...dataStructure,
      ...modifications
    }
  }

  return {
    ...dataStructure,
    children: dataStructure.children.map((childElement) =>
      modifyElement(childElement, targetPath, modifications, [
        ...basePath,
        childElement.key
      ])
    )
  }
}

export function modifyAllElements<T extends SharedDataStructure>(
  dataStructure: T[],
  modifications: Partial<ChildDataStructure>
): T[] {
  return dataStructure.map((structure) => ({
    ...structure,
    ...modifications,
    children: modifyAllElements(structure.children, modifications)
  }))
}
