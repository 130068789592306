import { type ExternalGitProvider } from 'types/ExternalGitProvider'
import { object, string } from 'yup'

/**
 * A Git provider capable of hosting remote repositories.
 * Can be both Matillion hosted or externally provided.
 */
export type GitProvider = ExternalGitProvider | 'matillion'

export interface BringYourOwnGitDetails {
  /**
   * The git provider as selected by the user.
   * This includes both Matillion's own internal
   * offering and also any external ones.
   */
  provider: GitProvider

  /**
   * The full name of the external git repository
   * as selected by the user. Optional since the user
   * will not select a repository should they pick
   * Matillion hosted Git.
   */
  repository?: string
}

export interface BringYourOwnGitClasses {
  /**
   * A class passed into the wrapper of
   * the provider selector component.
   */
  providerSelector?: string

  /**
   * A class passed into the wrapper of
   * the repository dropdown component.
   */
  repositoryDropdown?: string
}

export interface BringYourOwnGitFormProps {
  /**
   * Optional classes that are passed down
   * into the underlying form components.
   */
  classes?: BringYourOwnGitClasses
}

/**
 * A validation schema for the "Bring Your Own Git" form.
 */
export const bringYourOwnGitValidationSchema = object({
  provider: string().required(),
  repository: string().required('validation-error')
})
