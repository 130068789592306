import { LookupDefinition } from 'api/hooks/useGetParameterOptions/types'

import { ComponentInstanceId, TransformationJob } from 'job-lib/types/Job'

import { ValidationQueryCache } from 'modules/ComponentValidation/types'
import { getComponentDependencyData } from 'modules/ComponentValidation/utils/getComponentDependencyData'

export interface GetTransformSQLDependenciesOptions {
  validationQueryCache: ValidationQueryCache
  lookupDependencies: string[]
  componentId?: ComponentInstanceId | null
  job: TransformationJob
}

export type TransformSQLDependencies = Pick<LookupDefinition, 'transform.sql'>

export const getTransformSQLDependencies = ({
  validationQueryCache,
  lookupDependencies,
  componentId,
  job
}: GetTransformSQLDependenciesOptions):
  | TransformSQLDependencies
  | undefined => {
  if (componentId == null) return
  if (!lookupDependencies.includes('transform.sql')) return undefined
  if (!job) return undefined

  const bag = getComponentDependencyData(job, componentId, validationQueryCache)

  if (!Object.keys(bag?.cache).length) {
    return { 'transform.sql': {} }
  }

  return { 'transform.sql': { ...bag.cache } }
}
