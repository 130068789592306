import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { Typography } from '@matillion/component-library'
import classnames from 'classnames'

import {
  ComponentMetadata,
  ParameterDataType
} from 'api/hooks/useGetComponentMetadata/types'
import { ComponentSummaryId } from 'api/hooks/useGetComponentTree'
import { EditorColumn } from 'api/hooks/useGetParameterOptions/types'

import { jobActions } from 'job-lib/store'
import { isDPLParameterCollection } from 'job-lib/store/jobSlice/utils/isDPLParameterCollection'
import { ComponentInstance } from 'job-lib/types/Job'
import { ElementCollection } from 'job-lib/types/Parameters'

import { useValidationProvider } from 'modules/ComponentValidation/hooks/useValidationProvider'

import classes from './ComponentParameters.module.scss'
import { ComponentParameterItem } from './components/ComponentParameterItem/ComponentParameterItem'
import { convertElementCollectionToValue } from './utils/convertElementCollectionToValue'

interface ComponentParametersProps {
  componentInstance: ComponentInstance
  componentMetadata: ComponentMetadata
  componentSummaryId: ComponentSummaryId
}

export interface EditedParameter {
  parameterSlot: number
  parameterPath: string[]
  parameterDataType: ParameterDataType
  editedValue: ElementCollection
  editorColumns?: EditorColumn[]
}

export const ComponentParameters: FC<ComponentParametersProps> = ({
  componentInstance,
  componentMetadata,
  componentSummaryId
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { invalidateComponent } = useValidationProvider()

  const onEdit = useCallback(
    ({
      parameterSlot,
      parameterPath,
      parameterDataType,
      editedValue,
      editorColumns
    }: EditedParameter) => {
      const { parameters } = componentInstance
      const parameterValue = isDPLParameterCollection(parameters)
        ? convertElementCollectionToValue(
            editedValue,
            parameterDataType,
            editorColumns
          )
        : editedValue

      dispatch(
        jobActions.overrideComponentParameter({
          componentMetadata,
          parameterSlot,
          parameterPath,
          componentInstanceId: componentInstance.id,
          value: parameterValue
        })
      )

      invalidateComponent(componentInstance.id)
    },
    [componentInstance, componentMetadata, dispatch, invalidateComponent]
  )

  return (
    <div
      className={classes.ComponentParameters}
      aria-label={t('translation:componentProperties.tableTitle')}
      data-testid="component-parameter-editor"
      role="table"
    >
      <div role="rowgroup">
        <div
          role="row"
          className={classnames(
            classes.ComponentParameters__Row,
            classes.ComponentParameters__HeaderRow
          )}
        >
          <Typography
            as="div"
            format="mc"
            weight="bold"
            role="columnheader"
            scope="col"
            className={classnames(
              classes.ComponentParameters__Cell,
              classes['ComponentParameters__Cell--Name']
            )}
          >
            Parameter
          </Typography>
          <Typography
            as="div"
            format="mc"
            weight="bold"
            role="columnheader"
            scope="col"
            className={classnames(
              classes.ComponentParameters__Cell,
              classes['ComponentParameters__Cell--Value'],
              classes.ComponentParameters__ValueHeaderCell
            )}
          >
            Value
          </Typography>
        </div>
      </div>

      <div role="rowgroup" data-testid="component-parameters">
        {componentMetadata.parameters.map((parameter) => (
          <ComponentParameterItem
            key={`parameter-${parameter.dplID}`}
            parameter={parameter}
            componentSummaryId={componentSummaryId}
            componentInstance={componentInstance}
            componentMetadata={componentMetadata}
            onEdit={onEdit}
          />
        ))}
      </div>
    </div>
  )
}
