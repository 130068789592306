import {
  ComponentEditorTabType,
  ComponentMetadataResponse,
  EditorType,
  LookUpType,
  ParameterDataType,
  UseVariable
} from 'api/hooks/useGetComponentMetadata/types'

import { Cardinality, ConnectionPortType } from 'job-lib/types/Components'
import { JobType } from 'job-lib/types/JobType'

export const mockTableInputMetadata: ComponentMetadataResponse = {
  componentId: 'table-input',
  metadata: {
    schemaVersion: 1,
    emeraldId: '1354890871',
    componentType: JobType.Transformation,
    inputPorts: [],
    outputPorts: [
      {
        portId: ConnectionPortType.UNCONDITIONAL,
        cardinality: Cardinality.MANY
      }
    ],
    deprecated: false,
    exports: null,
    editorTabs: [
      ComponentEditorTabType.PROPERTIES,
      ComponentEditorTabType.SAMPLE,
      ComponentEditorTabType.METADATA,
      ComponentEditorTabType.SQL,
      ComponentEditorTabType.PLAN,
      ComponentEditorTabType.HELP
    ],
    parameters: [
      {
        id: 'component-name',
        dplID: 'componentName',
        metlSlot: 1,
        useVariable: UseVariable.NO,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'database',
        dplID: 'database',
        metlSlot: 6,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_DWH_DATABASES,
        lookupDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'schema',
        dplID: 'schema',
        metlSlot: 5,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_DWH_SCHEMAS,
        lookupDependencies: ['param.database'],
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'target-table',
        dplID: 'targetTable',
        metlSlot: 2,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_DWH_TABLES,
        lookupDependencies: ['param.database', 'param.schema'],
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'column-names',
        dplID: 'columnNames',
        metlSlot: 3,
        useVariable: UseVariable.NO,
        dataType: ParameterDataType.GRID,
        editorType: EditorType.MULTI_OPTION_SELECTOR,
        lookupType: LookUpType.DYNAMIC_DWH_COLUMNS,
        lookupDependencies: [
          'param.database',
          'param.schema',
          'param.target-table:param.table'
        ],
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'time-offset',
        dplID: 'timeOffset',
        metlSlot: 7,
        useVariable: UseVariable.NO,
        dataType: ParameterDataType.INTEGER,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: null,
        optional: true,
        staticOptions: null,
        defaultValue: null
      }
    ]
  }
}
