// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._104WttmN_o7lxHBXJkD5 .mwM_6Q8Nm2SM4K58HmAQ{margin-top:-25px;margin-bottom:10px}._104WttmN_o7lxHBXJkD5 .DDBPZAEF0ATAakKPd3c7{text-align:center}._104WttmN_o7lxHBXJkD5 ._xQ5X8ZqEOY__hH4wGKZ{padding-top:20px}._104WttmN_o7lxHBXJkD5 .l2D8OkFjDMAN8uSQyvYM{padding-top:15px}._104WttmN_o7lxHBXJkD5 .brRtjpdDrPIpkmChJCFt{display:flex;align-items:center}._104WttmN_o7lxHBXJkD5 .jZThVYmE19sN2UUbHCBQ{margin:20px 0}._104WttmN_o7lxHBXJkD5 .FyoAkPIiZMPYdi6PQQWY{margin:20px 0}._104WttmN_o7lxHBXJkD5 .pfjqC18wtFabfOazxdbM{display:flex;justify-content:space-between;margin-bottom:-8px;padding-top:32px}", "",{"version":3,"sources":["webpack://./src/components/HardResetBranchModal/HardResetBranchModal.module.scss"],"names":[],"mappings":"AAGE,6CACE,gBAAA,CACA,kBAAA,CAGF,6CACE,iBAAA,CAGF,6CACE,gBAAA,CAGF,6CACE,gBAAA,CAGF,6CACE,YAAA,CACA,kBAAA,CAGF,6CACE,aAAA,CAGF,6CACE,aAAA,CAGF,6CACE,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,gBAAA","sourcesContent":["@import '@matillion/component-library/dist/assets/styles/colours';\n\n.HardResetModal {\n  .TitleContainer {\n    margin-top: -25px;\n    margin-bottom: 10px;\n  }\n\n  .Title {\n    text-align: center;\n  }\n\n  .Description {\n    padding-top: 20px;\n  }\n\n  .Container {\n    padding-top: 15px;\n  }\n\n  .BranchName {\n    display: flex;\n    align-items: center;\n  }\n\n  .Alert {\n    margin: 20px 0;\n  }\n\n  .ConsentCheckbox {\n    margin: 20px 0;\n  }\n\n  .Buttons {\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: -8px;\n    padding-top: 32px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"HardResetModal": "_104WttmN_o7lxHBXJkD5",
	"TitleContainer": "mwM_6Q8Nm2SM4K58HmAQ",
	"Title": "DDBPZAEF0ATAakKPd3c7",
	"Description": "_xQ5X8ZqEOY__hH4wGKZ",
	"Container": "l2D8OkFjDMAN8uSQyvYM",
	"BranchName": "brRtjpdDrPIpkmChJCFt",
	"Alert": "jZThVYmE19sN2UUbHCBQ",
	"ConsentCheckbox": "FyoAkPIiZMPYdi6PQQWY",
	"Buttons": "pfjqC18wtFabfOazxdbM"
};
export default ___CSS_LOADER_EXPORT___;
