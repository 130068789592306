import { FC } from 'react'

import { SVGIconProps } from '../types'

export const RemoveAll: FC<SVGIconProps> = (props) => {
  return (
    <svg
      aria-label="Remove all"
      {...props}
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
    >
      <circle r="19" transform="matrix(-1 0 0 1 19 19)" fill="#51A620" />
      <path
        d="M23.3177 23.75L18.9995 19.4318L23.3177 15.1136"
        stroke="#F2F2F2"
        strokeWidth="2"
      />
      <path
        d="M17.2723 23.75L12.9541 19.4318L17.2723 15.1136"
        stroke="#F2F2F2"
        strokeWidth="2"
      />
    </svg>
  )
}
