import { useCallback } from 'react'

import { useMutation } from '@tanstack/react-query'
import {
  type MergeArgs,
  type MergeRequestBody,
  type MergeResponse
} from './types'
import useMapConflictSelections from 'hooks/useMapConflictSelections/useMapConflictSelections'
import { type ConflictVersion } from 'components/ConflictVersionSelector/types'
import useDesignerClient from 'api/clients/useDesignerClient'

const conflictMapping = new Map<ConflictVersion, string>([
  ['OURS', 'SOURCE'],
  ['THEIRS', 'TARGET']
])

const useMerge = () => {
  const { project, client } = useDesignerClient()
  const { map } = useMapConflictSelections(conflictMapping)

  const merge = useCallback(
    async ({
      mergeMessage,
      targetBranch,
      sourceCommitId,
      targetCommitId,
      conflictSelections
    }: MergeArgs) => {
      const body: MergeRequestBody = {
        sourceCommitId,
        targetCommitId,
        targetBranchName: targetBranch,
        commitMessage: mergeMessage,
        conflictResolutions: map(conflictSelections)
      }

      const url = `projects/${project}/merges`
      const { data } = await client.post<MergeResponse>(url, body)

      return data
    },
    [client, project]
  )

  return useMutation([project], merge)
}

export default useMerge
