import axios, { AxiosError } from 'axios'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { JobState } from 'job-lib/store/jobSlice/job.types'
import { JobType } from 'job-lib/types/JobType'

import { useValidationDependencies } from 'modules/ComponentValidation/hooks/useValidationDependencies/useValidationDependencies'

import { useSpringClient } from '../useSpringClient/useSpringClient'
import {
  ValidateComponentParsedResponse,
  ValidateComponentRequest,
  ValidateComponentResponse
} from './types'

const jobUrlMap = {
  [JobType.Orchestration]: '/validation/orchestration',
  [JobType.Transformation]: '/validation/transformation'
}

interface UseValidateComponentProps {
  jobState: JobState
}

export const useValidateComponent = ({
  jobState
}: UseValidateComponentProps) => {
  const { environmentId, agentId, projectId } = useProjectInfo()
  const { job, jobType } = jobState
  const client = useSpringClient()
  const getValidationDependencies = useValidationDependencies()

  return async ({ component, cache }: ValidateComponentRequest) => {
    if (component === undefined || job === null || jobType === null) {
      return
    }

    const transformationCache = { cache: cache ?? {} }
    const validationDependencies = getValidationDependencies(
      component.implementationID,
      component.parameters
    )

    try {
      const { data } = await client.post<ValidateComponentResponse>(
        jobUrlMap[jobType],
        {
          component: component,
          agentId: agentId,
          environmentId: environmentId,
          projectId: projectId,
          variables: job.variables,
          ...(jobType === JobType.Transformation ? transformationCache : {}),
          dependencies: validationDependencies
        }
      )

      const parsedData: ValidateComponentParsedResponse = {
        ...data,
        componentName:
          job.components[component.id].parameters[1].elements[1].values[1].value
      }

      return parsedData
    } catch (err) {
      const error = err as Error | AxiosError

      /* istanbul ignore else */
      if (axios.isAxiosError(error)) {
        throw error.response?.data
      }
    }
  }
}
