import { type RequestHandler, rest } from 'msw'

import { PROJECT_ID } from '__test-utils__/ids'

import { type DescribePullChangesResponse } from 'api/hooks/useDescribePullChanges/types'
import { MockedPullDescriptions } from 'api/hooks/useDescribePullChanges/useDescribePullChanges.responses'
import { APPLICATION_PROBLEM_JSON } from '../../../__test-utils__/pact.config'

export const useStubDescribePullChanges = (
  baseUrl = '',
  projectId = PROJECT_ID
) => {
  return Object.keys(MockedPullDescriptions).map(() => {
    const url = `${baseUrl}/working-tree-service/v1/projects/${projectId}/branches/:branchName/merges`

    return rest.get(url, async (req, res, ctx) => {
      const branchNameParam = req.params.branchName as string

      if (branchNameParam === 'branchNotFound') {
        return res(
          ctx.status(404),
          ctx.set('content-type', APPLICATION_PROBLEM_JSON),
          ctx.json({
            type: 'working-tree/branch_not_found',
            title: 'Not found',
            status: 404
          })
        )
      } else if (branchNameParam === 'uncommittedChangesDescribeConflict') {
        return res(
          ctx.status(409),
          ctx.set('content-type', APPLICATION_PROBLEM_JSON),
          ctx.json({
            type: 'working-tree/uncommitted_changes_prevent_merge',
            title: 'Conflict',
            status: 409
          })
        )
      } else if (branchNameParam === '500') {
        return res(ctx.status(500))
      } else {
        return res(
          ctx.json<DescribePullChangesResponse>(
            MockedPullDescriptions[branchNameParam]
          )
        )
      }
    })
  })
}

export const useDescribePullChangesHandlers: RequestHandler[] = [
  ...useStubDescribePullChanges(),
  ...useStubDescribePullChanges('', '500')
]
