import React, { FC } from 'react'

import {
  Icon,
  Tooltip,
  TreeItem,
  Typography
} from '@matillion/component-library'

import { Schema } from 'api/hooks/useSchemas'

import { SchemaFolder } from './components/SchemaFolder'
import { TablesTree } from './components/TablesTree/TablesTree'
import { ViewsTree } from './components/ViewsTree/ViewsTree'
import classes from './SchemaRow.module.scss'

interface Props {
  schema: Schema
}

const { Schema: SchemaIcon } = Icon

export const SchemaRow: FC<Props> = ({ schema }) => {
  return (
    <TreeItem
      key={schema.name}
      nodeId={schema.name}
      label={schema.name}
      leaf={
        <div
          className={classes.Folder}
          data-testid={`schema-row-${schema.name}`}
        >
          <SchemaIcon className={classes.Folder__Icon} aria-hidden />
          <Tooltip content={schema.name} onlyShowOnOverflow>
            <div className={classes.SchemaTooltip}>
              <Typography
                format="bcs"
                className={classes.SchemaTooltip__Text}
                as="span"
              >
                {schema.name}
              </Typography>
            </div>
          </Tooltip>
        </div>
      }
    >
      <SchemaFolder
        id="table"
        schemaName={schema.name}
        labelKey="sideBar.schemaPanel.tablesRow"
      >
        <TablesTree schemaName={schema.name} />
      </SchemaFolder>

      <SchemaFolder
        id="view"
        schemaName={schema.name}
        labelKey="sideBar.schemaPanel.viewsRow"
      >
        <ViewsTree schemaName={schema.name} />
      </SchemaFolder>
    </TreeItem>
  )
}
