import { ParameterDataType } from 'api/hooks/useGetComponentMetadata/types'
import {
  ColumnType,
  EditorColumn
} from 'api/hooks/useGetParameterOptions/types'

import { ElementCollection, ValueCollection } from 'job-lib/types/Parameters'

export interface ValueAndTypes {
  [name: string]: {
    value: string | null
    type: ColumnType
  }
}

const getDefaultValue = (editorColumn: EditorColumn) => {
  switch (editorColumn.name) {
    case 'Clean Staged Files':
      return 'On'
    case 'Recreate Target Table':
      return 'On'
    case 'Trim String Columns':
      return 'On'
    case 'String Null is Null':
      return 'Off'
    case 'File Prefix':
      return ''
    case 'Compression type':
      return 'Gzip'
    default:
      return editorColumn.defaultValue
  }
}

export const getDefaultValues = (
  editorColumns: EditorColumn[],
  elements: ElementCollection
): ValueAndTypes => {
  const initialValues: ValueAndTypes = {}

  editorColumns.forEach((column, index) => {
    if (column.name && elements?.[index + 1]?.values?.['1']?.value) {
      initialValues[column.name] = {
        value: elements[index + 1].values['1'].value,
        type: column.type
      }
      return
    }

    const value = getDefaultValue(column)

    if (!column.name) {
      return
    }

    initialValues[column.name] = {
      value,
      type: column.type
    }
  })

  return initialValues
}

export function convertToElements(entries: ValueAndTypes): ElementCollection {
  const asValues: ValueCollection[] = Object.values(entries).map((entry) => {
    return {
      1: {
        slot: 1,
        value: entry.value ?? '',
        type: 'STRING',
        dataType: entry.type as ParameterDataType
      }
    }
  })

  const elementsCollection: ElementCollection = {}

  asValues.forEach((values, index) => {
    const slot = index + 1

    elementsCollection[slot] = {
      slot,
      values
    }
  })

  return elementsCollection
}
