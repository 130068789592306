import { createElement, FC, PropsWithChildren } from 'react'

import classnames from 'classnames'

import { IconButtonProps } from '../types'
import classes from './IconButton.module.scss'

export const IconButton: FC<PropsWithChildren<IconButtonProps>> = ({
  children,
  className,
  disabled,
  onClick,
  icon,
  'aria-label': ariaLabel,
  ...props
}) => {
  return (
    <button
      type="button"
      disabled={disabled}
      onClick={onClick}
      className={classnames(classes.IconButton, className, {
        [classes['IconButton--disabled']]: disabled
      })}
      {...props}
    >
      {createElement(icon, { 'aria-label': ariaLabel })}
      {!!ariaLabel && (
        <span aria-hidden className={classes.IconButton__Tooltip}>
          {ariaLabel}
        </span>
      )}
    </button>
  )
}
