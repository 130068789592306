import { OrchestrationJob } from 'job-lib/types/Job'

export const canDeleteComponents = (
  idsToRemove: number[],
  job: OrchestrationJob
) => {
  const startComponentImplementationId = 444132438

  const componentsArray = Object.values(job.components)

  let numberOfStartComponents = componentsArray.filter(
    (component) => component.implementationID === startComponentImplementationId
  ).length

  idsToRemove.forEach((id) => {
    if (
      job.components[id].implementationID === startComponentImplementationId
    ) {
      numberOfStartComponents -= 1
    }
  })

  if (numberOfStartComponents > 0) {
    return true
  }

  return false
}
