import { FC } from 'react'

import { SVGIconProps } from '../types'

export const Reset: FC<SVGIconProps> = (props) => {
  return (
    <svg
      aria-label="Reset"
      {...props}
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
    >
      <circle r="19" transform="matrix(1 0 0 -1 19 19)" fill="#51A620" />
      <path
        d="M9.2001 19.0454L13.4001 14.5454L17.6001 19.0454"
        stroke="#F2F2F2"
        strokeWidth="2"
      />
      <path
        d="M19.4542 13C22.7927 13 25.4992 15.7064 25.4992 19.045C25.4992 22.3836 22.7927 25.09 19.4542 25.09C16.1156 25.09 13.4092 22.3836 13.4092 19.045V15.8126"
        stroke="#F2F2F2"
        strokeWidth="2"
      />
    </svg>
  )
}
