import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Alert,
  Button,
  Checkbox,
  Field,
  Typography
} from '@matillion/component-library'

import BranchIcon from 'icons/BranchIcon.svg'
import classes from './HardResetBranchModal.module.scss'
import useGitContext from '../../provider/GitProvider/useGitContext'
import { type HardResetBranchModalProps } from './types'
import useHardResetBranchAction from '../../hooks/useHardResetBranchAction'
import ModalTitle from '../ModalTitle'

const HardResetBranchModal = ({
  onHardReset,
  onClose
}: HardResetBranchModalProps) => {
  const { refreshWorkingTreeStatus, isNativeGit } = useGitContext()
  const [hasConsented, setHasConsented] = useState(false)

  const { t } = useTranslation('translation', {
    keyPrefix: 'git.hard-reset.modal'
  })

  const { branch: branchId } = useGitContext()
  const { hardResetBranch, loading: isLoading } = useHardResetBranchAction({
    onResolve: () => onClose?.()
  })

  const handleConsentChange = useCallback(() => {
    setHasConsented(!hasConsented)
  }, [hasConsented, setHasConsented])

  const hardResetHandler = () => {
    hardResetBranch().then(() => {
      refreshWorkingTreeStatus?.({
        refreshPipelines: true,
        refreshFileSummaries: true
      })

      onHardReset?.()
    })
  }

  return (
    <>
      <ModalTitle
        title={t('title')}
        description={t(`subtitle.${isNativeGit ? 'native' : 'legacy'}`)}
        data-testId="hard-reset-title"
      />

      <Alert
        type="warning"
        theme="dark"
        message={t('warning')}
        className={classes.Alert}
        data-testid="hard-reset-alert"
      />

      <div className={classes.Container}>
        <Typography
          format="bcs"
          weight="bold"
          data-testid="hard-reset-branch-label"
        >
          {t('label.branch')}
        </Typography>
        <div
          data-testid="hard-reset-branch-name"
          className={classes.BranchName}
        >
          <img src={BranchIcon} alt={'Branch Icon'} />
          <span>{branchId}</span>
        </div>
      </div>

      <Field
        text={t('consent')}
        inputComponent={Checkbox}
        onChange={handleConsentChange}
        className={classes.ConsentCheckbox}
        data-testid="hard-reset-consent-box"
      />

      <div className={classes.Buttons}>
        <Button
          type="reset"
          alt="secondary"
          text={t('cancel')}
          onClick={onClose}
          data-testid="cancel-hard-reset-button"
        />

        <Button
          alt="danger"
          type="submit"
          text={t('submit')}
          waiting={isLoading}
          onClick={hardResetHandler}
          disabled={!hasConsented}
          data-testid="hard-reset-branch-button"
        />
      </div>
    </>
  )
}

export default HardResetBranchModal
