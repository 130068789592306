import { FC } from 'react'

import {
  FreeformTextareaEditor,
  FreeformTextareaProps
} from '../FreeformTextareaEditor/FreeformTextareaEditor'

export const BashEditor: FC<FreeformTextareaProps> = ({ ...props }) => (
  <FreeformTextareaEditor {...props} language="shell" />
)
