import { useTranslation } from 'react-i18next'

import { type AxiosError } from 'axios'
import { type ProblemDetails } from 'types/ProblemDetails'
import {
  type ProblemDetailRegistry,
  type ProblemDetailResponse,
  type ResolveProblemDetailsResponse,
  type TranslationArg,
  type TranslationKeys
} from 'hooks/useProblemDetails/types'
import { type StringMap } from 'i18next'

const isAxiosError = (error: unknown): error is AxiosError => {
  // @ts-expect-error not much we can do in a type-guard
  return 'isAxiosError' in error
}

const useProblemDetails = (
  registry: ProblemDetailRegistry,
  messageArgs?: TranslationArg[]
): ResolveProblemDetailsResponse => {
  const { t } = useTranslation()

  const findTranslationKeyRecord = (problemType: string | undefined) => {
    return registry.find((value) => value.type === problemType)
  }

  const computeInterpolationMap = (): StringMap => {
    const reduceArgs = (map: StringMap, { name, value }: TranslationArg) => {
      map[name] = value
      return map
    }

    return messageArgs?.reduce<StringMap>(reduceArgs, {}) ?? {}
  }

  const computeTranslationText = (
    keys: TranslationKeys
  ): ProblemDetailResponse => {
    const interpolationMap = computeInterpolationMap()

    return {
      title: t(keys.titleKey, interpolationMap),
      message: t(keys.messageKey, interpolationMap)
    }
  }

  const parseErrorResponse = (error: unknown): ProblemDetails | undefined => {
    const isCorrectErrorType = isAxiosError(error)

    if (isCorrectErrorType && error.response && error.response.headers) {
      const contentTypeHeaders = error.response.headers['content-type']
      const hasCorrectContentType = contentTypeHeaders?.includes(
        'application/problem+json'
      )

      if (hasCorrectContentType) {
        return error.response.data as ProblemDetails
      }
    }

    return undefined
  }

  const resolveProblemDetails = (
    error: unknown,
    defaultTranslationKeys: TranslationKeys
  ): ProblemDetailResponse => {
    const problem = parseErrorResponse(error)
    if (problem) {
      const translationKeyRecord = findTranslationKeyRecord(problem.type)

      const translationKeys = translationKeyRecord?.translation
      if (translationKeys) {
        return computeTranslationText(translationKeys)
      }
    }

    return computeTranslationText(defaultTranslationKeys)
  }

  const isProblemType = (error: unknown, type: string) => {
    const problem = parseErrorResponse(error)
    return problem ? problem.type === type : false
  }

  return {
    isProblemType,
    resolveProblemDetails
  }
}

export default useProblemDetails
