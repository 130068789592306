import { Cardinality, ConnectionPortType } from 'job-lib/types/Components'
import { JobType } from 'job-lib/types/JobType'

import {
  ComponentEditorTabType,
  ComponentMetadataResponse,
  EditorType,
  LookUpType,
  ParameterDataType,
  UseVariable
} from '../../types'

export const mockFlattenVariantMetadata: ComponentMetadataResponse = {
  componentId: 'flatten-variant',
  metadata: {
    schemaVersion: 1,
    emeraldId: '416069678',
    componentType: JobType.Transformation,
    inputPorts: [
      {
        portId: ConnectionPortType.INPUT,
        cardinality: Cardinality.ONE
      }
    ],
    outputPorts: [
      {
        portId: ConnectionPortType.UNCONDITIONAL,
        cardinality: Cardinality.MANY
      }
    ],
    deprecated: false,
    exports: [],
    editorTabs: [
      ComponentEditorTabType.PROPERTIES,
      ComponentEditorTabType.SAMPLE,
      ComponentEditorTabType.METADATA,
      ComponentEditorTabType.SQL,
      ComponentEditorTabType.PLAN,
      ComponentEditorTabType.HELP
    ],
    parameters: [
      {
        id: 'component-name',
        dplID: 'componentName',
        metlSlot: 1,
        useVariable: UseVariable.NO,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'include-input-columns',
        dplID: 'includeInputColumns',
        metlSlot: 4,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: [
          {
            name: null,
            type: ParameterDataType.TEXT,
            options: ['Yes', 'No'],
            columnEditorType: null,
            defaultValue: null,
            lookupType: null,
            lookupDependencies: null
          }
        ],
        defaultValue: null
      },
      {
        id: 'column-mapping',
        dplID: 'columnMapping',
        metlSlot: 2,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.GRID,
        editorType: EditorType.GRID_EDITOR,
        lookupType: LookUpType.DYNAMIC_TRANSFORM_FLATTEN_VARIANT_COLUMN_MAPPING,
        lookupDependencies: ['transform.sql', 'param.column-flattens.alias'],
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'column-flattens',
        dplID: 'columnFlattens',
        metlSlot: 3,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.GRID,
        editorType: EditorType.GRID_EDITOR,
        lookupType: LookUpType.DYNAMIC_TRANSFORM_COLUMN_FLATTENS,
        lookupDependencies: ['transform.sql', 'param.column-flattens.alias'],
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      }
    ]
  }
}
