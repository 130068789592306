import { forwardRef, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'

import { Tooltip, TreeItem, Typography } from '@matillion/component-library'
import classNames from 'classnames'

import { useSchemaViews } from 'api/hooks/useSchemaViews/useSchemaViews'

import { ReactComponent as ViewIcon } from 'assets/SchemaView.svg'

import { useProjectNames } from 'hooks/useProjectInfo/useProjectNames'

import { SimpleRefetchRef } from 'modules/SideBar/types'

import classes from '../../SchemaRow.module.scss'
import { RowEmpty } from '../RowEmpty/RowEmpty'
import { RowError } from '../RowError/RowError'
import { RowLoading } from '../RowLoading/RowLoading'

interface Props {
  schemaName: string
}

const ViewsTree = forwardRef<SimpleRefetchRef, Props>(({ schemaName }, ref) => {
  const { warehouse } = useProjectNames()
  const { t } = useTranslation()
  const {
    isLoading,
    isRefetching,
    isError,
    refetch,
    data: views
  } = useSchemaViews(warehouse, schemaName)

  useImperativeHandle(
    ref,
    () => ({
      refetch
    }),
    [refetch]
  )

  if (isLoading || isRefetching) {
    return (
      <TreeItem
        nodeId={`${schemaName}-table-loading`}
        label="loading"
        leaf={<RowLoading data-testid="schema-tables-loading" />}
      />
    )
  }

  if (isError) {
    return (
      <TreeItem
        nodeId={`${schemaName}-table-error`}
        label="error"
        leaf={
          <RowError
            data-testid="schema-tables-error"
            text={t('sideBar.schemaPanel.rowViewsError')}
          />
        }
      />
    )
  }

  if (!views?.length) {
    return (
      <TreeItem
        nodeId={`${schemaName}-table-no-data`}
        label="no tables"
        leaf={
          <RowEmpty
            data-testid="schema-tables-empty"
            text={t('sideBar.schemaPanel.noViews')}
          />
        }
      />
    )
  }

  return (
    <>
      {views.map((view) => (
        <TreeItem
          key={`${schemaName}-${view.name}`}
          nodeId={view.name}
          label={view.name}
          leaf={
            <div
              className={classNames(classes.Folder, classes.DefaultCursor)}
              data-testid={`tree-schema-view-${schemaName}-${view.name}`}
            >
              <ViewIcon className={classes.Folder__Icon} aria-hidden />
              <Tooltip content={view.name} onlyShowOnOverflow>
                <div className={classes.SchemaTooltip}>
                  <Typography
                    format="bcs"
                    className={classes.SchemaTooltip__Text}
                    as="span"
                  >
                    {view.name}
                  </Typography>
                </div>
              </Tooltip>
            </div>
          }
        />
      ))}
    </>
  )
})

ViewsTree.displayName = 'ViewsTree'

export { ViewsTree }
