import { useTranslation } from 'react-i18next'

import { Button } from '@matillion/component-library'

import { useProjectPermission } from 'api/external/usePermission/useProjectPermission'
import { useSampleComponent } from 'api/hooks/useSampleComponent/useSampleComponent'

import { Loading } from 'components/Loading/Loading'

import { ComponentInstanceId } from 'job-lib/types/Job'
import { JobType } from 'job-lib/types/JobType'

import { useComponentValidationResult } from 'modules/ComponentValidation/hooks/useComponentValidationResult'

import { NoContentMessage } from '../NoContentMessage'
import classes from './SampleComponent.module.scss'
import { useSampleProvider } from './SampleProvider'
import { SampleTable } from './SampleTable'

interface SampleComponentProps {
  componentId: ComponentInstanceId
  componentName: string
  jobType: JobType
}

export const SampleComponent = ({
  componentId,
  componentName,
  jobType
}: SampleComponentProps) => {
  const { t } = useTranslation()
  const { hasPermission } = useProjectPermission('sampling_pipelines')
  const {
    isError: hasValidationError,
    isLoading: isValidating,
    isSuccess: isValidated,
    componentCache
  } = useComponentValidationResult(componentId)
  const {
    mutateAsync: sampleComponent,
    isLoading: isSampling,
    isError
  } = useSampleComponent(jobType)
  const { currentSample, setCurrentSample } = useSampleProvider()

  const isTransformationJob = jobType === JobType.Transformation
  const isLoading = isValidating || isSampling
  const isWaitingForValidation =
    isTransformationJob && !hasValidationError && !isValidated
  const isSamplingDisabled =
    isWaitingForValidation || hasValidationError || !hasPermission

  const renderSampleContent = () => {
    if (isWaitingForValidation) {
      if (!hasPermission) {
        return (
          <NoContentMessage text={t('sampleComponent.sampleNoPermission')} />
        )
      } else {
        return (
          <NoContentMessage
            text={t('sampleComponent.validationNotTriggeredYet')}
          />
        )
      }
    }

    if (hasValidationError) {
      return <NoContentMessage text={t('sampleComponent.validationError')} />
    }

    if (isLoading) {
      return (
        <Loading
          data-testid="fetching-sample-loading-spinner"
          className={classes.SampleLoading}
          text={t('sampleComponent.fetchingSample')}
        />
      )
    }

    if (isError) {
      return <NoContentMessage text={t('sampleComponent.sampleError')} />
    }

    if (!currentSample) {
      return (
        <NoContentMessage text={t('sampleComponent.sampleNotTriggeredYet')} />
      )
    }

    if (currentSample.rows.length === 0) {
      return <NoContentMessage text={t('sampleComponent.noResultsFound')} />
    }

    return (
      <SampleTable
        title={t('sampleComponent.tableTitle', { componentName })}
        data={currentSample}
      />
    )
  }

  const handleSample = () => {
    if (!componentCache) {
      return
    }

    const runSample = async () => sampleComponent({ componentCache })

    runSample().then((response) => {
      /* istanbul ignore else */
      if (response) {
        setCurrentSample(response)
      }
    })
  }

  return (
    <>
      <form className={classes.SampleComponentForm}>
        <div className={classes.SampleComponentForm__Container}>
          <Button
            data-testid="sample-button"
            className={classes.SampleComponentForm__SampleButton}
            size="sm"
            text={t('sampleComponent.sampleButtonText')}
            disabled={isSamplingDisabled}
            waiting={isLoading}
            onClick={handleSample}
          />
        </div>
      </form>

      {renderSampleContent()}
    </>
  )
}
