import { FC, ReactNode } from 'react'

import { Typography } from '@matillion/component-library'

import classes from '../ParameterOverlay.module.scss'

interface HeaderProps {
  children?: ReactNode
  title: string
}

export const Header: FC<HeaderProps> = ({ children, title }) => (
  <div className={classes.ParameterOverlay__Header}>
    <Typography format="tm" as="h2" id="parameter-overlay-title">
      {title}
    </Typography>
    <div className={classes.ParameterOverlay__Header_Actions}>{children}</div>
  </div>
)
