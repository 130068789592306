import { FunctionComponent, memo } from 'react'
import { ConnectionLineComponentProps, getStraightPath } from 'reactflow'

import { getEdgeStyle } from 'modules/Canvas/components/FlowCanvas/components/FlowEdge'

export const FlowConnectionLine: FunctionComponent<ConnectionLineComponentProps> =
  memo(({ fromX, fromY, toX, toY, fromHandle }) => {
    const [path] = getStraightPath({
      sourceX: fromX,
      sourceY: fromY,
      targetX: toX,
      targetY: toY
    })

    return (
      <g>
        <path fill="none" style={getEdgeStyle(fromHandle?.id)} d={path} />
      </g>
    )
  })

FlowConnectionLine.displayName = 'FlowConnectionLine'
