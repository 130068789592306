import { FunctionComponent, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useAgentHost } from 'api/hooks/useAgentHost/useAgentHost'
import useGetComponentTree from 'api/hooks/useGetComponentTree'

import { Loading } from 'components/Loading/Loading'
import { EmptyPanel } from 'components/Panels/EmptyPanel'
import { ErrorPanel } from 'components/Panels/ErrorPanel'

import config from 'config'

import { useFlags } from 'hooks/useFlags'
import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { useWorkingCopy } from 'modules/EtlDesigner/hooks/useWorkingCopy'
import { Filterable } from 'modules/SideBar/types'

import BrowserTree from '../BrowserTree/BrowserTree'
import classes from './ComponentBrowser.module.scss'
import { renderTree } from './components/renderTree'
import { filterData, nestComponentsInTree } from './components/utils'
import { getComponentFlagsInKebabCase } from './utils/getComponentFlagsInKebabCase'

export interface ComponentBrowserProps extends Filterable {}

const ComponentBrowser: FunctionComponent<ComponentBrowserProps> = ({
  searchTerm,
  expanded
}) => {
  const { summary } = useWorkingCopy()
  const { jobSummaryId } = useProjectInfo()
  const { componentTree, isLoading, isError } = useGetComponentTree(
    summary?.type
  )
  const { t } = useTranslation(['translation', 'components'])
  const expandedItems = { Data: true, Orchestration: true }
  const flags = useFlags()
  const {
    agentHost,
    isInitialLoading,
    isError: isAgentHostError
  } = useAgentHost()

  const { components, resultsFound } = useMemo(() => {
    const componentFlags = getComponentFlagsInKebabCase(flags)

    const enabledComponents = [
      ...config.enabledComponents,
      ...Object.keys(componentFlags)
    ].filter((component) => {
      if (component in componentFlags) {
        return componentFlags[component]
      }
      return component
    })

    const filteredData =
      searchTerm && componentTree
        ? filterData(componentTree, searchTerm, t)
        : componentTree

    return {
      components: nestComponentsInTree(
        filteredData,
        t,
        agentHost,
        enabledComponents
      ),
      resultsFound: Object.keys(filteredData ?? {}).length > 0
    }
  }, [componentTree, searchTerm, t, flags, agentHost])

  if (isLoading || isInitialLoading) {
    return <Loading className={classes.ComponentBrowser__Loading} />
  }

  if (isError || !agentHost || isAgentHostError) {
    return (
      <ErrorPanel
        includeLink={false}
        text={t('sideBar.componentPanel.networkError')}
      />
    )
  }

  if (!resultsFound) {
    return (
      <ErrorPanel
        includeLink={false}
        text={t('sideBar.componentPanel.notFound')}
      />
    )
  }
  if (summary && componentTree) {
    return (
      <BrowserTree expandedItems={expandedItems} expanded={expanded}>
        {components && renderTree(components)}
      </BrowserTree>
    )
  }

  if (!jobSummaryId) {
    return (
      <EmptyPanel>
        {t('translation:sideBar.componentPanel.noJobSelected')}
      </EmptyPanel>
    )
  }

  return null
}

export { ComponentBrowser }
