import React, { type PropsWithChildren, useEffect } from 'react'

import { type PactHookConfig } from './types'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import GitProvider, { type GitProviderProps } from 'provider/GitProvider'
import axios from 'axios'
import {
  PROJECT_ID,
  BRANCH_NAME,
  PACT_ID_HEADER
} from '__test-utils__/pact.config'
import { clientConfig } from 'api/clients/useClient/useClient'

type PactHookProvidersProps = PropsWithChildren<Partial<PactHookConfig>>

export const defaultProviderProps: GitProviderProps = {
  featureFlags: {
    pullChangesV2: true
  },
  branch: BRANCH_NAME,
  project: PROJECT_ID,
  isNativeGit: false,
  environment: 'test',
  designerClient: axios.create(),
  fileSummaries: [],
  onRefreshWorkingTreeStatus: () => {
    console.debug('onRefreshWorkingTreeStatus was called during a pact test.')
  }
}

const queryClientPact = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false
    }
  }
})

const CoreProviders = ({
  gitProvider,
  interactionId,
  children
}: PactHookProvidersProps) => {
  const providerProps = {
    ...defaultProviderProps,
    ...gitProvider
  }

  useEffect(() => {
    if (interactionId) {
      clientConfig.headers = {
        [PACT_ID_HEADER]: interactionId
      }
    }
  }, [interactionId])

  return (
    <div data-testid="core-pact-providers">
      <GitProvider {...providerProps}>
        <QueryClientProvider client={queryClientPact}>
          {children}
        </QueryClientProvider>
      </GitProvider>
    </div>
  )
}

const PactHookProviders = (props: PactHookProvidersProps) => {
  const CustomWrapper = props.wrapper

  if (CustomWrapper) {
    return (
      <CustomWrapper>
        <CoreProviders {...props} />
      </CustomWrapper>
    )
  }

  return <CoreProviders {...props} />
}

export default PactHookProviders
