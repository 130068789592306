import axios, { AxiosResponse } from 'axios'

import config from 'config'

/**
 * The resource type that is associated with a permission, at time of writing this is 'project' most of the time.
 * This value is paired with an ID to get the relationship between a resource and a permission.
 */
export type ResourceType = string

// types taken from https://permissions.core-dev.matillion.com/swagger-ui/index.html#/permissions-controller/checkPermission
export interface Resource {
  type: ResourceType
  id: string
}

export type Permission =
  | 'create_pipelines'
  | 'view_pipelines'
  | 'update_pipelines'
  | 'run_pipelines'
  | 'view_schemas'
  | 'sampling_pipelines'
  | 'validate_pipelines'
  | 'delete_pipelines'
  | 'create_schedules'

export interface PermissionCheckBody {
  resource: Resource
  permission: Permission
}

export enum PermissionValue {
  UNSPECIFIED = 'UNSPECIFIED',
  NO_PERMISSION = 'NO_PERMISSION',
  HAS_PERMISSION = 'HAS_PERMISSION',
  CONDITIONAL_PERMISSION = 'CONDITIONAL_PERMISSION',
  UNRECOGNIZED = 'UNRECOGNIZED'
}

export interface CheckPermissionResponse {
  permission: PermissionValue
}

export interface CheckPermissionArgs {
  token: string
  permission: Permission
  resource: Resource
}

export type CheckPermission = (
  args: CheckPermissionArgs
) => Promise<AxiosResponse<CheckPermissionResponse>>

const checkURL = '/api/v1/permission/check'
const permissionsApi = axios.create({
  headers: {
    'Content-Type': 'application/json'
  }
})

export const checkPermission: CheckPermission = async ({
  token,
  permission,
  resource
}) => {
  const authHeader = `Bearer ${token}`
  const body: PermissionCheckBody = {
    resource,
    permission
  }

  const response = await permissionsApi.post<
    PermissionCheckBody,
    AxiosResponse<CheckPermissionResponse>
  >(checkURL, body, {
    baseURL: config.PERMISSIONS_API_BASE_URL,
    headers: {
      Authorization: authHeader
    }
  })

  return response
}
