import { useCallback, useEffect, useState } from 'react'
import { type DescribeMergeConflict } from 'api/hooks/useDescribeMerge/types'
import { type ConflictVersionSelections } from './types'
import { type ConflictResolutionSelection } from 'components/ConflictResolutionTable/types'

const useResolveConflicts = (
  conflicts?: DescribeMergeConflict[]
): ConflictVersionSelections => {
  const [selections, setSelections] = useState<ConflictResolutionSelection[]>(
    []
  )

  const hasResolvedAll = selections.every(
    (selection) => selection.version !== 'NOT_SELECTED'
  )

  useEffect(() => {
    if (conflicts && conflicts?.length > 0) {
      setSelections(
        conflicts?.map((conflict) => ({
          fileName: conflict.fileName,
          version: 'NOT_SELECTED'
        }))
      )
    }
  }, [conflicts])

  const handleConflictVersionSelection = useCallback(
    (newSelection: ConflictResolutionSelection) => {
      setSelections((selected) =>
        selected.map((existingSelection) => {
          return existingSelection.fileName === newSelection.fileName
            ? newSelection
            : existingSelection
        })
      )
    },
    []
  )

  return {
    selections,
    hasResolvedConflicts: hasResolvedAll,
    updateSelection: handleConflictVersionSelection
  }
}

export default useResolveConflicts
