import { Fragment, FunctionComponent } from 'react'

import { Button, Tooltip, Typography } from '@matillion/component-library'
import classnames from 'classnames'

import { PopOverMenu } from 'components/PopOverMenu'

import classes from './InteractiveTabs.module.scss'
import { ScrollContainer } from './ScrollContainer'
import { TabCloseButton } from './TabCloseButton'
import { TabContextMenu } from './TabContextMenu'
import { TabsListProps } from './types'

export const TabList: FunctionComponent<
  React.PropsWithChildren<TabsListProps>
> = ({
  listClassName,
  onChange,
  currentTabIndex,
  setInternalTabIndexValue,
  children,
  onCloseAll
}) => {
  return (
    <ScrollContainer>
      <div
        role="tablist"
        data-testid="tab-list"
        className={classnames(classes.InteractiveTabs__TabList, listClassName)}
      >
        {children.map((tab, index) => (
          <Fragment key={tab.props.internalKey}>
            <PopOverMenu
              className={classes.InteractiveTabs__PopOver}
              positionAtMouse
              content={
                <TabContextMenu
                  title={tab.props.title}
                  index={index}
                  onTabClose={tab.props.onTabClose}
                  onCloseAll={onCloseAll}
                />
              }
            >
              {({ onContextMenu }) => (
                <div
                  className={classnames(
                    classes.InteractiveTabs__TabContainer,
                    tab.props.className,
                    {
                      [classes['InteractiveTabs__TabContainer--active']]:
                        index === currentTabIndex
                    }
                  )}
                >
                  <Button
                    className={classnames(classes.InteractiveTabs__TabButton)}
                    data-testid={tab.props['data-testid']}
                    aria-selected={index === currentTabIndex ? 'true' : 'false'}
                    type="button"
                    role="tab"
                    alt="text"
                    disabled={tab.props.disabled}
                    onClick={() => {
                      setInternalTabIndexValue(index)
                      if (onChange) {
                        onChange({ value: index, tab: tab.props })
                      }
                    }}
                    onContextMenu={onContextMenu}
                  >
                    {tab.props.iconBefore && (
                      <span className={classes.InteractiveTabs__TabIconBefore}>
                        {tab.props.iconBefore}
                      </span>
                    )}
                    <Tooltip content={tab.props.tooltip} onlyShowOnOverflow>
                      <div className={classes.InteractiveTabs__TabTitle}>
                        <Typography format="bcs" as="span">
                          {tab.props.title}
                        </Typography>
                      </div>
                    </Tooltip>
                  </Button>
                  {tab.props.onTabClose && (
                    <TabCloseButton
                      testId={`close-${
                        tab.props['data-testid'] ?? `tab-${index}`
                      }`}
                      title={tab.props.title}
                      onClick={tab.props.onTabClose}
                    />
                  )}
                </div>
              )}
            </PopOverMenu>
          </Fragment>
        ))}
      </div>
    </ScrollContainer>
  )
}
