import { FC, useCallback } from 'react'

import {
  ComponentMetadata,
  ComponentParameter,
  EditorType
} from 'api/hooks/useGetComponentMetadata/types'
import { ComponentSummaryId } from 'api/hooks/useGetComponentTree'
import { EditorColumn } from 'api/hooks/useGetParameterOptions/types'
import { ProblemDetails } from 'api/types/http-problem-details'

import { createHomogeneousElements } from 'job-lib/builders/createElementsCollection'
import { ComponentInstanceId } from 'job-lib/types/Job'
import { ElementCollection } from 'job-lib/types/Parameters'

import { Dropdown } from 'modules/ParameterEditors/components/Dropdown/Dropdown'
import { FreeTextInputEditor } from 'modules/ParameterEditors/components/FreeTextInputEditor/FreeTextInputEditor'
import { ModalTriggerEditor } from 'modules/ParameterEditors/components/ModalTriggerEditor/ModalTriggerEditor'

import classes from './ValueRenderer.module.scss'

export interface ValueRendererProps {
  labelId: string
  inputId: string

  value: string[]
  hasError: boolean
  elements?: ElementCollection
  componentMetadata: ComponentMetadata
  componentInstanceId: ComponentInstanceId
  componentSummaryId: ComponentSummaryId

  parameter?: ComponentParameter
  parameterName: string

  onEdit: (
    editedValue: ElementCollection,
    editorColumns?: EditorColumn[]
  ) => void
  onEditorError: (error?: ProblemDetails) => void
}

export const ValueRenderer: FC<ValueRendererProps> = ({
  labelId,
  inputId,
  value,
  hasError,
  elements,
  componentMetadata,
  componentSummaryId,
  componentInstanceId,
  parameter,
  parameterName,
  onEdit,
  onEditorError
}) => {
  const editorType = parameter?.editorType
  const isInlineEditor =
    editorType === EditorType.DROPDOWN || editorType === EditorType.FREETEXT

  const onEditSingleValue = useCallback(
    (val: string | string[]) =>
      onEdit(createHomogeneousElements(val, parameter?.dataType)),
    [onEdit, parameter]
  )

  if (editorType === EditorType.FREETEXT) {
    return (
      <div className={classes.ValueRenderer}>
        <FreeTextInputEditor
          inputId={inputId}
          hasError={hasError}
          value={value[0] || ''}
          onEdit={onEditSingleValue}
          labelId={labelId}
        />
      </div>
    )
  }

  if (editorType === EditorType.DROPDOWN) {
    return (
      <div className={classes.ValueRenderer}>
        <Dropdown
          inputId={inputId}
          hasError={hasError}
          value={value[0] || ''}
          onEdit={onEditSingleValue}
          onEditorError={onEditorError}
          componentSummaryId={componentSummaryId}
          componentMetaData={componentMetadata}
          parameter={parameter}
          parameterName={parameterName}
          resultsDataBoundary="auto-complete-property-dropdown"
        />
      </div>
    )
  }

  if (!isInlineEditor && editorType) {
    return (
      <div className={classes.ValueRenderer}>
        <div className={classes.ValueRenderer__InputContainer}>
          <ModalTriggerEditor
            inputId={inputId}
            value={value}
            elements={elements}
            hasError={hasError}
            onEdit={onEdit}
            onEditorError={onEditorError}
            editorType={editorType}
            componentSummaryId={componentSummaryId}
            componentMetaData={componentMetadata}
            parameter={parameter}
            parameterName={parameterName}
            componentInstanceId={componentInstanceId}
          />
        </div>
      </div>
    )
  }

  // TODO: is this a reachable branch of code, in practice? can we safely
  // fall through and let ModalTriggerEditor handle unrecognised editor types?
  return <div className={classes.ValueRenderer}>{value}</div>
}
