import { type GitProviderAuthModalProps } from './types'
import useInitOAuthAction from 'hooks/useInitOAuthAction'
import { useState } from 'react'
import AuthModalWaitingState from './states/AuthModalWaitingState'
import AuthModalLoadingState from './states/AuthModalLoadingState'
import AuthModalReadyState from './states/AuthModalReadyState'
import AuthModalFailedState from './states/AuthModalFailedState'

const AuthModalForm = ({ provider, onClose }: GitProviderAuthModalProps) => {
  const [hasFailed, setHasFailed] = useState(false)
  const [isWaitingForUser, setIsWaitingForUser] = useState(false)

  const { loading, authorizationURL } = useInitOAuthAction({
    provider,
    onFailure: () => {
      setHasFailed(true)
    }
  })

  const handleClickAuth = () => {
    window.open(authorizationURL)
    setIsWaitingForUser(true)
  }

  if (loading) {
    return <AuthModalLoadingState />
  }

  if (isWaitingForUser) {
    return <AuthModalWaitingState provider={provider} onAbandon={onClose} />
  }

  if (hasFailed) {
    return <AuthModalFailedState provider={provider} onCancel={onClose} />
  }

  return (
    <AuthModalReadyState
      onClose={onClose}
      loading={loading}
      provider={provider}
      onAuthorize={handleClickAuth}
    />
  )
}

export default AuthModalForm
