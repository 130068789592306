import { ReactNode } from 'react'

import { Typography } from '@matillion/component-library'

import classes from './ComponentParameterGroup.module.scss'

interface ComponentParameterGroupProps {
  children: ReactNode
  label: string
}

export const ComponentParameterGroup = ({
  children,
  label
}: ComponentParameterGroupProps) => (
  <fieldset
    data-testid="component-parameter-group"
    className={classes.ComponentParameterGroup}
  >
    <legend className={classes.ComponentParameterGroup__Label}>
      <Typography format="mc" weight="bold">
        {label}
      </Typography>
    </legend>
    {children}
  </fieldset>
)
