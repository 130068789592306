import { FC } from 'react'
import { PanelResizeHandle } from 'react-resizable-panels'

import classnames from 'classnames'

import classes from './ResizeableHandler.module.scss'
import { Props } from './types'

export const ResizeableHandler: FC<Props> = ({ orientation, disabled, id }) => {
  if (disabled) {
    return (
      <div
        className={classnames(
          classes.ResizeableHandler,
          classes['ResizeableHandler--Inactive'],
          classes[`ResizeableHandler--${orientation}`]
        )}
      >
        <div
          className={classnames(
            classes.ResizeableHandler__Indicator,
            classes[`ResizeableHandler__Indicator--${orientation}`]
          )}
        />
      </div>
    )
  }

  return (
    <PanelResizeHandle
      className={classnames(
        classes.ResizeableHandler,
        classes['ResizeableHandler--Active'],
        classes[`ResizeableHandler--${orientation}`]
      )}
      id={`resizeable-${id as string}-bar`}
    >
      <div
        className={classnames(
          classes.ResizeableHandler__Indicator,
          classes[`ResizeableHandler__Indicator--${orientation}`]
        )}
      />
    </PanelResizeHandle>
  )
}
