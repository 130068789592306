import { ComponentParameter } from 'api/hooks/useGetComponentMetadata/types'

interface CreateDPLParametersParams {
  componentName: string
  componentParameters?: ComponentParameter[]
}

interface ParameterDPL {
  componentName: string
  [key: string]: { [key: string]: string } | string | null
}

export const createDPLParameters = ({
  componentName,
  componentParameters = []
}: CreateDPLParametersParams): ParameterDPL => {
  const createParameters = (
    parameterDPL: ParameterDPL,
    parameter: ComponentParameter
  ) => {
    parameterDPL[parameter.dplID] = parameterDPL[parameter.dplID] ?? {}

    if (parameter.childProperties) {
      parameter.childProperties?.forEach((childParameter) => {
        createParameters(
          parameterDPL[parameter.dplID] as keyof object,
          childParameter
        )
      })
    } else {
      parameterDPL[parameter.dplID] = parameter.defaultValue ?? null
    }

    return parameterDPL
  }

  return componentParameters
    .filter((param) => param.dplID !== 'componentName')
    .reduce(
      (parameterDPL, parameter) => createParameters(parameterDPL, parameter),
      {
        componentName
      }
    )
}
