import { useCallback } from 'react'

import { useQuery } from '@tanstack/react-query'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { queryKeys } from '../../queryKeys'
import { useSpringClient } from '../useSpringClient/useSpringClient'
import { RunTasksResponse, State } from './types'

export const COMPLETED_STATES: State[] = [
  State.Success,
  State.Failed,
  State.Cancelled
]

export type TaskState = 'completed' | 'running'

const useGetRunTasks = (state: TaskState) => {
  const { projectId, branchId, agentId, environmentId } = useProjectInfo()
  const client = useSpringClient()

  const tasks = useCallback(async () => {
    const { data } = await client.get<RunTasksResponse>(
      `tasks?projectId=${projectId}&branchId=${branchId}&status=${state}&agentId=${agentId}&environmentId=${environmentId}`
    )

    return data
  }, [client, projectId, branchId, state, agentId, environmentId])

  const refetchInterval = process.env.REACT_APP_STATUS_OVERRIDE_REFETCH
    ? Number(process.env.REACT_APP_STATUS_OVERRIDE_REFETCH)
    : 3000

  return useQuery(
    [queryKeys.taskStatus, projectId, branchId, agentId, environmentId, state],
    tasks,
    { refetchInterval }
  )
}

export default useGetRunTasks
