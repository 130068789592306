import { useCaptureOAuthTokenResponses } from './useCaptureOAuthToken.responses'
import { rest } from 'msw'

export const useStubCaptureOAuthToken = (baseUrl = '') => {
  const url = `${baseUrl}/git-service/v1/providers/github/oauth/tokens`
  return Object.keys(useCaptureOAuthTokenResponses).map(() => {
    return rest.post(url, async (req, res, ctx) => {
      const request = await req.json()
      return res(ctx.json(useCaptureOAuthTokenResponses[request.state]))
    })
  })
}

export const useCaptureOAuthTokenHandlers = [...useStubCaptureOAuthToken()]
