import { JobSummary } from 'api/hooks/useGetJobSummaries'
import { RunTaskStatus } from 'api/hooks/useGetRunTasks/types'

import { JobType } from 'job-lib/types/JobType'

export enum TaskType {
  FlowSummary = 'FlowSummary'
}

export type EtlDocumentTypes = JobType | TaskType

export interface EtlDocument {
  key: string
  id: string
  type: EtlDocumentTypes
  title: string
  document: JobSummary | RunTaskStatus
  isActive: boolean
  closeDocument: () => void
  openDocument: () => void
}
