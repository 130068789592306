import { Typography } from '@matillion/component-library'

import classes from './ModalTitle.module.scss'

interface ModalTitleProps {
  title: string
  titleId?: string
  'data-testId'?: string
  description?: string
}
export function ModalTitle({
  title,
  'data-testId': testId,
  titleId,
  description
}: ModalTitleProps) {
  return (
    <div className={classes.ModalTitle} data-testid={testId}>
      <Typography format="tm" id={titleId}>
        {title}
      </Typography>

      {description && (
        <Typography className={classes.ModalTitle__Description}>
          {description}
        </Typography>
      )}
    </div>
  )
}
