import { useQuery } from '@tanstack/react-query'
import { queryKeys } from 'api/query-keys'
import { useCallback } from 'react'
import useEntitlementsClient from 'api/clients/useEntitlementsClient'
import { type EntitlementsResponse } from './types'

export const useEntitlementsQueryKey = () => {
  return [queryKeys.entitlements]
}

const useEntitlements = () => {
  const client = useEntitlementsClient()

  const getEntitlements = useCallback(async () => {
    const { data } = await client.get<EntitlementsResponse>('')
    return data
  }, [client])

  const queryKey = useEntitlementsQueryKey()

  return useQuery(queryKey, getEntitlements)
}

export default useEntitlements
