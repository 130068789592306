import { memo, MouseEventHandler, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { NodeResizer, NodeToolbar } from 'reactflow'

import { Icon, Prose, Textarea } from '@matillion/component-library'
import classNames from 'classnames'

import {
  DEFAULT_NOTE_HEIGHT,
  DEFAULT_NOTE_WIDTH
} from 'job-lib/hooks/useMakeNote/useMakeNote'
import { jobActions } from 'job-lib/store'

import { NoteNodeData } from 'modules/Canvas/hooks/useCanvasModel/useCanvasModel'

import classes from './EtlNote.module.scss'
import { EtlNoteToolbar } from './EtlNoteToolbar/EtlNoteToolbar'
import { useResizeNote } from './useResizeNote/useResizeNote'

const REACT_FLOW_NO_DRAG_CLASS_NAME = 'nodrag'
export interface EtlNoteProps {
  id: number
  isSelected?: boolean
  data: NoteNodeData
}

export const EtlNote = memo(({ id, isSelected, data }: EtlNoteProps) => {
  const { content } = data
  const [editing, setEditing] = useState(false)
  const [prevContent, setPrevContent] = useState(content)
  const [text, setText] = useState(content)
  const [temporaryTheme, setTemporaryTheme] = useState<string>()
  const ref = useRef<HTMLDivElement>(null)
  const dispatch = useDispatch()
  const {
    onResizeStart,
    onResizeEnd,
    resizingHeight,
    resizingWidth,
    resizing
  } = useResizeNote({
    id
  })

  const width = resizing ? resizingWidth ?? data.width : data.width
  const height = resizing ? resizingHeight ?? data.height : data.height

  const preventUntogglingNodeSelection: MouseEventHandler<HTMLElement> = (
    e
  ) => {
    e.stopPropagation()
  }

  if (content !== prevContent) {
    setText(content)
    setPrevContent(content)
  }

  return (
    <>
      <NodeToolbar
        data-testid="etl-note-toolbar"
        className={classes.EtlNote__Toolbar}
        align="start"
      >
        <EtlNoteToolbar
          id={id}
          selectedTheme={data.theme}
          selectTemporaryTheme={setTemporaryTheme}
        />
      </NodeToolbar>

      <NodeResizer
        minWidth={DEFAULT_NOTE_WIDTH}
        minHeight={DEFAULT_NOTE_HEIGHT}
        onResizeStart={onResizeStart}
        onResizeEnd={onResizeEnd}
      />
      <div
        onBlur={() => {
          setEditing(false)
          dispatch(
            jobActions.updateNote({ id, partialNote: { content: text } })
          )
        }}
        data-testid={`etl-note-${id}`}
        style={{ width, height }}
        ref={ref}
        className={classNames(classes.EtlNote, {
          [classes['EtlNote--isSelected']]: isSelected,
          [classes[`EtlNote__Themes--${temporaryTheme ?? data.theme}`]]:
            data.theme !== undefined
        })}
      >
        <div className={classes.EtlNote__DragBar}>
          <Icon.DragHandle />
        </div>
        {!editing && (
          <div
            data-testid="etl-note-prose"
            onClick={() => {
              setEditing(true)
            }}
            className={classes.EtlNote__Markdown}
          >
            <Prose>{text}</Prose>
          </div>
        )}
        {editing && (
          <Textarea
            autoFocus
            onFocus={(e) =>
              e.currentTarget.setSelectionRange(text.length, text.length)
            }
            name="note-textarea"
            className={classNames(
              classes.EtlNote__Textarea,
              REACT_FLOW_NO_DRAG_CLASS_NAME
            )}
            onChange={(e) => {
              setText(e.target.value)
            }}
            onClick={preventUntogglingNodeSelection}
            value={text}
            onKeyDown={(e) => {
              if (e.code === 'Escape') {
                setEditing(false)
                setText(content)
              }
              // ensure that keyboard events such as undo/redo which we want to
              // handle natively don't end up in the canvas
              // event handlers
              e.stopPropagation()
            }}
          />
        )}
      </div>
    </>
  )
})

EtlNote.displayName = 'EtlNote'
