import { FC } from 'react'

import { SVGIconProps } from '../types'

export const AddAll: FC<SVGIconProps> = (props) => {
  return (
    <svg
      aria-label="Add all"
      {...props}
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
    >
      <circle r="19" transform="matrix(1 0 0 -1 19 19)" fill="#51A620" />
      <path
        d="M13.818 14.25L18.1362 18.5682L13.818 22.8864"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M19.8635 14.25L24.1816 18.5682L19.8635 22.8864"
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  )
}
