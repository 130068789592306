import { FC, MouseEvent, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon, Typography } from '@matillion/component-library'
import classnames from 'classnames'

import classes from './PopOverMenu.module.scss'
import usePopOverContext from './usePopOverContext'

interface PopOverItemProps extends PropsWithChildren {
  'data-testid'?: string
  'data-heapid'?: string
  'data-heap-prop-jobtype'?: string
  disabled?: boolean
  shortcut?: string
  subMenu?: boolean
  onClick: (event: MouseEvent<HTMLButtonElement>) => void
}

export const PopOverItem: FC<PopOverItemProps> = ({
  'data-testid': dataTestid,
  'data-heapid': dataHeapid,
  'data-heap-prop-jobtype': dataHeapPropJobtype,
  children,
  disabled = false,
  onClick,
  shortcut,
  subMenu = false
}) => {
  const { t } = useTranslation()
  const { setIsOpen } = usePopOverContext()

  if (shortcut && subMenu) {
    console.warn(
      'PopOverItem: you should not use both a shortcut and a submenu in a menu item',
      dataTestid
    )
  }

  return (
    <li>
      <button
        disabled={disabled}
        data-testid={dataTestid}
        data-heapid={dataHeapid}
        data-heap-prop-jobtype={dataHeapPropJobtype}
        type="button"
        className={classnames(classes.PopOverItem, {
          [classes['PopOverItem--Disabled']]: disabled
        })}
        onClick={(event: MouseEvent<HTMLButtonElement>) => {
          onClick(event)
          setIsOpen(false)
        }}
      >
        <div className={classes.PopOverItem__Text}>
          <Typography
            format="bcs"
            as="span"
            className={classes.PopOverItem__Text}
          >
            {children}
          </Typography>
          {shortcut && !subMenu && (
            <Typography
              format="mc"
              as="span"
              className={classes.PopOverItem__Shortcut}
            >
              {shortcut}
            </Typography>
          )}
          {subMenu && (
            <Icon.ChevronDown
              role="img"
              aria-label={t('translation:contextMenu.submenu')}
              className={classes.PopOverItem__SubMenu}
            />
          )}
        </div>
      </button>
    </li>
  )
}
