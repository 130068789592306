import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon } from '@matillion/component-library'

import useGetProject from 'api/hooks/useGetProject/useGetProject'
import { ProjectType } from 'api/hooks/useListProjects/types'

import ToolbarSelector, { ToolbarSelectorType } from './ToolbarSelector'

const getProjectIcon = (type?: ProjectType) => {
  if (type === ProjectType.SNOWFLAKE) {
    return Icon.Snowflake
  }

  return Icon.WarningNoBG
}

const ProjectSelector: FC = () => {
  const { data, isLoading, isError } = useGetProject()
  const { t } = useTranslation()

  return (
    <ToolbarSelector
      data-testid="project-toolbar-projectName"
      text={data?.name ?? t('projectToolbox.errorLoading.project')}
      type={ToolbarSelectorType.PROJECT}
      icon={getProjectIcon(data?.warehouse)}
      isError={isError}
      isLoading={isLoading}
    />
  )
}

export default ProjectSelector
