import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'
import { Draft } from 'immer'

import { createNameParameter } from 'job-lib/builders/createComponent/createNameParameter'
import { generateNextComponentId } from 'job-lib/job-functions/generateNextSequenceId'
import { generateUniqueName } from 'job-lib/job-functions/generateUniqueName'
import { getComponentLabel } from 'job-lib/job-functions/getComponentLabel'
import {
  componentOutputConnectorFields,
  ConnectorWithType,
  inputConnectorFields,
  jobConnectorFields,
  makeConnectorId
} from 'job-lib/job-functions/job-functions'
import {
  ComponentInstance,
  OrchestrationComponentInstance,
  TransformationComponentInstance
} from 'job-lib/types/Job'
import { JobType } from 'job-lib/types/JobType'

import {
  JobState,
  JobStateOrchestration,
  JobStateTransformation
} from '../../job.types'
import { isDPLParameterCollection } from '../../utils/isDPLParameterCollection'
import { setDPLComponentNameOverride } from '../../utils/setDPLComponentNameOverride'

const insertConnectorIntoOrchestrationComponents = (
  state: Draft<JobStateOrchestration>,
  connector: ConnectorWithType
) => {
  const sourceComponent = state.job.components[connector.sourceID]
  const targetComponent = state.job.components[connector.targetID]

  sourceComponent[componentOutputConnectorFields[connector.outputType]].push(
    connector.id
  )

  targetComponent[inputConnectorFields[connector.outputType]].push(connector.id)
}

const insertConnectorIntoTransformationComponents = (
  state: Draft<JobStateTransformation>,
  connector: ConnectorWithType
) => {
  const sourceComponent = state.job.components[connector.sourceID]
  const targetComponent = state.job.components[connector.targetID]

  sourceComponent.outputConnectorIDs.push(connector.id)
  targetComponent.inputConnectorIDs.push(connector.id)
}

const insertConnectorIntoComponent = (
  state: Draft<JobStateTransformation | JobStateOrchestration>,
  connector: ConnectorWithType
) => {
  if (state.jobType === JobType.Orchestration) {
    insertConnectorIntoOrchestrationComponents(state, connector)
  }

  if (state.jobType === JobType.Transformation) {
    insertConnectorIntoTransformationComponents(state, connector)
  }
}

const insertConnectorIntoJob = (
  state: Draft<JobStateTransformation | JobStateOrchestration>,
  connector: ConnectorWithType
) => {
  if (state.jobType === JobType.Orchestration) {
    state.job[jobConnectorFields[connector.outputType]][connector.id] = {
      id: connector.id,
      sourceID: connector.sourceID,
      targetID: connector.targetID
    }
  }

  if (state.jobType === JobType.Transformation) {
    state.job.connectors[connector.id] = {
      id: connector.id,
      sourceID: connector.sourceID,
      targetID: connector.targetID
    }
  }
}

export type CloneComponentGroupPayload =
  | {
      componentInstances: OrchestrationComponentInstance[]
      componentType: JobType.Orchestration
      currentConnectors: ConnectorWithType[]
    }
  | {
      componentInstances: TransformationComponentInstance[]
      componentType: JobType.Transformation
      currentConnectors: ConnectorWithType[]
    }

export const cloneComponentGroup: CaseReducer<
  JobState,
  PayloadAction<CloneComponentGroupPayload>
> = (state, { payload }) => {
  if (!state.job) {
    return state
  }

  /** map from old ids to new ids */
  const copiedComponentIdMap: Record<number, number> = {}
  let newComponentId = generateNextComponentId(state.job)

  payload.componentInstances.forEach((copiedComponent) => {
    let newComponent: ComponentInstance
    if (payload.componentType === JobType.Orchestration) {
      newComponent = {
        ...copiedComponent,
        id: newComponentId,
        inputConnectorIDs: [],
        outputSuccessConnectorIDs: [],
        outputFailureConnectorIDs: [],
        outputUnconditionalConnectorIDs: [],
        outputTrueConnectorIDs: [],
        outputFalseConnectorIDs: [],
        outputIterationConnectorIDs: [],
        inputIterationConnectorIDs: []
      }
    } else {
      newComponent = {
        ...copiedComponent,
        id: newComponentId,
        outputConnectorIDs: [],
        inputConnectorIDs: []
      }
    }

    copiedComponentIdMap[copiedComponent.id] = newComponentId

    // generate a unique name for this addition
    const oldName = getComponentLabel(newComponent)
    const uniqueName = generateUniqueName(oldName, state.job.components)
    const isNewDPLParameterCollection = isDPLParameterCollection(
      newComponent.parameters
    )

    if (uniqueName !== oldName) {
      newComponent = {
        ...newComponent,
        parameters: {
          ...newComponent.parameters,
          1: createNameParameter(uniqueName)
        }
      }

      if (isNewDPLParameterCollection) {
        newComponent.parameters[2] = setDPLComponentNameOverride(
          newComponent,
          uniqueName
        )
      }
    }

    state.job.components[newComponentId] = newComponent

    newComponentId++
  })

  payload.currentConnectors.forEach((connector) => {
    const latestConnectorId = makeConnectorId(state.job)
    const newConnector: ConnectorWithType = {
      id: latestConnectorId,
      sourceID: copiedComponentIdMap[connector.sourceID],
      targetID: copiedComponentIdMap[connector.targetID],
      outputType: connector.outputType
    }

    insertConnectorIntoJob(state, newConnector)
    insertConnectorIntoComponent(state, newConnector)
  })

  return state
}
