import { type ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Field,
  Modal,
  Textarea,
  Typography
} from '@matillion/component-library'
import ConflictsAlert from '../ConflictsAlert/ConflictsAlert'

import useDescribeMerge from 'api/hooks/useDescribeMerge/useDescribeMerge'
import queryClient from 'api/queryClient'

import BranchSelector from 'components/BranchSelector/BranchSelector'
import CurrentBranchDisplay from 'components/CurrentBranchDisplay/CurrentBranchDisplay'
import ModalTitle from 'components/ModalTitle'
import useMergeAction from 'hooks/useMergeAction/useMergeAction'
import MergeArrowIcon from 'icons/MergeArrow.svg'
import classes from './MergeIntoBranchModal.module.scss'

import BranchSelectorLabel from 'components/BranchSelectorLabel/BranchSelectorLabel'
import ConflictResolutionTable from 'components/ConflictResolutionTable/ConflictResolutionTable'
import PublishJobsForm from 'components/PublishJobsForm/PublishJobsForm'
import useGitContext from 'provider/GitProvider/useGitContext'
import {
  type MergeBranchSelectorProps,
  type MergeIntoBranchModalProps
} from './types'
import useResolveConflicts from 'hooks/useResolveConflicts'
import { queryKeys } from 'api/query-keys'
import { type PublishToggleEvent } from '../PublishJobsForm/types'

const MergeBranchSelector = ({
  branch,
  branches,
  isInvalidating,
  setBranch
}: MergeBranchSelectorProps) => (
  <div className={classes.MergeModalDialog__MergeBranchSelector}>
    <div className={classes.MergeModalDialog__MergeBranchSelector_Branch}>
      <BranchSelectorLabel
        prefixKey="git.merge.sourceBranch.prefix"
        suffixKey="git.merge.sourceBranch.suffix"
      />

      <CurrentBranchDisplay />
    </div>
    <div
      className={
        classes.MergeModalDialog__MergeBranchSelector_ColumnBetweenBranches
      }
    >
      <img src={MergeArrowIcon} alt={'Merge Arrow Icon'} />
    </div>
    <div className={classes.MergeModalDialog__MergeBranchSelector_Branch}>
      <BranchSelectorLabel
        prefixKey="git.merge.targetBranch.prefix"
        suffixKey="git.merge.targetBranch.suffix"
      />
      <BranchSelector
        branch={branch}
        branches={branches}
        loading={isInvalidating}
        onSelectBranch={setBranch}
        excludeSourceBranch={true}
      />
    </div>
  </div>
)

export const MergeIntoBranchModal = ({
  onClose,
  branches,
  environments,
  onPublishPipelines
}: MergeIntoBranchModalProps) => {
  const { t } = useTranslation()
  const { branch, project } = useGitContext()

  const [isInvalidating, setIsInvalidating] = useState(false)
  const [targetBranch, setTargetBranch] = useState<string | undefined>()
  const [mergeCommitMessage, setMergeCommitMessage] = useState<
    string | undefined
  >('')

  const { data: mergeDescription, isLoading: isDescribeLoading } =
    useDescribeMerge(branch, targetBranch)

  const conflicts = mergeDescription?.conflicts
  const hasConflicts = !!conflicts && conflicts.length > 0
  const { selections, hasResolvedConflicts, updateSelection } =
    useResolveConflicts(conflicts)

  const [publish, setPublish] = useState(false)
  const [environment, setEnvironment] = useState<string | undefined>(undefined)
  const [envsUnavailable, setEnvsUnavailable] = useState(false)
  const publishAvailable = targetBranch === 'main'

  const handleMergeSuccess = useCallback(
    async (commitId: string) => {
      if (targetBranch && environment) {
        await onPublishPipelines({
          commitId,
          environment,
          branch: targetBranch
        })
      }

      onClose()
    },
    [onClose, onPublishPipelines, targetBranch, environment]
  )

  const {
    isValid: isMergeValid,
    isLoading: isMergeLoading,
    merge
  } = useMergeAction({
    conflictSelections: selections,
    mergeMessage: mergeCommitMessage,
    sourceCommitId: mergeDescription?.sourceCommitId,
    targetCommitId: mergeDescription?.targetCommit?.id,
    targetBranch,
    onSuccess: handleMergeSuccess,
    onFailure: onClose
  })

  const onMergeCommitMessageChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      setMergeCommitMessage(e.target.value)
    },
    []
  )

  const handleEnvsUnavailable = useCallback(() => {
    setPublish(false)
    setEnvsUnavailable(true)
  }, [setEnvsUnavailable])

  const handlePublishFormChange = useCallback(
    ({ enabled, environment }: PublishToggleEvent) => {
      setPublish(enabled)
      setEnvironment(environment)
    },
    [setPublish, setEnvironment]
  )

  useEffect(() => {
    if (targetBranch) {
      setMergeCommitMessage(`Merge from [${branch}] in to [${targetBranch}]`)

      if (!publishAvailable) {
        setPublish(false)
      }
    }
  }, [branch, conflicts, targetBranch, publishAvailable, setPublish])

  const isLoading = isMergeLoading
  const isPublishValid = environment && !envsUnavailable
  const cannotMerge =
    !isMergeValid ||
    (publish && !isPublishValid) ||
    (hasConflicts && !hasResolvedConflicts) ||
    isDescribeLoading

  useEffect(() => {
    setIsInvalidating(true)
    queryClient
      .invalidateQueries({
        queryKey: [queryKeys.merge],
        fetchStatus: 'idle'
      })
      .then(() => {
        setIsInvalidating(false)
      })
  }, [targetBranch, project])

  return (
    <Modal
      size="mid"
      onCancel={onClose}
      id="merge-dialog"
      disableBackdropClick
      ariaLabelledBy="merge-title"
      className={classes.MergeModalDialog__Dialog}
    >
      <ModalTitle
        titleId={'merge-title'}
        title={t('git.merge.modal.title')}
        description={t('git.merge.modal.subtitle')}
      />

      <MergeBranchSelector
        branches={branches}
        branch={targetBranch}
        setBranch={setTargetBranch}
        isInvalidating={isInvalidating}
      />

      {targetBranch && (
        <>
          {!isDescribeLoading && (
            <ConflictsAlert
              hasConflicts={hasConflicts}
              hasResolvedConflicts={hasResolvedConflicts}
            />
          )}

          {hasConflicts && (
            <>
              <p className={classes.MergeModalDialog__ConflictsLabel}>
                <Typography format="bcm" weight="bold" as="span">
                  {t('git.merge.conflicts')}
                </Typography>{' '}
                <Typography format="bcm" as="span">
                  ({conflicts.length})
                </Typography>
              </p>

              <ConflictResolutionTable
                selections={selections}
                onVersionSelection={updateSelection}
              />
            </>
          )}

          <Field
            id="merge-commit-message"
            inputComponent={Textarea}
            value={mergeCommitMessage}
            data-testid="commit-message-text"
            title={t('git.merge.commitMessage')}
            onChange={onMergeCommitMessageChange}
            aria-label={t('git.merge.commitMessage')}
            disabled={isMergeLoading || isDescribeLoading}
          />

          <div className={classes.MergeModalDialog__PublishContainer}>
            <PublishJobsForm
              environments={environments}
              unavailable={!publishAvailable}
              onChange={handlePublishFormChange}
              onUnavailable={handleEnvsUnavailable}
              warningKey={'git.publish.message.not-default-target-branch'}
            />
          </div>
        </>
      )}

      <div className={classes.MergeModalDialog__Buttons}>
        <Button
          id="cancel"
          alt="secondary"
          onClick={onClose}
          text={t('git.cancel')}
          data-testid="cancel-button"
        />

        <Button
          onClick={merge}
          id="perform-merge"
          waiting={isLoading}
          disabled={cannotMerge}
          text={t('git.merge.merge')}
          data-testid="modal-merge-submit"
          alt="positive"
        />
      </div>
    </Modal>
  )
}

export default MergeIntoBranchModal
