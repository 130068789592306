import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { Toaster } from '@matillion/component-library'

import { getComponentLabel } from 'job-lib/job-functions/getComponentLabel'
import { jobActions } from 'job-lib/store/jobSlice/job.slice'
import { UpdateLinks } from 'job-lib/store/jobSlice/job.types'
import { checkForTransformationCircularDeps } from 'job-lib/store/jobSlice/utils/checkForTransformationCircularDeps'
import { ComponentInstance } from 'job-lib/types/Job'
import { JobType } from 'job-lib/types/JobType'

import { useWorkingCopy } from 'modules/EtlDesigner/hooks/useWorkingCopy'

import { updateOrchestrationLinks } from './updateOrchestrationLinks'
import { updateTransformationLinks } from './updateTransformationLinks'

export const useUpdateLinks = () => {
  const dispatch = useDispatch()
  const jobState = useWorkingCopy()
  const { t } = useTranslation()
  const { makeToast } = Toaster.useToaster()

  return ({
    sourceComponentId,
    targetComponentId,
    sourceCardinality,
    targetCardinality,
    sourceType
  }: UpdateLinks) => {
    if (!jobState.job) return

    const createErrorToast = (targetComponent: ComponentInstance) => {
      const targetComponentName = getComponentLabel(targetComponent)

      makeToast({
        message: t('alerts.cannotCreateCircularLink.message'),
        title: t('alerts.cannotCreateCircularLink.actionText', {
          targetComponentName
        }),
        type: 'error'
      })
    }

    if (jobState.jobType === JobType.Transformation) {
      const newJobState = updateTransformationLinks({
        job: jobState.job,
        sourceComponentId,
        targetComponentId,
        sourceCardinality,
        targetCardinality,
        sourceType
      })

      checkForTransformationCircularDeps(newJobState)
        ? createErrorToast(newJobState.components[targetComponentId])
        : dispatch(
            jobActions.setJobAndSave({
              jobType: JobType.Transformation,
              job: newJobState
            })
          )
    }

    if (jobState.jobType === JobType.Orchestration) {
      const newJobState = updateOrchestrationLinks({
        job: jobState.job,
        sourceComponentId,
        targetComponentId,
        sourceCardinality,
        targetCardinality,
        sourceType
      })
      dispatch(
        jobActions.setJobAndSave({
          jobType: JobType.Orchestration,
          job: newJobState
        })
      )
    }
  }
}
