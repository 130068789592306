import { useEffect, useRef, useState } from 'react'

import {
  EtlCanvasNode,
  EtlCanvasNodeId
} from 'modules/Canvas/hooks/useCanvasModel/useCanvasModel'

export const useHasNodeCountChanged = (
  nodes: Map<EtlCanvasNodeId, EtlCanvasNode>
) => {
  const [loaded, setLoaded] = useState(false)
  const nodeCount = useRef<number>(0)

  useEffect(() => {
    nodeCount.current = nodes.size

    if (!loaded) {
      setLoaded(true)
    }
  }, [loaded, nodes])

  // loaded allows the canvas to first load the model and account for it being empty
  return loaded ? nodeCount.current < nodes.size : false
}
