import { JobVariableType } from 'job-lib/types/Variables'

import {
  ErrorStatuses,
  Fields,
  FormState,
  ProjectVariableType,
  ReducerActions
} from 'modules/ManageVariables/types'
import { isNumeric } from 'modules/ManageVariables/utils'

import { errorMessages } from '../errorMessages'
import { isFormValid } from './isFormValid'

export const updateDefaultValue = (
  state: FormState,
  action: {
    type: ReducerActions.UPDATE_DEFAULT_VALUE
    field: Fields.DEFAULT_VALUE
    value: string
  }
): FormState => {
  const { value } = action

  const variableType: JobVariableType | ProjectVariableType | null =
    state.VARIABLE_TYPE.value

  const isNumberType =
    variableType &&
    [ProjectVariableType.NUMBER, JobVariableType.NUMBER].includes(variableType)

  const isValid = isNumberType ? value === '' || isNumeric(value) : true

  const updatedState = {
    ...state,
    [Fields.DEFAULT_VALUE]: {
      value,
      isValid,
      error: isValid ? '' : errorMessages[ErrorStatuses.INCORRECT_NUMBER_FORMAT]
    }
  }

  return {
    ...updatedState,
    isFormValid: isFormValid(updatedState)
  }
}
