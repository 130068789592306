import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useCustomComponentSummary } from 'api/hooks/useGetComponentTree'

import { getComponentIcon } from 'utils/component-icons/component-icons'

export const useComponentInfo = () => {
  const { t } = useTranslation('componentSummaries')
  const { getSummary } = useCustomComponentSummary()

  const getDisplayName = (componentId: string): string => {
    return (
      getSummary(componentId)?.displayName ??
      t([`components.${componentId}.displayName`, 'unknownComponent'])
    )
  }

  const getIcon = (componentId: string) => {
    return getSummary(componentId)?.icon ?? getComponentIcon(componentId)
  }

  const getTags = (componentId: string): string[] => {
    return t(`componentSummaries:components.${componentId}.tags`, {
      returnObjects: true,
      defaultValue: []
    })
  }

  return {
    getTags: useCallback(getTags, [t]),
    getDisplayName: useCallback(getDisplayName, [getSummary, t]),
    getIcon: useCallback(getIcon, [getSummary])
  }
}
