import { RunTaskStatus, State } from '../types'

export const completedRunTaskSummaries: RunTaskStatus[] = [
  {
    id: 1,
    versionName: '1',
    jobName: 'alan_russell',
    environmentName: 'snowflake_dev',
    enqueuedTime: 1663596371000,
    startTime: 1663595716000,
    endTime: 1663595716000,
    flowInstanceId: '69bbf9f3-1119-4431-9608-0b3174a7192f',
    state: State.Success
  },
  {
    id: 2,
    versionName: '2',
    jobName: 'alan_russell',
    environmentName: 'snowflake_dev',
    enqueuedTime: 1663596371000,
    startTime: 1663596371000,
    endTime: 1663596371000,
    flowInstanceId: '72a57bc8-e718-4447-8165-4ee6a476ca18',
    state: State.Success
  },
  {
    id: 4,
    versionName: '4',
    jobName: 'alan_russell',
    environmentName: 'snowflake_dev',
    enqueuedTime: 1663698071000,
    startTime: 1663698071000,
    endTime: 1663698075000,
    flowInstanceId: '0732ade2-c6e5-47aa-96b5-6f27df28fedd',
    state: State.Success
  },
  {
    id: 6,
    versionName: '8',
    jobName: 'alan_russell2',
    environmentName: 'snowflake_dev',
    enqueuedTime: 1663701105000,
    startTime: 1663701105000,
    endTime: 1663701106000,
    flowInstanceId: '8e9b5dba-eb11-430c-947e-b6fc18fcafc3',
    state: State.Failed
  },
  {
    id: 7,
    versionName: '9',
    jobName: 'alan_russell3',
    environmentName: 'snowflake_dev',
    enqueuedTime: 1663702167000,
    startTime: 1663702167000,
    endTime: 1663702169000,
    flowInstanceId: 'a2abe5c7-e5f5-421c-93b4-8021a0161f25',
    state: State.Failed
  },
  {
    id: 8,
    versionName: '10',
    jobName: 'alan_russell4',
    environmentName: 'snowflake_dev',
    enqueuedTime: 1663702392000,
    startTime: 1663702392000,
    endTime: 1663702393000,
    flowInstanceId: '228febc4-6ba2-4b67-b74c-586f1c18205f',
    state: State.Failed
  },
  {
    id: 9,
    versionName: '11',
    jobName: 'alan_russell5',
    environmentName: 'snowflake_dev',
    enqueuedTime: 1663702717000,
    startTime: 1663702717000,
    endTime: 1663702721000,
    flowInstanceId: '5e7e215c-be2d-43a8-a2f1-10c007df5a63',
    state: State.Success
  },
  {
    id: 10,
    versionName: '12',
    jobName: 'alan_russell5',
    environmentName: 'snowflake_dev',
    enqueuedTime: 1663746470000,
    startTime: 1663746470000,
    endTime: 1663746474000,
    flowInstanceId: '8c1f54a3-ae5a-46f2-87f4-d584d3502e77',
    state: State.Success
  },
  {
    id: 11,
    versionName: '13',
    jobName: 'alan_russell5',
    environmentName: 'snowflake_dev',
    enqueuedTime: 1663768707000,
    startTime: 1663768707000,
    endTime: 1663768712000,
    flowInstanceId: '0dcc477c-da68-42dd-8d0b-8dbe6f7a0471',
    state: State.Success
  },
  {
    id: 3,
    versionName: '3',
    jobName: 'alan_russell',
    environmentName: 'snowflake_dev',
    enqueuedTime: 1663698056000,
    startTime: 1663698056000,
    endTime: 1663698060000,
    flowInstanceId: 'd6684c04-451f-43e4-9f34-7744b05cd577',
    state: State.Success
  },
  {
    id: 5,
    versionName: '7',
    jobName: 'alan_russell2',
    environmentName: 'snowflake_dev',
    enqueuedTime: 1663698650000,
    startTime: 1663698650000,
    endTime: 1663698655000,
    flowInstanceId: '2b57f3fa-3f2e-4376-bba2-889a216b5ea0',
    state: State.Success
  }
]
