import {
  JobStatsTreeResponse,
  StrandStatsTreeResponse
} from 'api/hooks/useGetFlowStats/types'

import { JobTree, TreeGridItemDepth } from '../types'
import { createChildrenDepth } from './tree-depth'

export const safeString = (string: string | undefined) => string || ''
export const safeArray = <T>(array: T[] | undefined) => array || []

export const getDuration = (
  startTime: number | undefined,
  endTime: number | undefined
): number => {
  return (endTime || NaN) - (startTime || NaN)
}

export const topJobToTreeItem = (job: JobStatsTreeResponse): JobTree => ({
  id: 'key-1',
  data: {
    component: undefined,
    message: undefined,
    duration: getDuration(job.startTime, job.endTime),
    rowCount: undefined,
    queued: job.enqueuedTime,
    started: job.startTime,
    completed: job.endTime
  },
  jobName: safeString(job.jobName),
  depth: '1',
  hasChildren: true,
  status: job.state
})

export const strandToTreeItems = (
  depth: TreeGridItemDepth,
  strand: StrandStatsTreeResponse,
  parentJobName: string,
  componentName: string,
  injectDynamicItems?: (
    parent: JobTree,
    strands: StrandStatsTreeResponse
  ) => void,
  jobName?: string,
  parentFlowInstanceId?: string,
  flowInstanceId?: string
): JobTree[] => {
  const rootJob: JobTree = {
    id: `key-${depth}`,
    data: {
      component: componentName,
      message: safeString(strand.message),
      duration: getDuration(strand.startTime, strand.endTime),
      rowCount: undefined,
      queued: strand.enqueuedTime,
      started: strand.startTime,
      completed: strand.endTime
    },
    jobName: parentJobName,
    depth: depth,
    hasChildren: Boolean(strand.tasks?.length),
    status: strand.state
  }

  const taskToItems = safeArray<JobTree>(
    strand.tasks?.reduce<JobTree[]>((acc, task, index) => {
      const hasChildren = !!task.childStrands?.length
      const strandDepth = createChildrenDepth(rootJob, index + 1)
      const newStrandItem: JobTree = {
        // TODO[DPCD-492] This cannot happen. Id MUST be defined and unique
        id: `key-${strandDepth}`,
        data: {
          component: task.componentName,
          message: task.message,
          duration: getDuration(task.startTime, task.endTime),
          rowCount: task.rowCount,
          queued: task.enqueuedTime,
          started: task.startTime,
          completed: task.endTime
        },
        workerRequests: task.workerRequests,
        jobName: jobName ?? parentJobName,
        depth: strandDepth,
        hasChildren: hasChildren,
        status: task.state,
        childJobId: task.childJobId,
        loader: task.childJobId
          ? {
              strandSequenceId: '',
              flowInstanceId: safeString(task.childJobId),
              injectDynamicItems: injectDynamicItems
            }
          : undefined
      }
      acc.push(newStrandItem)

      const children = task.childStrands?.map<JobTree>((e, i) => {
        const childrenDepth = createChildrenDepth(newStrandItem, i + 1001)
        return {
          id: `key-${childrenDepth}`,
          jobName: `Strand ${e}`,
          depth: childrenDepth,
          loader: {
            strandSequenceId: e.toString(),
            flowInstanceId: safeString(
              parentFlowInstanceId ?? strand.parentFlowInstanceId
            ),
            injectDynamicItems: injectDynamicItems
          }
        }
      })

      if (children) acc.push(...children)

      return acc
    }, [])
  )

  return [rootJob, ...taskToItems]
}

export const adjustStrandTasks = (
  itemToReplace: JobTree,
  strandTasks: JobTree[]
): void => {
  if (itemToReplace.childJobId) {
    strandTasks.forEach((e) => {
      if (e.loader?.strandSequenceId) {
        e.loader.flowInstanceId = safeString(itemToReplace.childJobId)
      }
    })
  }
}

export const adjustStrandTaskNames = (
  strandRootParent: JobTree | undefined,
  strandTasks: JobTree[],
  itemToReplace: JobTree
): void => {
  if (itemToReplace.loader && !itemToReplace.childJobId && strandRootParent) {
    strandTasks.forEach((e) => {
      if (e.data?.component) {
        e.jobName = safeString(strandRootParent.jobName)
      }
    })
  }
}
