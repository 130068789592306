import { Typography } from '@matillion/component-library'

import BranchIcon from 'icons/BranchIcon.svg'

import classes from './CurrentBranchDisplay.module.scss'
import useGitContext from 'provider/GitProvider/useGitContext'

const CurrentBranchDisplay = () => {
  const { branch } = useGitContext()

  return (
    <div
      className={classes.CurrentBranchDisplay}
      data-testid="current-branch-display"
    >
      <div className={classes.CurrentBranchDisplay__SelectorBox}>
        <img
          className={classes.CurrentBranchDisplay__BranchIcon}
          src={BranchIcon}
          alt={'Branch Icon'}
        />
        <Typography
          className={classes.CurrentBranchDisplay__BranchName}
          format="bcm"
        >
          {branch}
        </Typography>
      </div>
      {/* Disabled pending work which has been deferred until after reinvent
        <BranchDescriptionLoader branchName={branchId} />
      */}
    </div>
  )
}

export default CurrentBranchDisplay
