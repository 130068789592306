import { AutoComplete, Field, Typography } from '@matillion/component-library'
import React, { useCallback, useMemo } from 'react'
import { useFormikContext } from 'formik'
import { type GitRepositoryDropdownProps } from './types'
import { type BringYourOwnGitDetails } from '../BringYourOwnGitForm/types'
import { useTranslation } from 'react-i18next'
import classes from './GitRepositoryDropdown.module.scss'
import useGetProviderDisplayName from 'modules/BringYourOwnGit/hooks/useGetProviderDisplayName'

const GitRepositoryDropdown = ({
  repositories,
  loading,
  provider,
  className
}: GitRepositoryDropdownProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'git.byog.form.repository'
  })

  const { providerName } = useGetProviderDisplayName(provider)

  const { errors, handleChange, handleBlur, touched, values, setFieldValue } =
    useFormikContext<BringYourOwnGitDetails>()

  const handleValueChange = useCallback(
    (e: { target: { value: { name: string } } }) => {
      const repositoryValue = e.target.value.name

      setFieldValue('repository', repositoryValue).then(() => {
        handleChange(repositoryValue)
      })
    },
    [handleChange, setFieldValue]
  )

  const availableItems = useMemo(() => {
    return repositories.map((repo) => ({
      id: repo.id,
      name: repo.fullName
    }))
  }, [repositories])

  const errorText = useMemo(() => {
    if (!loading && availableItems.length === 0) {
      return (
        <span data-testid="no-repos-error">
          {t('no-repositories-available.message', { provider: providerName })}
          {t('no-repositories-available.help-prefix')}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://support.matillion.com"
          >
            {t('no-repositories-available.help-link')}
          </a>
          {t('no-repositories-available.help-suffix')}
        </span>
      )
    }

    if (touched.repository && errors.repository) {
      return (
        <span data-testid="repository-validation-error">
          {t(errors.repository)}
        </span>
      )
    }

    return null
  }, [loading, availableItems, touched.repository, errors.repository, t])

  const currentItem = useMemo(() => {
    const repository = repositories.find(
      (repo) => repo.fullName === values.repository
    )

    return {
      id: repository?.id,
      name: repository?.fullName
    }
  }, [repositories, values])

  return (
    <div data-testid="git-repository-selector" className={className}>
      <Typography format="bcs" className={classes.title} weight="bold">
        {t('title')}
      </Typography>

      <Typography format="bcs" className={classes.subtitle}>
        {t('subtitle')}
      </Typography>

      <Typography format="bcs" className={classes.label}>
        {t('label')}
      </Typography>

      <Field
        required
        loading={loading}
        name="repository"
        onBlur={handleBlur}
        errorText={errorText}
        value={currentItem}
        className={classes.dropdown}
        onChange={handleValueChange}
        inputComponent={AutoComplete}
        placeholder={t('placeholder')}
        availableItems={availableItems}
        data-testid="git-repository-dropdown"
        hasError={!!errors.repository && touched.repository}
      />
    </div>
  )
}

export default GitRepositoryDropdown
