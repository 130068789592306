import {
  ComponentMetadata,
  ComponentParameter,
  EditorType,
  LookUpType,
  ParameterDataType
} from 'api/hooks/useGetComponentMetadata/types'
import { ComponentSummaryId } from 'api/hooks/useGetComponentTree'
import { ColumnType } from 'api/hooks/useGetParameterOptions/types'

import { ComponentInstanceId } from 'job-lib/types/Job'
import { ElementCollection, ElementValueType } from 'job-lib/types/Parameters'

import { ModalTriggerEditor } from 'modules/ParameterEditors/components/ModalTriggerEditor/ModalTriggerEditor'

import { GridCell } from '../../types'
import classes from './GridExpressionEditor.module.scss'

export interface GridExpressionProps {
  componentMetaData: ComponentMetadata
  elements: ElementCollection
  parameter: ComponentParameter
  componentInstanceId: ComponentInstanceId
  componentSummaryId: ComponentSummaryId
  type: ElementValueType
  value: string | null
  slot: number
  rowSlot: number
  dataType: ColumnType
  parameterName: string
  lookupType?: LookUpType
  lookupDependencies?: string[]
  onChange: (cell: GridCell) => void
}

export const GridExpressionEditor = ({
  componentMetaData,
  elements,
  parameter,
  componentInstanceId,
  componentSummaryId,
  value: _value,
  slot,
  rowSlot,
  dataType,
  type,
  parameterName,
  lookupType,
  lookupDependencies,
  onChange
}: GridExpressionProps) => {
  const value = _value ?? ''

  return (
    <div className={classes.GridExpressionEditor}>
      <ModalTriggerEditor
        value={[value]}
        editorType={EditorType.JOIN_EDITOR}
        elements={elements}
        updateElementsWithEditorColumnData={(editorColumns) => {
          return {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  value,
                  dataType: ParameterDataType.TEXT
                },
                2: {
                  slot: 2,
                  type: 'STRING',
                  value: editorColumns?.[1]?.options?.[0] ?? '',
                  dataType: ParameterDataType.TEXT
                }
              }
            }
          }
        }}
        onEdit={(args) => {
          onChange({
            type,
            slot,
            rowSlot,
            dataType,
            value: args[1].values[1].value
          })
        }}
        componentInstanceId={componentInstanceId}
        componentMetaData={componentMetaData}
        inputId={`nested-row-${rowSlot}-slot-${slot}`}
        parameter={{
          ...parameter,
          staticOptions: null,
          options: null,
          lookupDependencies,
          lookupType
        }}
        componentSummaryId={componentSummaryId}
        parameterName={parameterName}
      />
    </div>
  )
}
