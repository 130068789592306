import { FunctionComponent, PropsWithChildren, useEffect, useMemo } from 'react'

import { datadogLogs } from '@datadog/browser-logs'
import { Loader } from '@matillion/component-library'
import { useUser } from '@matillion/hub-client'

import config from 'config'

import {
  ProjectInfo,
  useProjectInfo
} from 'hooks/useProjectInfo/useProjectInfo'

export enum RedirectReasons {
  MISSING_BRANCH_ID = 'MISSING_BRANCH_ID',
  MISSING_PROJECT_ID = 'MISSING_PROJECT_ID',
  MISSING_ENVIRONMENT_ID = 'MISSING_ENVIRONMENT_ID',
  MISSING_AGENT_ID = 'MISSING_AGENT_ID',
  PROJECT_NOT_FOUND = 'PROJECT_NOT_FOUND'
}

const getRedirectURL = (
  subdomain: string,
  projectId: string,
  reasons: RedirectReasons[]
) => {
  const url = new URL(
    `https://${subdomain}.${config.redirectOnMissingParamsURL}`
  )

  if (!reasons.includes(RedirectReasons.MISSING_PROJECT_ID)) {
    url.pathname = projectId
  }

  url.searchParams.set('redirect_reason', reasons.join(','))

  return url
}

const getRedirectReasons = ({
  projectInfo
}: {
  projectInfo: ProjectInfo
}): RedirectReasons[] => {
  const reasons: RedirectReasons[] = []

  if (!projectInfo.projectId) {
    reasons.push(RedirectReasons.MISSING_PROJECT_ID)
  }

  if (!projectInfo.branchId) {
    reasons.push(RedirectReasons.MISSING_BRANCH_ID)
  }

  if (!projectInfo.environmentId) {
    reasons.push(RedirectReasons.MISSING_ENVIRONMENT_ID)
  }

  if (!projectInfo.agentId) {
    reasons.push(RedirectReasons.MISSING_AGENT_ID)
  }

  return reasons
}

const log = (reasons: RedirectReasons[], projectInfo: ProjectInfo) => {
  const meta = {
    reasons,
    projectInfo
  }

  let message = 'User is missing information in the URL.'

  if (reasons.includes(RedirectReasons.PROJECT_NOT_FOUND)) {
    message += ' User is trying to access a project that does not exist.'
  }

  message += ' Redirecting them to project explorer.'

  datadogLogs.logger.info(message, meta)
}

export const ValidateLogAndBounce: FunctionComponent<PropsWithChildren> = ({
  children
}) => {
  const { subdomain } = useUser().organisation
  const projectInfo = useProjectInfo()

  const [redirectReasons, redirectURL] = useMemo(() => {
    const reasons = getRedirectReasons({ projectInfo })
    const url = getRedirectURL(subdomain, projectInfo.projectId, reasons)

    return [reasons, url]
  }, [projectInfo, subdomain])

  useEffect(() => {
    // only redirect in built environments or cypress
    if (process.env.NODE_ENV === 'production' || 'Cypress' in window) {
      if (redirectReasons.length) {
        // from the datadogLogs source code it seems to use sendBeacon https://developer.mozilla.org/en-US/docs/Web/API/Navigator/sendBeacon
        // which means we can safely trigger this log and then redirect and the log event should still arrive at datadog.
        log(redirectReasons, projectInfo)

        window.location.assign(redirectURL.toString())
      }
    }
  }, [projectInfo, redirectReasons, redirectURL, subdomain])

  if (redirectReasons.length) {
    if (process.env.NODE_ENV !== 'production') {
      return (
        <div>
          <h1>
            The app can&apos;t continue and so we need to redirect, reasons:{' '}
          </h1>
          {JSON.stringify(redirectReasons)}. <br />
          <br />
          <b>The app would normally redirect you here:</b>
          <a
            data-testid="missing-info-redirect-anchor"
            href={redirectURL.toString()}
          >
            {redirectURL.toString()}
          </a>
          ?<pre>{JSON.stringify({ projectInfo }, null, 2)}</pre>
        </div>
      )
    }

    return <Loader />
  }

  return <>{children}</>
}
