import { ComponentMetadataParameterId } from 'api/hooks/useGetComponentMetadata/types'
import { ComponentSummaryId } from 'api/hooks/useGetComponentTree'

// These consts are used to trigger specific behaviour in the UI
// e.g. they are used in to warn the user that you can't use the same job name
// Hardcoded values from CIS, we want to create a RunOrchestration component or RunTransformation component when a job is dragged onto the canvas

/** the Run Transformation component id */
export const runTransformationComponentId: ComponentSummaryId =
  'run-transformation'
/** the transformation job name parameter id */
export const transformationJobNameParameter: ComponentMetadataParameterId =
  'transformation-job'
/** the Run Orchestration component */
export const runOrchestrationComponentId: ComponentSummaryId =
  'run-orchestration'
/** the orchestration job name parameter id */
export const orchestrationJobNameParameter: ComponentMetadataParameterId =
  'orchestration-job'
export const componentNameParameter: ComponentMetadataParameterId =
  'component-name'

/*
 * inputId and outputId are modular connector specific parameters that need to be populated in the DPL
 * but do not need to be visible to the user in the UI
 */
export const inputIdParameter: ComponentMetadataParameterId = 'inputId'
export const profileParameter: ComponentMetadataParameterId = 'profile'
