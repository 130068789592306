import { EditorColumn } from 'api/hooks/useGetParameterOptions/types'

import { ComponentDependencyDataBag } from 'modules/ComponentValidation/types'

export interface ParameterOptions {
  editorColumns: EditorColumn[]
  componentInputDepBag?: ComponentDependencyDataBag
}

export interface UrlEditorProps extends ParameterOptions {
  parameterName: string
  value: string[]
  onDone: (editedValue: string[]) => unknown
}

export enum StorageEditorType {
  S3 = 'S3',
  AZURE = 'AZURE'
}
