import { useTranslation } from 'react-i18next'

import { Button } from '@matillion/component-library'

import ModalTitle from '../ModalTitle/ModalTitle'
import classes from './ConflictsModal.module.scss'
import useResolveConflicts from '../../hooks/useResolveConflicts'
import ConflictsAlert from '../ConflictsAlert'
import ConflictResolutionTable from '../ConflictResolutionTable'
import { type ConflictResolutionFormProps } from 'components/ConflictsModal/types'

const ConflictResolutionForm = ({
  submit,
  conflicts,
  onCancel,
  onResolve
}: ConflictResolutionFormProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'git.conflicts.modal'
  })

  const { selections, updateSelection, hasResolvedConflicts } =
    useResolveConflicts(conflicts)

  return (
    <div data-testid="conflict-resolution-form">
      <ModalTitle
        title={t('title')}
        description={t('subtitle')}
        titleId={'conflicts-modal-title'}
      />

      <ConflictsAlert
        hasConflicts
        className={classes.Alert}
        hasResolvedConflicts={hasResolvedConflicts}
      />

      <ConflictResolutionTable
        selections={selections}
        onVersionSelection={updateSelection}
      />

      <div className={classes.Footer}>
        <Button
          type="reset"
          alt="secondary"
          text={t('cancel')}
          onClick={onCancel}
          className={classes.Button}
          data-testid="cancel-resolve-conflicts-button"
        />

        <Button
          type="submit"
          text={submit}
          className={classes.Button}
          disabled={!hasResolvedConflicts}
          onClick={() => {
            onResolve(selections)
          }}
          data-testid="resolve-conflicts-button"
          alt="positive"
        />
      </div>
    </div>
  )
}

export default ConflictResolutionForm
