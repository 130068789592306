import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

import { ParameterOptionsQueryKey, queryKeys } from 'api/queryKeys'

import {
  ProjectInfo,
  useProjectInfo
} from 'hooks/useProjectInfo/useProjectInfo'

import { ComponentInstanceId } from 'job-lib/types/Job'
import { JobVariableCollection } from 'job-lib/types/Variables'

import { LookUpType } from '../useGetComponentMetadata/types'
import { useSpringClient } from '../useSpringClient/useSpringClient'
import {
  APIError,
  GetParameterOptionsResponse,
  LookupDefinition
} from './types'

const getParameterOptionsQueryKey = (
  {
    projectId,
    branchId,
    jobSummaryId
  }: Pick<ProjectInfo, 'projectId' | 'jobSummaryId' | 'branchId'>,
  lookupType: LookUpType | null,
  componentId: ComponentInstanceId | null,
  parameterId: string | undefined,
  parameterDependencies?: Record<string, string>,
  parameterIdDependencies?: Record<string, string> | null
) => {
  const keys: ParameterOptionsQueryKey = [
    queryKeys.parameterOptions,
    projectId,
    branchId,
    jobSummaryId,
    lookupType,
    componentId,
    parameterId
  ]

  if (parameterDependencies) {
    Object.entries(parameterDependencies).forEach(([key, value]) => {
      keys.push(key, value)
    })
  }

  if (parameterIdDependencies) {
    Object.entries(parameterIdDependencies).forEach(([key, value]) => {
      keys.push(key, value)
    })
  }

  return keys
}

export interface UseGetParameterOptionsRequest {
  lookupDefinition?: LookupDefinition
  parameterId?: string
  lookupType: LookUpType | null
  isEnabled?: boolean
  variables: JobVariableCollection
}

export const useGetParameterOptions = ({
  lookupDefinition,
  lookupType,
  parameterId,
  variables,
  isEnabled = false
}: UseGetParameterOptionsRequest) => {
  const {
    projectId,
    branchId,
    jobSummaryId,
    componentId,
    environmentId,
    agentId
  } = useProjectInfo()
  const client = useSpringClient()

  const url = `/parameter-options/${
    lookupType as LookUpType
  }?projectId=${projectId}&branchName=${branchId}&environmentId=${environmentId}&agentId=${agentId}`

  const {
    component,
    'transform.sql': transform,
    parameters,
    parameterMetadataDependencies,
    parameterIdDependencies
  } = lookupDefinition ?? {}

  const dependencies = {
    ...component,
    ...parameters,
    ...parameterIdDependencies,
    ...(transform ? { 'transform.sql': transform } : {}),
    ...(parameterMetadataDependencies ?? {})
  }

  return useQuery<GetParameterOptionsResponse | undefined, APIError>(
    getParameterOptionsQueryKey(
      { projectId, branchId, jobSummaryId },
      lookupType,
      componentId,
      parameterId,
      lookupDefinition?.parameters,
      lookupDefinition?.parameterIdDependencies
    ),
    async () => {
      try {
        const { data } = await client.post<GetParameterOptionsResponse>(url, {
          // keeping for backwards compatibility. Will be removed in a subsequent ticket.
          // https://matillion.atlassian.net/browse/DPCD-735
          ...dependencies,
          variables,
          dependencies
        })

        return data
      } catch (error) {
        /* istanbul ignore else */
        if (axios.isAxiosError(error)) {
          throw error.response?.data
        }
      }
    },
    { enabled: isEnabled && !!lookupType, staleTime: 10000 }
  )
}
