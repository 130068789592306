import { isDPLParameterCollection } from 'job-lib/store/jobSlice/utils/isDPLParameterCollection'
import { ComponentInstance } from 'job-lib/types/Job'
import { ParameterCollection } from 'job-lib/types/Parameters'

import { ParameterValue } from 'modules/WorkingCopyProvider/Pipeline'

export const getDPLParameters = (
  componentInstance: ComponentInstance | null,
  parameterCollection?: ParameterCollection
) => {
  const DPLValue =
    componentInstance?.parameters?.[2]?.elements?.[1].values?.[1].value ??
    parameterCollection?.[2]?.elements?.[1].values?.[1].value

  if (DPLValue) {
    return JSON.parse(DPLValue)
  }
  return {}
}

export const getParameterDPLValue = (
  parameterPath: string[],
  parameterDPL: ParameterValue
): ParameterValue => {
  if (parameterPath.length === 0) {
    return ''
  }

  return parameterPath.reduce((parameters: ParameterValue, key: string) => {
    const paramKey = key as keyof ParameterValue
    return parameters?.[paramKey] ?? null
  }, parameterDPL)
}

export const getParameterValue = (
  parameterCollection: ParameterCollection,
  parameterPath: string[]
) => {
  if (!isDPLParameterCollection(parameterCollection)) {
    return null
  }

  const parameters = getDPLParameters(null, parameterCollection)
  const value = getParameterDPLValue(parameterPath, parameters)

  return value
}
