import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '@matillion/component-library'

import { useProjectPermission } from 'api/external/usePermission/useProjectPermission'

import { PopOverItem, PopOverSection } from 'components/PopOverMenu'

import { useValidationProvider } from 'modules/ComponentValidation/hooks/useValidationProvider'
import {
  useCanCopy,
  useCanPaste
} from 'modules/CopyPasteComponent/CopyPasteContext'

import camelToKebabCase from 'utils/camelToKebabCase'
import { isMacOs } from 'utils/isMacOs'

import { useWorkingCopy } from '../../../EtlDesigner/hooks/useWorkingCopy'
import { canvasContextMenu, ContextMenuItem } from './CanvasContextMenu'
import classes from './ContextMenu.module.scss'

interface ContextMenuProps {
  jobName: string
  jobType?: string
  hasSelectedNodes: boolean
  onCommand: (id: string) => unknown
}

export const ContextMenu: FC<ContextMenuProps> = ({
  jobName,
  jobType,
  hasSelectedNodes,
  onCommand
}) => {
  const { undoManager } = useWorkingCopy()
  const { t } = useTranslation()
  const { isValidatingJob } = useValidationProvider()
  const { hasPermission: userCanRunPipeline, isLoading: permissionLoading } =
    useProjectPermission('run_pipelines')
  const canCopy = useCanCopy()
  const canPaste = useCanPaste()
  const canUndo = undoManager?.canUndo() ?? false
  const canRedo = undoManager?.canRedo() ?? false

  const isContextMacOS = {
    ...(isMacOs() && { context: 'macOS' })
  }

  const noPermissionOrLoading = permissionLoading || !userCanRunPipeline

  return (
    <>
      {canvasContextMenu({
        jobName,
        hasSelectedNodes,
        isValidatingJob,
        canCopy,
        canPaste,
        canUndo,
        canRedo,
        userCanRunPipeline: !noPermissionOrLoading
      }).map(({ id: sectionId, items }) => (
        <PopOverSection key={`canvas-context-menu-section-${sectionId}`}>
          {items.map(
            ({
              id,
              disabled,
              shortcut,
              subtext,
              subMenu,
              context
            }: ContextMenuItem) => (
              <PopOverItem
                key={`canvas-context-menu-item-${id}`}
                data-testid={`btn-context-menu-item-${id}`}
                data-heapid={`etld_context-menu-${camelToKebabCase(
                  id
                )}-cta_click`}
                data-heap-prop-jobtype={jobType}
                onClick={() => onCommand(id)}
                disabled={disabled}
                shortcut={shortcut && t(shortcut, context ?? isContextMacOS)}
                subMenu={subMenu}
              >
                {t(`translation:canvasContextMenu.${id}`)}
                {subtext && (
                  <Typography
                    as="span"
                    format="bcs"
                    className={classes.ContextMenuSubText}
                  >
                    {` (${jobName})`}
                  </Typography>
                )}
              </PopOverItem>
            )
          )}
        </PopOverSection>
      ))}
    </>
  )
}
