import { type RequestHandler, rest } from 'msw'
import { usePushChangesResponses } from './usePushChanges.responses'
import { type PushChangesResponse } from './types'
import { PROJECT_ID } from '__test-utils__/pact.config'

const buildUri = (baseUrl: string, project: string) => {
  return `${baseUrl}/working-tree-service/v1/projects/${project}/branches/:branch/push-to-remote`
}

const buildHandler = (baseUrl = '', project = PROJECT_ID) => {
  return rest.post(buildUri(baseUrl, project), async (req, res, ctx) => {
    const branchNameParam = req.params.branch as string

    if (branchNameParam === 'pushRejected') {
      return res(
        ctx.status(409),
        ctx.set('content-type', 'application/problem+json'),
        ctx.json({
          type: 'working-tree/push_to_remote_rejected',
          title: 'Rejected',
          status: 409
        })
      )
    } else if (branchNameParam === 'failing-branch') {
      return res(ctx.status(500))
    } else {
      return res(
        ctx.json<PushChangesResponse>(usePushChangesResponses[branchNameParam])
      )
    }
  })
}

export const usePushChangesHandlers: RequestHandler[] = [
  buildHandler('', 'test-project'),
  buildHandler('', 'gcl-test-project'),
  buildHandler('', PROJECT_ID)
]
