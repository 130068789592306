import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import classes from './EtlNoteThemePicker.module.scss'

interface EtlNoteThemePickerIconProps {
  theme: string
  selectedTheme?: string
  selectTheme: (theme: string) => void
  selectTemporaryTheme: (theme?: string) => void
}

export const EtlNoteThemePickerIcon = ({
  selectedTheme,
  theme,
  selectTheme,
  selectTemporaryTheme
}: EtlNoteThemePickerIconProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'notes.theme'
  })

  return (
    <div
      aria-label={t('themePickerAriaLabel', { theme })}
      onClick={(e) => {
        e.stopPropagation()
        selectTheme(theme)
      }}
      onMouseEnter={() => {
        selectTemporaryTheme(theme)
      }}
      className={classNames({
        [classes.EtlNoteThemePicker__Icon]: true,
        [classes[`EtlNoteThemePicker__Icon--${theme}`]]: true,
        [classes['EtlNoteThemePicker__Icon--selected']]: theme === selectedTheme
      })}
    />
  )
}
