import { ColumnEditorType } from 'api/hooks/useGetParameterOptions/types'

import { Cardinality, ConnectionPortType } from 'job-lib/types/Components'
import { JobType } from 'job-lib/types/JobType'

import {
  ComponentEditorTabType,
  ComponentMetadataResponse,
  EditorType,
  LookUpType,
  ParameterDataType,
  UseVariable
} from '../../types'

export const mockFixedFlowMetadata: ComponentMetadataResponse = {
  componentId: 'fixed-flow',
  metadata: {
    schemaVersion: 1,
    emeraldId: '738944574',
    componentType: JobType.Transformation,
    inputPorts: [],
    outputPorts: [
      {
        portId: ConnectionPortType.UNCONDITIONAL,
        cardinality: Cardinality.MANY
      }
    ],
    deprecated: false,
    exports: [],
    editorTabs: [
      ComponentEditorTabType.PROPERTIES,
      ComponentEditorTabType.SAMPLE,
      ComponentEditorTabType.METADATA,
      ComponentEditorTabType.SQL,
      ComponentEditorTabType.PLAN,
      ComponentEditorTabType.HELP
    ],
    parameters: [
      {
        id: 'component-name',
        dplID: 'componentName',
        metlSlot: 1,
        useVariable: UseVariable.NO,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'columns',
        dplID: 'columns',
        metlSlot: 2,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.GRID,
        editorType: EditorType.GRID_EDITOR,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: [
          {
            name: 'Name',
            type: 'TEXT',
            options: null,
            columnEditorType: ColumnEditorType.Freetext,
            defaultValue: null,
            lookupType: null,
            lookupDependencies: null
          },
          {
            name: 'Type',
            type: 'TEXT',
            options: [
              'VARCHAR',
              'NUMBER',
              'FLOAT',
              'BOOLEAN',
              'DATE',
              'TIMESTAMP',
              'TIME',
              'VARIANT'
            ],
            columnEditorType: ColumnEditorType.Dropdown,
            defaultValue: null,
            lookupType: null,
            lookupDependencies: null
          },
          {
            name: 'Size',
            type: 'INTEGER',
            options: null,
            columnEditorType: ColumnEditorType.Freetext,
            defaultValue: null,
            lookupType: null,
            lookupDependencies: null
          },
          {
            name: 'Scale',
            type: 'INTEGER',
            options: null,
            columnEditorType: ColumnEditorType.Freetext,
            defaultValue: null,
            lookupType: null,
            lookupDependencies: null
          }
        ],
        defaultValue: null
      },
      {
        id: 'values',
        dplID: 'values',
        metlSlot: 3,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.GRID,
        editorType: EditorType.GRID_EDITOR,
        lookupType: LookUpType.DYNAMIC_TRANSFORM_FIXED_FLOW_VALUES,
        lookupDependencies: ['param.columns'],
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      }
    ]
  }
}
