import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon } from '@matillion/component-library'

import { useProjectPermission } from 'api/external/usePermission/useProjectPermission'
import { JobSummary } from 'api/hooks/useGetJobSummaries'
import useRunJob from 'api/hooks/useRunJob/useRunJob'

import { ReactComponent as ExecutingOrchestrationSvg } from 'assets/canvas/executing-orchestration.svg'
import { ReactComponent as ExecutingTransformationSvg } from 'assets/canvas/executing-transformation.svg'

import { IconButton } from 'components/IconButton/IconButton'

import { JobType } from 'job-lib/types/JobType'

interface RunButtonProps {
  summary: JobSummary
  context: {
    context: string
  }
}

export const RunButton: FC<RunButtonProps> = ({ summary, context }) => {
  const { t } = useTranslation()
  const { mutate: onRunJob } = useRunJob({
    jobId: summary.jobId
  })
  const [showInProgressSvg, setShowInProgressSvg] = useState(false)
  const { hasPermission: canRun } = useProjectPermission('run_pipelines')

  const ExecutingJobSvg =
    summary.type === JobType.Transformation
      ? ExecutingTransformationSvg
      : ExecutingOrchestrationSvg

  const triggerJobExecution = () => {
    setShowInProgressSvg(true)
    onRunJob()
  }

  useEffect(() => {
    let timeout: number

    if (showInProgressSvg) {
      timeout = window.setTimeout(() => {
        setShowInProgressSvg(false)
      }, 1000)
    }

    return () => window.clearTimeout(timeout)
  }, [showInProgressSvg])

  return (
    <div>
      <IconButton
        label={t('canvasToolbox.runJob')}
        shortcut={t('shortcuts.commandEnter', context)}
        data-testid="canvas-toolbox-run-job"
        data-heap-prop-jobtype={summary.type}
        data-heapid="etld_toolbox-run-job-cta_click"
        onClick={triggerJobExecution}
        disabled={showInProgressSvg || !canRun}
        hideTooltip={!canRun}
        align={'end'}
      >
        {showInProgressSvg ? (
          <ExecutingJobSvg data-testid="executing-job-svg" aria-hidden />
        ) : (
          <Icon.Play />
        )}
      </IconButton>
    </div>
  )
}
