import { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Icon, Typography } from '@matillion/component-library'
import classNames from 'classnames'

import classes from './PanelWrapper.module.scss'

interface PanelHeaderProps {
  header: string
  id: string
  ariaLabelledById: string
  onSearchSelect: () => void
  onExpandSelect: () => void
  isExpanded: boolean
  searchEnabled: boolean
  contextMenu?: ReactNode
}

export const PanelHeader: FC<PanelHeaderProps> = ({
  header,
  id,
  ariaLabelledById,
  onSearchSelect,
  onExpandSelect,
  isExpanded,
  searchEnabled,
  contextMenu
}) => {
  const { t } = useTranslation()
  const dataTestId = `section-${id}-header`

  return (
    <div className={classes.PanelHeaderContainer}>
      <Typography
        format="bcs"
        className={classes.PanelHeader__Text}
        weight="bold"
        id={ariaLabelledById}
      >
        {header}
      </Typography>
      <div className={classes.ButtonActions}>
        {isExpanded && searchEnabled && (
          <Button
            size="sm"
            alt="ghost"
            className={classes.PanelHeader__Button}
            aria-label={t('translation:sideBar.componentPanel.searchButton', {
              panelID: id
            })}
            data-testid={`${dataTestId}-search`}
            onClick={onSearchSelect}
          >
            <span aria-hidden className={classNames(classes.PanelHeader__Icon)}>
              <Icon.Search />
            </span>
          </Button>
        )}
        {isExpanded && contextMenu}
        <Button
          size="sm"
          alt="ghost"
          className={classes.PanelHeader__Button}
          aria-labelledby={ariaLabelledById}
          data-testid={`${dataTestId}-toggle`}
          onClick={onExpandSelect}
        >
          <Icon.ChevronDown
            aria-hidden
            className={classNames([
              isExpanded && classes['PanelHeader__Icon--isExpanded']
            ])}
          />
        </Button>
      </div>
    </div>
  )
}
