import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Tooltip, TreeItem, Typography } from '@matillion/component-library'
import classNames from 'classnames'

import { ComponentSummary } from 'api/hooks/useGetComponentTree'

import { ComponentDragSource } from 'components/ComponentDrag/ComponentDragSource'

import { useComponentInfo } from 'hooks/useComponentInfo/useComponentInfo'

import { OutputPortType } from 'job-lib/types/Components'

import classes from '../ComponentBrowser.module.scss'
import { DisabledLabel } from './DisabledLabel'

const ComponentTreeItem: FC<{
  component: ComponentSummary
  isAvailableForAgent: boolean
}> = ({ component, isAvailableForAgent }) => {
  const { t } = useTranslation()
  const { componentId, outputPorts } = component

  const { getDisplayName, getIcon } = useComponentInfo()
  const isIterator =
    outputPorts.filter((o) => o.portId === OutputPortType.ITERATION).length > 0
  const imageUrl = getIcon(componentId)
  const displayName = getDisplayName(componentId)

  const treeItem = (
    <TreeItem
      key={componentId}
      nodeId={componentId}
      label={displayName}
      leaf={
        <div className={classes.Component}>
          <img
            role="presentation"
            src={imageUrl}
            className={classes.Component__Icon}
          />
          <Tooltip content={displayName} onlyShowOnOverflow>
            <div className={classes.Component__Text}>
              <Typography
                as="span"
                format="bcs"
                className={classNames(classes.Component__Text, {
                  [classes['Component__Text--disabled']]: !isAvailableForAgent
                })}
              >
                {displayName}
                {!isAvailableForAgent && (
                  <DisabledLabel
                    labelText={t('componentBrowser.disabledLabel')}
                  />
                )}
              </Typography>
            </div>
          </Tooltip>
        </div>
      }
    />
  )

  if (!isAvailableForAgent) {
    return treeItem
  }

  return (
    <ComponentDragSource
      data-testid="component-drag-source"
      isIterator={isIterator}
      componentId={componentId}
      imageUrl={imageUrl}
      dragOrigin="component-browser"
    >
      {treeItem}
    </ComponentDragSource>
  )
}

export { ComponentTreeItem }
