import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '@matillion/component-library'

import { HorizontalStack } from 'components/HorizontalStack/HorizontalStack'
import { JobTypeIcon } from 'components/JobTypeIcon'

import { useWorkingCopy } from 'modules/EtlDesigner/hooks/useWorkingCopy'

import { isMacOs } from 'utils/isMacOs'

import { RunButton } from './RunButton'
import classes from './Toolbox.module.scss'
import { ValidateButton } from './ValidateButton'
import { VariablesButton } from './VariablesButton'

const Toolbox: FunctionComponent = () => {
  const { t } = useTranslation()
  const { summary, isLoading, isError } = useWorkingCopy()
  const context = {
    context: isMacOs() ? 'macOS' : 'windows'
  }

  if (!summary?.type || isLoading || isError) return null

  return (
    <div className={classes.Toolbox}>
      <HorizontalStack withSpacer>
        <HorizontalStack>
          <div className={classes.Toolbox__JobIcon}>
            <JobTypeIcon jobType={summary.type} />
          </div>
          <Typography>{t(`canvasToolbox.${summary.type}`)}</Typography>
        </HorizontalStack>
        <VariablesButton />
        <ValidateButton context={context} jobType={summary.type} />
        <RunButton context={context} summary={summary} />
      </HorizontalStack>
    </div>
  )
}

export { Toolbox }
