import { type RequestHandler, rest } from 'msw'

import { MockedGetBranchInformation } from 'api/hooks/useGetBranch/useGetBranch.responses'
import { type BranchInformation } from 'api/hooks/useGetBranch/types'

export const useGetBranchHandlers: RequestHandler[] = [
  ...Object.keys(MockedGetBranchInformation).map((branchName) =>
    rest.get(`/branches/${branchName}`, async (_, res, ctx) => {
      return res(
        ctx.json<BranchInformation>(MockedGetBranchInformation[branchName])
      )
    })
  ),
  rest.get('/branches/500', async (_req, res, ctx) => {
    return res(ctx.status(500))
  }),
  rest.get('/branches/400', async (_req, res, ctx) => {
    return res(ctx.status(400))
  })
]

export const useStubGetBranch = (baseUrl = '') => {
  return Object.keys(MockedGetBranchInformation).map((branchName) =>
    rest.get(`${baseUrl}/branches/${branchName}`, async (_req, res, ctx) => {
      return res(
        ctx.json<BranchInformation>(MockedGetBranchInformation[branchName])
      )
    })
  )
}
