// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".B0hN7yZKtTfx_f2J_fxJ{margin-bottom:8px}.ToPqQUOSzcgVct375VPG{margin:25px 0;padding-left:58px}.xxGVTyE39rlbcac1MAtI{width:301px;height:45px}.jO4INGXBIS4edv2stZMf{margin-top:20px}", "",{"version":3,"sources":["webpack://./src/components/EnvironmentDropdown/EnvironmentDropdown.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CAGF,sBACE,aAAA,CACA,iBAAA,CAGF,sBACE,WAAA,CACA,WAAA,CAGF,sBACE,eAAA","sourcesContent":[".Label {\n  margin-bottom: 8px;\n}\n\n.Container {\n  margin: 25px 0;\n  padding-left: 58px;\n}\n\n.AutoComplete {\n  width: 301px;\n  height: 45px;\n}\n\n.FailureAlert {\n  margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Label": "B0hN7yZKtTfx_f2J_fxJ",
	"Container": "ToPqQUOSzcgVct375VPG",
	"AutoComplete": "xxGVTyE39rlbcac1MAtI",
	"FailureAlert": "jO4INGXBIS4edv2stZMf"
};
export default ___CSS_LOADER_EXPORT___;
