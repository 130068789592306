import { type GetRepositoriesResponse } from './types'
import { type ExternalGitProvider } from 'types/ExternalGitProvider'

export const useGetRepositoriesResponses: Record<
  ExternalGitProvider,
  GetRepositoriesResponse
> = {
  github: {
    repositories: [
      {
        id: '617541101',
        name: 'github-test-repository',
        fullName: 'test-org/git-hub-test-repository',
        owner: {
          id: 'github-owner-id',
          name: 'github-testing-org',
          type: 'ORGANISATION'
        }
      },
      {
        id: '413541701',
        name: 'github-test-repository-2',
        fullName: 'test-user/git-hub-test-repository-2',
        owner: {
          id: '1654861417',
          name: 'github-testing-user',
          type: 'USER'
        }
      }
    ]
  }
}
