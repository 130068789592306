import { AuthProviderEnvironment } from '@matillion/hub-client'

import enabledComponents from './enabled-components.json'

const version = process.env.REACT_APP_VERSION ?? '0.0.0-local'

const environment = process.env.REACT_APP_ENVIRONMENT ?? 'local'

type EnvironmentConfig = {
  AUTH_ENVIRONMENT: AuthProviderEnvironment
  WEBSOCKET_ENDPOINT: string
  DATADOG?: Record<string, string>
  API_BASE_URL: string
  WORKING_TREE_API_BASE_URL: string
  PROJECT_EXPLORER_API_BASE_URL: string
  PERMISSIONS_API_BASE_URL: string
  TEMP_AGENT_ID: string
  TEMP_ENVIRONMENT_ID: string
  PROPERTIES_ALERT_TIMEOUT: number
  AGENT_REFETCH_INTERVAL_MS: number
  PROJECT_EXPLORER_DOMAIN: string
  LAUNCHDARKLY: {
    CLIENT_ID?: string
    DEFAULT_FLAGS: Record<string, boolean>
  }
  INTERCOM_TOUR_ID: number
} & {
  [key: string]: string | undefined
}

// eslint-disable-next-line @typescript-eslint/no-var-requires
const envConfig: EnvironmentConfig = require(`./${environment}.json`)
const defaultRegion = 'eu1'

/**
 * makeReplacer is a function factory for replacing the region placeholder in the api URLs
 * @param url the url to replace the region placeholder in
 */
const makeReplacer =
  (url: string) =>
  (region: string = defaultRegion) =>
    url.replace('{{region}}', region)

const defaultEnabledComponents = enabledComponents
  .map((componentId) => `component-${componentId}`)
  .reduce(
    (flags, componentName) => ({
      ...flags,
      [componentName]: true
    }),
    {}
  )

const config = {
  environment,
  version,
  enabledComponents,
  sourceApp: 'etl_designer',
  redirectOnMissingParamsURL:
    envConfig.PROJECT_EXPLORER_DOMAIN || 'project-explorer-dev.matillion.com',
  webSocketEndpoint: envConfig.WEBSOCKET_ENDPOINT,
  authEnvironment: envConfig.AUTH_ENVIRONMENT,
  apiTokenAudience: envConfig.API_TOKEN_AUDIENCE,
  getApiBaseUrl: makeReplacer(envConfig.API_BASE_URL),
  PERMISSIONS_API_BASE_URL: envConfig.PERMISSIONS_API_BASE_URL,
  getProjectExplorerApiBaseUrl: makeReplacer(
    envConfig.PROJECT_EXPLORER_API_BASE_URL
  ),
  gtmId: envConfig.GTM_ID,

  datadog: envConfig.DATADOG && {
    env: envConfig.DATADOG.ENV,
    site: envConfig.DATADOG.SITE,
    clientToken: envConfig.DATADOG.CLIENT_TOKEN,
    service: envConfig.DATADOG.SERVICE,
    rumService: envConfig.DATADOG.RUM_SERVICE,
    applicationId: envConfig.DATADOG.APPLICATION_ID
  },

  tempAgentId: envConfig.TEMP_AGENT_ID,
  tempEnvironmentId: envConfig.TEMP_ENVIRONMENT_ID,

  propertiesAlertTimeout: envConfig.PROPERTIES_ALERT_TIMEOUT,
  agentRefetchIntervalMs: envConfig.AGENT_REFETCH_INTERVAL_MS,
  projectExplorerDomain: envConfig.PROJECT_EXPLORER_DOMAIN,

  launchDarkly: {
    clientId: envConfig.LAUNCHDARKLY.CLIENT_ID,
    defaultFlags: {
      ...defaultEnabledComponents,
      ...envConfig.LAUNCHDARKLY.DEFAULT_FLAGS
    }
  },
  browserStoreKeys: {
    onboarded: 'designer.onboarded',
    onboardingSessionStarted: 'designer.onboardingSessionStarted'
  },
  docs: {
    designerOverviewUrl:
      'https://docs.matillion.com/data-productivity-cloud/designer/docs/designer-overview/',
    orchestrationVideoUrl: 'https://fast.wistia.net/embed/iframe/hma4na1cl9',
    transformationVideoUrl: 'https://fast.wistia.net/embed/iframe/8qibe9apu8'
  },
  intercomTourId: envConfig.INTERCOM_TOUR_ID
}

// This allows us to override feature flags using Cypress for test purposes
if (process.env.REACT_APP_ENVIRONMENT === 'test') {
  config.launchDarkly.defaultFlags = Object.entries(
    config.launchDarkly.defaultFlags
  ).reduce((obj, [key, defaultValue]) => {
    const override = window.localStorage.getItem(`flag.${key}`)
    return {
      ...obj,
      [key]: override ? override === 'true' : defaultValue
    }
  }, {})
}

export default config
