import { useDispatch } from 'react-redux'

import { CreateProjectVariablesRequest } from 'api/hooks/useCreateProjectVariables/types'
import { useCreateProjectVariables } from 'api/hooks/useCreateProjectVariables/useCreateProjectVariables'

import { jobActions } from 'job-lib/store'
import {
  JobVariableBehaviour,
  JobVariableType,
  JobVariableVisibility,
  VariableScope
} from 'job-lib/types/Variables'

import {
  FormState,
  ManageVariableStageProps,
  ProjectVariableBehaviour,
  ProjectVariableType,
  Stages
} from 'modules/ManageVariables/types'

import { Form } from '../Form/Form'

export const Create = ({
  setStage,
  selectedVariableScope
}: {
  setStage: ManageVariableStageProps['setStage']
  selectedVariableScope: VariableScope
}) => {
  const { mutateAsync: createProjectVariables, isLoading } =
    useCreateProjectVariables()
  const jobDispatch = useDispatch()

  const onSubmit = (state: FormState, isProjectVariable: boolean) => {
    if (isProjectVariable) {
      const postData: CreateProjectVariablesRequest = {
        name: state.NAME.value,
        type: state.VARIABLE_TYPE.value as ProjectVariableType,
        behaviour: ProjectVariableBehaviour.COPIED,
        description: state.DESCRIPTION.value,
        value:
          state.DEFAULT_VALUE.value !== '' ? state.DEFAULT_VALUE.value : null,
        overrides: Object.values(state.PROJECT_DEFAULT_OVERRIDES.value).map(
          ({ environmentId, value }) => ({
            environmentId,
            value: value !== '' ? value : null
          })
        )
      }
      createProjectVariables(postData).then(() => {
        setStage(Stages.MANAGE)
      })
    } else {
      jobDispatch(
        jobActions.addJobVariable({
          definition: {
            name: state.NAME.value,
            description: state.DESCRIPTION.value,
            type: state.VARIABLE_TYPE.value as JobVariableType,
            behaviour: JobVariableBehaviour.COPIED,
            visibility: state.VISIBILITY.value as JobVariableVisibility
          },
          value: state.DEFAULT_VALUE.value
        })
      )
      setStage(Stages.MANAGE)
    }
  }

  const onCancel = () => {
    setStage(Stages.MANAGE)
  }
  return (
    <Form
      selectedVariableScope={selectedVariableScope}
      onCancel={onCancel}
      onSubmit={onSubmit}
      submitting={isLoading}
    />
  )
}
