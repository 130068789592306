import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Toaster } from '@matillion/component-library'

import useCommit from 'api/hooks/useCommit/useCommit'
import useProblemDetails from '../useProblemDetails/useProblemDetails'
import { type CommitActionArgs, type CommitActionResult } from './types'
import { type ProblemDetailRegistry } from '../useProblemDetails/types'
import useGitContext from 'provider/GitProvider/useGitContext'

const commonFailureTitleKey = 'git.commit.problem.default.title'

const problemDetailsRegistry: ProblemDetailRegistry = [
  {
    type: 'working-tree/commit_message_too_long',
    translation: {
      titleKey: commonFailureTitleKey,
      messageKey: 'git.commit.problem.message-length'
    }
  },
  {
    type: 'working-tree/no_changes_to_commit',
    translation: {
      titleKey: commonFailureTitleKey,
      messageKey: 'git.commit.problem.no-changes'
    }
  },
  {
    type: 'working-tree/local_branch_behind_remote',
    translation: {
      titleKey: commonFailureTitleKey,
      messageKey: 'git.commit.problem.branch-behind'
    }
  },
  {
    type: 'working-tree/branch_not_found',
    translation: {
      titleKey: commonFailureTitleKey,
      messageKey: 'git.commit.problem.branch-not-found'
    }
  }
]

const useCommitAction = ({
  commitMessage,
  onSuccess,
  onFailure
}: CommitActionArgs): CommitActionResult => {
  const { t } = useTranslation('translation', { keyPrefix: 'git.commit.toast' })
  const { mutateAsync: doCommit } = useCommit()
  const { makeToast } = Toaster.useToaster()
  const [isLoading, setIsLoading] = useState(false)
  const { resolveProblemDetails } = useProblemDetails(problemDetailsRegistry)
  const { isNativeGit } = useGitContext()

  const commit = useCallback(async () => {
    if (!isLoading) {
      setIsLoading(true)

      try {
        const response = await doCommit(commitMessage)

        makeToast({
          type: 'success',
          title: t('success.title'),
          message: t(`success.message${isNativeGit ? 'NativeGit' : ''}`)
        })

        await onSuccess(response.commitId)
      } catch (error) {
        const { title, message } = resolveProblemDetails(error, {
          titleKey: commonFailureTitleKey,
          messageKey: 'git.commit.problem.default.message'
        })

        makeToast({
          title,
          message,
          type: 'error'
        })

        onFailure()
      }
      setIsLoading(false)
    }
  }, [
    doCommit,
    isLoading,
    onSuccess,
    onFailure,
    commitMessage,
    makeToast,
    t,
    resolveProblemDetails
  ])

  return { isLoading, commit }
}

export default useCommitAction
