import { useCallback } from 'react'
import { type GetRepositoriesArgs, type GetRepositoriesResponse } from './types'
import { useQuery } from '@tanstack/react-query'
import { queryKeys } from 'api/query-keys'
import { type ExternalGitProvider } from 'types/ExternalGitProvider'
import useGitClient from 'api/clients/useGitClient'

export const useGetRepositoriesQueryKey = (provider?: ExternalGitProvider) => {
  return [queryKeys.getRepositories, provider]
}

const useGetRepositories = ({ provider }: GetRepositoriesArgs = {}) => {
  const client = useGitClient()

  const getRepositories = useCallback(async () => {
    const url = `/providers/${provider}/repositories`
    const { data } = await client.get<GetRepositoriesResponse>(url)
    return data
  }, [client, provider])

  const queryKey = useGetRepositoriesQueryKey(provider)
  return useQuery(queryKey, getRepositories, {
    enabled: !!provider
  })
}

export default useGetRepositories
