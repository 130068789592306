import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ParameterDataType } from 'api/hooks/useGetComponentMetadata/types'

import { ElementCollection } from 'job-lib/types/Parameters'

import { ParameterValue } from 'modules/WorkingCopyProvider/Pipeline'

import {
  isParameterVisible,
  removeInvisibleParameters
} from 'utils/isParameterVisible'

import { JobState, OverrideComponentParam } from './job.types'
import { addComponent } from './reducers/addComponent/addComponent'
import { addJobVariable } from './reducers/addJobVariable/addJobVariable'
import { addNote } from './reducers/addNote/addNote'
import { cloneComponentGroup } from './reducers/cloneComponentGroup/cloneComponentGroup'
import { deleteJobVariable } from './reducers/deleteJobVariable/deleteJobVariable'
import { deleteLink } from './reducers/deleteLink/deleteLink'
import { deleteNodes } from './reducers/deleteNodes/deleteNodes'
import { deleteNote } from './reducers/deleteNote/deleteNote'
import { setJob } from './reducers/setJob/setJob'
import { updateJobVariable } from './reducers/updateJobVariable/updateJobVariable'
import { updateNodePosition } from './reducers/updateNodePosition/updateNodePosition'
import { updateNote } from './reducers/updateNote/updateNote'
import { setParameterDPLValue } from './utils/setParameterDPLValue'

export const isElementCollection = (
  hasStructParams: boolean,
  value: ElementCollection | ParameterValue
): value is ElementCollection =>
  !hasStructParams &&
  value !== null &&
  typeof value === 'object' &&
  !Array.isArray(value)

export const jobInitialState: JobState = {
  job: null,
  jobType: null
}

export const jobSlice = createSlice({
  name: 'job',
  initialState: jobInitialState as JobState,
  reducers: {
    overrideComponentParameter(
      state,
      action: PayloadAction<OverrideComponentParam>
    ) {
      const {
        componentInstanceId,
        parameterSlot,
        parameterPath,
        value,
        componentMetadata
      } = action.payload

      if (!state.job?.components[componentInstanceId]) {
        return state
      }

      // Saving a component with DPL_PARAMS is required for any component that has a dataType of struct at the top
      // level parameter properties.
      const hasStructParams = componentMetadata.parameters.some(
        (p) =>
          p.dataType === ParameterDataType.STRUCT ||
          p.dataType === ParameterDataType.STRUCT_LIST
      )

      const parameterCollection =
        state.job?.components[componentInstanceId].parameters

      if (isElementCollection(hasStructParams, value)) {
        if (!parameterCollection[parameterSlot]) {
          parameterCollection[parameterSlot] = {
            slot: parameterSlot,
            elements: {},
            name: '',
            visible: true
          }
        }

        parameterCollection[parameterSlot].elements = value

        componentMetadata.parameters.forEach((param) => {
          parameterCollection[param.metlSlot].visible = isParameterVisible(
            param.metlSlot,
            parameterCollection,
            componentMetadata
          )
        })

        return state
      }

      const componentNameSlot = parameterCollection[1].elements[1]
      const parametersSlot = parameterCollection[2].elements[1]

      if (parameterPath.at(0) === 'componentName') {
        componentNameSlot.values[1].value = value as string
      }

      const parameterDPL = JSON.parse(parametersSlot.values[1].value)

      parametersSlot.values[1].value = setParameterDPLValue(
        parameterDPL,
        parameterPath,
        value
      )

      const newDpl = removeInvisibleParameters(
        action.payload.componentMetadata,
        parameterCollection
      )
      parametersSlot.values[1].value = JSON.stringify(newDpl)

      return state
    },
    setJob,
    // This is used by the useSaveJobListener and is used in cases
    // where we need to override the current job model
    setJobAndSave: setJob,
    addComponent,
    cloneComponentGroup,
    deleteNodes,
    deleteLink,
    addJobVariable,
    deleteJobVariable,
    updateJobVariable,
    addNote,
    updateNodePosition,
    updateNote,
    deleteNote
  }
})

export const jobActions = jobSlice.actions
