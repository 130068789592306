import { AgentHostEnum } from 'api/hooks/useAgentDetails/useAgentDetails'

import { JobType } from 'job-lib/types/JobType'

import { CustomComponentSummaryResult } from '../types'

export const mockCustomComponentInfo: CustomComponentSummaryResult = {
  results: [
    {
      schemaVersion: 1,
      componentId: 'modular-custom-component-1',
      presentationResourceVersion: 1,
      componentType: JobType.Orchestration,
      emeraldId: '720344340',
      inputPorts: [],
      outputPorts: [],
      dwhTypes: ['SNOWFLAKE'],
      cloudPlatforms: ['AWS'],
      agentHosts: [AgentHostEnum.Matillion, AgentHostEnum.Customer],

      connectorId: 'custom-component-1',
      displayName: 'Custom connector',
      icon: 'https://via.placeholder.com/20'
    }
  ]
}
