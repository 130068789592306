import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { LoadingSpinner, Typography } from '@matillion/component-library'

import classes from '../FlowStatsExplorer.module.scss'

interface LoaderProps {
  isLoading: boolean
  isError: boolean
  remove: () => void
}

const Loader: FC<LoaderProps> = ({ isLoading, isError, remove }) => {
  useEffect(() => {
    return () => {
      remove()
    }
  }, [remove])

  const { t } = useTranslation()
  if (isLoading) {
    return <LoadingSpinner data-testid="loading-spinner" />
  }
  if (isError) {
    return (
      <Typography className={classes.FakeColSpan} format="bcs" as="span">
        {t('taskExplorer.loader.errorLoadingStrand')}
      </Typography>
    )
  }
  return null
}

export default Loader
