import { FC, useEffect } from 'react'
import { Controller, FieldValues, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Field,
  Input,
  Modal,
  Typography
} from '@matillion/component-library'
import classnames from 'classnames'

import { useGetJobSummaries } from 'api/hooks/useGetJobSummaries'

import { JobType } from 'job-lib/types/JobType'

import { useEtlFlow } from 'modules/Canvas/hooks/useEtlFlow'

import { useCreateJobAction } from '../../hooks/useCreateJobAction'
import {
  isPipelineExistingPipeline,
  VALID_NAME_REGEX
} from '../../utils/validatePipelineName'
import classes from '../Modal.module.scss'

export interface CreatePipelineModalProps {
  type: JobType
  folderPath: string | null
  onClose: () => void
}

const CreatePipelineModal: FC<CreatePipelineModalProps> = ({
  type,
  onClose,
  folderPath
}) => {
  const { t } = useTranslation()
  const { data: summaries, isLoading: isLoadingJobNames } = useGetJobSummaries()
  const { setCenter } = useEtlFlow()

  const {
    handleSubmit,
    setFocus,
    control,
    formState: { isDirty, isValid }
  } = useForm({
    mode: 'all'
  })

  useEffect(() => {
    const timer = setTimeout(() => setFocus('jobName'), 1)
    return () => {
      clearTimeout(timer)
    }
  }, [setFocus])

  const onSubmit = (data: FieldValues) => {
    const jobName: string = data.jobName.trim()
    const runtimeName = folderPath ? `${folderPath}/${jobName}` : jobName
    create(runtimeName)
    setCenter(0, 0, {
      zoom: 1
    })
  }

  const { isLoading, create } = useCreateJobAction({
    type,
    onComplete: onClose
  })

  return (
    <Modal
      id="newJob-dialog"
      onCancel={onClose}
      ariaLabelledBy="newJob-title"
      size="mid"
      disableBackdropClick
      className={classes.CreateJobModal}
      setFocus={false}
    >
      <div className={classes.Modal__Heading}>
        <Typography
          as="h2"
          format="tl"
          id="newJob-title"
          className={classes.Modal__Title}
        >
          {t('translation:createPipeline.title', { context: type })}
        </Typography>
      </div>
      <form
        onSubmit={(e) => {
          handleSubmit(onSubmit)(e)
        }}
      >
        <div
          className={classnames(
            classes.Modal__Content,
            classes['Modal__Content--Input']
          )}
        >
          <Controller
            name="jobName"
            defaultValue=""
            control={control}
            shouldUnregister={true}
            rules={{
              required: t('translation:managePipeline.error.noEmptyString'),
              pattern: {
                value: VALID_NAME_REGEX,
                message: t('translation:managePipeline.error.noRegex')
              },
              validate: (name: string) => {
                /* istanbul ignore next */
                if (!summaries?.results) {
                  return
                }

                const path = folderPath ? `${folderPath}/` : ''
                const isDuplicatePipeline = isPipelineExistingPipeline(
                  `${path}${name}`,
                  type,
                  summaries.results
                )
                if (isDuplicatePipeline) {
                  return t('translation:managePipeline.error.noDuplicates')
                }
              }
            }}
            render={({
              field: { value, onChange, name, ref },
              fieldState: { error }
            }) => {
              return (
                <Field
                  id={name}
                  data-testid="modal-newJob-name-input"
                  title={t('translation:createPipeline.name')}
                  aria-label={t('translation:createPipeline.name')}
                  name={name}
                  value={value}
                  onChange={onChange}
                  placeholder={t('translation:createPipeline.namePlaceholder')}
                  hasError={!!error}
                  error={!!error}
                  errorText={error?.message}
                  disabled={isLoading || isLoadingJobNames}
                  inputComponent={Input}
                  inputRef={ref}
                />
              )
            }}
          />
        </div>
        <div className={classes.Modal__Actions}>
          <Button
            id="newJob-cancel"
            data-testid="modal-create-cancel"
            text={t('translation:managePipeline.cancel')}
            alt="secondary"
            onClick={onClose}
          />
          <Button
            id="newJob-create"
            data-testid="modal-create-submit"
            text={t('translation:createPipeline.create')}
            waiting={isLoading}
            disabled={isLoading || isLoadingJobNames || !isDirty || !isValid}
            type="submit"
            alt="positive"
          />
        </div>
      </form>
    </Modal>
  )
}

export default CreatePipelineModal
