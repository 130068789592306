import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Tooltip, Typography } from '@matillion/component-library'

import classes from './NameRenderer.module.scss'

export interface NameRendererProps {
  labelId: string
  inputId: string
  parameterName: string
  isOptional?: boolean
}

export const NameRenderer: FC<NameRendererProps> = ({
  parameterName,
  labelId,
  inputId,
  isOptional
}) => {
  const { t } = useTranslation()

  return (
    <Tooltip
      onlyShowOnOverflow
      content={
        <>
          {parameterName}
          {isOptional && <> {` (${t('common.optional')})`}</>}
        </>
      }
      className={classes.NameRenderer__Tooltip}
    >
      <label
        id={labelId}
        htmlFor={inputId}
        className={classes.NameRenderer__Label}
      >
        <Typography format="bcs" className={classes.NameRenderer__Name}>
          {parameterName}
        </Typography>

        {isOptional && (
          <Typography format="bcs" className={classes.NameRenderer__Optional}>
            {`(${t('common.optional')})`}
          </Typography>
        )}
      </label>
    </Tooltip>
  )
}
