import { RequestHandler, rest } from 'msw'

import config from 'config'

export const usePublishJobsHandlers: RequestHandler[] = [
  rest.post(
    `${config.getApiBaseUrl()}/v1/job-publications`,
    async (req, res, ctx) => {
      return res(ctx.status(201))
    }
  )
]
