import { useCallback } from 'react'

import { useMutation } from '@tanstack/react-query'

import { type PullChangesRequest, type PullMutationArgs } from './types'
import useMapConflictSelections from 'hooks/useMapConflictSelections'
import { type ConflictVersion } from 'components/ConflictVersionSelector/types'
import useGitContext from 'provider/GitProvider/useGitContext'
import useWorkingTreeClient from 'api/clients/useWorkingTreeClient'

const conflictMapping = new Map<ConflictVersion, string>([
  ['OURS', 'LOCAL'],
  ['THEIRS', 'REMOTE']
])

const usePullChanges = () => {
  const { project, branch } = useGitContext()
  const client = useWorkingTreeClient()
  const { map } = useMapConflictSelections(conflictMapping)

  const merge = useCallback(
    async ({ remoteCommitId, conflictSelections }: PullMutationArgs) => {
      const body: PullChangesRequest = {
        remoteCommitId,
        conflictResolutions: map(conflictSelections)
      }

      const url = `projects/${project}/branches/${branch}/merges`
      const { status } = await client.post(url, body)
      return status
    },
    [branch, client, map, project]
  )

  return useMutation([project], merge)
}

export default usePullChanges
