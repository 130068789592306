import { FunctionComponent } from 'react'

import { Icon, Typography } from '@matillion/component-library'
import { t } from 'i18next'

import classes from './InteractiveTabs.module.scss'
import { TabCloseButtonProps } from './types'

export const TabCloseButton: FunctionComponent<TabCloseButtonProps> = ({
  title,
  testId,
  onClick
}) => (
  <Typography
    as="div"
    format="mc"
    className={classes.InteractiveTabs__TabCloseButton}
    aria-label={t('tabs.closeTabName', {
      tabName: title
    })}
    data-testid={testId}
    role="button"
    // descendants of [role="tab"] are presentation by default
    // and as such will not be announced by screen readers
    tabIndex="-1"
    onClick={onClick}
  >
    <Icon.Cross />
  </Typography>
)
