import { DragEvent, FC, HTMLProps, useCallback, useRef } from 'react'

import { ComponentSummaryId } from 'api/hooks/useGetComponentTree'

import { InitialParameterValues } from 'job-lib/builders/createComponent/types'

import { DragOriginValues } from './ComponentDragSource'

export const DATA_TRANSFER_KEY_ID = 'component-id'
export const DATA_TRANSFER_KEY_PARAMETERS = 'component-parameters'
export const DATA_TRANSFER_KEY_NAME = 'component-name'
export const DATA_TRANSFER_KEY_DRAG_ORIGIN = 'drag-origin'

export interface ComponentMeta {
  initialParameters: InitialParameterValues
  /**
   * The point at which the drop happened relative to the container
   */
  relativeDropPoint: IPoint
  /**
   * The point on the page where the drop happened (clientX and clientY event)
   */
  absoluteDropPoint: IPoint
  componentName: string
  dragOrigin: DragOriginValues
}

export interface ComponentDropDestinationProps
  extends HTMLProps<HTMLDivElement> {
  onDropComponent: (
    componentId: ComponentSummaryId,
    meta: ComponentMeta
  ) => unknown
}

export interface IPoint {
  x: number
  y: number
}

export const ComponentDropDestination: FC<ComponentDropDestinationProps> = ({
  onDropComponent,
  children,
  ...props
}) => {
  const canvasWrapperRef = useRef<HTMLDivElement>(null)

  /* onDragOver is fired when the user drags something over this element, and is used to validate
   * whether or not this component will accept whatever that drop is */
  const onDragOver = useCallback((event: DragEvent<HTMLElement>) => {
    const isValidDrop = event.dataTransfer.types.includes(DATA_TRANSFER_KEY_ID)

    if (!isValidDrop) {
      return
    }

    // prevent default here makes it so the browser knows this is a droppable zone
    event.preventDefault()
  }, [])

  const onDrop = useCallback(
    (event: DragEvent<HTMLElement>) => {
      const componentId = event.dataTransfer.getData(DATA_TRANSFER_KEY_ID)
      const componentParams = event.dataTransfer.getData(
        DATA_TRANSFER_KEY_PARAMETERS
      )
      const componentName = event.dataTransfer.getData(DATA_TRANSFER_KEY_NAME)
      const dragOrigin: DragOriginValues = event.dataTransfer.getData(
        DATA_TRANSFER_KEY_DRAG_ORIGIN
      )
      if (!componentId || !canvasWrapperRef.current) {
        return
      }

      event.preventDefault()

      const parsedParams: InitialParameterValues = JSON.parse(componentParams)
      const canvasBounds = canvasWrapperRef.current.getBoundingClientRect()
      onDropComponent(componentId, {
        initialParameters: parsedParams,
        componentName,
        dragOrigin,
        relativeDropPoint: {
          x: event.clientX - canvasBounds.left,
          y: event.clientY - canvasBounds.top
        },
        absoluteDropPoint: {
          x: event.clientX,
          y: event.clientY
        }
      })
    },
    [onDropComponent]
  )

  return (
    <div
      ref={canvasWrapperRef}
      onDragOver={onDragOver}
      onDrop={onDrop}
      {...props}
    >
      {children}
    </div>
  )
}
