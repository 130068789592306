import { useAuth } from '@matillion/hub-client'
import { useQuery } from '@tanstack/react-query'

import {
  checkPermission,
  Permission,
  Resource
} from '../checkPermission/checkPermission'

export interface UsePermissionProps {
  resource: Resource
  permission: Permission
}

export const usePermission = ({ resource, permission }: UsePermissionProps) => {
  const { getToken } = useAuth()

  return useQuery({
    queryKey: ['permissions', resource.type, resource.id, permission],
    staleTime: Infinity, // this will mean that future calls matching the query key will always return the same value.
    cacheTime: Infinity, // until the page reloads
    queryFn: async () => {
      const token = await getToken()
      const response = await checkPermission({
        permission,
        resource,
        token
      })

      return response.data
    }
  })
}
