import { ListProjectVariablesResponse } from 'api/hooks/useListProjectVariables/types'

import { JobVariableCollection, VariableScope } from 'job-lib/types/Variables'

import {
  ErrorStatuses,
  Fields,
  FormState,
  ReducerActions
} from 'modules/ManageVariables/types'

import { errorMessages } from '../../Form/errorMessages'
import { reservedWords } from '../../Form/reservedWords'
import { isFormValid } from './isFormValid'

export const updateName = (
  state: FormState,
  action: {
    type: ReducerActions.UPDATE_NAME
    value: string
    jobVariables: JobVariableCollection | undefined
    projectVariables: ListProjectVariablesResponse
    originalName?: string
  }
): FormState => {
  let errorStatus = ErrorStatuses.NO_ERROR

  const { value, jobVariables, projectVariables, originalName } = action

  const isReservedWord = reservedWords.includes(
    action.value.toLowerCase().trim()
  )
  const isCorrectFormat = /^[a-zA-Z_$][\w$]+$/.test(value)

  if (!isCorrectFormat) {
    errorStatus = ErrorStatuses.INCORRECT_NAME_FORMAT
  }

  if (isReservedWord) {
    errorStatus = ErrorStatuses.RESERVED_WORD
  }
  const duplicatesExistingJobVariable = Boolean(jobVariables?.[value])
  const jobSelectedInForm =
    state.VARIABLE_SCOPE.value === VariableScope.JOB_VARIABLE
  const jobDuplicateError = duplicatesExistingJobVariable && jobSelectedInForm

  const duplicatesExistingProjectVariable = projectVariables.some(
    (pv) => pv.name === value
  )
  const projectSelectedInForm =
    state.VARIABLE_SCOPE.value === VariableScope.PROJECT_VARIABLE
  const projectDuplicateError =
    duplicatesExistingProjectVariable && projectSelectedInForm

  if (value !== originalName && (jobDuplicateError || projectDuplicateError)) {
    errorStatus = ErrorStatuses.DUPLICATE
  }

  if (!value) {
    errorStatus = ErrorStatuses.EMPTY
  }

  const isValid = errorStatus === ErrorStatuses.NO_ERROR

  const updatedState = {
    ...state,
    [Fields.NAME]: {
      isValid,
      value,
      error: errorMessages[errorStatus]
    }
  }

  return {
    ...updatedState,
    isFormValid: isFormValid(updatedState)
  }
}
