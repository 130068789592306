import { Fields, FormState } from 'modules/ManageVariables/types'

export const validateForm = (state: FormState): FormState => {
  const fields = Object.keys(Fields) as Array<keyof typeof Fields>

  const newState = fields.reduce(
    (acc, field) => {
      const currentField = state[Fields[field]]

      return {
        ...acc,
        [field]: {
          ...currentField,
          isValid: currentField.isValid
            ? true
            : Boolean(!currentField.error && currentField.value)
        }
      }
    },
    { ...state }
  )

  return newState
}
