import { FC, PropsWithChildren, ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { MicroCta, Typography } from '@matillion/component-library'
import classNames from 'classnames'

import { HorizontalCollapseIcon } from './CollapseIcon'
import classes from './Collapsible.module.scss'

export interface CollapsibleProps extends PropsWithChildren {
  collapsibleChildren: ReactNode
  disabled?: boolean
  onChange?: (isCollapsed: boolean) => unknown
  actionLabel: ReactNode
}

const CollapsibleLabel: FC<PropsWithChildren> = ({ children }) => (
  <Typography as="h3" format="bcs" weight="bold">
    {children}
  </Typography>
)

export const Collapsible: FC<CollapsibleProps> = ({
  actionLabel,
  children,
  collapsibleChildren,
  onChange,
  disabled
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false)
  const { t } = useTranslation()

  const toggleCollapsed = () => {
    setIsCollapsed((oldVal) => {
      const newVal = !oldVal

      if (onChange) {
        onChange(newVal)
      }

      return newVal
    })
  }

  return (
    <div className={classes.Collapsible}>
      <div
        className={classNames(
          classes.Collapsible__CollapsibleSection,
          isCollapsed && classes['Collapsible__CollapsibleSection--isCollapsed']
        )}
      >
        <div
          className={classNames(
            classes.Collapsible__CollapsibleSection__ActionBar,
            isCollapsed &&
              classes['Collapsible__CollapsibleSection__ActionBar--isCollapsed']
          )}
        >
          {!isCollapsed && <CollapsibleLabel>{actionLabel}</CollapsibleLabel>}

          <MicroCta
            className={
              classes.Collapsible__CollapsibleSection__ActionBar__ActionButton
            }
            aria-label={isCollapsed ? t('common.expand') : t('common.collapse')}
            alt="transparent"
            disabled={disabled}
            onClick={toggleCollapsed}
          >
            <HorizontalCollapseIcon isCollapsed={isCollapsed} />
          </MicroCta>
        </div>

        <div className={classes.Collapsible__CollapsibleSection__Content}>
          {!isCollapsed && collapsibleChildren}
        </div>
      </div>
      <div className={classNames(classes.Collapsible__Content)}>{children}</div>
    </div>
  )
}
