import { FunctionComponent, useEffect } from 'react'

import { Loader } from '@matillion/component-library'
import { useAuth } from '@matillion/hub-client'

const Logout: FunctionComponent = () => {
  const { logout } = useAuth()

  useEffect(() => {
    logout({ returnTo: window.location.origin })
  }, [logout])

  return <Loader />
}

export default Logout
