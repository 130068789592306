/* istanbul ignore file */

import { createContext } from 'react'

import { ValidationContextBag } from './types'

export const ValidationProviderContext = createContext<ValidationContextBag>({
  isValidatingJob: false,
  isUnvalidated: true,
  hasFinishedValidating: false,
  validationResults: [],
  validationQueryCache: {},
  setValidationEnabled: () => null,
  getValidationResult: () => ({
    isError: false,
    isLoading: false,
    isSuccess: false,
    failures: null,
    failureMessage: null
  }),
  validationsHistory: [],
  invalidateComponent: () => null,
  invalidateTransformationComponent: () => null,
  resetValidation: () => null
})
