import { useQuery } from '@tanstack/react-query'
import { type DescribeMergeResponse } from './types'
import { queryKeys } from 'api/query-keys'
import useDesignerClient from 'api/clients/useDesignerClient'

/**
 * The identifier in the path variable of the merges resource accepts a "commit range".
 * A commit range is Git terminology for two specifiers (branch name, commit id, or tag)
 * separated by 2 (..) or 3 (...) dots.
 *
 * @param sourceSpecifier A specifier identifying the source of the merge.
 * @param targetSpecifier A specifier identifying the target of the marge.
 */
const useDescribeMerge = (
  sourceSpecifier?: string,
  targetSpecifier?: string
) => {
  const { project, client } = useDesignerClient()

  return useQuery(
    [queryKeys.merge, project, targetSpecifier, queryKeys.describeMerge],
    async () => {
      const url = `projects/${project}/merges/${sourceSpecifier}...${
        targetSpecifier as string
      }`
      const { data } = await client.get<DescribeMergeResponse>(url)
      return data
    },
    { enabled: !!targetSpecifier }
  )
}

export default useDescribeMerge
