import { useEffect, useMemo, useState } from 'react'

import { datadogLogs } from '@datadog/browser-logs'

import {
  AgentHostEnum,
  useAgentDetails
} from '../useAgentDetails/useAgentDetails'

export const useAgentHost = () => {
  const { data, isInitialLoading, isError } = useAgentDetails()
  const [agentHost, setAgentHost] = useState<AgentHostEnum | null>(null)

  useEffect(() => {
    if (!data?.host && !isInitialLoading) {
      datadogLogs.logger.error('Agent host type not found', data)
    }
    if (!agentHost && data?.host) {
      setAgentHost(data?.host)
    }
  }, [isInitialLoading, data, agentHost])

  return useMemo(
    () => ({
      agentHost: agentHost,
      isInitialLoading,
      isError
    }),
    [agentHost, isInitialLoading, isError]
  )
}
