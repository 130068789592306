import { QueryClient } from '@tanstack/react-query'

import {
  ComponentMetadata,
  ComponentParameter,
  ParameterDataType
} from 'api/hooks/useGetComponentMetadata/types'
import { NamedEditorColumn } from 'api/hooks/useGetParameterOptions/types'
import { ParameterOptionsQueryKey, queryKeys } from 'api/queryKeys'

import { isDPLParameterCollection } from 'job-lib/store/jobSlice/utils/isDPLParameterCollection'
import {
  ComponentInstanceId,
  OrchestrationJob,
  TransformationJob
} from 'job-lib/types/Job'
import {
  ElementCollection,
  ParameterCollection
} from 'job-lib/types/Parameters'

import {
  getDPLParameters,
  getParameterDPLValue
} from 'modules/ComponentParameters/utils/getParameterValue'
import { ListValue, ParameterValue } from 'modules/WorkingCopyProvider/Pipeline'

interface GridColumnParams {
  queryClient: QueryClient
  projectId: string
  branchId: string
  jobSummaryId: string
  componentId: ComponentInstanceId | null
}

export interface GetParameterDependencyOptions extends GridColumnParams {
  lookupDependencies: string[]
  componentMetaData: ComponentMetadata
  componentId: ComponentInstanceId | null
  job: OrchestrationJob | TransformationJob | null
}

export interface GetParameterValueParams {
  parameterPath: string[]
  parameterId: string
  parameterPathIndex?: number
  componentParameters: ComponentParameter[]
  componentParameterCollection: ParameterCollection
  columnDependencies: GridColumnParams
}

interface GetMETLColumnValueParams {
  parameterCollection: ParameterCollection
  parameter: ComponentParameter
  column: string
  columnDependencies: GridColumnParams
}

const getMETLParameterValue = (
  parameterCollection: ParameterCollection,
  parameterDataType: ParameterDataType,
  metlSlot: number
) => {
  switch (parameterDataType) {
    case ParameterDataType.GRID: {
      return parameterCollection[metlSlot]?.elements
    }

    case ParameterDataType.LIST: {
      return Object.values(parameterCollection[metlSlot]?.elements).map(
        (slot) => slot.values?.[1].value
      )
    }

    default: {
      return parameterCollection[metlSlot]?.elements[1]?.values[1]?.value
    }
  }
}

const getMETLColumnValue = ({
  parameterCollection,
  parameter,
  column,
  columnDependencies
}: GetMETLColumnValueParams): ListValue => {
  if (
    !columnDependencies.componentId ||
    !parameterCollection[parameter.metlSlot]
  ) {
    return []
  }

  const queryKey: ParameterOptionsQueryKey = [
    queryKeys.parameterOptions,
    columnDependencies.projectId,
    columnDependencies.branchId,
    columnDependencies.jobSummaryId,
    parameter.lookupType ?? null,
    columnDependencies.componentId,
    parameter.id ?? parameter.dplID
  ]

  const gridColumns =
    parameter.staticOptions ??
    columnDependencies.queryClient.getQueryData<
      Record<string, NamedEditorColumn[]>
    >(queryKey)?.editorColumns
  const columnSlotIndex = gridColumns?.findIndex(
    (col) =>
      col.resourceID === column ||
      slugifyColumnName(col as NamedEditorColumn) === column
  )

  if (columnSlotIndex === undefined || columnSlotIndex === -1) {
    return []
  }

  return Object.values(parameterCollection[parameter.metlSlot]?.elements).map(
    (entry) => entry.values[columnSlotIndex + 1].value
  )
}

const getParameterValue = ({
  parameterPath,
  parameterId,
  parameterPathIndex = 0,
  componentParameters,
  componentParameterCollection,
  columnDependencies
}: GetParameterValueParams): ParameterValue | ElementCollection => {
  const parameter = componentParameters.find(
    (param) => param.id === parameterId || param.dplID === parameterId
  )

  if (!parameter) {
    return null
  }

  // TODO: this is temporary and will be removed when https://matillion.atlassian.net/browse/CIS-833 is completed
  // CIS will amend the metadata for the join, transpose-columns, and flatten-variant components so they receive entire grid values instead of columns
  // so a "param.abc.xyz" lookup will only ever refer to a nested parameter structure, where "xyz" is a child parameter of "abc"
  const isGridParameter = parameter.dataType === ParameterDataType.GRID

  // we know it's a grid column lookup when the current parameter is a type of GRID
  // and we're not on the last parameter in the parameterPath
  const isGridColumnLookup =
    isGridParameter && parameterPathIndex < parameterPath.length - 1

  if (isGridColumnLookup) {
    return getMETLColumnValue({
      parameter,
      parameterCollection: componentParameterCollection,
      column: String(parameterPath.at(-1)),
      columnDependencies
    })
  }

  const isLastItemInPath = parameterPathIndex === parameterPath.length - 1

  // we only want to look in child properties if the parameter has them
  // and we're not at the end of the parameter path
  if (parameter.childProperties && !isLastItemInPath) {
    return getParameterValue({
      parameterPath,
      parameterId: parameterPath[parameterPathIndex + 1],
      parameterPathIndex: parameterPathIndex + 1,
      componentParameters: parameter.childProperties,
      componentParameterCollection,
      columnDependencies
    })
  }

  const isDPLParameters = isDPLParameterCollection(componentParameterCollection)

  const parameterValue: ParameterValue | ElementCollection = isDPLParameters
    ? getParameterDPLValue(
        parameterPath,
        getDPLParameters(null, componentParameterCollection)
      )
    : getMETLParameterValue(
        componentParameterCollection,
        parameter.dataType,
        parameter.metlSlot
      )

  // for STRUCT parameters we want to surround the value in a "parameters" object
  // to indicate that it's a group of parameters
  if (parameter.dataType === ParameterDataType.STRUCT) {
    return {
      parameters: parameterValue as ParameterValue
    }
  }

  return parameterValue
}

export const getParameterDependencies = ({
  lookupDependencies,
  componentMetaData,
  componentId,
  job,
  queryClient,
  projectId,
  branchId,
  jobSummaryId
}: GetParameterDependencyOptions) => {
  return lookupDependencies?.reduce((definition, dependency) => {
    // this regex captures a parameter path followed by an optional alias
    const regex = /param\.([\w-.]+)(?::([\w-.]+))?/

    // the match contains the parameter path and an optional alias
    // e.g. param.schema:param.alias => ["schema", "param.alias"]
    // e.g. param.group1.group2.param1 => ["group1.group2.param1", undefined"]
    const match = regex.exec(dependency)

    if (!match) {
      return definition
    }

    const [lookupPrefix, lookupPath, lookupAlias] = match
    const parameterPath = lookupPath.split('.')

    const componentParameterCollection =
      job?.components[componentId as number]?.parameters ?? {}

    const parameterValue = getParameterValue({
      parameterPath,
      componentParameterCollection,
      componentParameters: componentMetaData.parameters,
      parameterId: String(parameterPath.at(0)),
      columnDependencies: {
        jobSummaryId,
        queryClient,
        projectId,
        branchId,
        componentId
      }
    })

    // a dependency is aliased when it includes the ":" character
    // e.g. param.abc:param.xyz means that the value of "abc" should be sent in the payload as "param.xyz"
    // the dependency alias is always the last item in the regex match
    const hasAlias = lookupAlias !== undefined

    // if it's an aliased dependency then take the last item in the regex match
    // otherwise take the input to the regex at index 0
    const dependencyKey = hasAlias ? String(lookupAlias) : String(lookupPrefix)

    return {
      ...definition,
      [dependencyKey]: parameterValue
    }
  }, {})
}

function slugifyColumnName(column: NamedEditorColumn) {
  return column.name.toLowerCase().replaceAll(' ', '-')
}
