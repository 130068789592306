import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  DataGridColumnProps,
  DataGridProps
} from '@matillion/component-library'
import classnames from 'classnames'

import { StrandStatsTreeResponse } from 'api/hooks/useGetFlowStats/types'
import { useGetStatsFlowInstance } from 'api/hooks/useGetFlowStats/useGetStatsFlowInstance'
import { RunTaskStatus } from 'api/hooks/useGetRunTasks/types'

import CellMessage from '../components/CellMessage'
import CellTypography from '../components/CellTypography'
import RowTreeSelector from '../components/RowTreeSelector'
import classes from '../FlowStatsExplorer.module.scss'
import {
  JobTree,
  JobTreeController,
  TreeGridItemDepth,
  TreeGridRowAriaAttributes
} from '../types'
import {
  customFormatDate,
  customFormatDuration
} from '../utils/stats.formatters'
import {
  adjustStrandTaskNames,
  adjustStrandTasks,
  safeString,
  strandToTreeItems
} from '../utils/stats.transformer'
import {
  filterTreeItems,
  findParent,
  sortTreeItems,
  trimItemDepth
} from '../utils/tree-depth'

export const useTreeGridController = (task: RunTaskStatus) => {
  const { t } = useTranslation()

  const [items, setItems] = useState<JobTree[]>([])
  const [filters, setFilters] = useState<TreeGridItemDepth[]>([])
  const { data, isLoading, isError, isSuccess } = useGetStatsFlowInstance(
    task.flowInstanceId,
    'BASE_JOB'
  )

  const getStartCollapsed = (strandTasks: JobTree[]): string[] => {
    return strandTasks.reduce<string[]>((acc, currentValue) => {
      if (currentValue.hasChildren || currentValue.childJobId) {
        acc.push(currentValue.depth)
      }
      return acc
    }, [])
  }

  const updateStartCollapsed = useCallback(
    (strandRoot: JobTree, strandTasks: JobTree[]): void => {
      const startCollapsed = getStartCollapsed(strandTasks)

      if (strandRoot.hasChildren) {
        startCollapsed.push(strandRoot.depth)
      }

      setFilters((oldFilters) =>
        Array.from(new Set([...oldFilters, ...startCollapsed]))
      )
    },
    []
  )

  const addStrandTasks = (
    oldItems: JobTree[],
    strandTasks: JobTree[]
  ): void => {
    strandTasks.forEach((tt) => {
      const foundTask = oldItems.findIndex((x) => x.depth === tt.depth)

      /* istanbul ignore next */
      if (foundTask === -1) {
        oldItems.push(tt)
      }
    })
  }

  const findStrandRootIndex = (
    oldItems: JobTree[],
    strandRoot: JobTree
  ): number => {
    return oldItems.findIndex((x) => x.depth === strandRoot.depth)
  }

  const updateItems = useCallback(
    (strandRoot: JobTree, strandTasks: JobTree[]): void => {
      setItems((oldItems) => {
        const foundIndex = findStrandRootIndex(oldItems, strandRoot)

        /* istanbul ignore next */
        if (foundIndex !== -1) {
          const itemToReplace = oldItems[foundIndex]
          const strandRootParentDepth = trimItemDepth(itemToReplace.depth)
          const strandRootParent = findParent(oldItems, strandRootParentDepth)
          adjustStrandTasks(itemToReplace, strandTasks)

          adjustStrandTaskNames(strandRootParent, strandTasks, itemToReplace)

          oldItems[foundIndex] = strandRoot
        }

        addStrandTasks(oldItems, strandTasks)
        return [...oldItems]
      })
    },
    []
  )

  /**
   * Inject dynamically loaded items in the item list.
   * @param {JobTree} parent
   * @param {StrandStatsTreeResponse} strand
   *
   * Following the specs of the API endpoints, these items are all coming from a StrandStatsTreeResponse payload:
   * - the top strand item already exists in the list (used to trigger the react-query request) and needs to be replaced
   * - the tasks needs to be added to the list, IF THEY DON'T ALREADY EXIST (see mount/unmount sequence)
   */

  const injectItems = useCallback(
    (
      parent: JobTree,
      strand: StrandStatsTreeResponse,
      jobName?: string
    ): void => {
      const [strandRoot, ...strandTasks] = strandToTreeItems(
        parent.depth,
        strand,
        safeString(parent.jobName),
        safeString(parent.data?.component),
        injectItems,
        safeString(jobName),
        safeString(parent.loader?.flowInstanceId)
      )

      updateStartCollapsed(strandRoot, strandTasks)

      updateItems(strandRoot, strandTasks)
    },
    [updateItems, updateStartCollapsed]
  )

  const getTreeGridProps = (): Partial<DataGridProps<JobTree>> => ({
    // TODO[DPCD-492] A11y - inject aria-expanded and aria-selected
    mapRowAriaAttributes: (e: JobTree): TreeGridRowAriaAttributes => ({
      'aria-expanded': !filters.includes(e.depth),
      'aria-rowindex': 0
    }),
    // TODO[DPCD-492] A11y: if used (it should because it's known), aria-rowindex needs injection too
    'aria-rowcount': -1,
    'aria-label': t('taskExplorer.gridTitle'),
    'aria-multiselectable': false,
    'aria-readonly': true,
    tabIndex: 0,
    role: 'treegrid'
  })

  const columnSpecs: Array<DataGridColumnProps<JobTree>> = [
    {
      key: 'id',
      title: t('taskExplorer.grid.job'),
      as: RowTreeSelector,
      className: classes.Cell__Expander,
      mapValues: (e: JobTree): JobTreeController => ({
        ...e,
        isInFilter: filters.includes(e.depth),
        expandItems: (parent) => {
          setFilters((oldFilters) => [...oldFilters, parent])
        },
        collapseItem: (parent) => {
          setFilters((oldFilters) => oldFilters.filter((ff) => ff !== parent))
        }
      })
    },
    {
      key: 'data.component',
      title: t('taskExplorer.grid.component'),
      className: classnames(classes.Cell__Component, classes.Cell),
      as: CellTypography,
      mapValues: (e: JobTree): string | undefined => e.data?.component
    },
    {
      key: 'data.duration',
      title: t('taskExplorer.grid.duration'),
      className: classnames(classes.Cell__TimeDuration, classes.Cell),
      as: CellTypography,
      mapValues: (e: JobTree) => ({
        children: customFormatDuration(e.data?.started, e.data?.completed)
      })
    },
    {
      key: 'data.queued',
      title: t('taskExplorer.grid.queued'),
      className: classnames(classes.Cell__TimeDuration, classes.Cell),
      as: CellTypography,
      mapValues: (e: JobTree) => ({
        children: customFormatDate(e.data?.queued)
      })
    },
    {
      key: 'data.started',
      title: t('taskExplorer.grid.started'),
      className: classnames(classes.Cell__TimeDuration, classes.Cell),
      as: CellTypography,
      mapValues: (e: JobTree) => ({
        children: customFormatDate(e.data?.started)
      })
    },
    {
      key: 'data.completed',
      title: t('taskExplorer.grid.completed'),
      className: classnames(classes.Cell__TimeDuration, classes.Cell),
      as: CellTypography,
      mapValues: (e: JobTree) => ({
        children: customFormatDate(e.data?.completed)
      })
    },
    {
      key: 'data.rowCount',
      title: t('taskExplorer.grid.rowCount'),
      className: classnames(classes.Cell__RowCount, classes.Cell),
      as: CellTypography,
      mapValues: (e: JobTree) => {
        const rowCount = e.data?.rowCount
        return { children: rowCount && rowCount > -1 ? rowCount : '' }
      }
    },
    {
      key: 'data.message',
      title: t('taskExplorer.grid.message'),
      className: classnames(classes.Cell__Message, classes.Cell),
      as: CellMessage,
      mapValues: (e: JobTree) => ({
        value: e.data?.message,
        workerRequests: e.workerRequests
      })
    }
  ]

  useEffect(() => {
    if (!data || !isSuccess) return

    const rootJob = strandToTreeItems(
      '1',
      data.rootJob,
      safeString(data.jobName),
      '',
      injectItems,
      undefined,
      undefined,
      data.flowInstanceId
    )

    setItems(rootJob)

    setFilters(getStartCollapsed(rootJob))
  }, [data, isSuccess, injectItems])

  const filteredItems = useMemo(() => {
    return [...items].sort(sortTreeItems).filter(filterTreeItems(filters))
  }, [items, filters])

  return {
    columnSpecs,
    items: filteredItems,
    isLoading,
    isError,
    isSuccess,
    filters,
    getTreeGridProps
  }
}
