import { useCallback } from 'react'

import { useQuery } from '@tanstack/react-query'

import { queryKeys } from 'api/queryKeys'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { OrchestrationJob, TransformationJob } from 'job-lib/types/Job'

import { JobSummaryId } from '../useGetJobSummaries'
import { useSpringClient } from '../useSpringClient/useSpringClient'

export const getJobQueryKey = (
  projectId: string,
  branchId: string,
  jobId: JobSummaryId
) => [queryKeys.pull, queryKeys.job, projectId, branchId, jobId]

export const useGetJob = (jobId: JobSummaryId) => {
  const { projectId, branchId, agentId } = useProjectInfo()
  const client = useSpringClient()

  const getAJob = useCallback(async () => {
    const { data } = await client.get<OrchestrationJob | TransformationJob>(
      `/projects/${projectId}/branches/${branchId}/jobs/${jobId}`,
      {
        headers: {
          // agent-id is required for DPL conversion.
          'agent-id': agentId
        }
      }
    )
    return data
  }, [client, projectId, branchId, agentId, jobId])

  return useQuery(getJobQueryKey(projectId, branchId, jobId), getAJob, {
    cacheTime: 0,
    staleTime: 0,
    enabled: Boolean(jobId)
  })
}
