import { type GitProviderAuthModalProps } from './types'
import { Modal } from '@matillion/component-library'
import AuthModalForm from './AuthModalForm'
import classes from './GitProviderAuthModal.module.scss'

const GitProviderAuthModal = ({
  provider,
  onClose
}: GitProviderAuthModalProps) => {
  return (
    <Modal
      size="mid"
      onCancel={onClose}
      disableBackdropClick
      className={classes.modal}
      id={`${provider}-auth-modal`}
      ariaLabelledBy="git-auth-title"
      data-testid={`${provider}-auth-modal`}
    >
      <AuthModalForm provider={provider} onClose={onClose} />
    </Modal>
  )
}

export default GitProviderAuthModal
