import { rest } from 'msw'

import config from 'config'

import { referenceMetadata } from './mockComponentMetaData'

export const getMockComponentMetadata = (cisId: string) =>
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  referenceMetadata.find((component) => component.componentId === cisId)!

export const useGetComponentMetadataHandlers = [
  rest.get(
    `${config.getApiBaseUrl()}/v1/components/:cisId`,
    (req, res, ctx) => {
      const cisId = req.params.cisId as string

      const metadata = referenceMetadata.find(
        (component) => component.componentId === cisId
      )

      if (metadata) {
        return res(ctx.json(metadata))
      }

      return res(ctx.status(500))
    }
  ),
  rest.get(
    `${config.getApiBaseUrl()}/v1/connector-components/:cisId`,
    (req, res, ctx) => {
      const cisId = req.params.cisId as string

      const metadata = referenceMetadata.find(
        (component) => component.componentId === cisId
      )

      if (metadata) {
        return res(ctx.json(metadata))
      }

      return res(ctx.status(500))
    }
  )
]
