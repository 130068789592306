import { RequestHandler, rest } from 'msw'

import { mockTransformationValues } from '__test-utils__/mockTransformationValues'

import config from 'config'

import { BaseComponentInstance } from 'job-lib/types/Job'
import { JobVariableCollection } from 'job-lib/types/Variables'

import {
  ValidateComponentGenericFailResponse,
  ValidateComponentResponse
} from '../types'

interface ValidationRequest {
  component: BaseComponentInstance
  agentId: string
  environmentId: string
  projectId: string
  variables: JobVariableCollection
}

function isRequestValid(
  req: Record<string, unknown> | ValidationRequest
): req is ValidationRequest {
  if (!req) {
    return false
  }
  if (typeof req !== 'object') {
    return false
  }
  return Boolean(
    req.component &&
      req.agentId &&
      req.environmentId &&
      req.projectId &&
      req.variables
  )
}

const invalidRequestResponse = {
  type: 'about:blank',
  title: 'Bad Request',
  status: 400,
  violations: [
    {
      field: 'projectId',
      message: 'this is a dummy response for an invalid request'
    }
  ]
}

const useValidateComponentHandler: RequestHandler[] = [
  rest.post(
    `${config.getApiBaseUrl()}/v1/validation/transformation`,
    async (req, res, ctx) => {
      const request = await req.json()

      if (!isRequestValid(request)) {
        return res(ctx.status(400), ctx.json(invalidRequestResponse))
      }

      const response: ValidateComponentResponse = {
        status:
          request.component.id === mockTransformationValues.FIXED_FLOW_0.id
            ? 'VALID'
            : 'INVALID',
        onSuccess: {
          type: 'cachedquery',
          sql: 'SELECT \n  * \nFROM (select ((seq8(1) * 20) + 1000 ) "high_sequence" from table(generator(rowcount => 451)))',
          columns: [
            {
              name: 'high_sequence',
              datatype: {
                type: 'bigint',
                size: 22
              },
              isIdentity: false
            }
          ]
        },
        failureMessage: null,
        failures: null
      }

      return res(ctx.json(response))
    }
  ),
  rest.post(
    `${config.getApiBaseUrl()}/v1/validation/orchestration`,
    async (req, res, ctx) => {
      const request = await req.json()

      if (!isRequestValid(request)) {
        return res(ctx.status(400), ctx.json(invalidRequestResponse))
      }

      const response: ValidateComponentResponse = {
        status: 'VALID',
        onSuccess: null,
        failureMessage: null,
        failures: null
      }

      return res(ctx.json(response))
    }
  )
]

const useValidateComponentFailedResponseHandler: RequestHandler[] = [
  rest.post(
    `${config.getApiBaseUrl()}/v1/validation/transformation`,
    async (req, res, ctx) => {
      const failedResponse: ValidateComponentGenericFailResponse = {
        detail: 'An error has occurred.',
        status: 500,
        title: 'Internal Server Error',
        type: 'about:blank',
        violations: [
          {
            additionalProp1: 'string',
            additionalProp2: 'string',
            additionalProp3: 'string'
          }
        ]
      }

      return res(ctx.status(500), ctx.json(failedResponse))
    }
  )
]

export const useValidateComponentHandlers = [
  ...useValidateComponentHandler,
  ...useValidateComponentFailedResponseHandler
]

export {
  useValidateComponentHandler,
  useValidateComponentFailedResponseHandler
}
