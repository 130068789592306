import { FC, PropsWithChildren, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Alert, Toaster } from '@matillion/component-library'
import axios from 'axios'

import { useCancelTask } from 'api/hooks/useCancelTask'
import { HistoryTaskStatus } from 'api/hooks/useGetRunTasks/types'

import { Loading } from 'components/Loading/Loading'
import { ErrorPanel } from 'components/Panels/ErrorPanel'

import { useSelectedJobs } from 'hooks/useSelectedJobs'

import { useValidationProvider } from 'modules/ComponentValidation/hooks/useValidationProvider'
import { useTaskStatus } from 'modules/FlowStatsExplorer/hooks/useTaskStatus'

import { EmptyTaskHistory } from './components/TaskHistory/EmptyTaskHistory'
import { historyComparer } from './components/TaskHistory/history.utilities'
import { TaskHistory } from './components/TaskHistory/TaskHistory'
import classes from './TaskHistoryPanel.module.scss'

interface ErrorStatuses {
  completedError?: number
  runningError?: number
}

const PartialError: FC<PropsWithChildren> = ({ children }) => (
  <Alert
    data-testid="partial-status-response-error"
    className={classes.TaskHistoryPanel__PartialError}
    message={children}
    type="error"
  />
)

const TaskHistoryPanel = () => {
  const { t } = useTranslation()
  const { tasks: runsHistory, running, completed } = useTaskStatus()
  const { mutate: mutateCancel } = useCancelTask()
  const { makeToast } = Toaster.useToaster()
  const cancelTask = useCallback(
    (flowInstanceId: string) => {
      mutateCancel(flowInstanceId, {
        onError() {
          makeToast({
            type: 'warning',
            title: t('taskHistory.cancelFailedTitle'),
            message: t('taskHistory.cancelFailedMessage')
          })
        }
      })
    },
    [mutateCancel, makeToast, t]
  )
  const { navigateToTask } = useSelectedJobs()

  const { validationsHistory } = useValidationProvider()

  const tasks: HistoryTaskStatus[] = useMemo(
    () => [...runsHistory, ...validationsHistory].sort(historyComparer),
    [runsHistory, validationsHistory]
  )

  const eitherLoading = completed.isLoading || running.isLoading
  const bothFetched = completed.isFetched && running.isFetched

  const errorStatuses: ErrorStatuses = {}

  if (
    axios.isAxiosError(running.error) &&
    running.error.response?.status !== 404
  ) {
    errorStatuses.runningError = running.error.response?.status
  }

  if (
    axios.isAxiosError(completed.error) &&
    completed.error.response?.status !== 404
  ) {
    errorStatuses.completedError = completed.error.response?.status
  }

  if (eitherLoading && !bothFetched) {
    return (
      <Loading
        data-testid="task-history-panel-loading"
        className={classes.TaskHistoryPanel__Loading}
      />
    )
  }

  if (errorStatuses.completedError && errorStatuses.runningError) {
    return (
      <ErrorPanel text={t('taskHistory.networkError')} includeLink={false} />
    )
  }

  return (
    <div className={classes.TaskHistoryPanel} data-testid="footer-right">
      {errorStatuses.completedError && (
        <PartialError>{t('taskHistory.completedTasksError')}</PartialError>
      )}

      {errorStatuses.runningError && (
        <PartialError>{t('taskHistory.runningTasksError')}</PartialError>
      )}

      {tasks.length > 0 ? (
        <TaskHistory
          tasks={tasks}
          navigateToTask={navigateToTask}
          cancelTask={cancelTask}
        />
      ) : (
        <div className={classes.EmptyTaskHistoryContainer}>
          <EmptyTaskHistory />
        </div>
      )}
    </div>
  )
}

export { TaskHistoryPanel }
