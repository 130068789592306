import { Button, Icon, Typography } from '@matillion/component-library'
import classes from '../AuthModalForm.module.scss'
import { useTranslation } from 'react-i18next'
import { type ExternalGitProvider } from 'types/ExternalGitProvider'
import useGetProviderDisplayName from 'modules/BringYourOwnGit/hooks/useGetProviderDisplayName'

export interface AuthModalReadyStateProps {
  provider: ExternalGitProvider
  onAuthorize: () => void
  onClose: () => void
  loading: boolean
}

const AuthModalReadyState = ({
  provider,
  loading,
  onAuthorize,
  onClose
}: AuthModalReadyStateProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'git.auth.modal.ready'
  })

  const { providerName } = useGetProviderDisplayName(provider)

  return (
    <div data-testid="git-auth-modal-ready">
      <Typography className={classes.title} format="tm" id="git-auth-title">
        {t('title', { provider: providerName })}
      </Typography>

      <Typography className={classes.body} format="bcm">
        {t('body.first', { provider: providerName })}
      </Typography>

      <Typography className={classes.body} format="bcm">
        {t('body.second', { provider: providerName })}
      </Typography>

      <div className={classes.buttons}>
        <Button
          id="cancel"
          alt="secondary"
          onClick={onClose}
          text={t('cancel')}
          disabled={loading}
          className={classes.button}
          data-testid="git-auth-cancel"
        />

        <Button
          alt="primary"
          id="auth-submit"
          waiting={loading}
          onClick={onAuthorize}
          className={classes.button}
          data-testid="git-auth-submit"
        >
          <Typography>{t('submit')}</Typography>
          <Icon.Export className={classes.exportIcon} />
        </Button>
      </div>
    </div>
  )
}

export default AuthModalReadyState
