/* eslint-disable react/display-name */
import { memo } from 'react'
import { areEqual, ListChildComponentProps } from 'react-window'

import { Draggable } from '@hello-pangea/dnd'

import { OptionProps } from '../types'
import { ListItem } from './ListItem'

interface RowProps {
  invalidItems: string[]
  items: string[]
  selectedItems: string[]
  draggingItem?: string | null
  toggleSelection: (item: string) => void
  toggleSelectionInGroup: (item: string) => void
  multiSelectTo: (item: string) => void
  filter: string
  options: OptionProps
}

export const Row = memo(
  ({ data, index, style }: ListChildComponentProps<RowProps>) => {
    const { invalidItems } = data
    const item = data.items[index]
    const isSelected = data.selectedItems.includes(item)
    /* istanbul ignore next */
    const isGhosting =
      isSelected && Boolean(data.draggingItem) && data.draggingItem !== item

    return (
      <Draggable draggableId={item} index={index} key={item}>
        {(provided, snapshot) => (
          <ListItem
            invalidItems={invalidItems}
            provided={provided}
            snapshot={snapshot}
            item={data.items[index]}
            style={style}
            filter={data.filter}
            isSelected={isSelected}
            isGhosting={isGhosting}
            toggleSelection={data.toggleSelection}
            toggleSelectionInGroup={data.toggleSelectionInGroup}
            multiSelectTo={data.multiSelectTo}
            selectionCount={data.selectedItems.length}
            options={data.options}
          />
        )}
      </Draggable>
    )
  },
  areEqual
)
