import { FC, useEffect } from 'react'

import { useGetStatsStrands } from 'api/hooks/useGetFlowStats/useGetStatsStrands'

import { DelayedLoader, JobTree } from '../types'
import Loader from './Loader'

interface StrandLoaderProps {
  parent: JobTree
  loader: DelayedLoader
}

const StrandLoader: FC<StrandLoaderProps> = ({ loader, parent }) => {
  const { strandSequenceId, flowInstanceId, injectDynamicItems } = loader
  const { isLoading, isError, data, remove } = useGetStatsStrands(
    flowInstanceId,
    strandSequenceId,
    parent.depth
  )

  useEffect(() => {
    if (!data || !injectDynamicItems) return

    injectDynamicItems?.(parent, data)
  }, [data, injectDynamicItems, parent])

  return <Loader isError={isError} isLoading={isLoading} remove={remove} />
}

export default StrandLoader
