import { useQuery } from '@tanstack/react-query'

import { type WorkingTreeBranchResponse } from './types'
import { queryKeys } from 'api/query-keys'
import useDesignerClient from 'api/clients/useDesignerClient'

export interface GetWorkingTreeBranchConfig {
  refetchOnMount: boolean
  staleTime: number
  cacheTime: number
  enabled: boolean
}

const useGetWorkingTreeBranch = (
  branchName?: string,
  config?: Partial<GetWorkingTreeBranchConfig>
) => {
  const { project, client } = useDesignerClient()

  return useQuery(
    [queryKeys.merge, project, branchName, queryKeys.getWorkingTreeBranch],
    async () => {
      const uri = `projects/${project}/branches/${branchName}/workingtree`
      const { data } = await client.get<WorkingTreeBranchResponse>(uri)
      return data
    },
    { ...config }
  )
}

export default useGetWorkingTreeBranch
