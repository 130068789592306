import { useMemo } from 'react'

import { useAgentHost } from 'api/hooks/useAgentHost/useAgentHost'
import useGetComponentTree, {
  ComponentSummary,
  useCustomComponentSummaries
} from 'api/hooks/useGetComponentTree'

import config from 'config'

import { useWorkingCopy } from 'modules/EtlDesigner/hooks/useWorkingCopy'
import { getComponentFlagsInKebabCase } from 'modules/SideBar/components/ComponentBrowser/utils/getComponentFlagsInKebabCase'

import { useComponentInfo } from '../useComponentInfo/useComponentInfo'
import { useFlags } from '../useFlags'

export interface ExtendedProps extends ComponentSummary {
  isAvailableForAgent: boolean
  displayName: string
  tags: string[]
  icon: string
}
type FlatComponentList = ExtendedProps[]

const useEnabledComponents = () => {
  const flags = useFlags()

  return useMemo(() => {
    const componentFlags = getComponentFlagsInKebabCase(flags)

    return [...config.enabledComponents, ...Object.keys(componentFlags)].filter(
      (component) => {
        if (component in componentFlags) {
          return componentFlags[component]
        }
        return component
      }
    )
  }, [flags])
}

const filterList = (
  searchTerm: string,
  components: FlatComponentList
): FlatComponentList => {
  if (searchTerm === '') {
    return components.sort((a, b) => a.displayName.localeCompare(b.displayName))
  }

  const startWordRegex = new RegExp('\\b' + searchTerm, 'i')

  const startWordMatches = components
    .filter((item) => startWordRegex.test(item.displayName))
    .sort((a, b) => a.displayName.localeCompare(b.displayName))

  const tagMatches = components.filter(
    (item) =>
      item.tags?.some((tag) => startWordRegex.test(tag)) &&
      !startWordMatches.includes(item)
  )

  tagMatches.sort((a, b) => {
    if (a.tags.length === 1 && b.tags.length === 1) {
      return a.displayName.localeCompare(b.displayName)
    }
    return a.tags.length - b.tags.length
  })

  return [...startWordMatches, ...tagMatches]
}

const useAvailableComponents = () => {
  const enabledComponents = useEnabledComponents()

  const { summary } = useWorkingCopy()
  const { getDisplayName, getTags, getIcon } = useComponentInfo()
  const { componentTree, isLoading, isError } = useGetComponentTree(
    summary?.type
  )

  const {
    agentHost,
    isInitialLoading,
    isError: isAgentHostError
  } = useAgentHost()

  const { data: customComponentsData } = useCustomComponentSummaries(
    summary?.type
  )

  const defaultComponents: ExtendedProps[] = useMemo(() => {
    if (!componentTree || !agentHost) {
      return []
    }

    return Object.values(componentTree)
      .filter(({ componentId, agentHosts }) =>
        enabledComponents.includes(componentId)
      )
      .map<ExtendedProps>((current) => {
        const displayName = getDisplayName(current.componentId)
        const icon = getIcon(current.componentId)
        const tags = getTags(current.componentId)

        return {
          ...current,
          displayName,
          icon,
          tags,
          isAvailableForAgent: current.agentHosts.includes(agentHost)
        }
      })
  }, [
    componentTree,
    agentHost,
    enabledComponents,
    getDisplayName,
    getIcon,
    getTags
  ])

  const customComponents: ExtendedProps[] = useMemo(() => {
    if (!customComponentsData || !agentHost) {
      return []
    }

    return customComponentsData.map<ExtendedProps>((current) => {
      const displayName = getDisplayName(current.componentId)
      const tags = getTags(current.componentId)
      const icon = getIcon(current.componentId)

      return {
        ...current,
        displayName,
        icon,
        tags,
        isAvailableForAgent: current.agentHosts.includes(agentHost)
      }
    })
  }, [customComponentsData, agentHost, getDisplayName, getTags, getIcon])

  const components = useMemo(
    () => [...defaultComponents, ...customComponents],
    [customComponents, defaultComponents]
  )

  if (!summary?.type) {
    return {
      isLoading: false,
      isError: false,
      components: []
    }
  }

  if (
    !componentTree ||
    isError ||
    isAgentHostError ||
    isLoading ||
    isInitialLoading ||
    !agentHost
  ) {
    return {
      isLoading: isLoading || isInitialLoading,
      isError: isError || isAgentHostError,
      components: []
    }
  }

  return {
    isLoading: false,
    isError: false,
    components
  }
}

export { useAvailableComponents, filterList }
