import { ReactElement } from 'react'

const REGEXP_CHARACTERS_PATTERN = /[\\^$.*+?()[\]{}|]/g

export const highlightText = (
  text: string,
  textToHighlight: string
): string | ReactElement => {
  if (
    textToHighlight &&
    text.toLowerCase().includes(textToHighlight.toLowerCase())
  ) {
    const substringToHighlight =
      text.match(
        new RegExp(
          textToHighlight.replace(REGEXP_CHARACTERS_PATTERN, '\\$&'),
          'gi'
        )
      )?.[0] ?? ''

    const [before, ...after] = text.split(substringToHighlight)

    return (
      <span>
        {before}
        <mark>{substringToHighlight}</mark>
        {after.join(substringToHighlight)}
      </span>
    )
  }

  return text
}
