import { FC, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Icon,
  MicroCta,
  Popover,
  Typography
} from '@matillion/component-library'

import { ElementForm } from './ElementForm/ElementForm'
import classes from './NestedDataPickerEditor.module.scss'
import { ChildDataStructure, DataStructure, DataStructureType } from './types'
import { addElement, modifyElement, omitElement } from './utils'

enum CONFIGURE_MODES {
  MAIN,
  ADD,
  EDIT
}

interface ActionsMenuProps {
  path: string[]
  element: DataStructure | ChildDataStructure
  parentElement?: DataStructure | ChildDataStructure
  root: DataStructure
  onChange: (newRoot: DataStructure) => void
}

function isChildDataStructure(
  structure: DataStructure | ChildDataStructure
): structure is ChildDataStructure {
  return (structure as ChildDataStructure).size !== undefined
}

export const ActionsMenu: FC<ActionsMenuProps> = ({
  path,
  element,
  parentElement,
  root,
  onChange
}) => {
  const parentRef = useRef<HTMLDivElement>(null)
  const { t } = useTranslation()
  const siblingKeys = useMemo(
    () =>
      parentElement?.children
        .map((sibling) => sibling.key)
        .filter((key) => element.key !== key) ?? [],
    [element, parentElement]
  )
  const childKeys = useMemo(
    () => element.children.map((child) => child.key),
    [element]
  )
  const [open, setOpen] = useState<boolean>(false)
  const [mode, setMode] = useState<CONFIGURE_MODES>(CONFIGURE_MODES.MAIN)

  const isRoot = !isChildDataStructure(element)
  const parentIsArray =
    parentElement && isChildDataStructure(parentElement) && parentElement.array

  const enableAddButton = [
    isRoot,
    !isRoot && element.type === DataStructureType.VARIANT && !element.array,
    !isRoot &&
      element.type === DataStructureType.VARIANT &&
      element.array &&
      element.children.length === 0
  ].some(Boolean)

  return (
    <>
      <div ref={parentRef} className={classes.ActionsParent} />
      <Popover
        className={classes.ActionsPopover}
        isOpen={open}
        anchor={
          <span>
            <MicroCta
              aria-label={t(
                'parameterEditor.NESTED_DATA_PICKER_EDITOR.actions'
              )}
              alt="transparent"
              size="sm"
              onClick={() => setOpen((prevState) => !prevState)}
            >
              <Icon.Ellipsis />
            </MicroCta>
          </span>
        }
        position="right"
        align="center"
        onClickOutside={() => {
          setOpen(false)
          setMode(CONFIGURE_MODES.MAIN)
        }}
        parentElement={parentRef.current as HTMLDivElement}
      >
        <>
          {mode === CONFIGURE_MODES.MAIN && (
            <>
              {enableAddButton && (
                <Button
                  className={classes.ActionsButton}
                  alt="text"
                  onClick={() => setMode(CONFIGURE_MODES.ADD)}
                >
                  <Icon.Add
                    aria-hidden="true"
                    className={classes.ActionsIcon}
                  />
                  <Typography format="bcs">
                    {t('parameterEditor.NESTED_DATA_PICKER_EDITOR.addElement')}
                  </Typography>
                </Button>
              )}
              {!isRoot && (
                <>
                  <Button
                    className={classes.ActionsButton}
                    alt="text"
                    onClick={() => setMode(CONFIGURE_MODES.EDIT)}
                  >
                    <Icon.Pencil
                      aria-hidden="true"
                      className={classes.ActionsIcon}
                    />
                    <Typography format="bcs">
                      {t(
                        'parameterEditor.NESTED_DATA_PICKER_EDITOR.editElement'
                      )}
                    </Typography>
                  </Button>
                  <Button
                    className={classes.ActionsButtonDelete}
                    alt="text"
                    onClick={() =>
                      onChange(omitElement(root, path, [root.key]))
                    }
                  >
                    <Icon.Trash
                      aria-hidden="true"
                      className={classes.ActionsIcon}
                    />
                    <Typography format="bcs">
                      {t(
                        'parameterEditor.NESTED_DATA_PICKER_EDITOR.deleteElement'
                      )}
                    </Typography>
                  </Button>
                </>
              )}
            </>
          )}
          {mode === CONFIGURE_MODES.ADD && (
            <ElementForm
              cta={t('parameterEditor.NESTED_DATA_PICKER_EDITOR.add')}
              existingKeys={childKeys}
              initialValues={{
                key: !isRoot && element.array ? `${element.key}-element` : '',
                type: '',
                alias: !isRoot && element.array ? `${element.key}-element` : '',
                size: 0,
                decimalPlaces: 0,
                array: !isRoot && element.array,
                selected: !isRoot && element.selected
              }}
              element={element}
              disabledFields={{
                key: !isRoot && element.array
              }}
              onSubmit={(changes) => {
                onChange(
                  addElement(
                    root,
                    path,
                    {
                      ...changes,
                      selected: true,
                      children: [],
                      column: root.key
                    },
                    [root.key]
                  )
                )

                setOpen(false)
                setMode(CONFIGURE_MODES.MAIN)
              }}
              onCancel={() => setMode(CONFIGURE_MODES.MAIN)}
            />
          )}
          {mode === CONFIGURE_MODES.EDIT && !isRoot && (
            <ElementForm
              cta={t('parameterEditor.NESTED_DATA_PICKER_EDITOR.apply')}
              existingKeys={siblingKeys}
              element={element}
              initialValues={{
                key: element.key,
                type: element.array ? 'ARRAY' : element.type,
                alias: element.alias,
                size: element.size,
                decimalPlaces: element.decimalPlaces,
                array: element.array,
                selected: element.selected
              }}
              disabledFields={{
                key: Boolean(parentIsArray),
                type: Boolean(parentIsArray)
              }}
              onSubmit={(changes) => {
                onChange(
                  modifyElement(root, path, { ...changes, column: root.key }, [
                    root.key
                  ])
                )
                setOpen(false)
                setMode(CONFIGURE_MODES.MAIN)
              }}
              onCancel={() => setMode(CONFIGURE_MODES.MAIN)}
            />
          )}
        </>
      </Popover>
    </>
  )
}
