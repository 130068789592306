import { FC } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList as List } from 'react-window'

import { Droppable } from '@hello-pangea/dnd'
import { SearchBar, Typography } from '@matillion/component-library'
import classnames from 'classnames'

import { OptionProps } from '../types'
import classes from './Column.module.scss'
import { ListItem } from './ListItem'
import { Row } from './Row'

export enum ColumnLabel {
  leftColumn = 'leftColumn',
  rightColumn = 'rightColumn'
}

interface ColumnProps {
  invalidItems: string[]
  columnData: Array<string | null>
  columnLabel: ColumnLabel
  description: string
  filter: string
  setFilter: (value: string) => void
  selectedItems: string[]
  items: string[]
  toggleSelection: (item: string) => void
  toggleSelectionInGroup: (item: string) => void
  multiSelectTo: (item: string) => void
  draggingItem: string | null
  options: OptionProps
}

interface ColumnHeaderProps {
  description: string
  numberOfItems: number
  columnLabel: string
}

const ColumnHeader: FC<ColumnHeaderProps> = ({
  description,
  numberOfItems,
  columnLabel
}) => {
  return (
    <div
      className={classes.Column__ListHeader}
      data-testid={`${columnLabel}-items-${numberOfItems}`}
    >
      <Typography
        format="bcs"
        className={classes.Column__ListHeaderDescription}
      >
        {description}
      </Typography>
      <Typography format="bcs">
        ({new Intl.NumberFormat().format(numberOfItems)})
      </Typography>
    </div>
  )
}

export const Column: FC<ColumnProps> = ({
  invalidItems,
  columnData,
  columnLabel,
  description,
  filter,
  setFilter,
  selectedItems,
  items,
  toggleSelection,
  toggleSelectionInGroup,
  multiSelectTo,
  draggingItem,
  options
}) => {
  return (
    <div className={classes.Column} data-testid={columnLabel}>
      <ColumnHeader
        description={description}
        numberOfItems={columnData.filter(Boolean).length}
        columnLabel={columnLabel}
      />
      <SearchBar
        data-testid={`filter-${columnLabel}`}
        placeholder={options.labels.searchLabel}
        aria-label={options.labels.searchLabel}
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        className={classes.Column__SearchBar}
      />
      <div
        data-testid={`column-${columnLabel}`}
        className={classnames(classes.Column__TableList, {
          [classes['Column__TableList--empty']]: !items.length
        })}
      >
        <AutoSizer>
          {({ width }) => (
            <Droppable
              droppableId={columnLabel}
              mode="virtual"
              renderClone={(provided, snapshot, rubric) => {
                /* istanbul ignore next */
                return (
                  <ListItem
                    invalidItems={invalidItems}
                    provided={provided}
                    snapshot={snapshot}
                    item={rubric.draggableId}
                    selectionCount={selectedItems.length}
                    options={options}
                  />
                )
              }}
            >
              {(provided) => (
                <List
                  height={options.listHeight}
                  itemCount={items.length}
                  itemSize={options.itemSize}
                  width={width - 1}
                  outerRef={provided.innerRef}
                  itemData={{
                    options,
                    items,
                    filter,
                    toggleSelection,
                    toggleSelectionInGroup,
                    multiSelectTo,
                    selectedItems,
                    draggingItem,
                    invalidItems
                  }}
                >
                  {Row}
                </List>
              )}
            </Droppable>
          )}
        </AutoSizer>
      </div>
    </div>
  )
}
