import { useCallback } from 'react'

import { useMutation } from '@tanstack/react-query'

import { type CommitResponse } from 'api/hooks/useCommit/types'
import useDesignerClient from 'api/clients/useDesignerClient'

const useCommit = () => {
  const { branch, project, client } = useDesignerClient()

  const commit = useCallback(
    async (commitMessage: string) => {
      const url = `branches/${branch}/commits?projectId=${project}`
      const { data } = await client.post<CommitResponse>(url, { commitMessage })
      return data
    },
    [client, project, branch]
  )

  return useMutation([project, branch], commit)
}

export default useCommit
