import { TFunction } from 'i18next'

import { AgentHostEnum } from 'api/hooks/useAgentDetails/useAgentDetails'
import { ComponentSummaryCollection } from 'api/hooks/useGetComponentTree'
import {
  ComponentSummary,
  ComponentTree
} from 'api/hooks/useGetComponentTree/types'

export const filterData = (
  data: ComponentSummaryCollection,
  searchTerm: string,
  t: (key: string) => string
) =>
  Object.values(data)
    .filter((componentInfo) => {
      const displayName = t(
        `componentSummaries:components.${componentInfo.componentId}.displayName`
      )
      return displayName.toLowerCase().includes(searchTerm?.toLowerCase())
    })
    .reduce((comps, comp) => {
      return {
        ...comps,
        [comp.componentId]: comp
      }
    }, {})

// I don't like the way this is written
// Originally copied from src/hooks/api/useGetComponentTree/nestComponentsInTree.ts
const getOrCreateRootFolder = (name: string, tree: ComponentTree) => {
  const item = tree[name] || {
    type: 'folder',
    name,
    children: {}
  }

  if (!tree[name]) {
    tree[name] = item
  }

  return item
}

const handleEmptyTreePath = (
  displayName: string,
  componentInfo: ComponentSummary,
  tree: ComponentTree,
  isAvailableForAgent: boolean
) => {
  if (tree[displayName]) {
    return
  }
  tree[displayName] = {
    type: 'component',
    component: componentInfo,
    name: displayName,
    isAvailableForAgent
  }
}
export const nestComponentsInTree = (
  data: ComponentSummaryCollection | undefined,
  t: TFunction,
  agentHost: AgentHostEnum | null,
  enabledComponents: string[] = []
) => {
  if (!data || !agentHost) {
    return
  }

  const tree: ComponentTree = {}

  Object.entries(data)
    .filter(([, { componentId }]) => enabledComponents.includes(componentId))
    .forEach(([, componentInfo]) => {
      const treePath: string[] | null = t(
        `componentSummaries:components.${componentInfo.componentId}.treePath`,
        {
          returnObjects: true,
          defaultValue: null
        }
      )
      const displayName = t(
        `componentSummaries:components.${componentInfo.componentId}.displayName`
      )

      if (!treePath) {
        return
      }

      const firstSegment = treePath.shift()
      const isAvailableForAgent = componentInfo.agentHosts.includes(agentHost)

      // empty array means we put this component in the root of the tree
      if (firstSegment === undefined) {
        handleEmptyTreePath(
          displayName,
          componentInfo,
          tree,
          isAvailableForAgent
        )
        return
      }

      let current = getOrCreateRootFolder(firstSegment, tree)

      /* istanbul ignore next */
      if (current.type === 'component') {
        return
      }

      treePath.forEach((segment) => {
        /* istanbul ignore next */
        if (current.type === 'component') {
          return
        }

        if (!current.children[segment]) {
          current.children[segment] = {
            type: 'folder',
            name: segment,
            children: {}
          }
        }

        current = current.children[segment]
      })

      current.children[displayName] = {
        name: displayName,
        type: 'component',
        component: componentInfo,
        isAvailableForAgent
      }
    })

  return tree
}

export default nestComponentsInTree
