import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'

import {
  isOrchestrationJob,
  isTransformationJob
} from 'job-lib/job-functions/job-functions'
import { OrchestrationJob, TransformationJob } from 'job-lib/types/Job'
import { JobType } from 'job-lib/types/JobType'

import { JobState, JobStateBase } from '../../job.types'

const getJobShape = (
  job: OrchestrationJob | TransformationJob
): JobType | null => {
  if (isOrchestrationJob(job)) {
    return JobType.Orchestration
  }

  if (isTransformationJob(job)) {
    return JobType.Transformation
  }

  return null
}

export const setJob: CaseReducer<JobState, PayloadAction<JobStateBase>> = (
  state,
  action
) => {
  const { job, jobType } = action.payload

  if (!job || !jobType) {
    return {
      job: null,
      jobType: null
    }
  }

  const jobShapeType = getJobShape(job)

  if (
    jobShapeType === JobType.Orchestration &&
    jobType === JobType.Orchestration
  ) {
    return {
      job: job as OrchestrationJob,
      jobType
    }
  }

  if (
    jobShapeType === JobType.Transformation &&
    jobType === JobType.Transformation
  ) {
    return {
      job: job as TransformationJob,
      jobType
    }
  }

  throw new TypeError(
    `Miss match of jobType and the shape of the job passed, got jobType: "${
      action.payload.jobType as string
    }" but a ${jobShapeType ?? 'unknown'} shaped job`
  )
}
