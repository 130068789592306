import { TransformationJob } from 'job-lib/types/Job'

export const removeTransformationLinks = ({
  job,
  componentInstanceIds
}: {
  job: TransformationJob
  componentInstanceIds: number[]
}) => {
  componentInstanceIds.forEach((componentInstanceId) => {
    const { inputConnectorIDs, outputConnectorIDs } =
      job.components[componentInstanceId]

    const removeLink = (connectorId: number) => {
      const { id, sourceID, targetID } = job.connectors[connectorId]

      job.components[sourceID].outputConnectorIDs = job.components[
        sourceID
      ].outputConnectorIDs.filter((outputId) => outputId !== id)

      job.components[targetID].inputConnectorIDs = job.components[
        targetID
      ].inputConnectorIDs.filter((inputId) => inputId !== id)
    }

    inputConnectorIDs.forEach(removeLink)
    outputConnectorIDs.forEach(removeLink)
  })

  componentInstanceIds.forEach((componentInstanceId) => {
    job.connectors = Object.keys(job.connectors)
      .filter((connectorId) => {
        return (
          job.connectors[connectorId as keyof object].sourceID !==
            componentInstanceId &&
          job.connectors[connectorId as keyof object].targetID !==
            componentInstanceId
        )
      })
      .reduce(
        (acc, key) => ({
          ...acc,
          [key]: { ...job.connectors[key as keyof object] }
        }),
        {}
      )
  })
}
