import { keyBy } from 'lodash'

import {
  ComponentMetadata,
  ComponentParameter,
  VisibleWhen,
  VisibleWhenOperator
} from 'api/hooks/useGetComponentMetadata/types'

import { isDPLParameterCollection } from 'job-lib/store/jobSlice/utils/isDPLParameterCollection'
import { ParameterCollection, ParameterSlot } from 'job-lib/types/Parameters'

import {
  getDPLParameters,
  getParameterDPLValue
} from 'modules/ComponentParameters/utils/getParameterValue'
import { ParameterValue } from 'modules/WorkingCopyProvider/Pipeline'

/**
 * Returns a boolean indicating whether the provided parameter slot is visible
 * If no metadata is provided it returns the parameter as visible
 * If no visibleWhen rules are provided it returns the parameter as visible
 * Otherwise it returns the parameter as visible depending on its visibleWhen rules
 * @param slot
 * @param parameters A list of parameters from the working copy
 * @param metadata Component metadata containing all parameters
 */
export const isParameterVisible = (
  slot: ParameterSlot | string,
  parameters: ParameterCollection,
  metadata?: ComponentMetadata,
  parameterPath: string[] = []
): boolean => {
  if (!metadata) {
    return true
  }

  const parameterMetadata = getParameterMetadata(parameterPath, metadata)

  const parameterKey = typeof slot === 'string' ? 'dplID' : 'metlSlot'
  const metadataKeyedBySlot = keyBy(parameterMetadata, parameterKey)

  const visibleWhen = metadataKeyedBySlot[slot]?.visibleWhen

  if (!visibleWhen) {
    return true
  }

  return parameterIsVisible(visibleWhen, metadata, parameterPath, parameters)
}

export function removeInvisibleParameters(
  metadata: ComponentMetadata,
  parameters: ParameterCollection
) {
  const dplParams = getDPLParameters(null, parameters)
  metadata.parameters.forEach((parameter) => {
    removeInvisibleParameter(parameter, metadata, [], parameters, dplParams)
  })

  return dplParams
}
function removeInvisibleParameter(
  parameter: ComponentParameter,
  metadata: ComponentMetadata,
  parameterPath: string[],
  parameters: ParameterCollection,
  dplParams: unknown
) {
  const { visibleWhen, dplID, childProperties } = parameter
  if (visibleWhen) {
    const visible = parameterIsVisible(
      visibleWhen,
      metadata,
      parameterPath,
      parameters
    )
    if (!visible) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let currentParameter: any = dplParams

      for (const element of parameterPath) {
        currentParameter = currentParameter[element]
      }

      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete currentParameter[dplID]

      return
    }
  }
  const newParameterPath = [...parameterPath, dplID]
  const parentDplValue = getParameterDPLValue(
    newParameterPath,
    dplParams as ParameterValue
  )
  if (parentDplValue) {
    childProperties?.forEach((childParameter) => {
      removeInvisibleParameter(
        childParameter,
        metadata,
        newParameterPath,
        parameters,
        dplParams
      )
    })
  }
}

function parameterIsVisible(
  visibleWhen: VisibleWhen[],
  metadata: ComponentMetadata,
  parameterPath: string[],
  parameters: ParameterCollection,
  dplParams?: unknown
): boolean {
  return visibleWhen.every(({ param, value, operator }) => {
    if (operator === VisibleWhenOperator.NEVER) {
      return false
    }

    if (operator === VisibleWhenOperator.DYNAMIC_VISIBILITY_LOOKUP) {
      // This will default to true until we properly implement this
      return true
    }

    if (!param) {
      return true
    }

    const metadataKeyedById: Record<string, ComponentParameter> =
      getKeyedMetadata(metadata)

    const depMetadata = metadataKeyedById[param]

    const getPathToParam = (): string[] =>
      param.includes('.') ? param.split('.') : [...parameterPath, param]

    const paramValue = isDPLParameterCollection(parameters)
      ? (getParameterDPLValue(
          getPathToParam(),
          dplParams ?? getDPLParameters(null, parameters)
        ) as string)
      : parameters?.[depMetadata.metlSlot]?.elements[1]?.values[1]?.value

    switch (operator) {
      case VisibleWhenOperator.EQUALS:
        return paramValue === value
      case VisibleWhenOperator.NOT_EQUALS:
        return paramValue !== value
      case VisibleWhenOperator.IN:
        return value.includes(paramValue)
      case VisibleWhenOperator.NOT_IN:
        return !value.includes(paramValue)
      case VisibleWhenOperator.HAS_VALUE:
        return Boolean(paramValue)
      case VisibleWhenOperator.MATCHES_REGEX_PATTERN:
        return new RegExp(value).test(paramValue)

      default:
        return true
    }
  })
}

function getParameterMetadata(
  parameterPath: string[],
  metadata: ComponentMetadata
) {
  return parameterPath.reduce(
    (
      currentParameter: ComponentParameter[],
      parameterId
    ): ComponentParameter[] => {
      const parameterList = currentParameter.find(
        (param) => param.dplID === parameterId
      )

      if (parameterList?.childProperties) {
        return parameterList.childProperties
      }
      return currentParameter
    },
    metadata?.parameters
  )
}

function getKeyedMetadata(metadata: ComponentMetadata) {
  const metadataKeyedById: Record<string, ComponentParameter> = {}

  metadata.parameters.forEach((componentParam) => {
    const keyId = componentParam.id
    const dplId = componentParam.dplID

    if (componentParam.childProperties) {
      processChildProperties(
        componentParam.childProperties,
        metadataKeyedById,
        keyId,
        dplId
      )
    }

    metadataKeyedById[keyId] = componentParam
    metadataKeyedById[dplId] = componentParam
  })
  return metadataKeyedById
}

function processChildProperties(
  childProperties: ComponentParameter[],
  metadataKeyedById: Record<string, ComponentParameter>,
  parentKeyId: string,
  parentDplId: string
) {
  childProperties.forEach((childParam) => {
    const childKeyId = `${parentKeyId}.${childParam.id}`
    const childDplId = `${parentDplId}.${childParam.dplID}`

    if (childParam.childProperties) {
      processChildProperties(
        childParam.childProperties,
        metadataKeyedById,
        childKeyId,
        childDplId
      )
    }

    metadataKeyedById[childKeyId] = childParam
    metadataKeyedById[childDplId] = childParam
  })
}
