import { getComponentLabel } from 'job-lib/job-functions/getComponentLabel'
import { getAllJobConnections } from 'job-lib/job-functions/job-functions'
import {
  ComponentInstanceId,
  OrchestrationJob,
  TransformationJob
} from 'job-lib/types/Job'

import { ComponentDependencyDataBag, ValidationQueryCache } from '../types'

export const getComponentDependencyData = (
  job: OrchestrationJob | TransformationJob,
  componentInstanceId: ComponentInstanceId,
  validationQueryCache: ValidationQueryCache
): ComponentDependencyDataBag => {
  // all connections that target this provided componentInstanceId
  const connections = getAllJobConnections(job).filter(
    ({ targetID }) => componentInstanceId === targetID
  )

  const dependencies = connections
    .map(({ sourceID }) => getComponentLabel(job.components[sourceID]))
    .filter(Boolean)

  const cache: ComponentDependencyDataBag['cache'] = {}

  dependencies.forEach((componentName) => {
    if (!validationQueryCache[componentName]) {
      return
    }

    cache[componentName] = validationQueryCache[componentName].onSuccess
  })

  return {
    cache
  }
}
