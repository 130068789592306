import {
  ComponentMetadata,
  ParameterDataType
} from 'api/hooks/useGetComponentMetadata/types'

import { OrchestrationComponentInstance } from 'job-lib/types/Job'
import { dplParamsKey } from 'job-lib/types/Parameters'

import { removeInvisibleParameters } from 'utils/isParameterVisible'

import { createDPLParameters } from './createDPLParameters'
import { createEmptyOrchestrationComponent } from './createOrchestrationComponent'
import { CreateComponentProps } from './types'

const createComponentInstance = (
  name: string,
  metadata: ComponentMetadata,
  x: number,
  y: number,
  parameterDPL: unknown
): Omit<OrchestrationComponentInstance, 'id'> => {
  const emptyOrchestrationComponent = createEmptyOrchestrationComponent(name)

  return {
    ...emptyOrchestrationComponent,
    implementationID: parseInt(metadata.emeraldId, 10),
    x,
    y,
    parameters: {
      ...emptyOrchestrationComponent.parameters,
      2: {
        slot: 2,
        name: dplParamsKey,
        elements: {
          1: {
            slot: 1,
            values: {
              1: {
                slot: 1,
                type: 'DPL_PARAMS',
                dataType: ParameterDataType.TEXT,
                value: JSON.stringify(parameterDPL)
              }
            }
          }
        },
        visible: true
      }
    }
  }
}

export const createModularOrchestrationComponent = ({
  t,
  initialValues,
  metadata,
  name,
  x,
  y
}: CreateComponentProps): Omit<OrchestrationComponentInstance, 'id'> => {
  const parameterDPL = createDPLParameters({
    componentName: name,
    componentParameters: metadata?.parameters
  })

  const componentInstance = createComponentInstance(
    name,
    metadata,
    x,
    y,
    parameterDPL
  )

  const newParameterDpl = removeInvisibleParameters(
    metadata,
    componentInstance.parameters
  )

  componentInstance.parameters[2].elements[1].values[1].value =
    JSON.stringify(newParameterDpl)

  return componentInstance
}
