import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Toaster } from '@matillion/component-library'

import useMerge from 'api/hooks/useMerge/useMerge'

import { type MergeActionArgs, type MergeActionResult } from './types'
import useProblemDetails from '../useProblemDetails/useProblemDetails'
import { type ProblemDetailRegistry } from '../useProblemDetails/types'

const commonFailureTitleKey = 'git.merge.problem.default.title'

const problemDetailsRegistry: ProblemDetailRegistry = [
  {
    type: 'working-tree/branch_not_found',
    translation: {
      titleKey: commonFailureTitleKey,
      messageKey: 'git.merge.problem.branch-not-found'
    }
  }
]

const isNotEmpty = (value?: string): value is string =>
  value !== undefined && value.trim() !== ''

const useMergeAction = ({
  mergeMessage,
  targetBranch,
  targetCommitId,
  sourceCommitId,
  conflictSelections,
  onSuccess,
  onFailure
}: MergeActionArgs): MergeActionResult => {
  const { makeToast } = Toaster.useToaster()
  const { mutateAsync, isLoading } = useMerge()
  const { t } = useTranslation('translation', { keyPrefix: 'git.merge.toast' })
  const { resolveProblemDetails } = useProblemDetails(problemDetailsRegistry, [
    {
      name: 'targetBranch',
      value: targetBranch ?? ''
    }
  ])

  const isValid =
    isNotEmpty(mergeMessage) &&
    isNotEmpty(targetBranch) &&
    isNotEmpty(targetCommitId) &&
    isNotEmpty(sourceCommitId)

  const merge = useCallback(async () => {
    if (isLoading) return
    if (!isValid) return

    try {
      const { commitId } = await mutateAsync({
        mergeMessage,
        targetBranch,
        sourceCommitId,
        targetCommitId,
        conflictSelections
      })

      makeToast({
        type: 'success',
        title: t('success.title', { branch: targetBranch }),
        message: t('success.message', { branch: targetBranch })
      })

      onSuccess(commitId)
    } catch (error) {
      const { title, message } = resolveProblemDetails(error, {
        titleKey: commonFailureTitleKey,
        messageKey: 'git.merge.problem.default.message'
      })

      makeToast({ type: 'error', title, message })

      onFailure()
    }
  }, [
    mutateAsync,
    isLoading,
    onSuccess,
    onFailure,
    mergeMessage,
    targetBranch,
    sourceCommitId,
    targetCommitId,
    conflictSelections,
    isValid,
    makeToast,
    t
  ])

  return { isLoading, merge, isValid }
}

export default useMergeAction
