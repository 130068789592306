import React from 'react'
import { useTranslation } from 'react-i18next'

import { useProjectPermission } from 'api/external/usePermission/useProjectPermission'

import { PopOverItem } from 'components/PopOverMenu'

import { JobType } from 'job-lib/types/JobType'

import { PipelineBrowserCommand } from '../../context/types'
import { usePipelineBrowserCommandProvider } from '../../effects/usePipelineBrowserCommand'
import { PipelineTreeReducerType } from '../../reducers/pipelineTreeReducer/types'

interface Props {
  folderPath?: string
}

const CreatePipelineContextMenuEntries = ({ folderPath }: Props) => {
  const { onCommand, onPipelineTreeCommand } =
    usePipelineBrowserCommandProvider()
  const { t } = useTranslation('translation', { keyPrefix: 'jobContextMenu' })
  const { hasPermission: canCreate } = useProjectPermission('create_pipelines')

  const handleCreatePipeline = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    jobType: JobType
  ) => {
    e.stopPropagation()

    onCommand({
      type: PipelineBrowserCommand.CREATE_PIPELINE,
      payload: jobType
    })

    onPipelineTreeCommand({
      type: PipelineTreeReducerType.SET_FOLDER_PATH,
      path: folderPath
    })
  }

  return (
    <>
      {[JobType.Orchestration, JobType.Transformation].map((jobType) => (
        <PopOverItem
          key={jobType}
          disabled={!canCreate}
          data-testid={`job-action-create-${jobType}`}
          onClick={(e) => handleCreatePipeline(e, jobType)}
        >
          {t('addNewJob', { context: jobType })}
        </PopOverItem>
      ))}
    </>
  )
}

export default CreatePipelineContextMenuEntries
