import { FunctionComponent } from 'react'

import { Typography } from '@matillion/component-library'
import { t } from 'i18next'

import { PopOverItem, PopOverSection } from 'components/PopOverMenu'

import classes from './InteractiveTabs.module.scss'
import { TabContextMenuProps } from './types'

export const TabContextMenu: FunctionComponent<TabContextMenuProps> = ({
  title,
  onTabClose,
  onCloseAll
}) => {
  if (!onTabClose || !onCloseAll) {
    return null
  }

  return (
    <PopOverSection>
      <PopOverItem
        data-testid="btn-context-menu-close-tab"
        onClick={onTabClose}
      >
        <Typography
          as="span"
          format="bcs"
          className={classes.ContextMenuSubText}
          data-testid={t('tabs.closeTabName', { tabName: title })}
        >
          {t('tabs.closeTab')}
        </Typography>
      </PopOverItem>
      <PopOverItem
        data-testid="btn-context-menu-close-all-tabs"
        onClick={onCloseAll}
      >
        <Typography
          as="span"
          format="bcs"
          className={classes.ContextMenuSubText}
        >
          {t('tabs.closeAllTabs')}
        </Typography>
      </PopOverItem>
    </PopOverSection>
  )
}
