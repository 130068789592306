import { PROJECT_ID } from '__test-utils__/ids'

import { GetProjectResponse, ProjectType } from '../types'

export const useGetProjectMockResponse: GetProjectResponse = {
  id: PROJECT_ID,
  name: 'mock-project-name',
  description: 'Mock project description',
  warehouse: ProjectType.SNOWFLAKE
}
