import React from 'react'
import { useTranslation } from 'react-i18next'

import { PopOverItem, PopOverSection } from 'components/PopOverMenu'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { usePipelineBrowserCommandProvider } from '../../effects/usePipelineBrowserCommand'
import { PipelineTreeReducerType } from '../../reducers/pipelineTreeReducer/types'
import CreatePipelineContextMenuEntries from '../CreatePipelineContextMenuEntries/CreatePipelineContextMenuEntries'

export interface FolderItemContextMenuProps {
  path: string
}

const FolderItemContextMenu = ({ path }: FolderItemContextMenuProps) => {
  const { t } = useTranslation()
  const { branchId, projectId } = useProjectInfo()
  const { onPipelineTreeCommand } = usePipelineBrowserCommandProvider()

  const handleCreateFolder = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation()

    onPipelineTreeCommand({
      type: PipelineTreeReducerType.CREATE_FOLDER,
      path: path
    })
  }

  const handleDeleteFolder = () => {
    onPipelineTreeCommand({
      type: PipelineTreeReducerType.DELETE_FOLDER,
      branch: branchId,
      project: projectId,
      path
    })
  }

  return (
    <PopOverSection>
      <CreatePipelineContextMenuEntries folderPath={path} />

      <PopOverItem
        onClick={handleCreateFolder}
        data-testid="folder-context-menu-create-folder"
      >
        {t('create-folder.context-menu.option')}
      </PopOverItem>

      <PopOverItem
        onClick={handleDeleteFolder}
        data-testid="folder-context-menu-delete-folder"
      >
        {t('delete-folder.context-menu.option')}
      </PopOverItem>
    </PopOverSection>
  )
}

export default FolderItemContextMenu
