import { ComponentMetadataResponse } from '../../types'

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const mockPythonScriptMetadata = {
  componentId: 'python-script',
  metadata: {
    schemaVersion: 1,
    emeraldId: '-1773186829',
    componentType: 'ORCHESTRATION',
    inputPorts: [
      {
        portId: 'INPUT',
        cardinality: 'ONE'
      }
    ],
    outputPorts: [
      {
        portId: 'SUCCESS',
        cardinality: 'MANY'
      },
      {
        portId: 'UNCONDITIONAL',
        cardinality: 'MANY'
      },
      {
        portId: 'FAILURE',
        cardinality: 'MANY'
      }
    ],
    deprecated: false,
    exports: [
      'COMPLETED_AT',
      'COMPONENT_NAME',
      'DURATION',
      'MESSAGE',
      'ROW_COUNT',
      'STARTED_AT',
      'STATUS'
    ],
    editorTabs: ['PROPERTIES', 'EXPORT', 'HELP'],
    parameters: [
      {
        id: 'component-name',
        dplID: 'componentName',
        resourceID: 'componentName',
        metlSlot: 1,
        useVariable: 'NO',
        dataType: 'TEXT',
        authProvider: null,
        editorType: 'FREETEXT',
        lookupType: null,
        lookupDependencies: null,
        validationDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null,
        secretTypes: [],
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        id: 'script',
        dplID: 'script',
        resourceID: 'script',
        metlSlot: 2,
        useVariable: 'YES',
        dataType: 'TEXT_PYTHON',
        authProvider: null,
        editorType: 'PYTHON',
        lookupType: null,
        lookupDependencies: null,
        validationDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue:
          "###\n# Variables are directly accessible: \n#   print (myvar)\n# Updating a variable:\n#   context.updateVariable('myvar', 'new-value')\n# Grid Variables are accessible via the context:\n#   print (context.getGridVariable('mygridvar'))\n# Updating a grid variable:\n#   context.updateGridVariable('mygridvar', [['list','of'],['lists','!']])\n# A database cursor can be accessed from the context (Jython only):\n#   cursor = context.cursor()\n#   cursor.execute('select count(*) from mytable')\n#   rowcount = cursor.fetchone()[0]\n###\n",
        secretTypes: [],
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        id: 'interpreter',
        dplID: 'interpreter',
        resourceID: 'interpreter',
        metlSlot: 3,
        useVariable: 'YES',
        dataType: 'TEXT',
        authProvider: null,
        editorType: 'DROPDOWN',
        lookupType: null,
        lookupDependencies: null,
        validationDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: [
          {
            name: null,
            resourceID: null,
            type: 'TEXT',
            options: ['Python 2', 'Python 3'],
            columnEditorType: 'DROPDOWN',
            defaultValue: 'Python 3',
            lookupType: null,
            lookupDependencies: null
          }
        ],
        defaultValue: null,
        secretTypes: [],
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        id: 'timeout',
        dplID: 'timeout',
        resourceID: 'timeout',
        metlSlot: 4,
        useVariable: 'YES',
        dataType: 'TEXT',
        authProvider: null,
        editorType: 'FREETEXT',
        lookupType: null,
        lookupDependencies: null,
        validationDependencies: null,
        visibleWhen: [
          {
            param: 'interpreter',
            value: 'Jython',
            operator: 'NOT_EQUALS'
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: '360',
        secretTypes: [],
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        id: 'user',
        dplID: 'user',
        resourceID: 'user',
        metlSlot: 5,
        useVariable: 'YES',
        dataType: 'TEXT',
        authProvider: null,
        editorType: 'DROPDOWN',
        lookupType: null,
        lookupDependencies: null,
        validationDependencies: null,
        visibleWhen: [
          {
            param: null,
            value: null,
            operator: 'NEVER'
          }
        ],
        optional: false,
        staticOptions: [
          {
            name: null,
            resourceID: null,
            type: 'TEXT',
            options: ['Privileged', 'Restricted'],
            columnEditorType: 'DROPDOWN',
            defaultValue: 'Restricted',
            lookupType: null,
            lookupDependencies: null
          }
        ],
        defaultValue: null,
        secretTypes: [],
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      }
    ]
  }
} as ComponentMetadataResponse
