import { AxiosInstance } from 'axios'

import { UpdateProjectVariablesRequest } from './types'

export async function updateProjectVariablesApi(
  client: AxiosInstance,
  projectId: string,
  variableId: string,
  updateProjectVariablesRequest: UpdateProjectVariablesRequest
) {
  const { status } = await client.patch(
    `/projects/${projectId}/variables/${variableId}`,
    updateProjectVariablesRequest
  )

  return status
}
