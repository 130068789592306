import { LookUpType } from 'api/hooks/useGetComponentMetadata/types'

import { ElementCollection } from 'job-lib/types/Parameters'

import {
  ColumnEditorType,
  ComponentDependency,
  GetParameterOptionsResponse
} from '../types'

export const mockTransformJobOptions: GetParameterOptionsResponse = {
  editorColumns: [
    {
      name: '',
      type: 'TEXT',
      options: ['Transformation Job', 'Mock Job 2'],
      columnEditorType: ColumnEditorType.Dropdown,
      defaultValue: null,
      lookupType: null,
      lookupDependencies: null
    }
  ]
}

export const mockDynamicDwhColumns: GetParameterOptionsResponse = {
  editorColumns: [
    {
      name: '',
      type: 'TEXT',
      options: ['Year', 'Month', 'Airline', 'Nicolas', 'Viv'],
      columnEditorType: ColumnEditorType.Dropdown,
      defaultValue: null,
      lookupType: null,
      lookupDependencies: null
    }
  ]
}

export const mockDynamicEmeraldParameter: GetParameterOptionsResponse = {
  editorColumns: [
    {
      name: '',
      type: 'TEXT',
      options: ['Dani', 'Leo', 'Lyndsey', 'Simon', 'Kristian'],
      columnEditorType: ColumnEditorType.Dropdown,
      defaultValue: null,
      lookupType: null,
      lookupDependencies: null
    }
  ]
}

export const mockJoinExpressionEditorColumns: GetParameterOptionsResponse = {
  editorColumns: [
    {
      name: 'Expression',
      resourceID: 'expression',
      type: 'TEXT_SQL',
      options: null,
      columnEditorType: ColumnEditorType.SqlEditor,
      defaultValue: null,
      lookupType: null,
      lookupDependencies: null
    },
    {
      name: 'Join Name',
      resourceID: 'joinName',
      type: 'TEXT',
      options: ['Expression'],
      columnEditorType: ColumnEditorType.Dropdown,
      defaultValue: null,
      lookupType: null,
      lookupDependencies: null
    }
  ],
  helperData: {
    sourceColumns: [
      {
        alias: 'main',
        column: 'month',
        type: 'VARCHAR'
      },
      {
        alias: 'secondary',
        column: 'month',
        type: 'VARCHAR'
      }
    ]
  }
}

export const mockDynamicEmeraldParametersCreateTableColumns: GetParameterOptionsResponse =
  {
    editorColumns: [
      {
        name: 'Column Name',
        type: 'TEXT',
        options: null,
        columnEditorType: ColumnEditorType.Freetext,
        defaultValue: null,
        lookupType: null,
        lookupDependencies: null
      },
      {
        name: 'Data Type',
        type: 'TEXT',
        options: [
          'VARCHAR',
          'NUMBER',
          'FLOAT',
          'BOOLEAN',
          'DATE',
          'TIMESTAMP',
          'TIME',
          'VARIANT'
        ],
        columnEditorType: ColumnEditorType.Dropdown,
        defaultValue: null,
        lookupType: null,
        lookupDependencies: null
      },
      {
        name: 'Size',
        type: 'INTEGER',
        options: null,
        columnEditorType: ColumnEditorType.Freetext,
        defaultValue: null,
        lookupType: null,
        lookupDependencies: null
      },
      {
        name: 'Precision',
        type: 'INTEGER',
        options: null,
        columnEditorType: ColumnEditorType.Freetext,
        defaultValue: null,
        lookupType: null,
        lookupDependencies: null
      },
      {
        name: 'Default Value',
        type: 'TEXT',
        options: null,
        columnEditorType: ColumnEditorType.Freetext,
        defaultValue: null,
        lookupType: null,
        lookupDependencies: null
      },
      {
        name: 'Not Null',
        type: 'TEXT',
        options: ['Yes', 'No'],
        columnEditorType: ColumnEditorType.Dropdown,
        defaultValue: null,
        lookupType: null,
        lookupDependencies: null
      },
      {
        name: 'Unique',
        type: 'TEXT',
        options: ['Yes', 'No'],
        columnEditorType: ColumnEditorType.Dropdown,
        defaultValue: null,
        lookupType: null,
        lookupDependencies: null
      },
      {
        name: 'Comment',
        type: 'TEXT',
        options: null,
        columnEditorType: ColumnEditorType.Freetext,
        defaultValue: null,
        lookupType: null,
        lookupDependencies: null
      }
    ]
  }

const mockJoinExpressionsOptionsHandler = (
  body: ComponentDependency | Record<string, unknown>
): GetParameterOptionsResponse => {
  const joins = (body as Record<string, unknown>)[
    'param.joins'
  ] as ElementCollection
  const options = Object.entries(joins).map(([, { values }]) => {
    return `${values[1].value}_${values[3].value}_${values[2].value}`
  })
  return {
    editorColumns: [
      {
        name: 'Expression',
        type: 'TEXT',
        options: [],
        columnEditorType: ColumnEditorType.Freeform,
        defaultValue: null,
        lookupType: null,
        lookupDependencies: null
      },
      {
        name: 'Join Name',
        type: 'TEXT',
        options,
        columnEditorType: ColumnEditorType.Dropdown,
        defaultValue: null,
        lookupType: null,
        lookupDependencies: null
      }
    ]
  }
}

export const mockLookupTypeData = {
  [LookUpType.DESIGNER_TRANSFORM_JOBS]: mockTransformJobOptions,
  [LookUpType.DYNAMIC_DWH_COLUMNS]: mockDynamicDwhColumns,
  [LookUpType.DYNAMIC_EMERALD_OPTIONS]: mockDynamicEmeraldParameter,
  [LookUpType.DYNAMIC_DWH_SCHEMAS]: mockDynamicDwhColumns,
  [LookUpType.DESIGNER_SECRET_TYPE]: mockDynamicDwhColumns,
  [LookUpType.DYNAMIC_TRANSFORM_WHEN_MATCHED_EXPRESSIONS]:
    mockJoinExpressionEditorColumns
}

export const mockLookupTypeHandlers: Partial<
  Record<
    LookUpType,
    (
      body: ComponentDependency | Record<string, unknown>
    ) => GetParameterOptionsResponse
  >
> = {
  [LookUpType.DYNAMIC_TRANSFORM_JOIN_EXPRESSIONS]:
    mockJoinExpressionsOptionsHandler
}

export const mockParameterOptionsFailedResponse = {
  timestamp: '2022-10-27T14:17:00.123Z',
  message: 'Method argument not valid',
  errors: [
    {
      field: 'componentId',
      rejectedValue: 123456,
      message: 'expected a component id not a UUID',
      severity: 'high'
    }
  ]
}

export const mockInvalidRequestResponse = {
  type: 'about:blank',
  title: 'Bad Request',
  status: 400,
  violations: [
    {
      field: 'variables',
      message:
        'this is a dummy response for when variables are not passed in the request'
    }
  ]
}
