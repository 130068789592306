import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'
import { queryKeys } from '../../query-keys'
import useWorkingTreeClient from 'api/clients/useWorkingTreeClient'
import useGitContext from 'provider/GitProvider/useGitContext'

const useDeleteBranch = () => {
  const client = useWorkingTreeClient()
  const { project, branch } = useGitContext()

  const deleteBranch = useCallback(async () => {
    const url = `projects/${project}/branches/${branch}`
    const { data } = await client.delete(url)
    return data
  }, [client, project, branch])

  return useMutation([queryKeys.delete, branch], deleteBranch)
}

export default useDeleteBranch
