import { JobSummary } from 'api/hooks/useGetJobSummaries'

import { FileStatus } from 'job-lib/types/FileStatus'
import { JobType } from 'job-lib/types/JobType'

/* A valid name has the following criteria:
- any number of trailing spaces
- any number of starting spaces
- includes letters, normal brackets or dashes
- must include at least one valid character
- words can only be separated by one space
*/
export const VALID_NAME_REGEX = /^ ?[\w()-]+(?: [\w()-]+)* ?$/

/**
 * Checks if the specified pipeline exists in the provided list of pipelines
 *
 * @param pipelineRuntimeName the full folder path + pipeline name (no extension)
 * @param pipelineType The type of pipeline
 * @param allPipelines a list of pipeline summaries
 * @returns true if exists, else false
 */
export const isPipelineExistingPipeline = (
  pipelineRuntimeName: string,
  pipelineType: JobType,
  allPipelines: JobSummary[]
) => {
  const matchedPipeline = allPipelines.find((existing) => {
    const hasSameRuntime =
      existing.runtimeName.toLowerCase() === pipelineRuntimeName.toLowerCase()
    const hasSameType = existing.type === pipelineType
    const hasNotBeenDeleted = existing.status !== FileStatus.DELETED
    return hasSameRuntime && hasSameType && hasNotBeenDeleted
  })

  return Boolean(matchedPipeline)
}
