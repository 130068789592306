import {
  ComponentEditorTabType,
  ComponentMetadataResponse,
  EditorType,
  ExportVariableType,
  LookUpType,
  ParameterDataType,
  UseVariable,
  VisibleWhenOperator
} from 'api/hooks/useGetComponentMetadata/types'
import { ColumnEditorType } from 'api/hooks/useGetParameterOptions/types'

import { Cardinality, ConnectionPortType } from 'job-lib/types/Components'
import { JobType } from 'job-lib/types/JobType'

export const mockTableUpdateMetadata: ComponentMetadataResponse = {
  componentId: 'table-update',
  metadata: {
    schemaVersion: 1,
    emeraldId: '-1848704949',
    componentType: JobType.Transformation,
    inputPorts: [
      {
        portId: ConnectionPortType.INPUT,
        cardinality: Cardinality.ONE
      }
    ],
    outputPorts: [],
    deprecated: false,
    exports: [
      ExportVariableType.COMPLETED_AT,
      ExportVariableType.COMPONENT_NAME,
      ExportVariableType.DURATION,
      ExportVariableType.MESSAGE,
      ExportVariableType.ROW_COUNT,
      ExportVariableType.STARTED_AT,
      ExportVariableType.STATUS
      //   "ROWS_DELETED",
      //   "ROWS_INSERTED",
      //   "ROWS_UPDATED",
    ],
    editorTabs: [
      ComponentEditorTabType.PROPERTIES,
      ComponentEditorTabType.EXPORT,
      ComponentEditorTabType.SAMPLE,
      ComponentEditorTabType.METADATA,
      ComponentEditorTabType.SQL,
      ComponentEditorTabType.PLAN,
      ComponentEditorTabType.HELP
    ],
    parameters: [
      {
        id: 'component-name',
        dplID: 'componentName',
        metlSlot: 1,
        useVariable: UseVariable.NO,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'warehouse',
        dplID: 'warehouse',
        metlSlot: 203,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_DWH_WAREHOUSES,
        lookupDependencies: null,
        optional: false,
        visibleWhen: null,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'database',
        dplID: 'database',
        metlSlot: 202,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_DWH_DATABASES,
        lookupDependencies: null,
        optional: false,
        visibleWhen: null,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'schema',
        dplID: 'schema',
        metlSlot: 201,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_DWH_SCHEMAS,
        lookupDependencies: ['param.database'],
        optional: false,
        visibleWhen: null,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'target-table',
        dplID: 'targetTable',
        metlSlot: 200,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_DWH_TABLES,
        lookupDependencies: ['param.database', 'param.schema'],
        optional: false,
        visibleWhen: null,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'target-alias',
        dplID: 'targetAlias',
        metlSlot: 204,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.FREETEXT,
        optional: false,
        visibleWhen: null,
        staticOptions: null,
        defaultValue: null,
        lookupType: null,
        lookupDependencies: null
      },
      {
        id: 'source-alias',
        dplID: 'source-alias',
        metlSlot: 204,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.FREETEXT,
        optional: false,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: null,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'join-expression',
        dplID: 'joinExpression',
        metlSlot: 206,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.GRID,
        editorType: EditorType.GRID_EDITOR,
        optional: false,
        lookupType: null,
        lookupDependencies: null,
        validationDependencies: null,
        visibleWhen: null,
        staticOptions: [
          {
            name: 'Expression',
            type: 'TEXT',
            options: null,
            columnEditorType: ColumnEditorType.Freetext,
            defaultValue: '',
            lookupType: null,
            lookupDependencies: null
          },
          {
            name: 'Join Name',
            type: 'TEXT',
            options: ['Join'],
            columnEditorType: ColumnEditorType.Freetext,
            defaultValue: 'Join',
            lookupType: null,
            lookupDependencies: null
          }
        ]
      },
      {
        id: 'when-matched',
        dplID: 'whenMatched',
        metlSlot: 207,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.GRID,
        editorType: EditorType.GRID_EDITOR,
        optional: false,
        lookupType: null,
        lookupDependencies: null,
        validationDependencies: null,
        visibleWhen: null,
        staticOptions: [
          {
            name: 'Expression',
            resourceID: 'expression',
            options: [],
            type: 'TEXT',
            columnEditorType: ColumnEditorType.Expression,
            defaultValue: null,
            lookupDependencies: [
              'transform.sql',
              'param.database',
              'param.schema',
              'param.targetTable',
              'param.targetAlias',
              'param.sourceAlias'
            ],
            lookupType: LookUpType.DYNAMIC_TRANSFORM_WHEN_MATCHED_EXPRESSIONS
          },
          {
            name: 'Operation',
            type: 'TEXT',
            options: ['Delete', 'Update'],
            columnEditorType: ColumnEditorType.Dropdown,
            defaultValue: 'Update',
            lookupDependencies: null,
            lookupType: null
          }
        ]
      },
      {
        id: 'update-mapping',
        dplID: 'updateMapping',
        metlSlot: 208,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.GRID,
        editorType: EditorType.GRID_EDITOR,
        optional: false,
        lookupType: null,
        lookupDependencies: null,
        validationDependencies: null,
        visibleWhen: null,
        staticOptions: [
          {
            name: 'Source Column',
            type: 'TEXT',
            columnEditorType: ColumnEditorType.Dropdown,
            lookupType: LookUpType.DYNAMIC_TRANSFORM_SOURCE_COLUMNS,
            lookupDependencies: ['transform.sql'],
            defaultValue: '',
            options: null
          },
          {
            name: 'Target Column',
            type: 'TEXT',
            defaultValue: '',
            options: null,
            columnEditorType: ColumnEditorType.Dropdown,
            lookupType: LookUpType.DYNAMIC_DWH_COLUMNS,
            lookupDependencies: [
              'param.database',
              'param.schema',
              'param.targetTable:param.table'
            ]
          }
        ]
      },
      {
        id: 'include-not-matched',
        dplID: 'includeNotMatched',
        metlSlot: 209,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.BOOLEAN,
        editorType: EditorType.DROPDOWN,
        optional: false,
        lookupType: null,
        lookupDependencies: null,
        validationDependencies: null,
        visibleWhen: null,
        staticOptions: [
          {
            name: null,
            type: 'TEXT',
            options: ['Yes', 'No'],
            columnEditorType: ColumnEditorType.Dropdown,
            defaultValue: 'No',
            lookupType: null,
            lookupDependencies: null
          }
        ],
        defaultValue: 'No'
      },
      {
        id: 'insert-mapping',
        dplID: 'insertMapping',
        metlSlot: 210,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.GRID,
        editorType: EditorType.GRID_EDITOR,
        lookupType: null,
        lookupDependencies: null,
        optional: false,
        visibleWhen: [
          {
            param: 'includeNotMatched',
            value: 'Yes',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        staticOptions: [
          {
            name: 'Source Column',
            type: 'TEXT',
            columnEditorType: ColumnEditorType.Dropdown,
            lookupType: LookUpType.DYNAMIC_TRANSFORM_SOURCE_COLUMNS,
            lookupDependencies: ['transform.sql'],
            defaultValue: '',
            options: []
          },
          {
            name: 'Target Column',
            type: 'TEXT',
            defaultValue: '',
            options: [],
            columnEditorType: ColumnEditorType.Dropdown,
            lookupType: LookUpType.DYNAMIC_DWH_COLUMNS,
            lookupDependencies: [
              'param.database',
              'param.schema',
              'param.targetTable:param.table'
            ]
          }
        ]
      }
    ]
  }
}
