import { useCallback } from 'react'

import { useQuery } from '@tanstack/react-query'

import config from 'config'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { queryKeys } from '../../queryKeys'
import { useSpringClient } from '../useSpringClient/useSpringClient'

export interface AgentDetailsResponse {
  status: AgentStatusEnum
  agentId: string
  host: AgentHostEnum | null
}

export enum AgentStatusEnum {
  Pending = 'PENDING',
  Running = 'RUNNING',
  Stopped = 'STOPPED',
  Unknown = 'UNKNOWN'
}

export enum AgentHostEnum {
  Matillion = 'MATILLION',
  Customer = 'CUSTOMER'
}

export interface AgentDetails {
  status?: AgentStatusEnum | null
  agentError?: Error | null
  /* is the agent available? i.e. is status === AVAILABLE */
  isAvailable: boolean
  lastFetchTime: Date
  agentId: string
  host: AgentHostEnum | null
}

/**
 * This hook will call agent status every 3 seconds and respond with
 * the status of the agent and the time that the fetch finished.
 *
 * If the agent request fails for any reason then the status will be UNKNOWN.
 */
export const useAgentDetails = () => {
  const client = useSpringClient()
  const { agentId } = useProjectInfo()

  const agentsDetails = useCallback<() => Promise<AgentDetails>>(async () => {
    let status: AgentDetails['status'] = null
    let agentError: AgentDetails['agentError'] = null
    let host: AgentDetails['host'] = null

    try {
      const response = await client.get<AgentDetailsResponse>(
        `agents/${agentId}`
      )

      status = response.data.status
      host = response.data.host
    } catch (err) {
      if (!err || !(err instanceof Error)) {
        agentError = new Error('Unknown error: ' + JSON.stringify(err))
      } else {
        agentError = err
      }
    }

    return {
      status,
      isAvailable: status === AgentStatusEnum.Running,
      lastFetchTime: new Date(),
      agentError,
      agentId,
      host
    }
  }, [client, agentId])

  return useQuery([queryKeys.agentDetails, agentId], agentsDetails, {
    refetchInterval: config.agentRefetchIntervalMs,
    refetchIntervalInBackground: true
  })
}
