import { useMemo } from 'react'

import { getComponentLabel } from 'job-lib/job-functions/getComponentLabel'
import { JobState } from 'job-lib/store/jobSlice/job.types'
import {
  ComponentInstanceId,
  OrchestrationJob,
  TransformationJob
} from 'job-lib/types/Job'
import { JobType } from 'job-lib/types/JobType'

import { ComponentQueryKeys } from '../types'

const generateTransformationQueryKeys = (job: TransformationJob) => {
  const componentKeys: ComponentQueryKeys = {}
  let componentDependencies: string[][] = []

  const buildTree = (componentId: ComponentInstanceId, keys: string[]) => {
    const componentName = getComponentLabel(job.components[componentId])
    const currentKeys = [...keys, componentName]

    componentDependencies.push(currentKeys)

    job.components[componentId].outputConnectorIDs.forEach((connectorId) => {
      const targetID = job.connectors[connectorId].targetID

      if (keys.includes(componentName)) {
        const targetComponent = getComponentLabel(job.components[targetID])
        return console.error(
          `The beginning of a circular dependency has been created between the ${componentName} and the ${targetComponent}`
        )
      }

      return buildTree(targetID, currentKeys)
    })
  }

  const componentIds: number[] = Object.keys(job?.components).map((id) =>
    parseInt(id)
  )
  const inputConnections: ComponentInstanceId[] = Object.values(
    job?.connectors
  ).map((connector) => Number(connector.targetID))

  const inputComponents = componentIds.filter(
    (componentId) => !inputConnections.includes(componentId)
  )

  inputComponents.forEach((component) => {
    buildTree(component, [])

    componentDependencies.forEach((dependencyArray) => {
      const componentName =
        dependencyArray.at(-1) ?? /* istanbul ignore next */ ''

      if (componentKeys[componentName]) {
        componentKeys[componentName].push(dependencyArray)
      } else {
        componentKeys[componentName] = [dependencyArray]
      }
    })

    componentDependencies = []
  })

  return componentKeys
}

const generateOrchestrationQueryKeys = (job: OrchestrationJob) => {
  const componentKeys: ComponentQueryKeys = Object.keys(job.components).reduce(
    (acc, componentId) => {
      const componentName = getComponentLabel(
        job.components[Number(componentId)]
      )

      return {
        ...acc,
        [componentName]: [[componentName]]
      }
    },
    {}
  )

  return componentKeys
}

export const useGenerateJobQueryKeys = ({
  job,
  jobType
}: JobState): ComponentQueryKeys | null => {
  return useMemo(() => {
    if (jobType === JobType.Transformation) {
      return generateTransformationQueryKeys(job)
    }

    if (jobType === JobType.Orchestration) {
      return generateOrchestrationQueryKeys(job)
    }
    return null
  }, [job, jobType])
}
