import { RequestHandler, rest } from 'msw'

import config from 'config'

import { ListProjectResponse } from '../types'
import { mockedProjects } from './MockedProjects'

export const useListProjectsHandlers: RequestHandler[] = [
  rest.get(`${config.getApiBaseUrl()}/v1/projects`, (_req, res, ctx) => {
    return res(ctx.json<ListProjectResponse>(mockedProjects))
  })
]
