import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Alert,
  Button,
  Checkbox,
  Field,
  Modal,
  Toaster,
  Typography
} from '@matillion/component-library'
import { CurrentBranchDisplay } from '@matillion/git-component-library'
import { useQueryClient } from '@tanstack/react-query'

import { useDeleteBranch } from 'api/hooks/useDeleteBranch'
import { getJobQueryKey } from 'api/hooks/useGetJob/useGetJob'
import { useGetJobSummariesQueryKey } from 'api/hooks/useGetJobSummaries'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import classes from './PullRemoteChangesModal.module.scss'

export interface PullRemoteChangesModalProps {
  close: () => void
}

const PullRemoteChangesModal = ({ close }: PullRemoteChangesModalProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'git.pull.modal' })
  const { branchId, projectId, jobSummaryId } = useProjectInfo()
  const { makeToast } = Toaster.useToaster()
  const { mutateAsync: deleteBranch, isLoading } = useDeleteBranch(branchId)
  const queryClient = useQueryClient()

  const [loading, setLoading] = useState(false)
  const [hasConsented, setHasConsented] = useState(false)

  const jobSummariesQueryKey = useGetJobSummariesQueryKey()

  const handleConsentChange = useCallback(() => {
    setHasConsented(!hasConsented)
  }, [hasConsented, setHasConsented])

  const handlePull = () => {
    deleteBranch()
      .then(async () => {
        makeToast({
          type: 'success',
          title: '',
          message: t('success-toast', { branch: branchId })
        })

        setLoading(true)

        await queryClient.invalidateQueries(jobSummariesQueryKey)
        queryClient.invalidateQueries(
          getJobQueryKey(projectId, branchId, jobSummaryId)
        )

        setLoading(false)
      })
      .catch(() => {
        makeToast({
          type: 'error',
          title: '',
          message: t('failure-toast', { branch: branchId })
        })
      })
      .finally(() => {
        close()
      })
  }

  return (
    <Modal
      size="mid"
      onCancel={close}
      disableBackdropClick
      data-testid="pull-v1-modal"
      ariaLabelledBy="pull-branch-modal"
    >
      <Typography format="tm" id="pull-title" className={classes.Title}>
        {t('title')}
      </Typography>

      <Alert
        type="warning"
        message={t('warning')}
        className={classes.Alert}
        data-testid="pull-remote-changes-alert"
      />

      <Typography format="bcs" weight="bold" data-testid="modal-branch-label">
        {t('label.branch')}
      </Typography>
      <CurrentBranchDisplay />

      <Field
        text={t('consent')}
        checked={hasConsented}
        inputComponent={Checkbox}
        onChange={handleConsentChange}
        className={classes.ConsentCheckbox}
        data-testid="pull-branch-consent-box"
      />

      <div className={classes.Footer}>
        <Button
          type="reset"
          alt="secondary"
          text={t('cancel')}
          onClick={close}
          className={classes.Button}
          data-testid="cancel-pull-branch-button"
        />

        <Button
          type="submit"
          text={t('submit')}
          onClick={handlePull}
          disabled={!hasConsented}
          className={classes.Button}
          waiting={isLoading || loading}
          data-testid="pull-branch-button"
          alt="positive"
        />
      </div>
    </Modal>
  )
}

export default PullRemoteChangesModal
