import { useTranslation } from 'react-i18next'

import { Modal, Toaster } from '@matillion/component-library'
import {
  ConflictResolutionSelection,
  DescribeMergeConflict
} from '@matillion/git-component-library'

import ConflictResolutionForm from './ConflictResolutionForm'

export interface ConflictsModalProps {
  submit: string
  close: () => void
  conflicts: DescribeMergeConflict[]
  onResolve: (resolutions: ConflictResolutionSelection[]) => unknown
}

const ConflictsModal = ({
  conflicts,
  onResolve,
  close,
  submit
}: ConflictsModalProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'git.conflicts.modal'
  })

  const { makeToast } = Toaster.useToaster()

  const handleCancel = () => {
    makeToast({
      type: 'info',
      title: t('toast.dismiss.title'),
      message: t('toast.dismiss.message')
    })
    close()
  }

  return (
    <Modal
      size="mid"
      disableBackdropClick
      onCancel={handleCancel}
      ariaLabelledBy="conflicts-modal"
    >
      <ConflictResolutionForm
        submit={submit}
        onResolve={onResolve}
        conflicts={conflicts}
        onCancel={handleCancel}
      />
    </Modal>
  )
}

export default ConflictsModal
