import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Typography } from '@matillion/component-library'
import { ServiceKey, useServiceUrl } from '@matillion/hub-client'

import { useProjectPermission } from 'api/external/usePermission/useProjectPermission'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import {
  getProjectExplorerLink,
  ProjectExplorerPaths
} from 'utils/getProjectExplorerLink'

import ScheduleModal from '../ScheduleModal'
import classes from './ScheduleButton.module.scss'

const ScheduleButton = () => {
  const { projectId } = useProjectInfo()
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false)
  const projectExplorerOrigin = useServiceUrl(
    ServiceKey.projectExplorerFrontend,
    true
  )
  const { t } = useTranslation('translation', { keyPrefix: 'schedule.button' })
  const { hasPermission, isLoading } = useProjectPermission('create_schedules')

  const handleScheduleRedirect = () => {
    window.location.assign(
      getProjectExplorerLink(
        projectExplorerOrigin,
        projectId,
        ProjectExplorerPaths.CREATE_SCHEDULE
      )
    )
  }

  const isEnabled = hasPermission && !isLoading

  return (
    <>
      <div>
        <Button
          onClick={() => setIsScheduleModalOpen(!isScheduleModalOpen)}
          aria-haspopup="false"
          data-testid="schedule-button"
          className={classes.ScheduleButton}
          aria-labelledby={t('aria-labelledby')}
          alt="secondary"
          disabled={!isEnabled}
        >
          <Typography format="bcs" weight="bold">
            {t('text')}
          </Typography>
        </Button>
      </div>
      {isScheduleModalOpen && isEnabled && (
        <ScheduleModal
          onInitiate={handleScheduleRedirect}
          onCancel={() => setIsScheduleModalOpen(false)}
        />
      )}
    </>
  )
}

export default ScheduleButton
