import { useCallback, useMemo } from 'react'

import { ComponentMetadataParameterId } from 'api/hooks/useGetComponentMetadata/types'
import { ComponentSummaryId } from 'api/hooks/useGetComponentTree'
import { EditorColumn } from 'api/hooks/useGetParameterOptions/types'

import {
  orchestrationJobNameParameter,
  runOrchestrationComponentId
} from 'job-lib/cisIds/knownComponentParameters'

import { useWorkingCopy } from 'modules/EtlDesigner/hooks/useWorkingCopy'

export interface ClientDataFilteringProps {
  componentSummaryId?: ComponentSummaryId
  parameterId?: ComponentMetadataParameterId
}

export type FilterOptionsFn = (column: EditorColumn[]) => EditorColumn[]

export interface Filters {
  [key: ComponentMetadataParameterId]: FilterOptionsFn
}

const useFilterRunOrchestrationParameter = (): Filters => {
  const { summary } = useWorkingCopy()

  return useMemo<Filters>(
    () => ({
      [orchestrationJobNameParameter]: (columns) =>
        columns.map((column) => {
          if (!summary || !column.options) {
            return column
          }

          return {
            ...column,
            options: column.options.filter((option) => option !== summary.name)
          }
        })
    }),
    [summary]
  )
}

export const useClientDataFiltering = ({
  parameterId,
  componentSummaryId
}: ClientDataFilteringProps) => {
  const runOrchestrationFilters = useFilterRunOrchestrationParameter()

  const filterColumns = useCallback(
    (editorColumns: EditorColumn[]) => {
      if (componentSummaryId === runOrchestrationComponentId) {
        const filter = parameterId ? runOrchestrationFilters[parameterId] : null

        if (filter) {
          return filter(editorColumns)
        }
      }

      return editorColumns
    },
    [parameterId, componentSummaryId, runOrchestrationFilters]
  )

  return {
    filterColumns
  }
}
