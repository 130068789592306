import { useQuery } from '@tanstack/react-query'

import {
  type BranchInformation,
  type GetBranchQueryConfig
} from 'api/hooks/useGetBranch/types'
import { queryKeys } from 'api/query-keys'
import useDesignerClient from 'api/clients/useDesignerClient'

const useGetBranch = (
  branchName?: string,
  config?: Partial<GetBranchQueryConfig>
) => {
  const { project, client } = useDesignerClient()

  return useQuery(
    [queryKeys.merge, project, branchName, queryKeys.getBranch],
    async () => {
      const url = `branches/${branchName}?projectId=${project}`
      const { data } = await client.get<BranchInformation>(url)
      return data
    },
    { ...config }
  )
}

export default useGetBranch
