import { PROJECT_ID, PROJECT_ID_WITH_NO_ENVS } from '__test-utils__/ids'

import { ListProjectResponse, ProjectType } from '../types'

export const mockedProjects: ListProjectResponse = {
  results: [
    {
      id: PROJECT_ID,
      name: 'mock-project-name',
      description: 'Mock project description',
      warehouse: ProjectType.SNOWFLAKE
    },
    {
      id: PROJECT_ID_WITH_NO_ENVS,
      name: 'paul-s-e2e-tests',
      description: 'project for testing e2e',
      warehouse: ProjectType.SNOWFLAKE
    }
  ]
}
