import { createContext } from 'react'

import { PipelineBrowserContextBag } from './types'

export const PipelineBrowserContext = createContext<PipelineBrowserContextBag>({
  onCommand: () => {
    console.debug('PipelineBrowserContext not initialised')
  },
  onPipelineTreeCommand: () => {
    console.debug('PipelineBrowserContext not initialised')
  },
  pipelineTree: []
})
