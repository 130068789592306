import { AxiosInstance } from 'axios'

import { CreateProjectVariablesRequest } from './types'

export async function createProjectVariablesApi(
  client: AxiosInstance,
  projectId: string,
  createProjectVariablesRequest: CreateProjectVariablesRequest
) {
  const { status } = await client.post(
    `/projects/${projectId}/variables`,
    createProjectVariablesRequest
  )

  return status
}
