// Copied to cypress' jobSummaryStubbedResponse

import {
  MOCK_ORCHESTRATION_JOB_NAME,
  MOCK_ORCHESTRATION_JOB_SUMMARY_ID,
  MOCK_TRANSFORMATION_JOB_NAME,
  MOCK_TRANSFORMATION_JOB_SUMMARY_ID
} from '__test-utils__/ids'

import { FileStatus } from 'job-lib/types/FileStatus'
import { JobType } from 'job-lib/types/JobType'

import { JobSummaries, JobSummaryResponse } from '../types'

export const mockJobSummaryResponse: JobSummaryResponse = {
  results: [
    {
      id: MOCK_ORCHESTRATION_JOB_SUMMARY_ID,
      name: MOCK_ORCHESTRATION_JOB_NAME,
      folder: '',
      runtimeName: MOCK_ORCHESTRATION_JOB_NAME,
      type: JobType.Orchestration,
      status: FileStatus.UNMODIFIED
    },
    {
      id: MOCK_TRANSFORMATION_JOB_SUMMARY_ID,
      name: MOCK_TRANSFORMATION_JOB_NAME,
      folder: '',
      runtimeName: MOCK_TRANSFORMATION_JOB_NAME,
      type: JobType.Transformation,
      status: FileStatus.UNMODIFIED
    },
    {
      id: 'examples/matillion_load.ORCHESTRATION',
      name: 'matillion_load',
      folder: 'examples',
      runtimeName: 'examples/matillion_load',
      type: JobType.Orchestration,
      status: FileStatus.UNMODIFIED
    },
    {
      id: 'examples/matillion_transformation.TRANSFORMATION',
      name: 'matillion_transformation',
      folder: 'examples',
      runtimeName: 'examples/matillion_transformation',
      type: JobType.Transformation,
      status: FileStatus.UNMODIFIED
    },
    {
      id: 'examples/matillion_drop.ORCHESTRATION',
      name: 'matillion_drop',
      folder: 'examples',
      runtimeName: 'examples/matillion_drop',
      type: JobType.Orchestration,
      status: FileStatus.UNMODIFIED
    },
    {
      id: 'one/one.ORCHESTRATION',
      name: 'one',
      folder: 'one',
      runtimeName: 'one/one',
      type: JobType.Orchestration,
      status: FileStatus.UNMODIFIED
    },
    {
      id: 'one/two/two.ORCHESTRATION',
      name: 'two',
      folder: 'one/two',
      runtimeName: 'one/two/two',
      type: JobType.Orchestration,
      status: FileStatus.UNMODIFIED
    },
    {
      id: 'one/two/three/three.ORCHESTRATION',
      name: 'three',
      folder: 'one/two/three',
      runtimeName: 'one/two/three/three',
      type: JobType.Orchestration,
      status: FileStatus.UNMODIFIED
    }
  ]
}

export const mockRootJobSummaries: JobSummaries = {
  results: [
    {
      jobId: MOCK_ORCHESTRATION_JOB_SUMMARY_ID,
      name: MOCK_ORCHESTRATION_JOB_NAME,
      folder: '',
      runtimeName: MOCK_ORCHESTRATION_JOB_NAME,
      type: JobType.Orchestration,
      status: FileStatus.UNMODIFIED
    },
    {
      jobId: MOCK_TRANSFORMATION_JOB_SUMMARY_ID,
      name: MOCK_TRANSFORMATION_JOB_NAME,
      folder: '',
      runtimeName: MOCK_TRANSFORMATION_JOB_NAME,
      type: JobType.Transformation,
      status: FileStatus.UNMODIFIED
    }
  ],
  isUsingJGit: false
}

export const mockJobSummaries: JobSummaries = {
  results: [
    {
      jobId: MOCK_ORCHESTRATION_JOB_SUMMARY_ID,
      name: MOCK_ORCHESTRATION_JOB_NAME,
      folder: '',
      runtimeName: MOCK_ORCHESTRATION_JOB_NAME,
      type: JobType.Orchestration,
      status: FileStatus.UNMODIFIED
    },
    {
      jobId: MOCK_TRANSFORMATION_JOB_SUMMARY_ID,
      name: MOCK_TRANSFORMATION_JOB_NAME,
      folder: '',
      runtimeName: MOCK_TRANSFORMATION_JOB_NAME,
      type: JobType.Transformation,
      status: FileStatus.UNMODIFIED
    },
    {
      jobId: 'examples/matillion_load.ORCHESTRATION',
      name: 'matillion_load',
      folder: 'examples',
      runtimeName: 'examples/matillion_load',
      type: JobType.Orchestration,
      status: FileStatus.UNMODIFIED
    },
    {
      jobId: 'examples/matillion_transformation.TRANSFORMATION',
      name: 'matillion_transformation',
      folder: 'examples',
      runtimeName: 'examples/matillion_transformation',
      type: JobType.Transformation,
      status: FileStatus.UNMODIFIED
    },
    {
      jobId: 'examples/matillion_drop.ORCHESTRATION',
      name: 'matillion_drop',
      folder: 'examples',
      runtimeName: 'examples/matillion_drop',
      type: JobType.Orchestration,
      status: FileStatus.UNMODIFIED
    },
    {
      jobId: 'one/one.ORCHESTRATION',
      name: 'one',
      folder: 'one',
      runtimeName: 'one/one',
      type: JobType.Orchestration,
      status: FileStatus.UNMODIFIED
    },
    {
      jobId: 'one/two/two.ORCHESTRATION',
      name: 'two',
      folder: 'one/two',
      runtimeName: 'one/two/two',
      type: JobType.Orchestration,
      status: FileStatus.UNMODIFIED
    },
    {
      jobId: 'one/two/three/three.ORCHESTRATION',
      name: 'three',
      folder: 'one/two/three',
      runtimeName: 'one/two/three/three',
      type: JobType.Orchestration,
      status: FileStatus.UNMODIFIED
    }
  ],
  isUsingJGit: false
}

export const mockDeletedJobSummaryResponse: JobSummaryResponse = {
  results: [
    {
      id: MOCK_ORCHESTRATION_JOB_SUMMARY_ID,
      name: MOCK_ORCHESTRATION_JOB_NAME,
      folder: '',
      runtimeName: MOCK_ORCHESTRATION_JOB_NAME,
      type: JobType.Orchestration,
      status: FileStatus.DELETED
    },
    {
      id: MOCK_TRANSFORMATION_JOB_SUMMARY_ID,
      name: MOCK_TRANSFORMATION_JOB_NAME,
      folder: '',
      runtimeName: MOCK_TRANSFORMATION_JOB_NAME,
      type: JobType.Transformation,
      status: FileStatus.UNMODIFIED
    }
  ]
}

export const mockDeletedJobSummaries: JobSummaryResponse = {
  results: [
    {
      id: MOCK_ORCHESTRATION_JOB_SUMMARY_ID,
      name: MOCK_ORCHESTRATION_JOB_NAME,
      folder: '',
      runtimeName: MOCK_ORCHESTRATION_JOB_NAME,
      type: JobType.Orchestration,
      status: FileStatus.DELETED
    },
    {
      id: MOCK_TRANSFORMATION_JOB_SUMMARY_ID,
      name: MOCK_TRANSFORMATION_JOB_NAME,
      folder: '',
      runtimeName: MOCK_TRANSFORMATION_JOB_NAME,
      type: JobType.Transformation,
      status: FileStatus.UNMODIFIED
    }
  ]
}

export const mockUnmodifiedJobSummary: JobSummaryResponse = {
  results: [
    {
      id: MOCK_TRANSFORMATION_JOB_SUMMARY_ID,
      name: MOCK_TRANSFORMATION_JOB_NAME,
      folder: '',
      runtimeName: MOCK_TRANSFORMATION_JOB_NAME,
      type: JobType.Transformation,
      status: FileStatus.UNMODIFIED
    }
  ]
}
