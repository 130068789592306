import { PropsWithChildren, useCallback, useMemo } from 'react'

import {
  GitProvider,
  GitProviderProps,
  WorkingTreeRefreshEvent
} from '@matillion/git-component-library'

import { getJobQueryKey } from './api/hooks/useGetJob/useGetJob'
import {
  useGetJobSummaries,
  useGetJobSummariesQueryKey
} from './api/hooks/useGetJobSummaries'
import { useSpringClient } from './api/hooks/useSpringClient/useSpringClient'
import queryClient from './api/queryClient'
import config from './config'
import { useFlags } from './hooks/useFlags'
import { useProjectInfo } from './hooks/useProjectInfo/useProjectInfo'
import { useSelectedJobs } from './hooks/useSelectedJobs'

export const GitLibraryProvider = ({ children }: PropsWithChildren) => {
  const { pullChangesV2 } = useFlags()
  const environment = config.environment
  const designerClient = useSpringClient()
  const { selectedJobs } = useSelectedJobs()
  const { branchId, projectId } = useProjectInfo()
  const jobSummariesQueryKey = useGetJobSummariesQueryKey()
  const { data: jobSummaries } = useGetJobSummaries({ includeDeleted: true })

  const onRefreshWorkingTreeStatus = useCallback(
    async ({
      refreshFileSummaries,
      refreshPipelines
    }: WorkingTreeRefreshEvent) => {
      if (refreshFileSummaries) {
        await queryClient.invalidateQueries(jobSummariesQueryKey)
      }

      if (refreshPipelines) {
        for (const id of selectedJobs) {
          const jobQueryKey = getJobQueryKey(projectId, branchId, id)
          queryClient.removeQueries(jobQueryKey)
        }
      }
    },
    [branchId, jobSummariesQueryKey, projectId, selectedJobs]
  )

  const props: GitProviderProps = useMemo(
    () => ({
      branch: branchId,
      project: projectId,
      featureFlags: {
        pullChangesV2
      },
      environment,
      designerClient,
      onRefreshWorkingTreeStatus,
      fileSummaries: jobSummaries?.results ?? [],
      isNativeGit: jobSummaries?.isUsingJGit ?? false
    }),
    [
      branchId,
      projectId,
      environment,
      pullChangesV2,
      designerClient,
      onRefreshWorkingTreeStatus,
      jobSummaries?.results,
      jobSummaries?.isUsingJGit
    ]
  )

  return <GitProvider {...props}>{children}</GitProvider>
}
