import React from 'react'

import { Typography } from '@matillion/component-library'

import { JobSummary } from 'api/hooks/useGetJobSummaries'

import { PortalDiv } from 'components/ComponentDrag/PortalDiv'
import { JobTypeIcon } from 'components/JobTypeIcon'

import classes from './PipelineItemPortal.module.scss'

export interface PipelineItemPortalProps {
  pipeline: JobSummary
  dragRef: React.Ref<HTMLDivElement>
}

const PipelineItemPortal = ({ pipeline, dragRef }: PipelineItemPortalProps) => {
  return (
    <PortalDiv
      childRef={dragRef}
      className={classes.Portal}
      childTestId={`pipeline-drag-portal-${pipeline.jobId}`}
    >
      <div className={classes.PortalContent}>
        <JobTypeIcon jobType={pipeline.type} />
        <Typography as="span" format="bcs" className={classes.Text}>
          {pipeline.name}
        </Typography>
      </div>
    </PortalDiv>
  )
}

export default PipelineItemPortal
