import { JobSummary } from 'api/hooks/useGetJobSummaries'

import { JobType } from 'job-lib/types/JobType'

import {
  PipelineBrowserCommand,
  PipelineBrowserCommandAction,
  PipelineBrowserCommandState
} from '../../context/types'

export const initialCommandState: PipelineBrowserCommandState = {
  jobSummary: null,
  jobType: null,
  isDeletingPipeline: false,
  isCreatingPipeline: false,
  isRenamingPipeline: false
}

export const commandReducer = (
  state: PipelineBrowserCommandState,
  { type, payload }: PipelineBrowserCommandAction
): PipelineBrowserCommandState => {
  switch (type) {
    case PipelineBrowserCommand.DELETE_PIPELINE: {
      return {
        ...state,
        isDeletingPipeline: true,
        jobSummary: payload as JobSummary
      }
    }
    case PipelineBrowserCommand.CREATE_PIPELINE: {
      return {
        ...state,
        isCreatingPipeline: true,
        jobType: payload as JobType
      }
    }
    case PipelineBrowserCommand.RENAME_PIPELINE:
      return {
        ...state,
        isRenamingPipeline: true,
        jobSummary: payload as JobSummary
      }
    case PipelineBrowserCommand.RESET: {
      return initialCommandState
    }
    default:
      return state
  }
}
