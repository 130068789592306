import {
  Button,
  Field,
  Radio,
  Tooltip,
  Typography,
  Icon
} from '@matillion/component-library'
import { type ChangeEvent, useCallback } from 'react'
import { useFormikContext } from 'formik'
import {
  type BringYourOwnGitDetails,
  type GitProvider
} from '../BringYourOwnGitForm/types'
import { type GitProviderSelectorProps } from './types'
import { useTranslation } from 'react-i18next'
import classes from './GitProviderSelector.module.scss'

const GitProviderSelector = ({ className }: GitProviderSelectorProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'git.byog.form.provider'
  })

  const { handleChange, values, setFieldValue, setFieldTouched } =
    useFormikContext<BringYourOwnGitDetails>()

  const handleValueChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const providerSelection = e.target.value as GitProvider

      if (providerSelection === 'matillion') {
        await setFieldValue('repository', undefined)
        await setFieldTouched('repository', false)
      }

      await setFieldValue('provider', providerSelection).then(() => {
        handleChange(e)
      })
    },
    [handleChange, setFieldValue]
  )

  return (
    <div data-testid="git-provider-form" className={className}>
      <div className={classes.titleWrapper}>
        <Typography format="bcs" className={classes.title} weight="bold">
          {t('title')}
        </Typography>

        <Tooltip content={t('tooltip')} className={classes.tooltip}>
          <Button
            alt="ghost"
            className={classes.tooltipButton}
            data-testid="git-provider-tooltip-button"
          >
            <Icon.InfoNoBg />
          </Button>
        </Tooltip>
      </div>

      <Typography format="bcs" className={classes.subtitle}>
        {t('subtitle')}
      </Typography>

      <Field
        value="matillion"
        name="git-provider"
        text={t('matillion')}
        inputComponent={Radio}
        onChange={handleValueChange}
        className={classes.matillion}
        data-testid="git-provider-matillion"
        checked={values.provider === 'matillion'}
      />
      <Field
        value="github"
        text={t('github')}
        name="git-provider"
        inputComponent={Radio}
        className={classes.github}
        onChange={handleValueChange}
        data-testid="git-provider-github"
        checked={values.provider === 'github'}
      />

      <div className={classes.gitHubAppsHelp}>
        <Typography format="bcs" className={classes.helpText}>
          {t('github-help')}
        </Typography>

        <span> </span>

        <Typography format="bcs">
          <a
            target="_blank"
            rel="noreferrer"
            className={classes.helpLink}
            href="https://support.matillion.com"
          >
            {t('github-help-link')}
            <Icon.Export className={classes.exportIcon} />
          </a>
        </Typography>
      </div>
    </div>
  )
}

export default GitProviderSelector
