import { Modal, ModalProps, Typography } from '@matillion/component-library'

import classes from './ModalSideBar.module.scss'

interface ModalSideBarProps extends Omit<ModalProps, 'ariaLabelledBy'> {
  title: string
  subtext?: string
}

export const ModalSideBar = ({
  children,
  title,
  subtext,
  ...rest
}: ModalSideBarProps) => {
  return (
    <Modal
      {...rest}
      ariaLabelledBy="sidebar-modal-title"
      size="mid"
      className={classes.ModalSideBar}
    >
      <>
        <div className={classes.ModalSideBar__Header}>
          <Typography
            id="sidebar-modal-title"
            format="ts"
            as="h2"
            className={classes.ModalSideBar__Title}
          >
            {title}
          </Typography>
          {subtext && (
            <Typography format="bcs" className={classes.ModalSideBar__SubText}>
              {subtext}
            </Typography>
          )}
        </div>
        {children}
      </>
    </Modal>
  )
}
