import React, { FC, SVGProps } from 'react'
import { useTranslation } from 'react-i18next'

import {
  LoadingSpinner,
  Icon as MFCIcon,
  Tooltip,
  Typography
} from '@matillion/component-library'
import classnames from 'classnames'

import classes from '../ProjectToolbar.module.scss'

export enum ToolbarSelectorType {
  PROJECT = 'PROJECT',
  BRANCH = 'BRANCH',
  ENVIRONMENT = 'ENVIRONMENT'
}

export interface ToolbarSelectorProps {
  isLoading?: boolean
  isError?: boolean
  text: string
  type: ToolbarSelectorType
  'data-testid': string
  icon: FC<SVGProps<SVGSVGElement>>
}

const ToolbarSelector: FC<ToolbarSelectorProps> = ({
  isLoading = false,
  isError = false,
  text,
  type,
  icon: PropsIcon,
  'data-testid': dataTestId
}) => {
  const { t } = useTranslation()
  const SelectorIcon = isError ? MFCIcon.InfoNoBg : PropsIcon

  const tooltip = t('translation:projectToolbox.type', {
    context: type,
    name: text
  })

  return (
    <Tooltip content={tooltip} align={'start'}>
      <div
        className={classnames(classes.Selector, {
          [classes.Selector__Error]: isError
        })}
        data-testid={dataTestId}
      >
        {isLoading && (
          <LoadingSpinner data-testid={`${dataTestId}-loading-spinner`} />
        )}
        {!isLoading && (
          <>
            <SelectorIcon
              className={classes.Selector__IconWrapper}
              data-testid={
                isError ? 'icon-selector-error' : `${dataTestId}-icon`
              }
            />
            <Typography format="bcs" className={classes.Selector__TextWrapper}>
              {text}
            </Typography>
          </>
        )}
      </div>
    </Tooltip>
  )
}

export default ToolbarSelector
