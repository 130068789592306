import { type JestPactOptions } from 'jest-pact'

export const basePath = {
  wts: '/working-tree-service/v1',
  git: '/git-service/v1',
  entitlements: '/v1/entitlements'
}

export const CONSUMER_NAME_GCL = 'git-component-library'
export const PROVIDER_NAME_WORKING_TREE = 'saas-etl-working-tree-service'
export const PROVIDER_NAME_GIT = 'saas-etl-git-service'
export const PROVIDER_NAME_ENTITLEMENTS = 'entitlements-service'
export const PACT_WRITE_MODE = 'update'
export const PACT_CORS_MODE = true

export const GET = 'GET'
export const POST = 'POST'
export const PATCH = 'PATCH'
export const PUT = 'PUT'
export const DELETE = 'DELETE'

export const ACCOUNT_ID = '00000000-0000-0000-0000-000000000000'
export const PROJECT_ID = '6f8bc2f3-96d5-449d-9551-15d0cdaaf413'
export const BRANCH_NAME = 'gcl-test-branch'

export const defaultHeaders = { 'account-id': ACCOUNT_ID }
export const PACT_ID_HEADER = 'Pact-Interaction-ID'
export const APPLICATION_PROBLEM_JSON = 'application/problem+json'
export const APPLICATION_JSON = 'application/json'

export const PACT_OPTIONS_WORKING_TREE: JestPactOptions = {
  consumer: CONSUMER_NAME_GCL,
  provider: PROVIDER_NAME_WORKING_TREE,
  pactfileWriteMode: PACT_WRITE_MODE,
  cors: PACT_CORS_MODE,
  spec: 3,
  logLevel: 'error'
}

export const PACT_OPTIONS_GIT: JestPactOptions = {
  consumer: CONSUMER_NAME_GCL,
  provider: PROVIDER_NAME_GIT,
  pactfileWriteMode: PACT_WRITE_MODE,
  cors: PACT_CORS_MODE,
  spec: 3,
  logLevel: 'error'
}

export const PACT_OPTIONS_ENTITLEMENTS: JestPactOptions = {
  consumer: CONSUMER_NAME_GCL,
  provider: PROVIDER_NAME_ENTITLEMENTS,
  pactfileWriteMode: PACT_WRITE_MODE,
  cors: PACT_CORS_MODE,
  spec: 3,
  logLevel: 'error'
}
