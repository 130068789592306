import { useQuery } from '@tanstack/react-query'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { queryKeys } from '../../queryKeys'
import { useSpringClient } from '../useSpringClient/useSpringClient'
import { ListSchemasResponse } from './types'

const useSchemas = (warehouse: string) => {
  const { projectId, environmentId } = useProjectInfo()
  const client = useSpringClient()

  return useQuery(
    [projectId, environmentId, queryKeys.listSchemas],
    async () => {
      const { data } = await client.get<ListSchemasResponse>(
        `projects/${projectId}/environments/${environmentId}/warehouse/${warehouse}/schemas`
      )
      return data
    },
    {
      enabled: !!warehouse
    }
  )
}

export default useSchemas
