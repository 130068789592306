import { RequestHandler, rest } from 'msw'

import config from 'config'

import { SampleComponentFailResponse, SampleComponentResponse } from '../types'
import mockSampleComponentResponse from './mockSampleComponentResponse.json'

const useSampleComponentHandler: RequestHandler[] = [
  rest.post(`${config.getApiBaseUrl()}/v1/sample/*`, async (req, res, ctx) => {
    const response: SampleComponentResponse = mockSampleComponentResponse

    return res(ctx.status(200), ctx.json(response))
  })
]

const useSampleComponentFailHandler: RequestHandler[] = [
  rest.post(`${config.getApiBaseUrl()}/v1/sample/*`, (req, res, ctx) => {
    const failedResponse: SampleComponentFailResponse = {
      timestamp: '2022-12-15T16:59:56.995Z',
      message:
        'Error encountered attempting to sample transformation component',
      errors: null
    }

    return res(ctx.status(500), ctx.json(failedResponse))
  })
]

const useSampleComponentNoResultsHandler: RequestHandler[] = [
  rest.post(`${config.getApiBaseUrl()}/v1/sample/*`, async (req, res, ctx) => {
    const response: SampleComponentResponse = {
      metadata: mockSampleComponentResponse.metadata,
      rows: []
    }

    return res(ctx.status(200), ctx.json(response))
  })
]

const useSampleComponentLimitHandler: RequestHandler[] = [
  rest.post(`${config.getApiBaseUrl()}/v1/sample/*`, async (req, res, ctx) => {
    const request = await req.json()
    const { limit } = request as Record<string, number>

    const response: SampleComponentResponse = {
      metadata: mockSampleComponentResponse.metadata,
      rows: Array(limit).fill(mockSampleComponentResponse.rows[0])
    }

    return res(ctx.status(200), ctx.json(response))
  })
]

export const useSampleComponentHandlers = [
  ...useSampleComponentHandler,
  ...useSampleComponentFailHandler,
  ...useSampleComponentNoResultsHandler
]

export {
  useSampleComponentHandler,
  useSampleComponentFailHandler,
  useSampleComponentNoResultsHandler,
  useSampleComponentLimitHandler,
  mockSampleComponentResponse
}
