import { useCallback } from 'react'

import { useQueries, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosInstance } from 'axios'

import {
  ProjectInfo,
  useProjectInfo
} from 'hooks/useProjectInfo/useProjectInfo'

import { queryKeys } from '../../queryKeys'
import {
  ComponentSummaryId,
  useCustomComponentSummary
} from '../useGetComponentTree'
import { useSpringClient } from '../useSpringClient/useSpringClient'
import {
  ComponentMetadataResponse,
  CustomComponentMetadataResponse
} from './types'

export const getComponentMetadataQueryKey = (
  { projectId, branchId, agentId }: ProjectInfo,
  id: ComponentSummaryId | null
) => [projectId, branchId, queryKeys.componentMetadata, id, agentId]

export const getCustomComponentMetadataQueryKey = (
  { projectId, branchId, agentId }: ProjectInfo,
  id: ComponentSummaryId | null
) => [projectId, branchId, queryKeys.customComponentMetadata, id, agentId]

export const getComponentMetadata = async (
  client: AxiosInstance,
  cisId: ComponentSummaryId,
  agentId: string
) => {
  const { data } = await client.get<ComponentMetadataResponse>(
    `/components/${encodeURIComponent(cisId)}?agentId=${agentId}`
  )
  return data
}

export const getCustomComponentMetadata = async (
  client: AxiosInstance,
  cisId: ComponentSummaryId,
  agentId: string
): Promise<CustomComponentMetadataResponse> => {
  const { data } = await client.get<CustomComponentMetadataResponse>(
    `/connector-components/${encodeURIComponent(cisId)}?agentId=${agentId}`
  )

  return data
}

export const useGetBulkComponentMetadata = (cisIds: ComponentSummaryId[]) => {
  const projectInfo = useProjectInfo()
  const client = useSpringClient()

  return useQueries({
    queries: cisIds.map((cisId) => ({
      queryKey: getComponentMetadataQueryKey(projectInfo, cisId),
      queryFn: async () => {
        return getComponentMetadata(client, cisId, projectInfo.agentId)
      }
    }))
  })
}

export const useFetchComponentMetadata = () => {
  const queryClient = useQueryClient()
  const { getSummary } = useCustomComponentSummary()
  const projectInfo = useProjectInfo()
  const client = useSpringClient()
  const getMetadata = async (
    componentId: string
  ): Promise<ComponentMetadataResponse> => {
    const customSummary = getSummary(componentId)

    return customSummary
      ? queryClient.fetchQuery({
          queryKey: getCustomComponentMetadataQueryKey(
            projectInfo,
            componentId
          ),
          queryFn: async () =>
            getCustomComponentMetadata(client, componentId, projectInfo.agentId)
        })
      : queryClient.fetchQuery({
          queryKey: getComponentMetadataQueryKey(projectInfo, componentId),
          queryFn: async () =>
            getComponentMetadata(client, componentId, projectInfo.agentId)
        })
  }

  return {
    getComponentMetadata: useCallback(getMetadata, [
      getSummary,
      projectInfo,
      queryClient,
      client
    ])
  }
}

export const useGetComponentMetadata = (cisId?: ComponentSummaryId | null) => {
  const projectInfo = useProjectInfo()
  const client = useSpringClient()
  const { getSummary } = useCustomComponentSummary()

  const isCustomSummary = getSummary(cisId as string)
  const key = isCustomSummary
    ? getCustomComponentMetadataQueryKey(projectInfo, cisId as string)
    : getComponentMetadataQueryKey(projectInfo, cisId as string)
  return useQuery(
    key,
    async () => {
      return isCustomSummary
        ? getCustomComponentMetadata(
            client,
            cisId as string,
            projectInfo.agentId
          )
        : getComponentMetadata(client, cisId as string, projectInfo.agentId)
    },
    { enabled: cisId != null }
  )
}
