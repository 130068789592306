import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'

import { JobState } from '../../job.types'

interface DeleteNotePayload {
  id: number
}

export const deleteNote: CaseReducer<
  JobState,
  PayloadAction<DeleteNotePayload>
> = (state, { payload: { id } }) => {
  delete state.job?.notes?.[id]
}
