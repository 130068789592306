import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Icon, Typography } from '@matillion/component-library'
import classNames from 'classnames'

import { SourceColumn } from 'modules/ParameterEditors/components/MultiExpressionsEditor'

import classes from './Fields.module.scss'

interface Props {
  column: SourceColumn
  onClick: () => unknown
}

const FieldsTableRow: FC<Props> = ({ column, onClick }) => {
  const { t } = useTranslation()

  const textVals = column.alias
    ? [column.alias, column.column]
    : [column.column]

  return (
    <tr
      key={`${column.alias}.${column.column}`}
      className={classes.FieldsTableRow}
      data-testid="field-row"
      onDoubleClick={onClick}
    >
      <td
        className={classNames(
          classes.FieldsTableCellLeft,
          classes.FieldsTableCell
        )}
      >
        <Typography format="bcs" className={classes.FieldName}>
          {textVals.join('.')}
        </Typography>
      </td>
      <td className={classes.FieldsTableCell}>
        <Typography format="bcs" className={classes.FieldType}>
          {column.type}
        </Typography>
        <Button
          aria-label={t('fieldsAndVariablesPanel.addField')}
          onClick={onClick}
          className={classes.AddButton}
        >
          <Icon.Add className={classes.AddButtonIcon} />
        </Button>
      </td>
    </tr>
  )
}

export default FieldsTableRow
