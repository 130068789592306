import { FC } from 'react'

import { Table as T, Typography } from '@matillion/component-library'

import {
  SampleComponentResponse,
  SampleComponentResponseColumns,
  SampleComponentResponseRows
} from 'api/hooks/useSampleComponent/types'

import classes from './SampleComponent.module.scss'

const { Table, TableHead, TableRow, TableHeaderCell, TableBody, TableCell } = T

interface SampleTableProps {
  title: string
  data: SampleComponentResponse
}

export const SampleTable: FC<SampleTableProps> = ({ title, data }) => {
  const getRowData = () => {
    const cells = data.rows.map(
      ({ columnData }: SampleComponentResponseRows, rowIndex: number) => {
        return (
          <TableRow
            key={`row-${rowIndex}`}
            className={classes.SampleComponentResults__Row}
          >
            {data.metadata.map((col, colIndex: number) => {
              const cell = columnData[col.name]

              return (
                <TableCell
                  key={`cell-${rowIndex}-${colIndex}`}
                  className={classes.SampleComponentResults__Cell}
                >
                  {cell.value}
                </TableCell>
              )
            })}
          </TableRow>
        )
      }
    )

    return cells
  }

  return (
    <div
      className={classes.SampleComponentResults}
      data-testid="sample-table-scroll-container"
    >
      <Table
        data-testid="sample-table-data"
        className={classes.SampleComponentResults__Table}
        aria-label={title}
      >
        <TableHead>
          <TableRow className={classes.SampleComponentResults__Row}>
            {data.metadata.map((column: SampleComponentResponseColumns) => (
              <TableHeaderCell
                key={column.name}
                className={classes.SampleComponentResults__HeaderCell}
              >
                <Typography format="bcs" weight="bold">
                  {column.name}
                </Typography>
              </TableHeaderCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{getRowData()}</TableBody>
      </Table>
    </div>
  )
}
