import { QueryClient } from '@tanstack/react-query'

/* istanbul ignore next */
const retry = process.env.REACT_APP_ENVIRONMENT === 'test' ? false : 3

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5, // 5 minutes
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: true,
      retry
    }
  }
})

export default queryClient
