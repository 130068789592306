import { FC, PropsWithChildren } from 'react'

import { EtlComponent } from 'components/EtlComponent/EtlComponent'

import { useComponentInfo } from 'hooks/useComponentInfo/useComponentInfo'
import { useSelectedJobs } from 'hooks/useSelectedJobs'

import { useComponentValidationResult } from 'modules/ComponentValidation/hooks/useComponentValidationResult'

export interface ComponentNodeProps {
  id: string
  isAttached?: boolean
  imageUrl?: string
  isSelected?: boolean
  isIterator?: boolean
  summaryId: string
}

export enum ValidationStatus {
  VALID = 'valid',
  INVALID = 'invalid',
  VALIDATING = 'validating',
  UNVALIDATED = 'unvalidated'
}

const getValidationStatus = ({
  isError,
  isSuccess,
  isLoading
}: {
  isError: boolean
  isSuccess: boolean
  isLoading: boolean
}) => {
  if (isError) return ValidationStatus.INVALID
  if (isSuccess) return ValidationStatus.VALID
  if (isLoading) return ValidationStatus.VALIDATING

  return ValidationStatus.UNVALIDATED
}

export const ComponentNode: FC<PropsWithChildren<ComponentNodeProps>> = ({
  id,
  imageUrl,
  isSelected,
  isAttached,
  isIterator,
  summaryId,
  children
}) => {
  const { navigateToComponent } = useSelectedJobs()
  const { isError, isSuccess, isLoading } = useComponentValidationResult(
    Number(id)
  )
  const validationStatus = getValidationStatus({
    isError,
    isSuccess,
    isLoading
  })

  const componentInfo = useComponentInfo()
  const displayName = componentInfo.getDisplayName(summaryId)

  return (
    <EtlComponent
      imageUrl={imageUrl}
      isSelected={isSelected}
      isIterator={isIterator}
      isAttached={isAttached}
      isError={isError}
      isSuccess={isSuccess}
      isLoading={isLoading}
      showLabel={!isIterator}
      data-status={validationStatus}
      onClick={() => {
        navigateToComponent(id)
      }}
      tooltipContent={displayName}
    >
      {children}
    </EtlComponent>
  )
}
