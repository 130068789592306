import { DataGridColumnProps } from '@matillion/component-library'
import { TFunction } from 'i18next'

import {
  ComponentMetadata,
  ComponentParameter
} from 'api/hooks/useGetComponentMetadata/types'
import { ComponentSummaryId } from 'api/hooks/useGetComponentTree'
import {
  ColumnType,
  EditorColumn
} from 'api/hooks/useGetParameterOptions/types'

import { ComponentInstanceId } from 'job-lib/types/Job'
import { ElementCollection, ElementValueType } from 'job-lib/types/Parameters'

import { GridDropdown } from './components/GridDropdown/GridDropdown'
import { GridExpressionEditor } from './components/GridExpressionEditor/GridExpressionEditor'
import { GridFreeText } from './components/GridFreeText/GridFreeText'
import { GridCell, GridRow } from './types'

export const getHighestRowSlot = (rows: GridRow[]): number => {
  const ids = rows.map(({ id }) => parseInt(id))

  if (!ids.length) return 1

  return Math.max(...ids) + 1
}

export const generateBlobRow = (newSlotId: number, columns: EditorColumn[]) => {
  const blobRow: GridRow = { id: String(newSlotId), cells: {} }
  columns.forEach(({ type, defaultValue }, i) => {
    const newColumnSlot = i + 1
    blobRow.cells[newColumnSlot] = {
      type: parseColumnType(type),
      value: defaultValue ?? '',
      slot: newColumnSlot,
      rowSlot: newSlotId,
      dataType: type
    }
  })
  return blobRow
}

export const parseColumnType = (columnType: ColumnType): ElementValueType => {
  if (columnType === 'INTEGER') {
    return 'INTEGER'
  }

  return 'STRING'
}

export const init = (
  elements: ElementCollection,
  columns: EditorColumn[]
): GridRow[] => {
  /**
   * Parses `elements` from the job to rows that can be consumed by DataGrid
   * It also adds a blob row at the end by default
   */
  const rows = Object.entries(elements).map(([rowSlot, rowData]) => {
    const tempRow: GridRow = { id: rowSlot, cells: {} }

    columns.forEach((column, i) => {
      const columnSlot = i + 1
      const element = rowData.values[columnSlot]

      tempRow.cells[columnSlot] = {
        type: parseColumnType(column.type),
        value: element?.value ?? '',
        slot: columnSlot,
        rowSlot: parseInt(rowSlot),
        dataType: column.type
      }
    })

    return tempRow
  })

  if (!rows.length) {
    // initialise table with an empty row
    const newRowSlot = getHighestRowSlot(rows)
    const blobRow = generateBlobRow(newRowSlot, columns)
    rows.push(blobRow)
  }

  return rows
}

export const generateGridColumns = ({
  columns,
  onChange,
  componentMetaData,
  elements,
  parameter,
  componentInstanceId,
  componentSummaryId,
  t
}: {
  columns: EditorColumn[]
  onChange: (cell: GridCell) => void
  componentMetaData: ComponentMetadata
  elements: ElementCollection
  parameter: ComponentParameter
  componentInstanceId: ComponentInstanceId
  componentSummaryId: ComponentSummaryId
  t: TFunction
}): Array<DataGridColumnProps<GridRow>> => {
  const inputMap = {
    FREETEXT: GridFreeText,
    DROPDOWN: GridDropdown,
    EXPRESSION: GridExpressionEditor
  }

  return columns
    .filter((c) => typeof c.columnEditorType === 'string')
    .map((column, i) => {
      const { resourceID, lookupType, lookupDependencies } = column
      const columnName = column.name ?? ''
      const resourceName = resourceID
        ? t(`componentProperties.columnIds.${resourceID}`)
        : ''
      const name = columnName || resourceName

      // This branch can't be tested as we filter out on column type above
      /* istanbul ignore next */
      const editorType =
        column.columnEditorType === null
          ? undefined
          : inputMap[column.columnEditorType as keyof object]

      return {
        key: `grid-input-${i + 1}`,
        as: editorType,
        title: name,
        style: {
          minWidth: '25rem'
        },
        mapValues: (row) => {
          return {
            ...row.cells[i + 1],
            onChange,
            componentInstanceId,
            componentMetaData,
            editorColumn: column,
            parameter: parameter,
            componentSummaryId,
            parameterName: name,
            lookupType,
            lookupDependencies
          }
        }
      }
    })
}
