import { type RequestHandler, rest } from 'msw'

import { PROJECT_ID } from '__test-utils__/ids'

import { type PullChangesRequest } from 'api/hooks/usePullChanges/types'
import { MockedPullChangesResponses } from 'api/hooks/usePullChanges/usePullChanges.responses'

export const useStubPullChanges = (baseUrl = '', projectId = PROJECT_ID) => {
  return Object.keys(MockedPullChangesResponses).map((branchName) => {
    const url = `${baseUrl}/working-tree-service/v1/projects/${projectId}/branches/${branchName}/merges`

    return rest.post(url, async (req, res, ctx) => {
      const body: PullChangesRequest = await req.json()
      if (body.remoteCommitId === '500') {
        return res(ctx.status(500))
      } else {
        return res(ctx.status(MockedPullChangesResponses[branchName]))
      }
    })
  })
}

export const usePullChangesHandlers: RequestHandler[] = [
  ...useStubPullChanges()
]
