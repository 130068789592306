import { ComponentInstanceId } from 'job-lib/types/Job'

import { LookUpType } from './hooks/useGetComponentMetadata/types'

const queryKeys = {
  customComponentMetadata: 'customComponentMetadata',
  componentMetadata: 'componentMetadata',
  componentSummaries: 'componentSummaries',
  customComponentSummaries: 'customComponentSummaries',
  jobSummaries: 'jobSummaries',
  job: 'job',
  project: 'project',
  taskStatus: 'taskStatus',
  taskDetails: 'taskDetails',
  taskStatusUpdate: 'flow-update',
  parameterOptions: 'parameterOptions',
  branches: 'branches',
  getBranch: 'getBranch',
  getWorkingTreeBranch: 'getWorkingTreeBranch',
  describeMerge: 'getMerge',
  validateComponent: 'validateComponent',
  listEnv: 'listEnvironments',
  listSchemas: 'listSchemas',
  listSchemaTables: 'listSchemaTables',
  listSchemaViews: 'listSchemaViews',
  listProjects: 'listProjects',
  listProjectVariables: 'listProjectVariables',
  merge: 'merge',
  pull: 'pull',
  describePull: 'describePull',
  agentDetails: 'agentDetails'
} as const

export type ParameterOptionsQueryKey = [
  typeof queryKeys.parameterOptions,
  string,
  string,
  string,
  LookUpType | null,
  ComponentInstanceId | null,
  ...Array<string | undefined>
]

export { queryKeys }
