import { useMutation } from '@tanstack/react-query'

import { useSpringClient } from 'api/hooks/useSpringClient/useSpringClient'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { Job } from 'modules/Canvas/hooks/useCanvasModel/useCanvasModel'

export interface UseSaveJob {
  job: Job
}

export const useSaveJob = () => {
  const { projectId, branchId, jobSummaryId, agentId } = useProjectInfo()
  const client = useSpringClient()

  const { mutateAsync } = useMutation(async ({ job }: UseSaveJob) => {
    const { data } = await client.put<Job>(
      `/projects/${projectId}/branches/${branchId}/jobs/${jobSummaryId}`,
      job,
      {
        headers: {
          // agent-id is required for DPL conversion.
          'agent-id': agentId
        }
      }
    )
    return data
  })

  return mutateAsync
}
