import { useCallback } from 'react'

import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { FileStatus } from 'job-lib/types/FileStatus'

import {
  JobSummaries,
  JobSummaryId,
  useGetJobSummariesQueryKey
} from '../useGetJobSummaries'
import { useSpringClient } from '../useSpringClient/useSpringClient'

export const jobSummariesQueryUpdater = (
  deleteJobId: JobSummaryId,
  summaries?: JobSummaries
): JobSummaries | undefined => {
  if (!summaries) {
    return undefined
  }

  return {
    isUsingJGit: summaries?.isUsingJGit ?? false,
    results: summaries.results.map((summary) => {
      const isDeletedJob = summary.jobId === deleteJobId
      return {
        ...summary,
        status: isDeletedJob ? FileStatus.DELETED : summary.status
      }
    })
  }
}

export type DeleteJobArgs = JobSummaryId

export const useDeleteJob = () => {
  const { projectId, branchId } = useProjectInfo()
  const client = useSpringClient()
  const queryClient = useQueryClient()
  const getJobSummariesQueryKey = useGetJobSummariesQueryKey()

  const deleteAJob = useCallback(
    async (jobSummaryId: DeleteJobArgs) => {
      return client.delete(
        `/projects/${projectId}/branches/${branchId}/jobs/${jobSummaryId}`
      )
    },
    [client, projectId, branchId]
  )

  return useMutation(deleteAJob, {
    onSuccess: (_, jobSummaryId) => {
      queryClient.setQueryData<JobSummaries>(
        getJobSummariesQueryKey,
        (summaries) => jobSummariesQueryUpdater(jobSummaryId, summaries)
      )
    }
  })
}
