// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".t0EvmcEnu8ZBbnIouGjM{margin-bottom:10px}", "",{"version":3,"sources":["webpack://./src/components/ConflictsAlert/ConflictsAlert.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA","sourcesContent":[".Alert {\n  margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Alert": "t0EvmcEnu8ZBbnIouGjM"
};
export default ___CSS_LOADER_EXPORT___;
