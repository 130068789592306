import { FC, PropsWithChildren } from 'react'

import classes from './PopOverMenu.module.scss'

export const PopOverSection: FC<PropsWithChildren> = ({ children }) => {
  return (
    <li>
      <ul className={classes.PopOver__Section}>{children}</ul>
    </li>
  )
}
