import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import {
  Button,
  Icon,
  Modal,
  Toaster,
  Typography
} from '@matillion/component-library'

import { useDeleteJob } from 'api/hooks/useDeleteJob/useDeleteJob'
import { JobSummaryId } from 'api/hooks/useGetJobSummaries'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { usePipelineBrowserCommandProvider } from '../../effects/usePipelineBrowserCommand'
import { PipelineTreeReducerType } from '../../reducers/pipelineTreeReducer/types'
import classes from './DeleteFolderModal.module.scss'

export interface DeleteFolderModalProps {
  pipelines: JobSummaryId[]
  path: string
  onClose: () => void
}

const DeleteFolderModal = ({
  path,
  pipelines,
  onClose
}: DeleteFolderModalProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'delete-folder.modal'
  })

  const { onPipelineTreeCommand } = usePipelineBrowserCommandProvider()

  const { makeToast } = Toaster.useToaster()
  const [loading, setLoading] = useState(false)
  const { branchId, projectId } = useProjectInfo()
  const { mutateAsync: deletePipeline } = useDeleteJob()

  const handleSubmit = () => {
    const deletePromises = pipelines.map(async (pipelineId) => {
      return deletePipeline(pipelineId)
    })

    setLoading(true)

    Promise.allSettled(deletePromises).then((results) => {
      const failedDeletes = results.filter(
        (result) => result.status === 'rejected'
      )
      const failedQuantity = failedDeletes.length

      if (failedQuantity > 0) {
        makeToast({
          type: 'error',
          title: t('error.title'),
          message: t('error.message', { failedQuantity })
        })
      } else {
        onPipelineTreeCommand({
          type: PipelineTreeReducerType.REMOVE_FOLDER,
          branch: branchId,
          project: projectId,
          path
        })
      }

      onClose()
    })
  }

  return (
    <Modal
      size="mid"
      setFocus={false}
      onCancel={onClose}
      disableBackdropClick
      id="delete-folder-dialog"
      className={classes.Modal}
      data-testid="delete-folder-modal"
      ariaLabelledBy="delete-folder-title"
    >
      <Icon.Error className={classes.Icon} />

      <Typography format="tm" className={classes.Title}>
        {t('title')}
      </Typography>

      <div className={classes.Text}>
        <Trans
          values={{ folder: path }}
          components={{ bold: <strong /> }}
          i18nKey="delete-folder.modal.are-you-sure"
        />
      </div>

      <Typography format="bcm" className={classes.Text}>
        {t('sub-folders-warning')}
      </Typography>

      <Typography format="bcm" className={classes.Text}>
        {t('schedules-warning')}
      </Typography>

      <Typography format="bcm" className={classes.Text}>
        {t('final-warning')}
      </Typography>

      <div className={classes.Buttons}>
        <Button
          alt="secondary"
          onClick={onClose}
          text={t('cancel')}
          id="delete-folder-cancel"
          data-testid="delete-folder-cancel"
        />
        <Button
          alt="danger"
          type="submit"
          text={t('submit')}
          disabled={loading}
          onClick={handleSubmit}
          id="delete-folder-create"
          data-testid="delete-folder-submit"
        />
      </div>
    </Modal>
  )
}

export default DeleteFolderModal
