import { useCallback } from 'react'

import { useQuery } from '@tanstack/react-query'

import { useSpringClient } from 'api/hooks/useSpringClient/useSpringClient'
import { queryKeys } from 'api/queryKeys'

import { useFlags } from 'hooks/useFlags'
import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { EmeraldId } from 'job-lib/types/Job'
import { JobType } from 'job-lib/types/JobType'

import { ProjectType } from '../useGetProject/types'
import useGetProject from '../useGetProject/useGetProject'
import {
  ComponentSummary,
  ComponentSummaryResult,
  CustomComponentSummary,
  CustomComponentSummaryResult
} from './types'

export type ComponentSummaryCollection = Record<
  EmeraldId | string,
  ComponentSummary
>

export const makeComponentSummaryCollection = (
  summaries: ComponentSummary[]
) => {
  const keyedByObject: ComponentSummaryCollection = {}

  summaries.forEach((cs) => {
    keyedByObject[cs.emeraldId] = cs
  })

  return keyedByObject
}

export const getComponentSummariesQueryKey = (jobType?: JobType) => [
  queryKeys.componentSummaries,
  jobType
]

export const getCustomComponentSummariesQueryKey = (jobType?: JobType) => [
  queryKeys.customComponentSummaries,
  jobType
]

export const useCustomComponentSummaries = (jobType?: JobType) => {
  const client = useSpringClient()
  const project = useGetProject()
  const warehouse = project?.data?.warehouse
  const { agentId } = useProjectInfo()
  const { enableCustomConnectors } = useFlags()

  return useQuery(
    getCustomComponentSummariesQueryKey(jobType),
    async () => {
      const { data } = await client.get<CustomComponentSummaryResult>(
        `/connector-components?agentId=${agentId}`
      )
      data.results.forEach((summary) => {
        summary.componentId = summary.connectorId
      })

      return getFilteredSummaries(
        data,
        warehouse,
        jobType
      ) as CustomComponentSummary[]
    },
    { enabled: !!warehouse && enableCustomConnectors }
  )
}

export const useCustomComponentSummary = () => {
  const summaries = useCustomComponentSummaries()?.data
  return {
    getSummary: useCallback(
      (componentId: string) =>
        summaries?.find((summary) => summary.componentId === componentId),
      [summaries]
    )
  }
}

/**
 * returns an object containing the component summaries indexed by emeraldId
 */
export const useStandardComponentSummaries = (jobType?: JobType) => {
  const client = useSpringClient()
  const project = useGetProject()
  const warehouse = project?.data?.warehouse
  const { agentId } = useProjectInfo()

  return useQuery(
    getComponentSummariesQueryKey(jobType),
    async () => {
      const { data } = await client.get<ComponentSummaryResult>(
        `/components?agentId=${agentId}`
      )

      const filteredComponentSummaries = getFilteredSummaries(
        data,
        warehouse,
        jobType
      )

      return filteredComponentSummaries
    },
    { enabled: !!warehouse }
  )
}

export const useComponentSummaries = (jobType?: JobType) => {
  const {
    data: standardSummaries = [],
    isLoading: isStandardLoading,
    isError: isStandardError
  } = useStandardComponentSummaries(jobType)
  const {
    data: customSummaries = [],
    isLoading: isCustomLoading,
    isError: isCustomError
  } = useCustomComponentSummaries(jobType)
  const { enableCustomConnectors } = useFlags()

  return enableCustomConnectors
    ? {
        isLoading: isCustomLoading || isStandardLoading,
        isError: isStandardError || isCustomError,
        data: [...standardSummaries, ...customSummaries]
      }
    : {
        isLoading: isStandardLoading,
        isError: isStandardError,
        data: standardSummaries
      }
}

const useGetComponentTree = (jobType?: JobType) => {
  const { data, ...rest } = useStandardComponentSummaries(jobType)

  return {
    componentTree: makeComponentSummaryCollection(data ?? []),
    ...rest
  }
}

export default useGetComponentTree
export * from './types'
function getFilteredSummaries(
  data: ComponentSummaryResult,
  warehouse: ProjectType | undefined,
  jobType: JobType | undefined
) {
  return data.results.filter((component) => {
    const isSupportedByWarehouse =
      warehouse && component.dwhTypes
        ? component.dwhTypes.some(
            (wh) => wh.toLowerCase() === warehouse.toLowerCase()
          )
        : true
    const isCorrectJobType = jobType
      ? component.componentType === jobType
      : true

    return isCorrectJobType && isSupportedByWarehouse
  })
}
