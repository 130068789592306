import { useComponentInstanceMetadataQuery } from 'hooks/useComponentInstanceMetadataQuery/useComponentInstanceMetadataQuery'

import { ComponentInstanceId } from 'job-lib/types/Job'

import { isParameterVisible } from 'utils/isParameterVisible'

import { useValidationProvider } from './useValidationProvider'

export const useComponentValidationResult = (
  componentInstanceId: ComponentInstanceId
) => {
  const { getValidationResult } = useValidationProvider()
  const {
    metadata: componentMetadata,
    componentSummaryId: componentId,
    componentInstance
  } = useComponentInstanceMetadataQuery(componentInstanceId)

  const validationResult = getValidationResult({
    componentInstanceId,
    componentMetadata,
    componentSummaryId: componentId
  })

  const componentParameters = componentInstance?.parameters ?? {}

  const visibleFailures = (validationResult.failures ?? []).filter(
    (failure) => {
      const failureSlot = failure.path?.at(1)

      if (!failureSlot) {
        return true
      }

      const isDplSlot = isNaN(parseInt(failureSlot, 10))

      if (isDplSlot) {
        return isParameterVisible(
          failure.path[1],
          componentParameters,
          componentMetadata,
          failure.path.slice(1)
        )
      }

      return isParameterVisible(
        parseInt(failureSlot, 10),
        componentParameters,
        componentMetadata
      )
    }
  )

  return {
    ...validationResult,
    failures: visibleFailures
  }
}
