import React, { FC, ReactElement, ReactNode, useRef, useState } from 'react'
import { ImperativePanelHandle, Panel } from 'react-resizable-panels'

import { Orientation, ResizeableHandler } from 'components/ResizeableHandler'

import { Filterable } from 'modules/SideBar/types'

import { PanelHeader } from './PanelHeader'
import { PanelSearch } from './PanelSearch'
import classes from './PanelWrapper.module.scss'

interface PanelWrapperProps {
  id: string
  header: string
  order: number
  defaultSize?: number
  collapsed?: boolean
  children: ReactElement<Filterable> | Array<ReactElement<Filterable>>
  searchEnabled?: boolean
  contextMenu?: ReactNode
}

const PanelWrapper: FC<PanelWrapperProps> = ({
  id,
  header,
  order,
  collapsed = false,
  children,
  searchEnabled = false,
  contextMenu
}) => {
  const [isOpen, setIsOpen] = useState(!collapsed)
  const [isSearchActive, setSearchActive] = useState<boolean>(false)

  const ariaControlsId = `sectionId-${id}-controls`
  const ariaLabelledById = `sectionId-${id}-header`

  const [searchTerm, setSearchTerm] = useState<string>('')
  const refPanel = useRef<ImperativePanelHandle>(null)

  return (
    <>
      <ResizeableHandler
        orientation={Orientation.HORIZONTAL}
        disabled={!isOpen}
        id={id}
      />

      {isSearchActive ? (
        <PanelSearch
          name={id}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          onClear={() => setSearchActive(false)}
        />
      ) : (
        <PanelHeader
          header={header}
          id={id}
          ariaLabelledById={ariaLabelledById}
          onSearchSelect={() => setSearchActive(true)}
          isExpanded={isOpen}
          onExpandSelect={() => setIsOpen(!isOpen)}
          searchEnabled={searchEnabled}
          contextMenu={contextMenu}
        />
      )}
      {isOpen && (
        <Panel ref={refPanel} order={order} collapsible id={id}>
          <div
            id={ariaControlsId}
            role="region"
            aria-labelledby={ariaLabelledById}
            className={classes.PanelContent}
            data-testid={`${id}-browser`}
            tabIndex={0}
          >
            {React.Children.map(children, (child: ReactElement<Filterable>) => {
              if (React.isValidElement(child)) {
                return React.cloneElement(child, {
                  searchTerm: isSearchActive ? searchTerm : undefined,
                  expanded: isSearchActive && !!searchTerm
                })
              }

              return child
            })}
          </div>
        </Panel>
      )}
    </>
  )
}

export default PanelWrapper
