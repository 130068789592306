import { rest } from 'msw'

import {
  MOCK_NEW_CREATED_JOB_SUMMARY_ID,
  MOCK_ORCHESTRATION_JOB_NAME,
  MOCK_TRANSFORMATION_JOB_NAME
} from '__test-utils__/ids'

import config from 'config'

import { JobType } from 'job-lib/types/JobType'

import {
  mockNewOrchestrationJob,
  mockNewTransformationJob
} from '../../useCreateJob/__handlers__/mockCreateJobData'
import { mockOrchestrationJob } from './mockOrchestrationJob'
import { mockTransformationJob } from './mockTransformationJob'

export const useGetJobHandlers = [
  rest.get(
    `${config.getApiBaseUrl()}/v1/projects/*/branches/*/jobs/:jobId`,
    (req, res, ctx) => {
      const { jobId } = req.params

      if (jobId.includes(MOCK_ORCHESTRATION_JOB_NAME)) {
        return res(ctx.json(mockOrchestrationJob))
      }

      if (jobId.includes(MOCK_TRANSFORMATION_JOB_NAME)) {
        return res(ctx.json(mockTransformationJob))
      }

      const type = (jobId as string).split('.')[1]

      if (jobId === `${MOCK_NEW_CREATED_JOB_SUMMARY_ID}.TRANSFORMATION`) {
        const body =
          type === JobType.Transformation
            ? mockNewTransformationJob
            : mockNewOrchestrationJob
        return res(ctx.json(body))
      }

      return res(ctx.status(500))
    }
  )
]
