import { ChangeEvent, FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Field, Input } from '@matillion/component-library'

import {
  ComponentMetadata,
  ComponentParameter
} from 'api/hooks/useGetComponentMetadata/types'
import { ColumnEditorType } from 'api/hooks/useGetParameterOptions/types'

import { DynamicDropdown } from 'components/DynamicDropdown/DynamicDropdown'
import {
  ParameterOverlayButton,
  ParameterOverlayFooter,
  ParameterOverlayHeader
} from 'components/ParameterOverlay'

import { ElementCollection } from 'job-lib/types/Parameters'

import { ParameterOptions } from 'modules/ParameterEditors/types'

import classes from './MultiPropertiesEditor.module.scss'
import { convertToElements, getDefaultValues } from './utils'

export interface MultiPropertiesEditorProps extends ParameterOptions {
  inputId: string
  elements: ElementCollection
  parameter: ComponentParameter
  parameterName: string
  componentMetadata: ComponentMetadata
  componentInstanceId: number
  componentSummaryId: string
  onDone: (editedValue: ElementCollection) => void
}

export const MultiPropertiesEditor: FC<MultiPropertiesEditorProps> = ({
  editorColumns,
  inputId,
  elements,
  parameter,
  parameterName,
  componentMetadata,
  componentInstanceId,
  componentSummaryId,
  onDone
}) => {
  const { t } = useTranslation()

  const [values, setValues] = useState(
    getDefaultValues(editorColumns, elements)
  )

  const onSubmit = () => {
    onDone(convertToElements(values))
  }

  return (
    <>
      <ParameterOverlayHeader title={parameterName} />
      <div className={classes.MultiPropertiesEditor__FieldContainer}>
        {editorColumns.map((column) => {
          const name = column.name
            ? column.name
            : t(`componentProperties.columnIds.${column.resourceID as string}`)

          if (!column.name && !column.resourceID) return null

          const commonProps = {
            key: name,
            id: name,
            name: name,
            title: name,
            'aria-label': name,
            placeholder: name
          }

          const fieldValue = values[name]?.value ?? ''

          switch (column.columnEditorType) {
            case ColumnEditorType.Dropdown:
              return (
                <Field
                  {...commonProps}
                  inputComponent={DynamicDropdown}
                  inputId={`${inputId}-${name}`}
                  componentMetadata={componentMetadata}
                  componentSummaryId={componentSummaryId}
                  parameter={{
                    ...column,
                    id: componentInstanceId.toString(),
                    metlSlot: parameter.metlSlot
                  }}
                  parameterName={parameterName}
                  value={fieldValue}
                  onEdit={(selectedValue: string) => {
                    setValues((existingValues) => ({
                      ...existingValues,
                      [name]: {
                        value: selectedValue ?? '',
                        type: column.type
                      }
                    }))
                  }}
                />
              )

            case ColumnEditorType.Freetext:
              return (
                <Field
                  {...commonProps}
                  inputComponent={Input}
                  value={fieldValue}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setValues((v) => ({
                      ...v,
                      [name]: {
                        value: e.target.value ?? '',
                        type: column.type
                      }
                    }))
                  }}
                />
              )

            default:
              return null
          }
        })}
      </div>
      <ParameterOverlayFooter>
        <ParameterOverlayButton onClick={onSubmit} text={t('common.save')} />
      </ParameterOverlayFooter>
    </>
  )
}
