import { createContext } from 'react'

import { type GitContextBag } from './types'
import axios from 'axios'

export const GitContext = createContext<GitContextBag>({
  isNativeGit: true,
  hasUncommittedChanges: false,
  designerClient: axios.create(),
  branch: 'branch-not-initialised',
  project: 'project-not-initialised',
  featureFlags: {
    pullChangesV2: false
  },
  refreshWorkingTreeStatus: () => {
    console.debug(
      'Cannot refresh WTS status as the GitContext is not initialised'
    )
  }
})
