import { useTranslation } from 'react-i18next'

import { DataGrid } from '@matillion/component-library'
import classNames from 'classnames'

import ConflictVersionSelector from '../ConflictVersionSelector/ConflictVersionSelector'
import { type ConflictVersion } from '../ConflictVersionSelector/types'
import JobInformation from '../JobInformation/JobInformation'
import classes from './ConflictResolutionTable.module.scss'
import { type ConflictResolutionTableProps, type Row } from './types'

const ConflictResolutionTable = ({
  selections,
  onVersionSelection
}: ConflictResolutionTableProps) => {
  const { t } = useTranslation()

  const wrapperClasses = [classes.Wrapper]

  const shouldScroll = selections.length > 4
  if (shouldScroll) {
    wrapperClasses.push(classes.FixedHeight)
  }

  return (
    <div className={classNames(wrapperClasses)}>
      <DataGrid
        data-testid="conflict-resolution-table"
        compact
        columns={[
          {
            key: 'file',
            sortable: false,
            as: JobInformation,
            title: t('git.merge.table.headers.file'),
            mapValues: (row: Row) => ({
              name: row.file
            })
          },
          {
            key: 'version',
            sortable: false,
            as: ConflictVersionSelector,
            title: t('git.merge.table.headers.version'),
            mapValues: (row: Row) => ({
              version: row.version,
              onSelect: (version: ConflictVersion) => { onVersionSelection({ fileName: row.file, version }) }
            })
          }
        ]}
        hasFixedHeader={shouldScroll}
        rows={selections.map(({ fileName, version }) => ({
          id: fileName,
          file: fileName,
          version
        }))}
        headerClassName={classes.Header}
        rowClassName={classes.Row}
        className={classes.Table}
      />
    </div>
  )
}

export default ConflictResolutionTable
