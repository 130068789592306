import { Cardinality, ConnectionPortType } from 'job-lib/types/Components'
import { JobType } from 'job-lib/types/JobType'

import {
  ComponentEditorTabType,
  ComponentMetadataResponse,
  EditorType,
  ExportVariableType,
  LookUpType,
  ParameterDataType,
  UseVariable,
  VisibleWhenOperator
} from '../../types'

export const mockCreateFileFormatMetaData: ComponentMetadataResponse = {
  componentId: 'create-file-format',
  metadata: {
    schemaVersion: 1,
    emeraldId: '655623811',
    componentType: JobType.Orchestration,
    inputPorts: [
      {
        portId: ConnectionPortType.INPUT,
        cardinality: Cardinality.ONE
      }
    ],
    outputPorts: [
      {
        portId: ConnectionPortType.SUCCESS,
        cardinality: Cardinality.MANY
      },
      {
        portId: ConnectionPortType.UNCONDITIONAL,
        cardinality: Cardinality.MANY
      },
      {
        portId: ConnectionPortType.FAILURE,
        cardinality: Cardinality.MANY
      }
    ],
    deprecated: false,
    exports: [
      ExportVariableType.COMPLETED_AT,
      ExportVariableType.COMPONENT_NAME,
      ExportVariableType.DURATION,
      ExportVariableType.MESSAGE,
      ExportVariableType.ROW_COUNT,
      ExportVariableType.STARTED_AT,
      ExportVariableType.STATUS
    ],
    editorTabs: [
      ComponentEditorTabType.PROPERTIES,
      ComponentEditorTabType.SQL,
      ComponentEditorTabType.EXPORT,
      ComponentEditorTabType.HELP
    ],
    parameters: [
      {
        id: 'component-name',
        dplID: 'componentName',
        metlSlot: 1,
        useVariable: UseVariable.NO,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'create-replace',
        dplID: 'createReplace',
        metlSlot: 6,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'database',
        dplID: 'database',
        metlSlot: 7,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'schema',
        dplID: 'schema',
        metlSlot: 8,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'file-format-name',
        dplID: 'fileFormatName',
        metlSlot: 5,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'file-type',
        dplID: 'fileType',
        metlSlot: 11,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'create-replace',
            value: ['Drop', 'Drop if exists'],
            operator: VisibleWhenOperator.NOT_IN
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'compression',
        dplID: 'compression',
        metlSlot: 12,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'create-replace',
            value: ['Drop', 'Drop if exists'],
            operator: VisibleWhenOperator.NOT_IN
          },
          {
            param: 'file-type',
            value: 'ORC',
            operator: VisibleWhenOperator.NOT_EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'record-delimiter',
        dplID: 'recordDelimiter',
        metlSlot: 20,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: [
          {
            param: 'create-replace',
            value: ['Drop', 'Drop if exists'],
            operator: VisibleWhenOperator.NOT_IN
          },
          {
            param: 'file-type',
            value: 'CSV',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'field-delimiter',
        dplID: 'fieldDelimiter',
        metlSlot: 21,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: [
          {
            param: 'create-replace',
            value: ['Drop', 'Drop if exists'],
            operator: VisibleWhenOperator.NOT_IN
          },
          {
            param: 'file-type',
            value: 'CSV',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'skip-header',
        dplID: 'skipHeader',
        metlSlot: 22,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: [
          {
            param: 'create-replace',
            value: ['Drop', 'Drop if exists'],
            operator: VisibleWhenOperator.NOT_IN
          },
          {
            param: 'file-type',
            value: 'CSV',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'skip-blank-lines',
        dplID: 'skipBlankLines',
        metlSlot: 35,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'create-replace',
            value: ['Drop', 'Drop if exists'],
            operator: VisibleWhenOperator.NOT_IN
          },
          {
            param: 'file-type',
            value: 'CSV',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'date-format',
        dplID: 'dateFormat',
        metlSlot: 23,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: [
          {
            param: 'create-replace',
            value: ['Drop', 'Drop if exists'],
            operator: VisibleWhenOperator.NOT_IN
          },
          {
            param: 'file-type',
            value: 'CSV',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'time-format',
        dplID: 'timeFormat',
        metlSlot: 24,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: [
          {
            param: 'create-replace',
            value: ['Drop', 'Drop if exists'],
            operator: VisibleWhenOperator.NOT_IN
          },
          {
            param: 'file-type',
            value: 'CSV',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'timestamp-format',
        dplID: 'timestampFormat',
        metlSlot: 25,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: [
          {
            param: 'create-replace',
            value: ['Drop', 'Drop if exists'],
            operator: VisibleWhenOperator.NOT_IN
          },
          {
            param: 'file-type',
            value: 'CSV',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'escape',
        dplID: 'escape',
        metlSlot: 27,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: [
          {
            param: 'create-replace',
            value: ['Drop', 'Drop if exists'],
            operator: VisibleWhenOperator.NOT_IN
          },
          {
            param: 'file-type',
            value: 'CSV',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'escape-unenclosed-field',
        dplID: 'escapeUnenclosedField',
        metlSlot: 28,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: [
          {
            param: 'create-replace',
            value: ['Drop', 'Drop if exists'],
            operator: VisibleWhenOperator.NOT_IN
          },
          {
            param: 'file-type',
            value: 'CSV',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'trim-space-2',
        dplID: 'trimSpace2',
        metlSlot: 29,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'create-replace',
            value: ['Drop', 'Drop if exists'],
            operator: VisibleWhenOperator.NOT_IN
          },
          {
            param: 'file-type',
            value: 'CSV',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'field-optionally-enclosed',
        dplID: 'fieldOptionallyEnclosed',
        metlSlot: 30,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: [
          {
            param: 'create-replace',
            value: ['Drop', 'Drop if exists'],
            operator: VisibleWhenOperator.NOT_IN
          },
          {
            param: 'file-type',
            value: 'CSV',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'null-if-2',
        dplID: 'nullIf2',
        metlSlot: 31,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.GRID,
        editorType: EditorType.GRID_EDITOR,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'create-replace',
            value: ['Drop', 'Drop if exists'],
            operator: VisibleWhenOperator.NOT_IN
          },
          {
            param: 'file-type',
            value: 'CSV',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'error-on-column-count-mismatch',
        dplID: 'errorOnColumnCountMismatch',
        metlSlot: 32,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'create-replace',
            value: ['Drop', 'Drop if exists'],
            operator: VisibleWhenOperator.NOT_IN
          },
          {
            param: 'file-type',
            value: 'CSV',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'empty-field-as-null',
        dplID: 'emptyFieldAsNull',
        metlSlot: 33,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'create-replace',
            value: ['Drop', 'Drop if exists'],
            operator: VisibleWhenOperator.NOT_IN
          },
          {
            param: 'file-type',
            value: 'CSV',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'replace-invalid-characters',
        dplID: 'replaceInvalidCharacters',
        metlSlot: 36,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'create-replace',
            value: ['Drop', 'Drop if exists'],
            operator: VisibleWhenOperator.NOT_IN
          },
          {
            param: 'file-type',
            value: 'CSV',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'encoding-type',
        dplID: 'encodingType',
        metlSlot: 34,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'create-replace',
            value: ['Drop', 'Drop if exists'],
            operator: VisibleWhenOperator.NOT_IN
          },
          {
            param: 'file-type',
            value: 'CSV',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'enable-octal',
        dplID: 'enableOctal',
        metlSlot: 40,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'create-replace',
            value: ['Drop', 'Drop if exists'],
            operator: VisibleWhenOperator.NOT_IN
          },
          {
            param: 'file-type',
            value: 'JSON',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'allow-duplicates',
        dplID: 'allowDuplicates',
        metlSlot: 41,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'create-replace',
            value: ['Drop', 'Drop if exists'],
            operator: VisibleWhenOperator.NOT_IN
          },
          {
            param: 'file-type',
            value: 'JSON',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'strip-outer-array',
        dplID: 'stripOuterArray',
        metlSlot: 42,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'create-replace',
            value: ['Drop', 'Drop if exists'],
            operator: VisibleWhenOperator.NOT_IN
          },
          {
            param: 'file-type',
            value: 'JSON',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'strip-null-values',
        dplID: 'stripNullValues',
        metlSlot: 43,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'create-replace',
            value: ['Drop', 'Drop if exists'],
            operator: VisibleWhenOperator.NOT_IN
          },
          {
            param: 'file-type',
            value: 'JSON',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'ignore-utf8-errors',
        dplID: 'ignoreUtf8Errors',
        metlSlot: 44,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'create-replace',
            value: ['Drop', 'Drop if exists'],
            operator: VisibleWhenOperator.NOT_IN
          },
          {
            param: 'file-type',
            value: 'JSON',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'nest-columns',
        dplID: 'nestColumns',
        metlSlot: 45,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'create-replace',
            value: ['Drop', 'Drop if exists'],
            operator: VisibleWhenOperator.NOT_IN
          },
          {
            param: null,
            value: null,
            operator: VisibleWhenOperator.NEVER
          },
          {
            param: 'file-type',
            value: 'JSON',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'null-if-3',
        dplID: 'nullIf3',
        metlSlot: 46,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.GRID,
        editorType: EditorType.GRID_EDITOR,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'create-replace',
            value: ['Drop', 'Drop if exists'],
            operator: VisibleWhenOperator.NOT_IN
          },
          {
            param: 'file-type',
            value: 'JSON',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'trim-space-3',
        dplID: 'trimSpace3',
        metlSlot: 47,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'create-replace',
            value: ['Drop', 'Drop if exists'],
            operator: VisibleWhenOperator.NOT_IN
          },
          {
            param: 'file-type',
            value: 'JSON',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'null-if-4',
        dplID: 'nullIf4',
        metlSlot: 50,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.GRID,
        editorType: EditorType.GRID_EDITOR,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'create-replace',
            value: ['Drop', 'Drop if exists'],
            operator: VisibleWhenOperator.NOT_IN
          },
          {
            param: 'file-type',
            value: 'AVRO',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'trim-space-4',
        dplID: 'trimSpace4',
        metlSlot: 51,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'create-replace',
            value: ['Drop', 'Drop if exists'],
            operator: VisibleWhenOperator.NOT_IN
          },
          {
            param: 'file-type',
            value: 'AVRO',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'null-if-1',
        dplID: 'nullIf1',
        metlSlot: 70,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.GRID,
        editorType: EditorType.GRID_EDITOR,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'create-replace',
            value: ['Drop', 'Drop if exists'],
            operator: VisibleWhenOperator.NOT_IN
          },
          {
            param: 'file-type',
            value: 'ORC',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'trim-space-1',
        dplID: 'trimSpace1',
        metlSlot: 71,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'create-replace',
            value: ['Drop', 'Drop if exists'],
            operator: VisibleWhenOperator.NOT_IN
          },
          {
            param: 'file-type',
            value: 'ORC',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'null-if',
        dplID: 'nullIf',
        metlSlot: 66,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.GRID,
        editorType: EditorType.GRID_EDITOR,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'create-replace',
            value: ['Drop', 'Drop if exists'],
            operator: VisibleWhenOperator.NOT_IN
          },
          {
            param: 'file-type',
            value: 'PARQUET',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'trim-space',
        dplID: 'trimSpace',
        metlSlot: 67,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'create-replace',
            value: ['Drop', 'Drop if exists'],
            operator: VisibleWhenOperator.NOT_IN
          },
          {
            param: 'file-type',
            value: 'PARQUET',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'ignore-utf8-errors-1',
        dplID: 'ignoreUtf8Errors1',
        metlSlot: 60,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'create-replace',
            value: ['Drop', 'Drop if exists'],
            operator: VisibleWhenOperator.NOT_IN
          },
          {
            param: 'file-type',
            value: 'XML',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'preserve-space',
        dplID: 'preserveSpace',
        metlSlot: 61,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'create-replace',
            value: ['Drop', 'Drop if exists'],
            operator: VisibleWhenOperator.NOT_IN
          },
          {
            param: 'file-type',
            value: 'XML',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'strip-outer-element',
        dplID: 'stripOuterElement',
        metlSlot: 62,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'create-replace',
            value: ['Drop', 'Drop if exists'],
            operator: VisibleWhenOperator.NOT_IN
          },
          {
            param: 'file-type',
            value: 'XML',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'disable-snowflake-data',
        dplID: 'disableSnowflakeData',
        metlSlot: 63,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'create-replace',
            value: ['Drop', 'Drop if exists'],
            operator: VisibleWhenOperator.NOT_IN
          },
          {
            param: 'file-type',
            value: 'XML',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'disable-auto-convert',
        dplID: 'disableAutoConvert',
        metlSlot: 64,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'create-replace',
            value: ['Drop', 'Drop if exists'],
            operator: VisibleWhenOperator.NOT_IN
          },
          {
            param: 'file-type',
            value: 'XML',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      }
    ]
  }
}
