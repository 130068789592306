import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ComponentMetadataParameterId } from 'api/hooks/useGetComponentMetadata/types'
import { ComponentSummaryId } from 'api/hooks/useGetComponentTree'

import {
  componentNameParameter,
  orchestrationJobNameParameter,
  runOrchestrationComponentId
} from 'job-lib/cisIds/knownComponentParameters'
import { getComponentLabel } from 'job-lib/job-functions/getComponentLabel'
import { ComponentInstance } from 'job-lib/types/Job'

import { useWorkingCopy } from 'modules/EtlDesigner/hooks/useWorkingCopy'

export type Validator = (value: string) => string | null

export interface Validations {
  [key: ComponentMetadataParameterId]: Validator
}

const useValidateRunOrchestrationParameter = (): Validations => {
  const { summary } = useWorkingCopy()
  const { t } = useTranslation('translation', {
    keyPrefix: 'parameterEditor.errors'
  })

  return useMemo<Validations>(
    () => ({
      [orchestrationJobNameParameter]: (value) => {
        if (summary?.name === value) {
          return t('runOrchestrationCannotRunItself')
        }

        return null
      }
    }),
    [summary, t]
  )
}

const useComponentNameValidator = (): Validator => {
  const { job } = useWorkingCopy()
  const { t } = useTranslation('translation', {
    keyPrefix: 'parameterEditor.errors'
  })

  return (value: string) => {
    if (job) {
      const componentNames: string[] = []
      Object.entries(job.components).forEach(
        ([id, component]: [string, ComponentInstance]) => {
          componentNames.push(getComponentLabel(component))
        }
      )

      if (componentNames.filter((v) => v === value).length > 1) {
        return t('duplicateComponentNames')
      }
    }
    return null
  }
}

export interface ClientParameterValidationProps {
  parameterId?: ComponentMetadataParameterId
  componentSummaryId?: ComponentSummaryId
  value?: string[] | string
}

const firstError = (vals: string[], validators: Validator[]) => {
  for (const val of vals) {
    for (const validator of validators) {
      const error = validator(val)
      if (error) {
        return error
      }
    }
  }
  return null
}

export const useClientParameterValidation = () => {
  const orchestrationValidations = useValidateRunOrchestrationParameter()
  const componentNameValidator = useComponentNameValidator()

  return ({
    componentSummaryId: componentId,
    parameterId,
    value
  }: ClientParameterValidationProps) => {
    if (value == null) {
      return null
    }

    const valArray = Array.isArray(value) ? value : [value]
    const validators = []

    if (componentId === runOrchestrationComponentId) {
      if (parameterId && orchestrationValidations[parameterId]) {
        validators.push(orchestrationValidations[parameterId])
      }
    }

    if (parameterId === componentNameParameter) {
      validators.push(componentNameValidator)
    }

    return firstError(valArray, validators)
  }
}
