// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Ys9bUhfAVASwiFkUbeg7{border-radius:16px}", "",{"version":3,"sources":["webpack://./src/modules/BringYourOwnGit/components/GitProviderAuthModal/GitProviderAuthModal.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA","sourcesContent":[".modal {\n  border-radius: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "Ys9bUhfAVASwiFkUbeg7"
};
export default ___CSS_LOADER_EXPORT___;
