import {
  ErrorStatuses,
  Fields,
  FormState,
  ReducerActions
} from 'modules/ManageVariables/types'

import { errorMessages } from '../errorMessages'
import { ReducerAction } from './formReducer'
import { isFormValid } from './isFormValid'

export const updateValue = (
  state: FormState,
  action: ReducerAction
): FormState => {
  if (action.type !== ReducerActions.UPDATE_FIELD) {
    return state
  }

  if (action.field === Fields.NAME) {
    throw new Error(
      'The name field requires stricter validation checking, updateValue cannot be used in this instance'
    )
  }

  let errorStatus = ErrorStatuses.NO_ERROR

  const { field, value } = action
  const isNonInputField = [
    Fields.VARIABLE_SCOPE,
    Fields.VARIABLE_TYPE,
    Fields.VISIBILITY
  ].includes(field)

  if (isNonInputField && !value) {
    errorStatus = ErrorStatuses.SELECT_OPTION
  }
  const nameField =
    field === Fields.VARIABLE_SCOPE
      ? {
          isValid: null,
          value: '',
          error: errorMessages[ErrorStatuses.EMPTY]
        }
      : state[Fields.NAME]

  const updatedState = {
    ...state,
    [field]: {
      isValid: errorStatus === ErrorStatuses.NO_ERROR,
      value,
      error: errorMessages[errorStatus]
    },
    [Fields.NAME]: nameField
  }

  return {
    ...updatedState,
    isFormValid: isFormValid(updatedState)
  }
}
