import { FC, useEffect } from 'react'

import { DataGrid } from '@matillion/component-library'

import classes from '../../UrlEditor.module.scss'
import { GridColumns, GridRow, SortOrder } from '../hooks/useGrid'

interface ContainerGridProps {
  fullPath: string[]
  columns: GridColumns
  rows: GridRow[]
  setSort: (sort: SortOrder) => void
  setFullPath: (path: string[]) => void
  setWarehouseUrl: (url: string) => void
}

const ContainerGrid: FC<ContainerGridProps> = ({ columns, rows, setSort }) => {
  useEffect(() => {
    return () => setSort(SortOrder.ASC)
  }, [setSort])

  return (
    <DataGrid
      columns={columns}
      rows={rows}
      className={classes['Buckets--Table']}
      defaultSort={{ container: SortOrder.ASC }}
      onSort={(state) => setSort(state.container as SortOrder)}
      hasFixedHeader
      compact
    />
  )
}

export default ContainerGrid
