import { FC } from 'react'

import { Tooltip, Typography } from '@matillion/component-library'
import classNames from 'classnames'

import classes from './RowTypography.module.scss'

export interface Props {
  children: string
  taskKey: string
}

const RowTypography: FC<Props> = ({ children, taskKey }) => {
  return (
    <Tooltip content={children} onlyShowOnOverflow>
      {/* We need this div because the tooltip uses cloneElement and typography breaks that contract */}
      <div
        data-testid={`task-history-row-${taskKey}`}
        className={classNames(classes.RowTypography, 'u-truncate-text')}
      >
        <Typography className="u-truncate-text" format="bcs" as="span">
          {children}
        </Typography>
      </div>
    </Tooltip>
  )
}

export default RowTypography
