import { FC } from 'react'

import { Typography } from '@matillion/component-library'

import { ComponentMetadata } from 'api/hooks/useGetComponentMetadata/types'

import { useComponentInfo } from 'hooks/useComponentInfo/useComponentInfo'

import { ComponentInstance } from 'job-lib/types/Job'

import classes from '../../PropertiesPanel.module.scss'
import { ComponentStatus } from '../ComponentStatus'

interface ComponentOverviewProps {
  componentId: string
  componentInstance: ComponentInstance
  metadata: ComponentMetadata
}

export const ComponentOverview: FC<ComponentOverviewProps> = ({
  componentId,
  componentInstance,
  metadata
}) => {
  const { getDisplayName, getIcon } = useComponentInfo()
  const icon = getIcon(componentId)
  const displayName = getDisplayName(componentId)

  return (
    <div className={classes.ComponentOverview}>
      <div
        className={classes.ComponentOverview__Name}
        data-testid="component-name"
      >
        <span className={classes.ComponentOverview__IconBefore}>
          <img
            src={icon}
            className={classes.ComponentOverview__Image}
            alt={displayName}
          />
        </span>

        <Typography className="u-truncate-text" format="bcs">
          {displayName}
        </Typography>
      </div>

      <ComponentStatus componentInstance={componentInstance} />
    </div>
  )
}
