import { type ApiContextBag, type DeploymentEnvironment } from './context/types'

export const apiConfig: Record<DeploymentEnvironment, ApiContextBag> = {
  test: {
    audience: 'https://api.platform-dev.matillion.com',
    wts: '',
    git: '',
    entitlements: ''
  },
  dev: {
    audience: 'https://api.platform-dev.matillion.com',
    wts: 'https://working-tree.{{region}}.platform-dev.matillion.com',
    git: 'https://git.{{region}}.platform-dev.matillion.com',
    entitlements: 'https://entitlements-{{region}}.core-dev.matillion.com'
  },
  preprod: {
    audience: 'https://api.platform-preprod.matillion.com',
    wts: 'https://working-tree.{{region}}.platform-preprod.matillion.com',
    git: 'https://git.{{region}}.platform-preprod.matillion.com',
    entitlements: 'https://entitlements-{{region}}.core-preprod.matillion.com'
  },
  perf: {
    audience: 'https://api.platform-dev.matillion.com',
    wts: 'https://working-tree.{{region}}.platform-perf.matillion.com',
    git: 'https://git.{{region}}.platform-perf.matillion.com',
    entitlements: 'https://entitlements-{{region}}.core-dev.matillion.com'
  },
  prod: {
    audience: 'https://api.platform.matillion.com',
    wts: 'https://working-tree.{{region}}.platform.matillion.com',
    git: 'https://git.{{region}}.platform.matillion.com',
    entitlements: 'https://entitlements-{{region}}.core.matillion.com'
  }
}
