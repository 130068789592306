import {
  ComponentEditorTabType,
  ComponentMetadataResponse,
  EditorType,
  ExportVariableType,
  LookUpType,
  ParameterDataType,
  UseVariable,
  VisibleWhenOperator
} from 'api/hooks/useGetComponentMetadata/types'

import { JobType } from 'job-lib/types/JobType'

import { ColumnEditorType } from '../../../useGetParameterOptions/types'

export const mockTestComponentMetadata: ComponentMetadataResponse = {
  componentId: 'dev-test-component',
  metadata: {
    schemaVersion: 1,
    emeraldId: '9999999999',
    componentType: JobType.Orchestration,
    inputPorts: [],
    outputPorts: [],
    deprecated: false,
    exports: [
      ExportVariableType.COMPLETED_AT,
      ExportVariableType.COMPONENT_NAME,
      ExportVariableType.DURATION,
      ExportVariableType.MESSAGE,
      ExportVariableType.ROW_COUNT,
      ExportVariableType.STARTED_AT,
      ExportVariableType.STATUS
    ],
    editorTabs: [
      ComponentEditorTabType.PROPERTIES,
      ComponentEditorTabType.EXPORT,
      ComponentEditorTabType.HELP
    ],
    parameters: [
      {
        id: 'free-text',
        dplID: 'freeText',
        metlSlot: 1,
        useVariable: UseVariable.NO,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: null
      },
      {
        id: 'free-text-multiline',
        dplID: 'freeTextMultiline',
        metlSlot: 2,
        useVariable: UseVariable.NO,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.FREETEXT_MULTI_LINE,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: null
      },
      {
        id: 'multi-expressions',
        dplID: 'multiExpressions',
        metlSlot: 3,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.GRID,
        editorType: EditorType.MULTI_EXPRESSIONS_EDITOR,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: [
          {
            name: 'Calculation',
            type: ParameterDataType.TEXT,
            options: [],
            columnEditorType: ColumnEditorType.Freeform,
            defaultValue: null,
            lookupType: null,
            lookupDependencies: null
          },
          {
            name: 'Output Column',
            type: ParameterDataType.TEXT,
            options: [],
            columnEditorType: ColumnEditorType.Freetext,
            defaultValue: null,
            lookupType: null,
            lookupDependencies: null
          }
        ]
      },
      {
        id: 'joins',
        dplID: 'joins',
        metlSlot: 4,
        useVariable: UseVariable.NO,
        dataType: ParameterDataType.GRID,
        editorType: EditorType.GRID_EDITOR,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: [
          {
            name: 'Join Table',
            type: 'TEXT',
            options: [],
            columnEditorType: ColumnEditorType.Freetext,
            defaultValue: null,
            lookupType: null,
            lookupDependencies: null
          },
          {
            name: 'Join Alias',
            type: 'TEXT',
            options: [],
            columnEditorType: ColumnEditorType.Freetext,
            defaultValue: null,
            lookupType: null,
            lookupDependencies: null
          },
          {
            name: 'Join Type',
            type: 'TEXT',
            options: ['Inner', 'Left', 'Right', 'Full'],
            columnEditorType: ColumnEditorType.Dropdown,
            defaultValue: null,
            lookupType: null,
            lookupDependencies: null
          }
        ]
      },
      {
        id: 'join-expressions',
        dplID: 'joinExpressions',
        metlSlot: 5,
        useVariable: UseVariable.NO,
        dataType: ParameterDataType.GRID,
        editorType: EditorType.JOIN_EDITOR,
        lookupType: LookUpType.DYNAMIC_TRANSFORM_JOIN_EXPRESSIONS,
        lookupDependencies: ['transform.sql', 'param.joins'],
        visibleWhen: null,
        optional: false,
        staticOptions: null
      },
      {
        id: 's3-object-prefix',
        dplID: 's3ObjectPrefix',
        metlSlot: 4,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.S3_URL,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: [
          {
            param: 'stage',
            value: '[Custom]',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'columns',
        dplID: 'columns',
        metlSlot: 6,
        useVariable: UseVariable.NO,
        dataType: ParameterDataType.GRID,
        editorType: EditorType.NESTED_DATA_PICKER_SF,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: null
      }
    ]
  }
}
