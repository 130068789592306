import { type FunctionComponent } from 'react'

import { t } from 'i18next'

import { OrchestrationJob, TransformationJob } from 'icons'
import { JobType } from 'types/JobType'

export interface JobTypeIconProps {
  jobType: JobType
}

export const JobTypeIcon: FunctionComponent<JobTypeIconProps> = ({ jobType }) =>
  jobType === JobType.Orchestration ? (
    <OrchestrationJob
      title={t('canvas.orchestrationJob')}
      data-testid="orchestration-job-icon"
    />
  ) : (
    <TransformationJob
      title={t('canvas.transformationJob')}
      data-testid="transformation-job-icon"
    />
  )
