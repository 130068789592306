import { FC } from 'react'
import { NodeProps } from 'reactflow'

import { FlowNodeWrapper } from 'modules/Canvas/components/FlowCanvas/components/FlowNodeWrapper'
import { getIdFromReactFlowId } from 'modules/Canvas/hooks/useCanvasModel/utils'

import { ComponentNode } from './ComponentNode'
import { EtlNodeProps } from './models'

export const IteratorNode: FC<NodeProps<EtlNodeProps>> = ({
  id,
  selected,
  data: {
    imageUrl,
    label,
    inputPorts,
    outputPorts,
    iteratorPorts,
    hasInputConnection,
    hasOutputConnection,
    attachedNode,
    summaryId
  }
}) => (
  <FlowNodeWrapper
    id={getIdFromReactFlowId(id)}
    showPorts={selected || attachedNode?.selected}
    inputPorts={inputPorts}
    outputPorts={outputPorts}
    iteratorPorts={attachedNode ? [] : iteratorPorts}
    hasInputConnection={hasInputConnection}
    hasOutputConnection={hasOutputConnection}
  >
    <ComponentNode
      id={getIdFromReactFlowId(id)}
      isIterator
      isAttached={!!attachedNode}
      isSelected={selected}
      imageUrl={imageUrl}
      summaryId={summaryId}
    >
      {label}
    </ComponentNode>

    {attachedNode && (
      <ComponentNode
        id={getIdFromReactFlowId(attachedNode.id)}
        imageUrl={attachedNode.data.imageUrl}
        isAttached
        isSelected={attachedNode.selected}
        summaryId={attachedNode.data.summaryId}
      >
        {attachedNode.data.label}
      </ComponentNode>
    )}
  </FlowNodeWrapper>
)
