import { useQuery } from '@tanstack/react-query'

import { queryKeys } from 'api/queryKeys'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { useProjectExplorerClient } from '../useProjectExplorerClient/useProjectExplorerClient'
import { GetProjectResponse, ProjectType } from './types'

export const useGetProject = () => {
  const { projectId } = useProjectInfo()
  const client = useProjectExplorerClient()

  return useQuery(
    [queryKeys.project, projectId],
    async () => {
      const { data } = await client.get<GetProjectResponse>(
        `projects/${projectId}`
      )

      return {
        ...data,
        /*
          the PE API currently returns mixed case (Snowflake vs snowflake) for this key;
          eventually it will be migrated to all lowercase and this can be removed
        */
        warehouse: data.warehouse.toLowerCase() as ProjectType
      }
    },
    { retry: 0 }
  )
}

export default useGetProject
