import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Toaster } from '@matillion/component-library'

import useDescribePullChanges from 'api/hooks/useDescribePullChanges/useDescribePullChanges'
import usePullChanges from 'api/hooks/usePullChanges/usePullChanges'

import { type ConflictResolutionSelection } from 'components/ConflictResolutionTable/types'
import useGitContext from 'provider/GitProvider/useGitContext'
import {
  type PullActionResponse,
  type PullArgs
} from 'hooks/usePullAction/types'
import useProblemDetails from '../useProblemDetails/useProblemDetails'
import {
  type ProblemDetailRegistry,
  type TranslationKeys
} from '../useProblemDetails/types'

const commonFailureTitleKey = 'git.pull.problem.default.title'
const problemDetailRegistry: ProblemDetailRegistry = [
  {
    type: 'working-tree/branch_not_found',
    translation: {
      titleKey: commonFailureTitleKey,
      messageKey: 'git.pull.problem.branch-not-found'
    }
  },
  {
    type: 'working-tree/uncommitted_changes_prevent_merge',
    translation: {
      titleKey: 'git.pull.problem.uncommitted.title',
      messageKey: 'git.pull.problem.uncommitted.message'
    }
  }
]
const defaultTranslationKeys: TranslationKeys = {
  titleKey: commonFailureTitleKey,
  messageKey: 'git.pull.problem.default.message'
}

const usePullAction = (args: PullArgs): PullActionResponse => {
  const { onSuccess, onFailure, onConflict } = args

  const { makeToast } = Toaster.useToaster()
  const [loading, setLoading] = useState(false)
  const { mutateAsync: pullChanges } = usePullChanges()
  const { branch, refreshWorkingTreeStatus } = useGitContext()
  const [remoteHeadCommit, setRemoteHeadCommit] = useState<string>()
  const { refetch: describePull } = useDescribePullChanges(branch, false)
  const { resolveProblemDetails } = useProblemDetails(problemDetailRegistry)
  const { t } = useTranslation('translation', { keyPrefix: 'git.pull.toast' })

  const doPullChanges = useCallback(
    async (
      remoteId: string,
      selections: ConflictResolutionSelection[] = []
    ) => {
      setLoading(true)

      try {
        await pullChanges({
          conflictSelections: selections,
          remoteCommitId: remoteId
        }).then(() => {
          refreshWorkingTreeStatus?.({
            refreshFileSummaries: true,
            refreshPipelines: true
          })
        })

        makeToast({
          type: 'success',
          title: t('do.success.title'),
          message: t('do.success.message')
        })

        onSuccess?.()
      } catch (error) {
        const { title, message } = resolveProblemDetails(
          error,
          defaultTranslationKeys
        )

        makeToast({
          title,
          message,
          type: 'error'
        })

        onFailure?.()
      } finally {
        setLoading(false)
      }
    },
    [makeToast, onSuccess, pullChanges, t]
  )

  const resolveConflicts = useCallback(
    async (selections: ConflictResolutionSelection[]) => {
      if (!remoteHeadCommit) {
        makeToast({
          type: 'error',
          title: t('missing-commit-id.error.title'),
          message: t('missing-commit-id.error.message')
        })

        onFailure?.()

        return
      }

      await doPullChanges(remoteHeadCommit, selections)
    },
    [doPullChanges, makeToast, remoteHeadCommit, t]
  )

  const pull = useCallback(async () => {
    setLoading(true)

    makeToast({
      type: 'info',
      title: t('do.in-progress.title'),
      message: t('do.in-progress.message')
    })

    try {
      const {
        error,
        isError,
        data: pullDescription,
        isLoading: isDescribeLoading
      } = await describePull()

      if (isError) {
        const { title, message } = resolveProblemDetails(
          error,
          defaultTranslationKeys
        )

        makeToast({ type: 'error', title, message })
        onFailure?.()

        return
      }

      if (!isDescribeLoading && pullDescription) {
        const { conflicts, remoteCommitId } = pullDescription
        setRemoteHeadCommit(remoteCommitId)

        if (conflicts.length === 0) {
          await doPullChanges(remoteCommitId)
        } else {
          onConflict(conflicts)
        }
      }
    } finally {
      setLoading(false)
    }
  }, [describePull, doPullChanges, onConflict, makeToast, t])

  return {
    isLoading: loading,
    pullRemoteChanges: pull,
    resolvePullConflicts: resolveConflicts
  }
}

export default usePullAction
