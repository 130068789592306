import { kebabCase } from 'lodash'

import { EtlFlags } from 'hooks/useFlags'

export const getComponentFlagsInKebabCase = (
  flags: EtlFlags
): Record<string, boolean> => {
  const componentFlags = Object.entries(flags).filter(([flag]) =>
    flag.startsWith('component')
  )

  const formattedFlags = componentFlags.map(
    ([flagName, flagValue]): [string, boolean] => {
      // TODO: this is a temporary hack to release dynamics365
      // we will replace this with a new regex that can sort all the use-cases defined in the spec file
      // https://matillion.atlassian.net/browse/DPCD-1660
      const isS3Component = flagName.startsWith('componentS3')
      const isModularComponent = flagName.startsWith('componentModular')

      if (isS3Component || isModularComponent) {
        return [
          customKebabCase(flagName.split('component')?.[1]),
          Boolean(flagValue)
        ]
      }

      return [kebabCase(flagName.split('component')?.[1]), Boolean(flagValue)]
    }
  )

  const componentFlagList = formattedFlags.reduce((flagList, [key, value]) => {
    return {
      ...flagList,
      [key]: value
    }
  }, {})

  return componentFlagList
}

// converts a camel or pascal cased string to kebab-case
// the lodash kebabCase function is not suitable for our use-case
// because it does not separate numbers that are preceded by characters
// this method keeps numbers together with text e.g s3Load becomes s3-load
function customKebabCase(str: string): string {
  return str.replace(
    /[A-Z]+(?![a-z])|[A-Z]/g,
    ($, matchOffset) => (matchOffset ? '-' : '') + $.toLowerCase()
  )
}
