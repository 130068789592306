import { FC, lazy, Suspense, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Alert } from '@matillion/component-library'

import { CodeEditorRef } from 'components/CodeEditor'
import { Loading } from 'components/Loading/Loading'
import {
  ParameterOverlayButton,
  ParameterOverlayFooter,
  ParameterOverlayHeader
} from 'components/ParameterOverlay'

import { EntriesList } from 'modules/ParameterEditors/components/MultiExpressionsEditor/components/EntriesList'

import SQLShortcuts from '../../../../components/SQLShortcuts/SQLShortcuts'
import { Collapsible } from './components/Collapsible/Collapsible'
import FieldsAndVariablesPanel from './components/FieldsAndVariablesPanel/FieldsAndVariablesPanel'
import classes from './MultiExpressionsEditor.module.scss'
import { ExpressionEntry, MultiExpressionsEditorProps } from './types'
import { convertFromElementsAndSanitise, convertToElements } from './utils'

const CodeEditor = lazy(async () => import('components/CodeEditor'))

export const JoinEditor: FC<MultiExpressionsEditorProps> = ({
  parameterName,
  elements,
  editorColumns,
  helperData,
  problemDetail,
  onDone
}) => {
  const { t } = useTranslation()
  const codeEditorRef = useRef<CodeEditorRef>(null)
  const joinNames = editorColumns[1]?.options

  /* istanbul ignore next */
  const focusExpressionInput = () => codeEditorRef.current?.focus()
  const [entries, setEntries] = useState<ExpressionEntry[]>(
    convertFromElementsAndSanitise(elements, joinNames || [])
  )

  const [activeIndex, setActiveIndex] = useState<number>(0)

  const activeEntry = entries[activeIndex]
  const hasEntries = entries.length > 0
  const noEntriesErrorAlertText = t(
    'parameterEditor.MULTI_EXPRESSIONS_EDITOR.errorAlertMessage',
    { parameterName, problemDetail: problemDetail?.detail }
  )

  const sourceColumns = helperData?.sourceColumns
    ? helperData.sourceColumns
    : []

  const dispatchEditorUpdate = (updateText: string) => {
    codeEditorRef.current?.insertTextAtCursor(updateText)
  }

  return (
    <>
      <ParameterOverlayHeader title={parameterName} />
      {!hasEntries && (
        <Alert
          aria-label={noEntriesErrorAlertText}
          className={classes.ErrorAlert}
          type="error"
          message={noEntriesErrorAlertText}
        />
      )}
      <Collapsible
        onChange={(isCollapsed) => {
          if (isCollapsed) {
            focusExpressionInput()
          }
        }}
        disabled={!hasEntries}
        actionLabel={t('parameterEditor.MULTI_EXPRESSIONS_EDITOR.expressions')}
        collapsibleChildren={
          <div className={classes.SideBar}>
            <div className={classes.SideBar__Child}>
              <EntriesList
                readOnly={true}
                value={entries}
                activeIndex={activeIndex}
                onSelect={(selectedIndex) => {
                  setActiveIndex(selectedIndex)
                  focusExpressionInput()
                }}
                emptyMessage={t(
                  'parameterEditor.MULTI_EXPRESSIONS_EDITOR.noExpressionsYet'
                )}
              />
            </div>
            <div className={classes.SideBar__Child}>
              <FieldsAndVariablesPanel
                onColumnClick={(column) =>
                  dispatchEditorUpdate(`"${column.alias}"."${column.column}"`)
                }
                sourceColumns={sourceColumns}
              />
            </div>
          </div>
        }
      >
        <div className={classes.EditorWrapper}>
          <Suspense fallback={<Loading />}>
            <CodeEditor
              ref={codeEditorRef}
              className={classes.CodeMirrorCodeEditor}
              autoFocus={hasEntries}
              readOnly={!hasEntries}
              language="sql"
              value={activeEntry ? activeEntry.expression : ''}
              onChange={(newValue: string) =>
                setEntries(
                  entries.map((entry, index) => {
                    if (index === activeIndex) {
                      return {
                        ...entry,
                        expression: newValue
                      }
                    }
                    return entry
                  })
                )
              }
            />
          </Suspense>
        </div>
        <div>
          <SQLShortcuts
            updateEditor={dispatchEditorUpdate}
            disabled={!hasEntries}
          />
        </div>
      </Collapsible>
      <ParameterOverlayFooter>
        <ParameterOverlayButton
          disabled={!hasEntries}
          onClick={() => onDone(convertToElements(entries, editorColumns))}
          text={t('common.save')}
        />
      </ParameterOverlayFooter>
    </>
  )
}
