import { AxiosInstance } from 'axios'

export interface RenameJobApi {
  client: AxiosInstance
  projectId: string
  branchName: string
  jobId: string
  newJobId: string
}

export const renameJobApi = async ({
  client,
  projectId,
  branchName,
  jobId,
  newJobId
}: RenameJobApi) => {
  const { status } = await client.patch(
    `/projects/${projectId}/branches/${branchName}/jobs/${jobId}`,
    {
      fileName: newJobId
    }
  )

  return {
    status,
    newJobId
  }
}
