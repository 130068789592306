import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useFlags } from 'hooks/useFlags'
import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { createEmptyOrchestrationJob } from 'job-lib/builders/createOrchestrationJob'
import { createEmptyTransformationJob } from 'job-lib/builders/createTransformationJob'
import { useMakeComponent } from 'job-lib/hooks/useMakeComponent/useMakeComponent'
import { generateNextComponentId } from 'job-lib/job-functions/generateNextSequenceId'
import { OrchestrationJob, TransformationJob } from 'job-lib/types/Job'
import { JobType } from 'job-lib/types/JobType'

import { JobSummaries, useGetJobSummariesQueryKey } from '../useGetJobSummaries'
import { useSpringClient } from '../useSpringClient/useSpringClient'
import { summariesUpdater } from './summariesUpdater'
import { CreateJobResponse, UseCreateJobData } from './types'

interface UseCreateJobProps {
  runtimeName: string
  jobType: JobType
}

export const useCreateJob = () => {
  const { projectId, branchId, agentId } = useProjectInfo()
  const client = useSpringClient()
  const queryClient = useQueryClient()
  const getJobSummariesQueryKey = useGetJobSummariesQueryKey()
  const [makeComponent] = useMakeComponent()
  const { enableExperimentalDpl } = useFlags()

  return useMutation(
    async ({ runtimeName, jobType }: UseCreateJobProps) => {
      let jobContents: OrchestrationJob | TransformationJob
      if (jobType === JobType.Transformation) {
        jobContents = createEmptyTransformationJob()
      } else {
        const startComponent = await makeComponent({
          id: 'start',
          x: 0,
          y: 0
        })

        if (startComponent.componentType === JobType.Transformation) {
          // CIS could return that the start component is a transformation component
          throw new Error(
            'Start component must be of type orchestration, not transformation'
          )
        }

        jobContents = createEmptyOrchestrationJob()
        const id = generateNextComponentId({ components: {} })
        jobContents.components[id] = {
          id,
          ...startComponent.component
        }
      }

      const url = `/projects/${projectId}/branches/${branchId}/jobs`
      const requestBody: UseCreateJobData = {
        id: getJobId(enableExperimentalDpl, runtimeName, jobType),
        contents: jobContents
      }
      const response = await client.post<CreateJobResponse>(url, requestBody, {
        headers: {
          // agent-id is required for DPL conversion.
          'agent-id': agentId
        }
      })
      return response.data
    },
    {
      onSuccess: (data: CreateJobResponse) => {
        /*
         * currently we don't optimistically update the pipeline query itself
         * as we may have inserted components as part of creation. currently,
         * we don't have access to `jobContents`, so we couldn't accurately reflect
         * the state of the newly-created pipeline
         *
         * optimistically updating the summaries allows us to immediately navigate
         * to the created pipeline, and then wait for it to load as normal
         */
        queryClient.setQueryData<JobSummaries>(
          getJobSummariesQueryKey,
          summariesUpdater({
            jobId: data.id,
            name: data.name,
            folder: data.folder,
            runtimeName: data.runtimeName,
            type: data.type,
            status: data.status
          })
        )
      }
    }
  )

  /**
   * Determines what a new job's ID should be.
   * @param useDpl whether or not the job should be created with the new DPL extension.
   * @param runtimeName the runtimeName of the job (path + name, no extension).
   * @param type type of the job.
   */
  function getJobId(
    useDpl: boolean,
    runtimeName: string,
    type: JobType
  ): string {
    if (useDpl && type === JobType.Orchestration) {
      return `${runtimeName}.orch.yaml`
    }
    if (useDpl && type === JobType.Transformation) {
      return `${runtimeName}.tran.yaml`
    }

    return `${runtimeName}.${type}`
  }
}
