import { type RequestHandler, rest } from 'msw'

import { PROJECT_ID, PROJECT_ID_WITH_NO_ENVS } from '__test-utils__/ids'

import { type DescribeMergeResponse } from 'api/hooks/useDescribeMerge/types'
import { MockedMergeDescription } from 'api/hooks/useDescribeMerge/useDescribeMerge.responses'

export const useStubDescribeMerge = (baseUrl = '', projectId = PROJECT_ID) => {
    return Object.keys(MockedMergeDescription).map((targetSpecifier: string) => {
        // We don't want to have to write stubs for every combination of source and
        // target specifier. So instead, we just care about the target.
        const url = `${baseUrl}/projects/${projectId}/merges/*...${targetSpecifier}`
        return rest.get(url, async (_req, res, ctx) => {
            const response = MockedMergeDescription[targetSpecifier]
            return res(ctx.json<DescribeMergeResponse>(response))
        })
    })
}

export const useDescribeMergeHandlers: RequestHandler[] = [
  ...useStubDescribeMerge('', PROJECT_ID),
  ...useStubDescribeMerge('', 'test-project'),
  ...useStubDescribeMerge('', PROJECT_ID_WITH_NO_ENVS),
  rest.get(
    `v1/projects/${PROJECT_ID}/merges/500...internalServerError`,
    async (_req, res, ctx) => res(ctx.status(500))
  )
]
