import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import {
  DataGrid,
  DataGridColumnProps,
  DataGridRow,
  Typography
} from '@matillion/component-library'
import classNames from 'classnames'

import { COMPLETED_STATES } from 'api/hooks/useGetRunTasks'
import {
  HistoryTaskStatus,
  HistoryTaskType,
  RunTaskStatus,
  State
} from 'api/hooks/useGetRunTasks/types'

import { customFormatDate } from 'modules/FlowStatsExplorer/utils/stats.formatters'

import { completedTimeOrInterimState } from './history.utilities'
import RowCTA from './RowCTA'
import RowTypography, { Props as RowTypographyProps } from './RowTypography'
import classes from './TaskHistory.module.scss'
import {
  TaskStatusRow,
  Props as TaskStatusRowProps
} from './TaskHistoryTaskStatus'

interface TaskRow {
  task: TaskStatusRowProps
  environment: RowTypographyProps & { taskKey: 'environment' }
  job: RowTypographyProps & { taskKey: 'job' }
  queued: RowTypographyProps & { taskKey: 'queued' }
  completed: RowTypographyProps & { taskKey: 'completed' }
  actions?: {
    taskKey: 'actions'
    onNavigate: (id: string) => void
  }
}

type Row = TaskRow & DataGridRow

type Columns = DataGridColumnProps<Row> & { key: keyof TaskRow }

export interface Props {
  tasks: HistoryTaskStatus[]
  navigateToTask: (taskId: string) => void
  cancelTask: (flowInstanceId: string) => void
}

export const TaskHistory: FC<Props> = ({
  tasks,
  navigateToTask,
  cancelTask
}) => {
  const { t } = useTranslation()

  const columns = useMemo<Columns[]>(
    () => [
      {
        key: 'task',
        title: t('taskHistory.tableTitleTask'),
        as: TaskStatusRow
      },
      {
        key: 'environment',
        title: t('taskHistory.tableTitleEnvironment'),
        as: RowTypography
      },
      {
        key: 'job',
        title: t('taskHistory.tableTitleJob'),
        as: RowTypography
      },
      {
        key: 'queued',
        title: t('taskHistory.tableTitleQueued'),
        as: RowTypography
      },
      {
        key: 'completed',
        title: t('taskHistory.tableTitleCompleted'),
        as: RowTypography
      },
      {
        key: 'actions',
        title: '',
        'aria-label': t('taskHistory.taskShowDetails'),
        as: RowCTA,
        className: classes.TaskHistory__CTA
      }
    ],
    [t]
  )

  const rows = useMemo<Row[]>(
    () =>
      tasks.map((task) => ({
        id: task.id,
        task: {
          state: task.state,
          taskKey: 'task',
          taskType: (task as RunTaskStatus).flowInstanceId
            ? HistoryTaskType.TASK_RUN
            : HistoryTaskType.TASK_VALIDATE
        },
        environment: { children: task.environmentName, taskKey: 'environment' },
        job: { children: task.jobName, taskKey: 'job' },
        queued: {
          children: customFormatDate(task?.enqueuedTime),
          taskKey: 'queued'
        },
        completed: {
          children: completedTimeOrInterimState(task, t),
          taskKey: 'completed'
        },
        actions: {
          taskKey: 'actions',
          id: task.id,
          isCompleted:
            COMPLETED_STATES.includes(task.state) &&
            (task as RunTaskStatus).flowInstanceId,
          isExecuting:
            task.state === State.Running &&
            (task as RunTaskStatus).flowInstanceId,
          isCancelling:
            task.state === State.Cancelling &&
            (task as RunTaskStatus).flowInstanceId,
          onNavigate: () => navigateToTask(task.id.toString()),
          onCancel: () => cancelTask((task as RunTaskStatus).flowInstanceId)
        }
      })),
    [tasks, navigateToTask, cancelTask, t]
  )

  return (
    <>
      <Typography
        className={classNames(
          classes.TaskHistory__TasksHeader,
          classes['TaskHistory__TasksHeader--HasData']
        )}
        format="bcs"
        weight="bold"
      >
        {t('translation:taskHistory.panelTitle')}
      </Typography>
      <DataGrid
        data-testid="task-history"
        columns={columns}
        rows={rows}
        className={classes.TaskHistoryGrid}
        compact
      />
    </>
  )
}
