import { FC, useEffect } from 'react'

import { useGetStatsFlowInstance } from 'api/hooks/useGetFlowStats/useGetStatsFlowInstance'

import { DelayedLoader, JobTree } from '../types'
import Loader from './Loader'

interface ChildJobLoaderProps {
  parent: JobTree
  loader: DelayedLoader
}

const ChildJobLoader: FC<ChildJobLoaderProps> = ({ loader, parent }) => {
  const { flowInstanceId, injectDynamicItems } = loader
  const { isLoading, isError, data, remove } = useGetStatsFlowInstance(
    flowInstanceId,
    parent.depth
  )

  useEffect(() => {
    if (!data || !injectDynamicItems) return

    injectDynamicItems?.(parent, data.rootJob, data.jobName)
  }, [data, injectDynamicItems, parent])

  return <Loader isError={isError} isLoading={isLoading} remove={remove} />
}

export default ChildJobLoader
