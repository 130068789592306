import { useEffect } from 'react'

import { useGitContext } from '@matillion/git-component-library'
import {
  Dispatch,
  isAnyOf,
  ListenerMiddlewareInstance,
  MiddlewareAPI
} from '@reduxjs/toolkit'

import { useSaveJob, UseSaveJob } from 'api/hooks/useSaveJob/useSaveJob'

import { jobActions, RootState } from 'job-lib/store'

import {
  SET_STATE_FROM_YJS_ACTION,
  SetStateAction
} from 'modules/redux-yjs-bindings/src/patchRedux'

export const effectUpdateWts = async (
  listenerApi: MiddlewareAPI<Dispatch, RootState>,
  saveJob: (ops: UseSaveJob) => Promise<unknown>
) => {
  const {
    job: { job, jobType }
  } = listenerApi.getState()

  if (!job || !jobType) {
    return
  }

  await saveJob({
    job
  })
}

export const useSaveJobListener = (
  listenerMiddleware: ListenerMiddlewareInstance<RootState>
) => {
  const saveJob = useSaveJob()
  const { refreshWorkingTreeStatus } = useGitContext()

  useEffect(() => {
    return listenerMiddleware.startListening({
      matcher: isAnyOf(
        jobActions.addComponent,
        jobActions.deleteNodes,
        jobActions.overrideComponentParameter,
        jobActions.deleteLink,
        jobActions.updateNodePosition,
        jobActions.setJobAndSave,
        jobActions.addJobVariable,
        jobActions.deleteJobVariable,
        jobActions.cloneComponentGroup,
        jobActions.updateJobVariable,
        jobActions.addNote,
        jobActions.updateNote,
        jobActions.deleteNote,
        (action): action is SetStateAction =>
          action.type === SET_STATE_FROM_YJS_ACTION
      ),
      effect: async (_action, listenerApi) => {
        listenerApi.cancelActiveListeners()

        await listenerApi.delay(100)
        await effectUpdateWts(listenerApi, saveJob).then(() => {
          refreshWorkingTreeStatus?.({ refreshFileSummaries: true })
        })
      }
    })
  }, [saveJob, listenerMiddleware, refreshWorkingTreeStatus])
}
