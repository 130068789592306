import { FunctionComponent } from 'react'
import { Outlet } from 'react-router-dom'

import { ValidateLogAndBounce } from './ValidateLogAndBounce'
import ValidateProjectPermissions from './ValidateProjectPermissions'

export const ProjectGuard: FunctionComponent = () => (
  <ValidateLogAndBounce>
    <ValidateProjectPermissions>
      <Outlet />
    </ValidateProjectPermissions>
  </ValidateLogAndBounce>
)
