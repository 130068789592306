import { Typography } from '@matillion/component-library'

import classes from '../ComponentBrowser.module.scss'

interface DisabledLabelProps {
  labelText: string
}

export const DisabledLabel = ({ labelText }: DisabledLabelProps) => {
  return (
    <Typography
      data-testid="disabled-label"
      as="span"
      format="bcs"
      weight="bold"
      className={classes.Component__DisabledLabel}
    >
      {labelText}
    </Typography>
  )
}
