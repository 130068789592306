import { useQuery } from '@tanstack/react-query'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { queryKeys } from '../../queryKeys'
import { useSpringClient } from '../useSpringClient/useSpringClient'
import { ListProjectResponse, ProjectType } from './types'

export const useListProjects = () => {
  const { projectId } = useProjectInfo()
  const client = useSpringClient()

  return useQuery([projectId, queryKeys.listProjects], async () => {
    const { data } = await client.get<ListProjectResponse>('projects')

    return {
      results: data.results.map((project) => ({
        ...project,
        /*
          the PE API currently returns mixed case (Snowflake vs snowflake) for this key;
          eventually it will be migrated to all lowercase and this can be removed
        */
        warehouse: project.warehouse.toLowerCase() as ProjectType
      }))
    }
  })
}
