import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import useListBranches from 'api/hooks/useListBranches'

import { ReactComponent as BranchSvg } from 'components/Git/Icons/BranchIcon.svg'

import { ProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import ToolbarSelector, { ToolbarSelectorType } from './ToolbarSelector'

const BranchSelector: FC<{ projectInfo: ProjectInfo }> = ({ projectInfo }) => {
  const { branchId } = projectInfo
  const { data, isLoading, isError } = useListBranches()
  const { t } = useTranslation()

  const branch = data?.find((e) => e.name === branchId)

  return (
    <ToolbarSelector
      data-testid="project-toolbar-branchSelector"
      text={branch?.name ?? t('projectToolbox.errorLoading.branch')}
      type={ToolbarSelectorType.BRANCH}
      icon={BranchSvg}
      isError={isError}
      isLoading={isLoading}
    />
  )
}

export default BranchSelector
