import { BRANCH_ID } from '__test-utils__/ids'

export const MockedPullChangesResponses: {
  [branchName: string]: number
} = {
  [BRANCH_ID]: 204,
  BranchWithConflicts: 204,
  BranchNoConflicts: 204,
  main: 204
}
