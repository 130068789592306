import { FC, PropsWithChildren, Ref, useEffect, useMemo } from 'react'
import ReactDOM from 'react-dom'

interface PortalDivProps extends PropsWithChildren {
  className?: string
  childRef?: Ref<HTMLDivElement>
  childTestId?: string
}
export const PortalDiv: FC<PortalDivProps> = ({
  children,
  className,
  childRef,
  childTestId
}) => {
  const container = useMemo(() => document.createElement('div'), [])

  if (className) {
    container.className = className
  }

  useEffect(() => {
    document.body.appendChild(container)

    return () => {
      document.body.removeChild(container)
    }
  }, [container])

  return ReactDOM.createPortal(
    <div data-testid={childTestId} ref={childRef}>
      {children}
    </div>,
    container
  )
}
