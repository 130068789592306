import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import {
  ComponentMetadata,
  ComponentParameter,
  EditorType
} from 'api/hooks/useGetComponentMetadata/types'
import { ComponentSummaryId } from 'api/hooks/useGetComponentTree'
import { EditorColumn } from 'api/hooks/useGetParameterOptions/types'

import { isModularConnector } from 'job-lib/cisIds/idType'
import {
  inputIdParameter,
  profileParameter
} from 'job-lib/cisIds/knownComponentParameters'
import { isDPLParameterCollection } from 'job-lib/store/jobSlice/utils/isDPLParameterCollection'
import { ComponentInstance } from 'job-lib/types/Job'
import { ElementCollection } from 'job-lib/types/Parameters'

import { EditedParameter } from 'modules/ComponentParameters/ComponentParameters'
import { getComponentParameterKey } from 'modules/ComponentParameters/utils/getComponentParameterKey'
import { getParameterValue } from 'modules/ComponentParameters/utils/getParameterValue'
import { convertParameterValueToMetl } from 'modules/WorkingCopyProvider/effects/useMetlPipeline/convertParametersToMetl'

import { isParameterVisible } from 'utils/isParameterVisible'

import { ComponentParameter as Parameter } from '../ComponentParameter/ComponentParameter'
import { ComponentParameterGroup } from '../ComponentParameterGroup/ComponentParameterGroup'

interface ComponentParameterItemProps {
  parameter: ComponentParameter
  componentInstance: ComponentInstance
  componentMetadata: ComponentMetadata
  componentSummaryId: ComponentSummaryId
  parameterPath?: string[]
  onEdit: (params: EditedParameter, editorColumns?: EditorColumn[]) => void
}

export const ComponentParameterItem: FC<ComponentParameterItemProps> = ({
  parameter,
  componentInstance,
  componentMetadata,
  componentSummaryId,
  parameterPath = [],
  onEdit
}) => {
  const { t } = useTranslation()

  const isDPLInstance = isDPLParameterCollection(componentInstance.parameters)
  const parameterSlot = parameter.metlSlot
  const parameterId = parameter.dplID
  const parameterDataType = parameter.dataType

  const isVisible = isParameterVisible(
    isDPLInstance ? parameterId : parameterSlot,
    componentInstance.parameters,
    componentMetadata,
    parameterPath
  )

  // we wouldn't normally handle visibility of a component inside of itself, it would be the responsibility of the parent
  // we have to include this check here due to the recursive nature of this component
  if (!isVisible) {
    return null
  }

  const parameterName = t([
    `componentProperties.parameterIds.${parameter.resourceID as string}`,
    `componentProperties.parameterLabels.${parameter.id}`,
    parameter.displayName ?? ''
  ])

  if (parameter.editorType === EditorType.PARAMETER_GROUP) {
    parameterPath.push(parameterId)

    return (
      <ComponentParameterGroup
        key={`parameter-group-${parameterId}`}
        label={parameterName}
      >
        {parameter.childProperties?.flatMap((childParam) => {
          if (childParam.editorType === EditorType.CONNECTION_EDITOR) {
            return renderConnectionEditorFields(childParam, parameterPath)
          }
          return renderComponentParameterItem(childParam, parameterPath)
        })}
      </ComponentParameterGroup>
    )
  }

  /*
   * editors use the METL element collection to know what values to render
   * DPL enabled components such as modular connectors are embedded into the METL model in a single parameter slot
   * to support DPL enabled components we need to convert the embedded DPL values into METL format before they get passed to the editor
   */
  const parameterElements = isDPLInstance
    ? convertParameterValueToMetl(
        parameter,
        // the conversion from parameter value to METL expects a DPL parameter value
        // this is extracting the parameter value out of the embedded DPL in the METL
        getParameterValue(componentInstance.parameters, [
          ...parameterPath,
          parameterId
        ])
      )
    : componentInstance.parameters?.[parameterSlot]?.elements ?? {}

  const parameterValues = Object.values(parameterElements).map(
    (el) => el.values[1]?.value
  )
  const isModular = isModularConnector(componentSummaryId)

  /* HACK: these are two known parameter ID's specific to modular connectors that we need populated in the DPL but not displayed to the user
   * DPL does not store hidden fields so they cannot be visibleWhen rules. This is a temporary solution that will be fixed by
   * https://matillion.atlassian.net/browse/DPCD-1480
   */
  const isVisuallyHiddenParameterId =
    isDPLInstance &&
    isModular &&
    [inputIdParameter, profileParameter].includes(parameter.dplID)

  const parameterBag = {
    parameterSlot,
    componentSummaryId,
    parameterName,
    id: parameter.dplID,
    isOptional: parameter.optional,
    isVisuallyHidden: isVisuallyHiddenParameterId,
    parameterMetadata: parameter,
    value: parameterValues,
    visible: isVisible,
    elements: parameterElements
  }

  return (
    <Parameter
      key={getComponentParameterKey(componentInstance.id, parameterId)}
      parameter={parameterBag}
      componentInstance={componentInstance}
      componentMetadata={componentMetadata}
      path={[...parameterPath, parameterId]}
      onEdit={(
        editedValue: ElementCollection,
        editorColumns?: EditorColumn[]
      ) =>
        onEdit({
          parameterSlot,
          parameterDataType,
          editedValue,
          editorColumns,
          parameterPath: [...parameterPath, parameterId]
        })
      }
    />
  )

  function renderComponentParameterItem(
    componentParameter: ComponentParameter,
    path: string[]
  ) {
    return (
      <ComponentParameterItem
        key={`parameter-${componentParameter.dplID}`}
        parameter={componentParameter}
        componentSummaryId={componentSummaryId}
        componentInstance={componentInstance}
        componentMetadata={componentMetadata}
        onEdit={onEdit}
        parameterPath={path}
      />
    )
  }
  // this is a hack to support inline editing of connections,
  // flattening the connection editor fields into the top level
  function renderConnectionEditorFields(
    componentParameter: ComponentParameter,
    path: string[]
  ) {
    return [
      ...(componentParameter.childProperties?.map((childParameter) => {
        if (childParameter.dplID === 'overrides') {
          return childParameter.childProperties?.map((override) => {
            return renderComponentParameterItem(override, [
              ...path,
              componentParameter.dplID,
              childParameter.dplID
            ])
          })
        }
        return renderComponentParameterItem(childParameter, [
          ...path,
          componentParameter.dplID
        ])
      }) ?? [])
    ]
  }
}
