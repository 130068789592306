import { type RequestHandler, rest } from 'msw'
import { useGetRepositoriesResponses } from './useGetRepositories.responses'
import { type GetRepositoriesResponse } from './types'
import { type ExternalGitProvider } from 'types/ExternalGitProvider'

export const useStubGetRepositories = (baseUrl = '') => {
  return Object.keys(useGetRepositoriesResponses).map((provider) => {
    const url = `${baseUrl}/git-service/v1/providers/${provider}/repositories`
    return rest.get(url, async (_, res, ctx) => {
      return res(
        ctx.json<GetRepositoriesResponse>(
          useGetRepositoriesResponses[provider as ExternalGitProvider]
        )
      )
    })
  })
}

export const useStubGetRepositoriesError = () => {
  const url = '/git-service/v1/providers/:provider/repositories'

  return rest.get(url, async (req, res, ctx) => {
    const providerParam = req.params.provider as string

    switch (providerParam) {
      case 'AUTH_REQUIRED': {
        return res(
          ctx.status(401),
          ctx.set('content-type', 'application/problem+json'),
          ctx.json({
            title: 'Unauthorised',
            status: 401,
            detail: 'Authorisation required for provider [github]',
            type: 'git/provider_authorisation_required',
            provider: 'GitHub'
          })
        )
      }
      case 'AUTH_FAILED': {
        return res(
          ctx.status(401),
          ctx.set('content-type', 'application/problem+json'),
          ctx.json({
            title: 'Unauthorised',
            status: 401,
            detail: 'Authorisation failed for provider [github]',
            type: 'git/provider_authorisation_failed',
            provider: 'GitHub'
          })
        )
      }
      case 'NOT_FOUND': {
        return res(
          ctx.status(404),
          ctx.set('content-type', 'application/problem+json'),
          ctx.json({
            title: 'Not Found',
            status: 404,
            detail: 'The git provider [AZURE] was not found',
            type: 'git/provider_not_found'
          })
        )
      }
      case '500': {
        return res(ctx.status(500))
      }
    }
  })
}

export const useGetRepositoriesHandlers: RequestHandler[] = [
  ...useStubGetRepositories(),
  useStubGetRepositoriesError()
]
