import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Toaster } from '@matillion/component-library'

import { usePublishJobs } from 'api/hooks/usePublishJobs'

const usePublishJobsAction = (branchName?: string, environmentId?: string) => {
  const [loading, setLoading] = useState(false)
  const { doPublish } = useDoPublishJobs()

  const publish = useCallback(
    async (commitId: string) => {
      if (!loading && environmentId && branchName) {
        setLoading(true)
        await doPublish({ branchName, commitId, environmentId })
        setLoading(false)
      }
    },
    [branchName, doPublish, environmentId, loading]
  )

  return { publish, loading }
}

interface DoPublishArgs {
  branchName: string
  commitId: string
  environmentId: string
}

export const useDoPublishJobs = () => {
  const { mutateAsync: publish } = usePublishJobs()

  const { t } = useTranslation('translation', {
    keyPrefix: 'git.publish.toast'
  })
  const { makeToast } = Toaster.useToaster()

  return {
    doPublish: async (args: DoPublishArgs) => {
      return publish(args)
        .then(() => {
          makeToast({
            type: 'success',
            title: t('success.title'),
            message: t('success.message')
          })
        })
        .catch(() => {
          makeToast({
            type: 'error',
            title: t('error.title'),
            message: t('error.message')
          })
        })
    }
  }
}

export default usePublishJobsAction
