import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'

import { JobType } from 'job-lib/types/JobType'

import { JobState } from '../../job.types'
import { deleteOrchestrationLink } from './deleteOrchestrationLink'
import { deleteTransformationLink } from './deleteTransformationLink'

export const deleteLink: CaseReducer<JobState, PayloadAction<number>> = (
  state,
  { payload: linkId }
) => {
  const { jobType, job } = state

  if (jobType === null || job === null) {
    return state
  }

  if (jobType === JobType.Orchestration) {
    deleteOrchestrationLink(linkId, job)
  }

  if (jobType === JobType.Transformation) {
    deleteTransformationLink(linkId, job)
  }

  return state
}
