import { useMemo } from 'react'

import { useQueryClient } from '@tanstack/react-query'

import { ValidateComponentParsedResponse } from 'api/hooks/useValidateComponent/types'
import { useValidateComponent } from 'api/hooks/useValidateComponent/useValidateComponent'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { getComponentLabel } from 'job-lib/job-functions/getComponentLabel'
import { JobState } from 'job-lib/store/jobSlice/job.types'
import { JobType } from 'job-lib/types/JobType'

import { ComponentQueryKeys } from '../types'
import { getDefaultQueryKeys } from '../utils/getDefaultQueryKeys'
import { getQueryKey } from '../utils/getQueryKey'

export const useGenerateOrchestrationQueries = ({
  jobState,
  lastValidated,
  componentQueryKeys
}: {
  jobState: JobState
  lastValidated: number
  componentQueryKeys: ComponentQueryKeys | null
}) => {
  const { job, jobType } = jobState

  const { jobSummaryId } = useProjectInfo()
  const validateComponent = useValidateComponent({ jobState })
  const queryClient = useQueryClient()

  const queries = useMemo(() => {
    if (jobType !== JobType.Orchestration || !componentQueryKeys) {
      return null
    }

    const components = Object.keys(job.components)

    return components.map((key) => {
      const componentId = Number(key)
      const componentName = getComponentLabel(job.components[componentId])

      const hasQueryCache = queryClient
        .getQueriesData<ValidateComponentParsedResponse>(
          getDefaultQueryKeys(jobSummaryId)
        )
        .find((cache) => cache[1]?.componentName === componentName)

      const componentKeys = componentQueryKeys[componentName]
      const isComponentValidationEnabled =
        Boolean(lastValidated) && componentKeys !== undefined && !hasQueryCache

      return {
        queryKey: getQueryKey(jobSummaryId, componentKeys),
        enabled: isComponentValidationEnabled,
        staleTime: 0,
        queryFn: async () =>
          validateComponent({
            component: job?.components[componentId]
          })
      }
    })
  }, [
    componentQueryKeys,
    jobType,
    job,
    queryClient,
    jobSummaryId,
    lastValidated,
    validateComponent
  ])

  return queries
}
