import { createContext } from 'react'

import * as Y from 'yjs'

import { JobSummary } from 'api/hooks/useGetJobSummaries'

export interface WorkingCopyContextBag {
  summary: JobSummary | null
  isLoading: boolean
  isError: boolean
  undoManager: Y.UndoManager | null
}

export const WorkingCopyContext = createContext<WorkingCopyContextBag>({
  summary: null,
  isLoading: false,
  isError: false,
  undoManager: null
})
