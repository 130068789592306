import { Fragment, PropsWithChildren, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon, Typography } from '@matillion/component-library'
import classNames from 'classnames'

import { ComponentDragSource } from 'components/ComponentDrag/ComponentDragSource'

import { ExtendedProps } from 'hooks/useAvailableComponents/useAvailableComponents'

import { OutputPortType } from 'job-lib/types/Components'

import { DisabledLabel } from '../../../ComponentBrowser/components/DisabledLabel'
import { ComponentName } from '../ComponentName'
import classes from './ComponentRow.module.scss'

interface Props {
  component: ExtendedProps
  displayName: string
  searchTerm: string
  isDraggable?: boolean
  onSelectComponent?: (id: string) => Promise<void>
}

interface WrapperProps extends PropsWithChildren {
  component: ExtendedProps
  isDraggable?: boolean
  icon: string
}

const Wrapper = ({ children, isDraggable, component, icon }: WrapperProps) => {
  const isIterator =
    component.outputPorts.filter((o) => o.portId === OutputPortType.ITERATION)
      .length > 0

  return isDraggable ? (
    <ComponentDragSource
      data-testid="component-drag-source"
      isIterator={isIterator}
      componentId={component.componentId}
      imageUrl={icon}
      dragOrigin="component-browser"
    >
      {children}
    </ComponentDragSource>
  ) : (
    <Fragment>{children}</Fragment>
  )
}

const ComponentRow = ({
  component,
  displayName,
  searchTerm,
  isDraggable,
  onSelectComponent
}: Props) => {
  const { t } = useTranslation()

  const handleClick = useCallback(() => {
    if (!onSelectComponent || !component.isAvailableForAgent) {
      return
    }

    onSelectComponent(component.componentId)
  }, [onSelectComponent, component])

  const canBeDragged = component.isAvailableForAgent ? isDraggable : false

  return (
    <Wrapper
      component={component}
      isDraggable={canBeDragged}
      icon={component.icon}
    >
      <li
        className={classNames(
          classes.ComponentRow,
          isDraggable && classes.ComponentRow__Draggable,
          !component.isAvailableForAgent && classes.ComponentRow__Unselectable
        )}
        onClick={handleClick}
        onKeyDown={(e) => e.key === 'Enter' && handleClick()}
        tabIndex={0}
        data-testid={`row-${displayName}`}
      >
        <a role="button" className={classes.ComponentRow__Wrapper}>
          {isDraggable && (
            <Icon.DragHandle
              className={classNames(
                classes.DragHandle,
                !component.isAvailableForAgent && classes.DragHandle__Invisible
              )}
              data-testid={`drag-icon-${component.componentId}`}
            />
          )}

          <div className={classes.ComponentRow__Icon}>
            <img role="presentation" src={component.icon} alt={displayName} />
          </div>

          <ComponentName displayName={displayName} searchTerm={searchTerm} />

          <div className={classes.ComponentRow__Badge}>
            {!component.isAvailableForAgent ? (
              <DisabledLabel labelText={t('componentBrowser.disabledLabel')} />
            ) : (
              <Typography format="bcs" className={classes.ComponentRow__Tag}>
                {component.tags[0]}
              </Typography>
            )}
          </div>
        </a>
      </li>
    </Wrapper>
  )
}

export { ComponentRow }
