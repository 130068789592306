import { ReactFlowInstance, useReactFlow } from 'reactflow'

import {
  ComponentNodeData,
  EtlCanvasEdgeData,
  NoteNodeData
} from './useCanvasModel/useCanvasModel'

export type EtlFlowInstance = ReactFlowInstance<
  ComponentNodeData | NoteNodeData,
  EtlCanvasEdgeData
>

export const useEtlFlow = () =>
  useReactFlow<ComponentNodeData | NoteNodeData, EtlCanvasEdgeData>()
