import { useQuery } from '@tanstack/react-query'

import { type DescribePullChangesResponse } from './types'
import { queryKeys } from 'api/query-keys'
import useWorkingTreeClient from 'api/clients/useWorkingTreeClient'
import useGitContext from 'provider/GitProvider/useGitContext'

const useDescribePullChangesQueryKey = (
  projectId: string | undefined,
  branchName?: string
) => {
  return [
    queryKeys.describePull,
    projectId,
    branchName,
    queryKeys.describeMerge
  ]
}

/**
 * Describes the outcome of pulling changes from the remote into the local
 * copy of the working tree store. Will describe conflicts with uncommitted local
 * changes, should there be any.
 *
 * @param branchName The name of the branch whose remote to pull from.
 * @param enabled Whether or not the query is enabled.
 */
const useDescribePullChanges = (branchName?: string, enabled = true) => {
  const client = useWorkingTreeClient()
  const { project } = useGitContext()

  const queryKey = useDescribePullChangesQueryKey(project, branchName)

  return useQuery(
    queryKey,
    async () => {
      const url = `/projects/${project}/branches/${branchName}/merges`
      const { data } = await client.get<DescribePullChangesResponse>(url)
      return data
    },
    {
      enabled
    }
  )
}

export default useDescribePullChanges
