import { mockOrchestrationValues } from '__test-utils__/mockOrchestrationValues'

import { ParameterDataType } from 'api/hooks/useGetComponentMetadata/types'

import { Cardinality } from 'job-lib/types/Components'
import { ExecutionHint, OrchestrationJob } from 'job-lib/types/Job'
import { ValidationState } from 'job-lib/types/Parameters'
import {
  JobVariableBehaviour,
  JobVariableType,
  JobVariableVisibility
} from 'job-lib/types/Variables'

export const mockOrchestrationJob: OrchestrationJob = {
  revision: 47,
  created: 1670264117822,
  timestamp: 1670264117822,
  components: {
    [mockOrchestrationValues.START_0.id]: {
      id: mockOrchestrationValues.START_0.id,
      inputCardinality: Cardinality.ZERO,
      outputCardinality: Cardinality.MANY,
      connectorHint: 'UNCONDITIONAL',
      executionHint: ExecutionHint.Flow,
      implementationID: 444132438,
      x: -2736,
      y: 64,
      width: 32,
      height: 32,
      inputConnectorIDs: [],
      outputSuccessConnectorIDs: [],
      outputFailureConnectorIDs: [],
      outputUnconditionalConnectorIDs: [15665],
      outputTrueConnectorIDs: [],
      outputFalseConnectorIDs: [],
      exportMappings: {},
      parameters: {
        1: {
          slot: 1,
          name: 'Name',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: mockOrchestrationValues.START_0.name
                }
              }
            }
          },
          visible: true,
          status: {},
          columnNames: []
        }
      },
      validationStatus: {},
      status: {},
      expectedFailure: null,
      activationStatus: 'ENABLED',
      outputIterationConnectorIDs: [],
      inputIterationConnectorIDs: []
    },
    [mockOrchestrationValues.CREATE_TABLE_0.id]: {
      id: mockOrchestrationValues.CREATE_TABLE_0.id,
      inputCardinality: Cardinality.ONE,
      outputCardinality: Cardinality.MANY,
      connectorHint: 'SUCCESS_FAIL',
      executionHint: ExecutionHint.Execute,
      implementationID: 1611478312,
      x: -2450,
      y: 199,
      width: 32,
      height: 32,
      inputConnectorIDs: [],
      outputSuccessConnectorIDs: [],
      outputFailureConnectorIDs: [],
      outputUnconditionalConnectorIDs: [],
      outputTrueConnectorIDs: [],
      outputFalseConnectorIDs: [],
      exportMappings: {},
      parameters: {
        1: {
          slot: 1,
          name: 'Name',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: mockOrchestrationValues.CREATE_TABLE_0.name
                }
              }
            }
          },
          visible: true,
          status: {
            1278: {
              environmentID: 1278,
              validationState: ValidationState.OK,
              validationMessage: ''
            }
          },
          columnNames: ['Unknown']
        },
        2: {
          slot: 2,
          name: 'New Table Name',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: ''
                }
              }
            }
          },
          visible: true,
          status: {
            1278: {
              environmentID: 1278,
              validationState: ValidationState.INPUT_REQUIRED,
              validationMessage: 'Input required.'
            }
          },
          columnNames: ['Unknown']
        },
        3: {
          slot: 3,
          name: 'Create/Replace',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'Create'
                }
              }
            }
          },
          visible: true,
          status: {
            1278: {
              environmentID: 1278,
              validationState: ValidationState.OK,
              validationMessage: ''
            }
          },
          columnNames: ['Unknown']
        },
        4: {
          slot: 4,
          name: 'Table Metadata',
          elements: {},
          visible: true,
          status: {
            1278: {
              environmentID: 1278,
              validationState: ValidationState.INPUT_REQUIRED,
              validationMessage: 'No input provided'
            }
          },
          columnNames: [
            'Column Name',
            'Data Type',
            'Size',
            'Decimal Places',
            'Encoding Type',
            'Allow Nullable',
            'Comment'
          ]
        },
        5: {
          slot: 5,
          name: 'Distribution Style',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'Auto'
                }
              }
            }
          },
          visible: true,
          status: {
            1278: {
              environmentID: 1278,
              validationState: ValidationState.OK,
              validationMessage: ''
            }
          },
          columnNames: ['Unknown']
        },
        6: {
          slot: 6,
          name: 'Distribution Key',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: ''
                }
              }
            }
          },
          visible: false,
          status: {
            1278: {
              environmentID: 1278,
              validationState: ValidationState.INPUT_REQUIRED,
              validationMessage: 'Input required.'
            }
          },
          columnNames: ['Distribution Key']
        },
        7: {
          slot: 7,
          name: 'Sort Key',
          elements: {},
          visible: true,
          status: {
            1278: {
              environmentID: 1278,
              validationState: ValidationState.OK,
              validationMessage: ''
            }
          },
          columnNames: ['Sort Key']
        },
        8: {
          slot: 8,
          name: 'Schema',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: '[Environment Default]'
                }
              }
            }
          },
          visible: true,
          status: {
            1278: {
              environmentID: 1278,
              validationState: ValidationState.OK,
              validationMessage: ''
            }
          },
          columnNames: ['Unknown']
        },
        9: {
          slot: 9,
          name: 'Primary Key',
          elements: {},
          visible: true,
          status: {
            1278: {
              environmentID: 1278,
              validationState: ValidationState.OK,
              validationMessage: ''
            }
          },
          columnNames: ['Primary Key']
        },
        11: {
          slot: 11,
          name: 'Identity Columns',
          elements: {},
          visible: true,
          status: {
            1278: {
              environmentID: 1278,
              validationState: ValidationState.OK,
              validationMessage: ''
            }
          },
          columnNames: ['Column Name', 'Seed', 'Step']
        },
        14: {
          slot: 14,
          name: 'Sort Key Options',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'Compound'
                }
              }
            }
          },
          visible: false,
          status: {
            1278: {
              environmentID: 1278,
              validationState: ValidationState.OK,
              validationMessage: ''
            }
          },
          columnNames: ['Unknown']
        },
        15: {
          slot: 15,
          name: 'Backup Table',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'Yes'
                }
              }
            }
          },
          visible: true,
          status: {
            1278: {
              environmentID: 1278,
              validationState: ValidationState.OK,
              validationMessage: ''
            }
          },
          columnNames: ['Unknown']
        }
      },
      validationStatus: {
        1278: {
          environmentID: 1278,
          validationState: ValidationState.PARAMS_ERROR,
          validationMessage: 'Parameters contain errors.'
        }
      },
      status: {
        1278: {
          environmentID: 1278,
          sql: 'No SQL could be generated because of invalid parameters.',
          showSql: false
        }
      },
      expectedFailure: null,
      activationStatus: 'ENABLED',
      outputIterationConnectorIDs: [],
      inputIterationConnectorIDs: [13704]
    },
    [mockOrchestrationValues.AND_0.id]: {
      id: mockOrchestrationValues.AND_0.id,
      inputCardinality: Cardinality.MANY,
      outputCardinality: Cardinality.MANY,
      connectorHint: 'UNCONDITIONAL',
      executionHint: ExecutionHint.Flow,
      implementationID: 235671163,
      x: -2608,
      y: 64,
      width: 32,
      height: 32,
      inputConnectorIDs: [15665],
      outputSuccessConnectorIDs: [],
      outputFailureConnectorIDs: [],
      outputUnconditionalConnectorIDs: [15668],
      outputTrueConnectorIDs: [],
      outputFalseConnectorIDs: [],
      exportMappings: {},
      parameters: {
        1: {
          slot: 1,
          name: 'Name',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: mockOrchestrationValues.AND_0.name
                }
              }
            }
          },
          visible: true,
          status: {},
          columnNames: []
        }
      },
      validationStatus: {},
      status: {},
      expectedFailure: null,
      activationStatus: 'ENABLED',
      outputIterationConnectorIDs: [],
      inputIterationConnectorIDs: []
    },
    [mockOrchestrationValues.END_FAILURE_0.id]: {
      id: mockOrchestrationValues.END_FAILURE_0.id,
      inputCardinality: Cardinality.ONE,
      outputCardinality: Cardinality.ZERO,
      connectorHint: 'UNCONDITIONAL',
      executionHint: ExecutionHint.Flow,
      implementationID: 515156205,
      x: -2304,
      y: 144,
      width: 32,
      height: 32,
      inputConnectorIDs: [15698],
      outputSuccessConnectorIDs: [],
      outputFailureConnectorIDs: [],
      outputUnconditionalConnectorIDs: [],
      outputTrueConnectorIDs: [],
      outputFalseConnectorIDs: [],
      exportMappings: {},
      parameters: {
        1: {
          slot: 1,
          name: 'Name',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: mockOrchestrationValues.END_FAILURE_0.name
                }
              }
            }
          },
          visible: true,
          status: {},
          columnNames: []
        }
      },
      validationStatus: {},
      status: {},
      expectedFailure: null,
      activationStatus: 'ENABLED',
      outputIterationConnectorIDs: [],
      inputIterationConnectorIDs: []
    },
    [mockOrchestrationValues.END_SUCCESS_0.id]: {
      id: mockOrchestrationValues.END_SUCCESS_0.id,
      inputCardinality: Cardinality.ONE,
      outputCardinality: Cardinality.ZERO,
      connectorHint: 'UNCONDITIONAL',
      executionHint: ExecutionHint.Flow,
      implementationID: -1946388514,
      x: -2304,
      y: -16,
      width: 32,
      height: 32,
      inputConnectorIDs: [13701],
      outputSuccessConnectorIDs: [],
      outputFailureConnectorIDs: [],
      outputUnconditionalConnectorIDs: [],
      outputTrueConnectorIDs: [],
      outputFalseConnectorIDs: [],
      exportMappings: {},
      parameters: {
        1: {
          slot: 1,
          name: 'Name',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: mockOrchestrationValues.END_SUCCESS_0.name
                }
              }
            }
          },
          visible: true,
          status: {},
          columnNames: []
        }
      },
      validationStatus: {},
      status: {},
      expectedFailure: null,
      activationStatus: 'ENABLED',
      outputIterationConnectorIDs: [],
      inputIterationConnectorIDs: []
    },
    [mockOrchestrationValues.RUN_TRANSFORMATION_0.id]: {
      id: mockOrchestrationValues.RUN_TRANSFORMATION_0.id,
      inputCardinality: Cardinality.ONE,
      outputCardinality: Cardinality.MANY,
      connectorHint: 'SUCCESS_FAIL',
      executionHint: ExecutionHint.Transform,
      implementationID: 1896325668,
      x: -2304,
      y: 64,
      width: 32,
      height: 32,
      inputConnectorIDs: [16086],
      outputSuccessConnectorIDs: [],
      outputFailureConnectorIDs: [],
      outputUnconditionalConnectorIDs: [],
      outputTrueConnectorIDs: [],
      outputFalseConnectorIDs: [],
      exportMappings: {},
      parameters: {
        1: {
          slot: 1,
          name: 'Name',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: mockOrchestrationValues.RUN_TRANSFORMATION_0.name
                }
              }
            }
          },
          visible: true,
          status: {
            1278: {
              environmentID: 1278,
              validationState: ValidationState.OK,
              validationMessage: ''
            }
          },
          columnNames: ['Unknown']
        },
        2: {
          slot: 2,
          name: 'Transformation Job',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: ''
                }
              }
            }
          },
          visible: true,
          status: {
            1278: {
              environmentID: 1278,
              validationState: ValidationState.INPUT_REQUIRED,
              validationMessage: 'Input required.'
            }
          },
          columnNames: ['Transformation Job']
        },
        3: {
          slot: 3,
          name: '',
          elements: {},
          visible: false,
          status: {
            1278: {
              environmentID: 1278,
              validationState: ValidationState.OK,
              validationMessage: ''
            }
          },
          columnNames: ['Unknown']
        },
        4: {
          slot: 4,
          name: 'Set Scalar Variables',
          elements: {},
          visible: true,
          status: {
            1278: {
              environmentID: 1278,
              validationState: ValidationState.OK,
              validationMessage: ''
            }
          },
          columnNames: ['Variable', 'Value']
        },
        5: {
          slot: 5,
          name: 'Set Grid Variables',
          elements: {},
          visible: true,
          status: {
            1278: {
              environmentID: 1278,
              validationState: ValidationState.OK,
              validationMessage: ''
            }
          },
          columnNames: ['Job Grid Variable', 'Status']
        }
      },
      validationStatus: {
        1278: {
          environmentID: 1278,
          validationState: ValidationState.PARAMS_ERROR,
          validationMessage: 'Parameters contain errors.'
        }
      },
      status: {},
      expectedFailure: null,
      activationStatus: 'ENABLED',
      outputIterationConnectorIDs: [],
      inputIterationConnectorIDs: []
    },
    [mockOrchestrationValues.CREATE_TABLE_1.id]: {
      id: mockOrchestrationValues.CREATE_TABLE_1.id,
      inputCardinality: Cardinality.ONE,
      outputCardinality: Cardinality.MANY,
      connectorHint: 'SUCCESS_FAIL',
      executionHint: ExecutionHint.Execute,
      implementationID: 1611478312,
      x: -2380,
      y: 365,
      width: 32,
      height: 32,
      inputConnectorIDs: [],
      outputSuccessConnectorIDs: [],
      outputFailureConnectorIDs: [],
      outputUnconditionalConnectorIDs: [],
      outputTrueConnectorIDs: [],
      outputFalseConnectorIDs: [],
      exportMappings: {},
      parameters: {
        1: {
          slot: 1,
          name: 'Name',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: mockOrchestrationValues.CREATE_TABLE_1.name
                }
              }
            }
          },
          visible: true,
          status: {
            1278: {
              environmentID: 1278,
              validationState: ValidationState.OK,
              validationMessage: ''
            }
          },
          columnNames: ['Unknown']
        },
        2: {
          slot: 2,
          name: 'New Table Name',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: ''
                }
              }
            }
          },
          visible: true,
          status: {
            1278: {
              environmentID: 1278,
              validationState: ValidationState.INPUT_REQUIRED,
              validationMessage: 'Input required.'
            }
          },
          columnNames: ['Unknown']
        },
        3: {
          slot: 3,
          name: 'Create/Replace',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'Create'
                }
              }
            }
          },
          visible: true,
          status: {
            1278: {
              environmentID: 1278,
              validationState: ValidationState.OK,
              validationMessage: ''
            }
          },
          columnNames: ['Unknown']
        },
        4: {
          slot: 4,
          name: 'Table Metadata',
          elements: {},
          visible: true,
          status: {
            1278: {
              environmentID: 1278,
              validationState: ValidationState.INPUT_REQUIRED,
              validationMessage: 'No input provided'
            }
          },
          columnNames: [
            'Column Name',
            'Data Type',
            'Size',
            'Decimal Places',
            'Encoding Type',
            'Allow Nullable',
            'Comment'
          ]
        },
        5: {
          slot: 5,
          name: 'Distribution Style',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'Auto'
                }
              }
            }
          },
          visible: true,
          status: {
            1278: {
              environmentID: 1278,
              validationState: ValidationState.OK,
              validationMessage: ''
            }
          },
          columnNames: ['Unknown']
        },
        6: {
          slot: 6,
          name: 'Distribution Key',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: ''
                }
              }
            }
          },
          visible: false,
          status: {
            1278: {
              environmentID: 1278,
              validationState: ValidationState.INPUT_REQUIRED,
              validationMessage: 'Input required.'
            }
          },
          columnNames: ['Distribution Key']
        },
        7: {
          slot: 7,
          name: 'Sort Key',
          elements: {},
          visible: true,
          status: {
            1278: {
              environmentID: 1278,
              validationState: ValidationState.OK,
              validationMessage: ''
            }
          },
          columnNames: ['Sort Key']
        },
        8: {
          slot: 8,
          name: 'Schema',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: '[Environment Default]'
                }
              }
            }
          },
          visible: true,
          status: {
            1278: {
              environmentID: 1278,
              validationState: ValidationState.OK,
              validationMessage: ''
            }
          },
          columnNames: ['Unknown']
        },
        9: {
          slot: 9,
          name: 'Primary Key',
          elements: {},
          visible: true,
          status: {
            1278: {
              environmentID: 1278,
              validationState: ValidationState.OK,
              validationMessage: ''
            }
          },
          columnNames: ['Primary Key']
        },
        11: {
          slot: 11,
          name: 'Identity Columns',
          elements: {},
          visible: true,
          status: {
            1278: {
              environmentID: 1278,
              validationState: ValidationState.OK,
              validationMessage: ''
            }
          },
          columnNames: ['Column Name', 'Seed', 'Step']
        },
        14: {
          slot: 14,
          name: 'Sort Key Options',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'Compound'
                }
              }
            }
          },
          visible: false,
          status: {
            1278: {
              environmentID: 1278,
              validationState: ValidationState.OK,
              validationMessage: ''
            }
          },
          columnNames: ['Unknown']
        },
        15: {
          slot: 15,
          name: 'Backup Table',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'Yes'
                }
              }
            }
          },
          visible: true,
          status: {
            1278: {
              environmentID: 1278,
              validationState: ValidationState.OK,
              validationMessage: ''
            }
          },
          columnNames: ['Unknown']
        }
      },
      validationStatus: {
        1278: {
          environmentID: 1278,
          validationState: ValidationState.INPUT_ERROR,
          validationMessage: 'Component requires 1 input connection.'
        }
      },
      status: {
        1278: {
          environmentID: 1278,
          sql: 'No SQL could be generated because of invalid parameters.',
          showSql: false
        }
      },
      expectedFailure: null,
      activationStatus: 'ENABLED',
      outputIterationConnectorIDs: [],
      inputIterationConnectorIDs: []
    },
    [mockOrchestrationValues.SQL_SCRIPT_0.id]: {
      id: mockOrchestrationValues.SQL_SCRIPT_0.id,
      inputCardinality: Cardinality.ONE,
      outputCardinality: Cardinality.MANY,
      connectorHint: 'SUCCESS_FAIL',
      executionHint: ExecutionHint.Execute,
      implementationID: -798585337,
      x: -2607,
      y: 305,
      width: 32,
      height: 32,
      inputConnectorIDs: [],
      outputSuccessConnectorIDs: [],
      outputFailureConnectorIDs: [],
      outputUnconditionalConnectorIDs: [],
      outputTrueConnectorIDs: [],
      outputFalseConnectorIDs: [],
      exportMappings: {},
      parameters: {
        1: {
          slot: 1,
          name: 'Name',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: mockOrchestrationValues.SQL_SCRIPT_0.name
                }
              }
            }
          },
          visible: true
        },
        2: {
          slot: 2,
          name: 'SQL Script',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value:
                    "INSERT INTO FROSTY_BORG.ETLD.SQL_SCRIPT_TEST_TABLE\\n\tVALUES ('test varchar value', 1, 84893085, TRUE, '1968-04-22', '2015-10-16 04:38:22', '21:48:15.000')"
                }
              }
            }
          },
          visible: true
        }
      },
      expectedFailure: null,
      activationStatus: 'ENABLED',
      outputIterationConnectorIDs: [],
      inputIterationConnectorIDs: []
    },
    [mockOrchestrationValues.RETRY_0.id]: {
      id: mockOrchestrationValues.RETRY_0.id,
      inputCardinality: Cardinality.ONE,
      outputCardinality: Cardinality.MANY,
      connectorHint: 'SUCCESS_FAIL',
      executionHint: ExecutionHint.Iterate,
      implementationID: -158614544,
      x: -2448,
      y: 64,
      width: 32,
      height: 16,
      inputConnectorIDs: [15668],
      outputSuccessConnectorIDs: [13701],
      outputFailureConnectorIDs: [15698],
      outputUnconditionalConnectorIDs: [16086],
      outputTrueConnectorIDs: [],
      outputFalseConnectorIDs: [],
      exportMappings: {},
      parameters: {
        1: {
          slot: 1,
          name: 'Name',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: mockOrchestrationValues.RETRY_0.name
                }
              }
            }
          },
          visible: true,
          status: {
            1288: {
              environmentID: 1288,
              validationState: ValidationState.OK,
              validationMessage: ''
            }
          },
          columnNames: ['Unknown']
        },
        2: {
          slot: 2,
          name: 'Number of Retries',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'INTEGER',
                  dataType: ParameterDataType.INTEGER,
                  value: '1'
                }
              }
            }
          },
          visible: true,
          status: {
            1288: {
              environmentID: 1288,
              validationState: ValidationState.OK,
              validationMessage: ''
            }
          },
          columnNames: ['Unknown']
        },
        3: {
          slot: 3,
          name: 'Retry Delay',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'Retry immediately'
                }
              }
            }
          },
          visible: true,
          status: {
            1288: {
              environmentID: 1288,
              validationState: ValidationState.OK,
              validationMessage: ''
            }
          },
          columnNames: ['Unknown']
        },
        4: {
          slot: 4,
          name: 'Retry Interval',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'INTEGER',
                  dataType: ParameterDataType.INTEGER,
                  value: '1'
                }
              }
            }
          },
          visible: false,
          status: {
            1288: {
              environmentID: 1288,
              validationState: ValidationState.OK,
              validationMessage: ''
            }
          },
          columnNames: ['Unknown']
        },
        5: {
          slot: 5,
          name: 'Retry Time Unit',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'Second'
                }
              }
            }
          },
          visible: false,
          status: {
            1288: {
              environmentID: 1288,
              validationState: ValidationState.OK,
              validationMessage: ''
            }
          },
          columnNames: ['Retry Time Unit']
        }
      },
      validationStatus: {
        1288: {
          environmentID: 1288,
          validationState: ValidationState.OK,
          validationMessage: ''
        }
      },
      status: {},
      expectedFailure: null,
      activationStatus: 'ENABLED',
      outputIterationConnectorIDs: [13704],
      inputIterationConnectorIDs: []
    },
    [mockOrchestrationValues.S3_LOAD_0.id]: {
      id: mockOrchestrationValues.S3_LOAD_0.id,
      inputCardinality: Cardinality.ONE,
      outputCardinality: Cardinality.MANY,
      connectorHint: 'SUCCESS_FAIL',
      executionHint: ExecutionHint.Execute,
      implementationID: 773867971,
      x: 464,
      y: 0,
      width: 32,
      height: 32,
      inputConnectorIDs: [],
      outputSuccessConnectorIDs: [],
      outputFailureConnectorIDs: [],
      outputUnconditionalConnectorIDs: [],
      outputTrueConnectorIDs: [],
      outputFalseConnectorIDs: [],
      exportMappings: {},
      parameters: {
        1: {
          slot: 1,
          name: 'Name',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: mockOrchestrationValues.S3_LOAD_0.name
                }
              }
            }
          },
          visible: true
        },
        4: {
          slot: 4,
          name: 'S3 Object Prefix',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 's3://etld-test-metl-bucket/'
                }
              }
            }
          },
          visible: true
        },
        5: {
          slot: 5,
          name: 'Warehouse',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'REGRESSION_WH'
                }
              }
            }
          },
          visible: true
        },
        6: {
          slot: 6,
          name: 'Database',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'FROSTY_BORG'
                }
              }
            }
          },
          visible: true
        },
        7: {
          slot: 7,
          name: 'Schema',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'ETLD'
                }
              }
            }
          },
          visible: true
        },
        8: {
          slot: 8,
          name: 'Target Table',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'S3_LOAD_EXAMPLE'
                }
              }
            }
          },
          visible: true
        },
        9: {
          slot: 9,
          name: 'Load Columns',
          elements: {},
          visible: true
        },
        10: {
          slot: 10,
          name: 'Format',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: '[Custom]'
                }
              }
            }
          },
          visible: true
        },
        11: {
          slot: 11,
          name: 'File Type',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'CSV'
                }
              }
            }
          },
          visible: false
        },
        12: {
          slot: 12,
          name: 'Compression',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'AUTO'
                }
              }
            }
          },
          visible: false
        },
        20: {
          slot: 20,
          name: 'Record Delimiter',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: ''
                }
              }
            }
          },
          visible: false
        },
        21: {
          slot: 21,
          name: 'Field Delimiter',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: ','
                }
              }
            }
          },
          visible: false
        },
        22: {
          slot: 22,
          name: 'Skip Header',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: '1'
                }
              }
            }
          },
          visible: false
        },
        23: {
          slot: 23,
          name: 'Date Format',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: ''
                }
              }
            }
          },
          visible: false
        },
        24: {
          slot: 24,
          name: 'Time Format',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: ''
                }
              }
            }
          },
          visible: false
        },
        25: {
          slot: 25,
          name: 'Timestamp Format',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: ''
                }
              }
            }
          },
          visible: false
        },
        26: { slot: 26, name: '', elements: {}, visible: false },
        27: {
          slot: 27,
          name: 'Escape',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: ''
                }
              }
            }
          },
          visible: false
        },
        28: {
          slot: 28,
          name: 'Escape Unenclosed Field',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: ''
                }
              }
            }
          },
          visible: false
        },
        29: {
          slot: 29,
          name: 'Trim Space',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'False'
                }
              }
            }
          },
          visible: false
        },
        30: {
          slot: 30,
          name: 'Field Optionally Enclosed',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: '"'
                }
              }
            }
          },
          visible: false
        },
        31: {
          slot: 31,
          name: 'Null If',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: ''
                }
              }
            }
          },
          visible: false
        },
        32: {
          slot: 32,
          name: 'Error On Column Count Mismatch',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'False'
                }
              }
            }
          },
          visible: false
        },
        33: {
          slot: 33,
          name: 'Empty Field as Null',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'False'
                }
              }
            }
          },
          visible: false
        },
        34: {
          slot: 34,
          name: 'Encoding Type',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: ''
                }
              }
            }
          },
          visible: false
        },
        35: {
          slot: 35,
          name: 'Skip Blank Lines',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'False'
                }
              }
            }
          },
          visible: false
        },
        36: {
          slot: 36,
          name: 'Replace Invalid Characters',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'False'
                }
              }
            }
          },
          visible: false
        },
        40: {
          slot: 40,
          name: 'Enable Octal',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'False'
                }
              }
            }
          },
          visible: false
        },
        41: {
          slot: 41,
          name: 'Allow Duplicates',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'False'
                }
              }
            }
          },
          visible: false
        },
        42: {
          slot: 42,
          name: 'Strip Outer Array',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'False'
                }
              }
            }
          },
          visible: false
        },
        43: {
          slot: 43,
          name: 'Strip Null Values',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'False'
                }
              }
            }
          },
          visible: false
        },
        44: {
          slot: 44,
          name: 'Ignore UTF8 Errors',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'False'
                }
              }
            }
          },
          visible: false
        },
        45: {
          slot: 45,
          name: 'Nest Columns',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'False'
                }
              }
            }
          },
          visible: false
        },
        46: {
          slot: 46,
          name: 'Null If',
          elements: {},
          visible: false
        },
        47: {
          slot: 47,
          name: 'Trim Space',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'False'
                }
              }
            }
          },
          visible: false
        },
        50: {
          slot: 50,
          name: 'Null If',
          elements: {},
          visible: false
        },
        51: {
          slot: 51,
          name: 'Trim Space',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'False'
                }
              }
            }
          },
          visible: false
        },
        60: {
          slot: 60,
          name: 'Ignore UTF8 Errors',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'False'
                }
              }
            }
          },
          visible: false
        },
        61: {
          slot: 61,
          name: 'Preserve Space',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'False'
                }
              }
            }
          },
          visible: false
        },
        62: {
          slot: 62,
          name: 'Strip Outer Element',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'False'
                }
              }
            }
          },
          visible: false
        },
        63: {
          slot: 63,
          name: 'Disable Snowflake Data',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'False'
                }
              }
            }
          },
          visible: false
        },
        64: {
          slot: 64,
          name: 'Disable Auto Convert',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'False'
                }
              }
            }
          },
          visible: false
        },
        66: {
          slot: 66,
          name: 'Null If',
          elements: {},
          visible: false
        },
        67: {
          slot: 67,
          name: 'Trim Space',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'False'
                }
              }
            }
          },
          visible: false
        },
        70: {
          slot: 70,
          name: 'Null If',
          elements: {},
          visible: false
        },
        71: {
          slot: 71,
          name: 'Trim Space',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'False'
                }
              }
            }
          },
          visible: false
        },
        120: {
          slot: 120,
          name: 'On Error',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'Abort Statement'
                }
              }
            }
          },
          visible: true
        },
        121: {
          slot: 121,
          name: 'n',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'INTEGER',
                  dataType: ParameterDataType.INTEGER,
                  value: ''
                }
              }
            }
          },
          visible: false
        },
        122: {
          slot: 122,
          name: 'Size Limit (B)',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'INTEGER',
                  dataType: ParameterDataType.INTEGER,
                  value: ''
                }
              }
            }
          },
          visible: true
        },
        123: {
          slot: 123,
          name: 'Purge Files',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'False'
                }
              }
            }
          },
          visible: true
        },
        124: {
          slot: 124,
          name: 'Truncate Columns',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'False'
                }
              }
            }
          },
          visible: true
        },
        125: {
          slot: 125,
          name: 'Force Load',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'True'
                }
              }
            }
          },
          visible: true
        },
        126: {
          slot: 126,
          name: 'Match By Column Name',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'None'
                }
              }
            }
          },
          visible: false
        },
        140: {
          slot: 140,
          name: 'Encryption',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'None'
                }
              }
            }
          },
          visible: true
        },
        141: {
          slot: 141,
          name: 'Master Key',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: ''
                }
              }
            }
          },
          visible: false
        },
        142: {
          slot: 142,
          name: 'KMS Key Id',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: ''
                }
              }
            }
          },
          visible: false
        },
        143: {
          slot: 143,
          name: 'Authentication',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'Credentials'
                }
              }
            }
          },
          visible: true
        },
        144: {
          slot: 144,
          name: 'Storage Integration',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: ''
                }
              }
            }
          },
          visible: false
        },
        200: {
          slot: 200,
          name: 'Stage',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: '[Custom]'
                }
              }
            }
          },
          visible: true
        },
        201: {
          slot: 201,
          name: 'Pattern',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'etld-metl-test-data/1000_rows.csv'
                }
              }
            }
          },
          visible: true
        },
        203: {
          slot: 203,
          name: 'Metadata Fields',
          elements: {},
          visible: false
        }
      },
      expectedFailure: null,
      activationStatus: 'ENABLED',
      outputIterationConnectorIDs: [],
      inputIterationConnectorIDs: []
    },
    [mockOrchestrationValues.SQS_MESSAGE_0.id]: {
      id: mockOrchestrationValues.SQS_MESSAGE_0.id,
      inputCardinality: Cardinality.ONE,
      outputCardinality: Cardinality.MANY,
      connectorHint: 'SUCCESS_FAIL',
      executionHint: 'EXECUTE',
      implementationID: -159239741,
      x: 500,
      y: 0,
      width: 32,
      height: 32,
      inputConnectorIDs: [983273],
      outputSuccessConnectorIDs: [],
      outputFailureConnectorIDs: [],
      outputUnconditionalConnectorIDs: [],
      outputTrueConnectorIDs: [],
      outputFalseConnectorIDs: [],
      exportMappings: {},
      parameters: {
        1: {
          slot: 1,
          name: 'Name',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: mockOrchestrationValues.SQS_MESSAGE_0.name
                }
              }
            }
          },
          visible: true
        },
        2: {
          slot: 2,
          name: 'Region',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'eu-west-1'
                }
              }
            }
          },
          visible: true
        },
        3: {
          slot: 3,
          name: 'Queue Name',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'ETLD_SQS_TESTING'
                }
              }
            }
          },
          visible: true
        },
        4: {
          slot: 4,
          name: 'Message',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'Plain text message test'
                }
              }
            }
          },
          visible: true
        },
        5: {
          slot: 5,
          name: 'Message Format',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'Plain'
                }
              }
            }
          },
          visible: true
        },
        6: {
          slot: 6,
          name: 'Message Group Id',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: ''
                }
              }
            }
          },
          visible: false
        },
        7: {
          slot: 7,
          name: 'Message Deduplication Id',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: ''
                }
              }
            }
          },
          visible: false
        }
      },
      expectedFailure: null,
      activationStatus: 'ENABLED',
      outputIterationConnectorIDs: [],
      inputIterationConnectorIDs: []
    },
    [mockOrchestrationValues.CREATE_FILE_FORMAT_0.id]: {
      id: mockOrchestrationValues.CREATE_FILE_FORMAT_0.id,
      inputCardinality: Cardinality.ONE,
      outputCardinality: Cardinality.MANY,
      connectorHint: 'SUCCESS_FAIL',
      executionHint: null,
      implementationID: 655623811,
      x: 416,
      y: 486,
      width: 32,
      height: 32,
      inputConnectorIDs: [1060],
      outputSuccessConnectorIDs: [],
      outputFailureConnectorIDs: [],
      outputUnconditionalConnectorIDs: [],
      outputTrueConnectorIDs: [],
      outputFalseConnectorIDs: [],
      exportMappings: {},
      parameters: {
        1: {
          slot: 1,
          name: 'Name',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: mockOrchestrationValues.CREATE_FILE_FORMAT_0.name
                }
              }
            }
          },
          visible: true
        },
        5: {
          slot: 5,
          name: 'File format name',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'ETLD_FILE_FORMAT_TEST'
                }
              }
            }
          },
          visible: true
        },
        6: {
          slot: 6,
          name: 'Create/Replace',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'Create if not exists'
                }
              }
            }
          },
          visible: true
        },
        7: {
          slot: 7,
          name: 'Database',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'FROSTY_BORG'
                }
              }
            }
          },
          visible: true
        },
        8: {
          slot: 8,
          name: 'Schema',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'ETLD'
                }
              }
            }
          },
          visible: true
        },
        11: {
          slot: 11,
          name: 'File type',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'CSV'
                }
              }
            }
          },
          visible: true
        },
        12: {
          slot: 12,
          name: 'Compression',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'AUTO'
                }
              }
            }
          },
          visible: true
        },
        20: {
          slot: 20,
          name: 'Record delimiter',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: '\\n'
                }
              }
            }
          },
          visible: true
        },
        21: {
          slot: 21,
          name: 'Field delimiter',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: ','
                }
              }
            }
          },
          visible: true
        },
        22: {
          slot: 22,
          name: 'Skip header',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: '1'
                }
              }
            }
          },
          visible: true
        },
        23: {
          slot: 23,
          name: 'Date format',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'yyyy-mm-dd'
                }
              }
            }
          },
          visible: true
        },
        24: {
          slot: 24,
          name: 'Time format',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'auto'
                }
              }
            }
          },
          visible: true
        },
        25: {
          slot: 25,
          name: 'Timestamp format',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'auto'
                }
              }
            }
          },
          visible: true
        },
        27: {
          slot: 27,
          name: 'Escape',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'NONE'
                }
              }
            }
          },
          visible: true
        },
        28: {
          slot: 28,
          name: 'Escape unenclosed field',
          elements: {},
          visible: true
        },
        29: {
          slot: 29,
          name: 'Trim space',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'False'
                }
              }
            }
          },
          visible: true
        },
        30: {
          slot: 30,
          name: 'Field optionally enclosed',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'NONE'
                }
              }
            }
          },
          visible: true
        },
        31: {
          slot: 31,
          name: 'Null if',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'Test'
                }
              }
            }
          },
          visible: true
        },
        32: {
          slot: 32,
          name: 'Error on column count mismatch',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'False'
                }
              }
            }
          },
          visible: true
        },
        33: {
          slot: 33,
          name: 'Empty field as null',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'True'
                }
              }
            }
          },
          visible: true
        },
        34: {
          slot: 34,
          name: 'Encoding type',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'UTF8'
                }
              }
            }
          },
          visible: true
        }
      },
      expectedFailure: null,
      activationStatus: 'ENABLED',
      outputIterationConnectorIDs: [],
      inputIterationConnectorIDs: []
    },
    [mockOrchestrationValues.SNS_MESSAGE_0.id]: {
      id: mockOrchestrationValues.SNS_MESSAGE_0.id,
      inputCardinality: Cardinality.ONE,
      outputCardinality: Cardinality.MANY,
      connectorHint: 'SUCCESS_FAIL',
      executionHint: ExecutionHint.Execute,
      implementationID: 438858066,
      x: 163,
      y: 200,
      width: 32,
      height: 32,
      inputConnectorIDs: [],
      outputSuccessConnectorIDs: [],
      outputFailureConnectorIDs: [],
      outputUnconditionalConnectorIDs: [],
      outputTrueConnectorIDs: [],
      outputFalseConnectorIDs: [],
      exportMappings: {},
      parameters: {
        1: {
          slot: 1,
          name: 'Name',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: mockOrchestrationValues.SNS_MESSAGE_0.name
                }
              }
            }
          },
          visible: true,
          status: {},
          columnNames: []
        },
        2: {
          slot: 2,
          name: 'componentProperties.parameterLabels.aws-region',
          elements: {},
          visible: true,
          status: {},
          columnNames: []
        },
        3: {
          slot: 3,
          name: 'componentProperties.parameterLabels.topic-name',
          elements: {},
          visible: true,
          status: {},
          columnNames: []
        },
        4: {
          slot: 4,
          name: 'componentProperties.parameterLabels.subject',
          elements: {},
          visible: true,
          status: {},
          columnNames: []
        },
        5: {
          slot: 5,
          name: 'componentProperties.parameterLabels.message',
          elements: {},
          visible: true,
          status: {},
          columnNames: []
        }
      },
      validationStatus: {},
      status: {},
      expectedFailure: null,
      activationStatus: 'ENABLED',
      outputIterationConnectorIDs: [],
      inputIterationConnectorIDs: []
    },
    [mockOrchestrationValues.ASSERT_TABLE_0.id]: {
      id: mockOrchestrationValues.ASSERT_TABLE_0.id,
      inputCardinality: Cardinality.ONE,
      outputCardinality: Cardinality.MANY,
      connectorHint: 'SUCCESS_FAIL',
      executionHint: ExecutionHint.Execute,
      implementationID: -377483579,
      x: 160,
      y: 0,
      width: 32,
      height: 32,
      inputConnectorIDs: [1056131],
      outputSuccessConnectorIDs: [],
      outputFailureConnectorIDs: [],
      outputUnconditionalConnectorIDs: [],
      outputTrueConnectorIDs: [],
      outputFalseConnectorIDs: [],
      exportMappings: {},
      parameters: {
        1: {
          slot: 1,
          name: 'Name',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: mockOrchestrationValues.ASSERT_TABLE_0.name
                }
              }
            }
          },
          visible: true
        },
        2: {
          slot: 2,
          name: 'Database',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: ''
                }
              }
            }
          },
          visible: true
        },
        3: {
          slot: 3,
          name: 'Schema',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: ''
                }
              }
            }
          },
          visible: true
        },
        4: {
          slot: 4,
          name: 'Table Name',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'dmn_airport'
                }
              }
            }
          },
          visible: true
        },
        5: {
          slot: 5,
          name: 'Table Metadata',
          elements: {},
          visible: true
        },
        100: {
          slot: 100,
          name: 'Exists',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'Yes'
                }
              }
            }
          },
          visible: true
        },
        101: {
          slot: 101,
          name: 'Row Count Comparison Type',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: ''
                }
              }
            }
          },
          visible: true
        },
        102: {
          slot: 102,
          name: 'Row Count Value',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'BIGINT',
                  dataType: ParameterDataType.NUMERIC,
                  value: ''
                }
              }
            }
          },
          visible: true
        },
        103: {
          slot: 103,
          name: 'Lower Value',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'BIGINT',
                  dataType: ParameterDataType.NUMERIC,
                  value: ''
                }
              }
            }
          },
          visible: false
        },
        104: {
          slot: 104,
          name: 'Upper Value',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'BIGINT',
                  dataType: ParameterDataType.NUMERIC,
                  value: ''
                }
              }
            }
          },
          visible: false
        }
      },
      expectedFailure: null,
      activationStatus: 'ENABLED',
      outputIterationConnectorIDs: [],
      inputIterationConnectorIDs: []
    },
    [mockOrchestrationValues.IF_0.id]: {
      id: mockOrchestrationValues.IF_0.id,
      inputCardinality: Cardinality.ONE,
      outputCardinality: Cardinality.MANY,
      connectorHint: 'SUCCESS_FAIL',
      executionHint: ExecutionHint.Execute,
      implementationID: -1357378929,
      x: -165,
      y: -45,
      width: 32,
      height: 32,
      inputConnectorIDs: [],
      outputSuccessConnectorIDs: [],
      outputFailureConnectorIDs: [],
      outputUnconditionalConnectorIDs: [],
      outputTrueConnectorIDs: [],
      outputFalseConnectorIDs: [],
      exportMappings: {},
      parameters: {
        1: {
          slot: 1,
          name: 'Name',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  value: mockOrchestrationValues.IF_0.name,
                  dataType: ParameterDataType.TEXT
                }
              }
            }
          },
          visible: true
        },
        2: {
          slot: 2,
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  value: 'Simple',
                  dataType: ParameterDataType.TEXT
                }
              }
            }
          },
          name: 'Mode',
          visible: true
        },
        3: {
          slot: 3,
          elements: {},
          name: 'Condition',
          visible: true
        },
        4: {
          slot: 4,
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  value: 'And',
                  dataType: ParameterDataType.TEXT
                }
              }
            }
          },
          name: 'Combine Conditions',
          visible: true
        },
        5: {
          slot: 5,
          elements: {},
          name: 'Condition',
          visible: false
        }
      },
      expectedFailure: null,
      activationStatus: 'ENABLED',
      outputIterationConnectorIDs: [],
      inputIterationConnectorIDs: []
    }
  },
  successConnectors: {
    13701: {
      id: 13701,
      sourceID: mockOrchestrationValues.RETRY_0.id,
      targetID: mockOrchestrationValues.END_SUCCESS_0.id
    }
  },
  failureConnectors: {
    15698: {
      id: 15698,
      sourceID: mockOrchestrationValues.RETRY_0.id,
      targetID: mockOrchestrationValues.END_FAILURE_0.id
    }
  },
  unconditionalConnectors: {
    15665: {
      id: 15665,
      sourceID: mockOrchestrationValues.START_0.id,
      targetID: mockOrchestrationValues.AND_0.id
    },
    15668: {
      id: 15668,
      sourceID: mockOrchestrationValues.AND_0.id,
      targetID: mockOrchestrationValues.RETRY_0.id
    },
    16086: {
      id: 16086,
      sourceID: mockOrchestrationValues.RETRY_0.id,
      targetID: mockOrchestrationValues.RUN_TRANSFORMATION_0.id
    }
  },
  trueConnectors: {},
  falseConnectors: {},
  iterationConnectors: {
    13704: {
      id: 13704,
      sourceID: mockOrchestrationValues.RETRY_0.id,
      targetID: mockOrchestrationValues.CREATE_TABLE_0.id
    }
  },
  noteConnectors: {},
  canUndo: true,
  undoCommand: 'Set Parameter',
  undoCreated: 1670264111743,
  canRedo: false,
  redoCommand: '',
  redoCreated: -1,
  notes: {},
  variables: {
    variable_with_description: {
      definition: {
        name: 'variable_with_description',
        type: JobVariableType.TEXT,
        behaviour: JobVariableBehaviour.SHARED,
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum volutpat malesuada tristique. Mauris suscipit turpis nec scelerisque convallis. Nullam condimentum mi at justo condimentum semper. Sed at porttitor dolor. Donec lectus tortor, scelerisque non semper sit amet, rhoncus vitae nisi. Mauris ut lacus augue. Integer malesuada neque vitae odio interdum ultricies. Praesent id venenatis justo, at laoreet velit. Suspendisse malesuada metus et eros auctor auctor. Proin aliquet enim ut bibendum laoreet. Fusce tincidunt, risus quis efficitur malesuada, risus ipsum facilisis ligula, ut tempor libero dui non justo. Proin dignissim sagittis elementum. Curabitur viverra facilisis euismod.',
        visibility: JobVariableVisibility.PUBLIC
      },
      value: ''
    },
    private_variable_with_default: {
      definition: {
        name: 'private_variable_with_default',
        type: JobVariableType.TEXT,
        behaviour: JobVariableBehaviour.SHARED,
        description: '',
        visibility: JobVariableVisibility.PRIVATE
      },
      value: 'default value'
    },
    numeric_variable_with_default: {
      definition: {
        name: 'numeric_variable_with_default',
        type: JobVariableType.NUMBER,
        behaviour: JobVariableBehaviour.SHARED,
        description: '',
        visibility: JobVariableVisibility.PUBLIC
      },
      value: '1'
    },
    shared_variable: {
      definition: {
        name: 'shared_variable',
        type: JobVariableType.TEXT,
        behaviour: JobVariableBehaviour.SHARED,
        description: '',
        visibility: JobVariableVisibility.PUBLIC
      },
      value: ''
    },
    copied_variable: {
      definition: {
        name: 'copied_variable',
        type: JobVariableType.TEXT,
        behaviour: JobVariableBehaviour.COPIED,
        description: '',
        visibility: JobVariableVisibility.PUBLIC
      },
      value: ''
    }
  }
}
