import { useCallback } from 'react'

import { useMutation } from '@tanstack/react-query'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { useSpringClient } from '../useSpringClient/useSpringClient'
import { PublishJobsRequest } from './types'

export interface PublishArgs {
  commitId: string
  environmentId: string
  branchName: string
}

export const usePublishJobs = () => {
  const { projectId } = useProjectInfo()
  const client = useSpringClient()

  const publish = useCallback(
    async (args: PublishArgs) => {
      const request: PublishJobsRequest = {
        environmentId: args.environmentId,
        commitId: args.commitId,
        branchName: args.branchName,
        projectId
      }

      const { status } = await client.post('job-publications', request)
      return status
    },
    [client, projectId]
  )

  return useMutation([projectId], publish)
}
