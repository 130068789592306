import { FC, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Tab, Tabs } from '@matillion/component-library'

import { useListProjectVariables } from 'api/hooks/useListProjectVariables/useListProjectVariables'

import { VariableScope } from 'job-lib/types/Variables'

import { useWorkingCopy } from 'modules/EtlDesigner/hooks/useWorkingCopy'

import { ManageVariableStageProps, Stages } from '../../types'
import { JobVariables } from './JobVariables'
import classes from './Manage.module.scss'
import { ProjectVariables } from './ProjectVariables/ProjectVariables'

const TabContentContainer: FC<PropsWithChildren> = ({ children }) => (
  <div className={classes.ManageVariables__Content}>{children}</div>
)

export const Manage = ({
  setStage,
  setSelectedVariable,
  setSelectedVariableScope,
  selectedVariableScope
}: ManageVariableStageProps) => {
  const { job } = useWorkingCopy()

  const {
    data: projectVariables,
    isFetching: isLoading,
    isError
  } = useListProjectVariables({ staleTime: 0 })
  const { t } = useTranslation('translation', { keyPrefix: 'manageVariables' })

  const handleTabChange = ({ tab }: Record<'tab', unknown>) => {
    const scope: VariableScope = (
      tab as Record<'data-tab-scope', VariableScope>
    )['data-tab-scope']

    /* istanbul ignore else */
    if (scope && setSelectedVariableScope) {
      setSelectedVariableScope(scope)
    }
  }

  const numJobVariables = Object.keys(job?.variables ?? {}).length
  const numProjectVariables = projectVariables?.length ?? 0

  return (
    <>
      <Button
        className={classes.ManageVariables__CreateButton}
        onClick={() => setStage(Stages.CREATE)}
        text={t('common.createVariable')}
        data-testid="create-variable"
        alt="positive"
      />
      <Tabs
        className={classes.ManageVariables__Tabs}
        listClassName={classes.ManageVariables__TabList}
        contentClassName={classes.ManageVariables__Content}
        contentContainer={TabContentContainer}
        activeIndex={
          selectedVariableScope === VariableScope.JOB_VARIABLE ? 0 : 1
        }
        onChange={handleTabChange}
      >
        <Tab
          data-tab-scope={VariableScope.JOB_VARIABLE}
          data-testid="tab-job-variables"
          title={t('manage.jobVariablesWithCount', {
            numJobVariables
          })}
        >
          <JobVariables
            variables={job?.variables}
            onDelete={(name) => {
              setSelectedVariable?.({
                type: VariableScope.JOB_VARIABLE,
                name
              })
              setStage(Stages.DELETE)
            }}
            onEdit={(name) => {
              setSelectedVariable?.({
                type: VariableScope.JOB_VARIABLE,
                name
              })
              setStage(Stages.EDIT)
            }}
          />
        </Tab>
        <Tab
          data-tab-scope={VariableScope.PROJECT_VARIABLE}
          data-testid="tab-project-variables"
          title={t('manage.projectVariablesWithCount', {
            numProjectVariables
          })}
        >
          <ProjectVariables
            isLoading={isLoading}
            isError={isError}
            variables={projectVariables}
            onDelete={(id, name) => {
              setSelectedVariable?.({
                type: VariableScope.PROJECT_VARIABLE,
                id,
                name
              })
              setStage(Stages.DELETE)
            }}
            onEdit={(id, name) => {
              setSelectedVariable?.({
                type: VariableScope.PROJECT_VARIABLE,
                id,
                name
              })
              setStage(Stages.EDIT)
            }}
          />
        </Tab>
      </Tabs>
    </>
  )
}
