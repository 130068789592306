import { FC, lazy, Suspense, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon, Typography } from '@matillion/component-library'

import { CodeEditorRef } from 'components/CodeEditor'
import { Loading } from 'components/Loading/Loading'
import {
  ParameterOverlayButton,
  ParameterOverlayFooter,
  ParameterOverlayHeader
} from 'components/ParameterOverlay'
import SQLShortcuts from 'components/SQLShortcuts/SQLShortcuts'
import { TextInput } from 'components/TextInput'

import { ComponentDependencyDataBag } from 'modules/ComponentValidation/types'

import { Collapsible } from './components/Collapsible/Collapsible'
import { EntriesList } from './components/EntriesList'
import FieldsAndVariablesPanel from './components/FieldsAndVariablesPanel/FieldsAndVariablesPanel'
import classes from './MultiExpressionsEditor.module.scss'
import {
  ExpressionEntry,
  MultiExpressionsEditorProps,
  SourceColumns
} from './types'
import { blankEntry, convertToElements, init } from './utils'

const CodeEditor = lazy(async () => import('components/CodeEditor'))

const DuplicateNameError = () => {
  const { t } = useTranslation()

  return (
    <div className={classes.ValidityMessage}>
      <Icon.Error />
      <div>
        <Typography as="span" format="bcs" weight="bold">
          {t('parameterEditor.MULTI_EXPRESSIONS_EDITOR.duplicateNameTitle')}
        </Typography>{' '}
        <Typography as="span" format="bcs">
          {t('parameterEditor.MULTI_EXPRESSIONS_EDITOR.duplicateNameDetail')}
        </Typography>
      </div>
    </div>
  )
}

/**
 * Get the source columns from the validation cache.
 */
const getSourceColumns = (
  depBag?: ComponentDependencyDataBag
): SourceColumns => {
  if (!depBag?.cache) {
    return []
  }

  const cols: SourceColumns = []

  Object.entries(depBag.cache).forEach(([componentName, cache]) => {
    if (cache?.columns) {
      cache.columns.forEach((v) => {
        cols.push({
          alias: '',
          column: v.name,
          type: v.datatype?.type
        })
      })
    }
  })

  return cols
}

export const MultiExpressionsEditor: FC<MultiExpressionsEditorProps> = ({
  editorColumns,
  parameterName,
  onDone,
  elements,
  componentInputDepBag
}) => {
  const { t } = useTranslation()
  const nameInputRef = useRef<HTMLInputElement>(null)
  const codeEditorRef = useRef<CodeEditorRef>(null)

  const [activeIndex, setActiveIndex] = useState<number>(0)
  /* istanbul ignore next */
  const focusNameInput = () => setTimeout(() => nameInputRef.current?.focus())
  useEffect(() => {
    focusNameInput()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const [entries, setEntries] = useState<ExpressionEntry[]>(init(elements))
  const activeEntry = entries[activeIndex]
  const duplicateName =
    activeEntry &&
    entries.filter((entry) => entry.name === activeEntry.name).length > 1

  const handleOnNameChange = (newValue: string) =>
    setEntries((curEntries) =>
      curEntries.map((entry, index) => {
        if (index === activeIndex) {
          return {
            ...entry,
            name: newValue
          }
        }
        return entry
      })
    )

  const dispatchEditorUpdate = (updateText: string) => {
    codeEditorRef.current?.insertTextAtCursor(updateText)
  }

  const sourceColumns = getSourceColumns(componentInputDepBag)

  return (
    <>
      <ParameterOverlayHeader title={parameterName} />
      <Collapsible
        disabled={!entries.length}
        onChange={(isCollapsed) => {
          if (isCollapsed) {
            setTimeout(() => {
              codeEditorRef.current?.focus()
            })
          }
        }}
        actionLabel={t('parameterEditor.MULTI_EXPRESSIONS_EDITOR.expressions')}
        collapsibleChildren={
          <div className={classes.SideBar}>
            <div className={classes.SideBar__Child}>
              <EntriesList
                value={entries}
                activeIndex={activeIndex}
                onSelect={setActiveIndex}
                onAdd={() => {
                  setEntries((oldEntries) => [
                    ...oldEntries,
                    blankEntry(oldEntries.length)
                  ])
                  setActiveIndex(entries.length)
                  focusNameInput()
                }}
                onRemove={() => {
                  setEntries(
                    entries.filter((entry, index) => index !== activeIndex)
                  )
                  setActiveIndex(0)
                }}
                emptyMessage={t(
                  'parameterEditor.MULTI_EXPRESSIONS_EDITOR.noExpressionsYet'
                )}
              />
            </div>
            <div className={classes.SideBar__Child}>
              <FieldsAndVariablesPanel
                data-testid="multi-expression-editor-fields-and-vars"
                sourceColumns={sourceColumns}
                onColumnClick={({ column }) =>
                  dispatchEditorUpdate(`"${column}"`)
                }
              />
            </div>
          </div>
        }
      >
        <div className={classes.NameWrapper}>
          <TextInput
            ref={nameInputRef}
            className={classes.NameWrapper__NameInput}
            aria-label={t('parameterEditor.MULTI_EXPRESSIONS_EDITOR.name')}
            placeholder={t(
              'parameterEditor.MULTI_EXPRESSIONS_EDITOR.namePlaceholder'
            )}
            disabled={!entries.length}
            value={activeEntry ? activeEntry.name : ''}
            onChange={handleOnNameChange}
            error={duplicateName}
          />
          {duplicateName && <DuplicateNameError />}
        </div>
        <div className={classes.EditorWrapper}>
          <Suspense fallback={<Loading />}>
            <CodeEditor
              language="sql"
              ref={codeEditorRef}
              className={classes.CodeMirrorCodeEditor}
              readOnly={!entries.length}
              value={activeEntry ? activeEntry.expression : ''}
              onChange={(newValue: string) =>
                setEntries(
                  entries.map((entry, index) => {
                    if (index === activeIndex) {
                      return {
                        ...entry,
                        expression: newValue
                      }
                    }
                    return entry
                  })
                )
              }
            />
          </Suspense>
        </div>
        <div>
          <SQLShortcuts updateEditor={dispatchEditorUpdate} />
        </div>
      </Collapsible>
      <ParameterOverlayFooter>
        <ParameterOverlayButton
          disabled={duplicateName}
          onClick={() => onDone(convertToElements(entries, editorColumns))}
          text={t('common.save')}
        />
      </ParameterOverlayFooter>
    </>
  )
}
