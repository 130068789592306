import { initReactI18next } from 'react-i18next'

import i18n from 'i18next'

import componentSummaries from '../locales/en/componentSummaries.json'
import en from '../locales/en/translation.json'

const resources = {
  en: {
    translation: { ...en },
    componentSummaries: { ...componentSummaries }
  }
}

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  interpolation: {
    escapeValue: false // react already safes from xss
  }
})

export default i18n
