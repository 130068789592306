import { RefObject, useEffect } from 'react'

const isInParent = (el: Element | null, container: HTMLElement): boolean => {
  if (!el) {
    return false
  }

  if (el === container) {
    return true
  }

  return isInParent(el.parentElement, container)
}

export const useScopedEvent = <K extends keyof DocumentEventMap>(
  type: K,
  ref: RefObject<HTMLElement>,
  listener: (this: Document, ev: DocumentEventMap[K]) => unknown
) => {
  useEffect(() => {
    const handler = (evt: DocumentEventMap[K]) => {
      if (!ref.current) {
        return
      }
      if (isInParent(document.activeElement, ref.current)) {
        listener.call(document, evt)
      }
    }

    document.addEventListener(type, handler)

    return () => document.removeEventListener(type, handler)
  }, [type, listener, ref])
}
