import { forwardRef, HTMLProps } from 'react'
import { Panel, PanelGroup } from 'react-resizable-panels'

import { Orientation, ResizeableHandler } from 'components/ResizeableHandler'

import ComponentPropertiesPanel from 'modules/ComponentProperties/ComponentPropertiesPanel'
import { TaskHistoryPanel } from 'modules/TaskHistoryPanel/TaskHistoryPanel'

export const Footer = forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    return (
      <PanelGroup direction="horizontal" autoSaveId="footer">
        <Panel id="component-properties" defaultSize={50}>
          <ComponentPropertiesPanel />
        </Panel>

        <ResizeableHandler
          orientation={Orientation.VERTICAL}
          id="inner-footer"
        />

        <Panel id="task-history" defaultSize={50}>
          <TaskHistoryPanel />
        </Panel>
      </PanelGroup>
    )
  }
)

Footer.displayName = 'Footer'
