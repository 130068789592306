import { Radio, Typography } from '@matillion/component-library'

import {
  SelectableCard,
  SelectableCardBody,
  SelectableCardHeader,
  type SelectableCardProps
} from 'components/SelectableCard'

import classes from './RadioCard.module.scss'

export interface RadioCardProps {
  children: React.ReactNode
  positiveTag?: string
  onCheck: SelectableCardProps['onSelection']
  checked: SelectableCardProps['selected']
  ariaLabel: string
}

export function RadioCard({
  children,
  positiveTag,
  checked,
  onCheck,
  ariaLabel,
  ...otherProps
}: RadioCardProps) {
  return (
    <div {...otherProps} className={classes.RadioCard}>
      <SelectableCard selected={checked} onSelection={onCheck}>
        <SelectableCardHeader>
          {!!positiveTag && <PositiveTag>{positiveTag}</PositiveTag>}
          <div className={classes.RadioCard__RadioContainer}>
            <Radio
              checked={checked}
              onChange={onCheck}
              aria-label={ariaLabel}
            />
          </div>
        </SelectableCardHeader>
        <SelectableCardBody>{children}</SelectableCardBody>
      </SelectableCard>
    </div>
  )
}

interface PositiveTagProps {
  children: string
}

function PositiveTag({ children }: PositiveTagProps) {
  return (
    <div className={classes.RadioCard__PositiveTag}>
      <Typography format="bcs">{children}</Typography>
    </div>
  )
}
