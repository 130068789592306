import { FC } from 'react'

import {
  Button as MCLButton,
  ButtonProps as MCLButtonProps
} from '@matillion/component-library'
import classNames from 'classnames'

import classes from '../ParameterOverlay.module.scss'

export const Button: FC<Omit<MCLButtonProps, 'children'>> = ({
  className,
  ...rest
}) => {
  return (
    <MCLButton
      {...rest}
      className={classNames(classes.Button, className)}
      alt="positive"
    />
  )
}
