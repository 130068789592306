import { FC, PropsWithChildren, useCallback, useState } from 'react'
import { Handle, Position } from 'reactflow'

import classNames from 'classnames'

import { useFlags } from 'hooks/useFlags'
import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { ConnectionPortType, Port } from 'job-lib/types/Components'

import { AddNextComponent } from 'modules/Canvas/components/AddNextComponent/AddNextComponent'
import { FlowPort } from 'modules/Canvas/components/FlowCanvas/components/FlowPort'
import classes from 'modules/Canvas/components/FlowCanvas/FlowCanvas.module.scss'
import { useCanvasState } from 'modules/Canvas/hooks/useCanvasState'

export interface FlowNodeWrapperProps {
  id: string
  showPorts?: boolean
  inputPorts: Port[]
  outputPorts: Port[]
  iteratorPorts?: Port[]
  hasInputConnection?: boolean
  hasOutputConnection?: boolean
  isIterator?: boolean
}

export const useAddComponentVisibility = (
  componentId: string,
  outputPorts: Port[],
  hasOutputConnection?: boolean
) => {
  const { componentId: selectedComponentId } = useProjectInfo()
  const { enableNewAddComponentsExperience } = useFlags()

  if (!enableNewAddComponentsExperience) {
    return false
  }

  if (Number(componentId) !== selectedComponentId) {
    return false
  }

  if (outputPorts.length === 0) {
    return false
  }

  if (hasOutputConnection) {
    return false
  }

  return true
}

const usePortVisibility = (componentId: string, showPortsDefault?: boolean) => {
  const { isConnecting, connectionNodeId, connectionHandleId } =
    useCanvasState()
  const [isHovered, setIsHovered] = useState<boolean>(false)

  const showAllPorts = !isConnecting && (showPortsDefault || isHovered)

  const isConnectionSourceComponent = connectionNodeId === componentId
  const isConnectionPortInput = connectionHandleId === ConnectionPortType.INPUT
  const isValidConnectionTarget = isConnecting && !isConnectionSourceComponent

  const fullInputPort =
    isValidConnectionTarget && isConnecting && !isConnectionPortInput

  const showInputPorts =
    (isConnectionSourceComponent && isConnectionPortInput) ||
    (isValidConnectionTarget && !isConnectionPortInput)
  const showOutputPorts =
    (isConnectionSourceComponent && !isConnectionPortInput) ||
    (isValidConnectionTarget && isConnectionPortInput)

  const onMouseEnter = useCallback(() => {
    setIsHovered(true)
  }, [])

  const onMouseLeave = useCallback(() => {
    setIsHovered(false)
  }, [])

  return {
    onMouseEnter,
    onMouseLeave,
    isConnecting,
    isConnectionSourceComponent,
    connectionSourceHandle: connectionHandleId,
    showInputPorts: showAllPorts || showInputPorts,
    showOutputPorts: showAllPorts || showOutputPorts,
    fullInputPort
  }
}

export const FlowNodeWrapper: FC<PropsWithChildren<FlowNodeWrapperProps>> = ({
  id,
  showPorts,
  inputPorts,
  outputPorts,
  iteratorPorts = [],
  hasOutputConnection,
  children
}) => {
  const {
    onMouseEnter,
    onMouseLeave,
    showInputPorts,
    showOutputPorts,
    isConnectionSourceComponent,
    connectionSourceHandle,
    fullInputPort
  } = usePortVisibility(id, showPorts)

  const showAddNextComponent = useAddComponentVisibility(
    id,
    outputPorts,
    hasOutputConnection
  )

  const firstInput = inputPorts[0]

  return (
    <div
      className={classes.FlowNodeWrapper}
      data-testid="component-node"
      data-nodeid={id}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}

      {/*
       * handles are where edges connect to nodes on the canvas.
       * this terminology comes from react-flow itself
       */}
      <Handle
        className={classNames(
          classes.FlowNodeWrapper__Handle,
          classes['FlowNodeWrapper__Handle--input']
        )}
        type="target"
        position={Position.Left}
        isConnectable={false}
      />

      <Handle
        className={classNames(
          classes.FlowNodeWrapper__Handle,
          classes['FlowNodeWrapper__Handle--output']
        )}
        type="source"
        position={Position.Right}
        isConnectable={false}
      />

      {/* add a full sized input port to make connecting components easier */}
      {fullInputPort && firstInput && (
        <div className={classes.FlowNodeWrapper__FullInput}>
          <Handle
            type="target"
            id={firstInput.portId}
            position={Position.Left}
            className={classes.FlowNodeWrapper__FullInput__Handle}
          />
        </div>
      )}

      {showAddNextComponent && (
        <div className={classes.FlowNodeWrapper__AddNextOffset}>
          <AddNextComponent sourceComponentId={parseInt(id)} />
        </div>
      )}

      {/*
       * ports are where connections are formed, and are
       * displayed contextually when users interact with nodes.
       * this terminology is our own, and is based on METL
       */}
      <div
        className={classNames(
          classes.FlowNodeWrapper__Ports,
          classes.FlowNodeWrapper__InputPorts,
          {
            [classes['FlowNodeWrapper__Ports--hidden']]: !showInputPorts
          }
        )}
      >
        {inputPorts.map((port) => (
          <FlowPort
            key={`input-port-widget-${port.portId}`}
            type="target"
            position={Position.Left}
            id={port.portId}
            isConnectable={showInputPorts}
            active={
              isConnectionSourceComponent &&
              port.portId === connectionSourceHandle
            }
          />
        ))}
      </div>

      <div
        className={classNames(
          classes.FlowNodeWrapper__Ports,
          classes.FlowNodeWrapper__OutputPorts,
          {
            [classes['FlowNodeWrapper__Ports--hidden']]: !showOutputPorts
          }
        )}
      >
        {outputPorts.map((port) => (
          <FlowPort
            key={`output-port-widget-${port.portId}`}
            type="source"
            position={Position.Right}
            id={port.portId}
            isConnectable={showOutputPorts}
            active={
              isConnectionSourceComponent &&
              port.portId === connectionSourceHandle
            }
          />
        ))}
      </div>

      <div
        className={classNames(
          classes.FlowNodeWrapper__Ports,
          classes.FlowNodeWrapper__IteratorPorts,
          {
            [classes['FlowNodeWrapper__Ports--hidden']]: !showOutputPorts
          }
        )}
      >
        {iteratorPorts.map((port) => (
          <FlowPort
            key={`iteration-port-widget-${port.portId}`}
            type="source"
            position={Position.Bottom}
            id={port.portId}
            isConnectable={showOutputPorts}
            active={
              isConnectionSourceComponent &&
              port.portId === connectionSourceHandle
            }
          />
        ))}
      </div>
    </div>
  )
}
