import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useFetchComponentMetadata } from 'api/hooks/useGetComponentMetadata/useGetComponentMetadata'
import { ComponentSummaryId } from 'api/hooks/useGetComponentTree'
import useGetProject from 'api/hooks/useGetProject/useGetProject'

import { useComponentInfo } from 'hooks/useComponentInfo/useComponentInfo'

import { createModularOrchestrationComponent } from 'job-lib/builders/createComponent/createModularOrchestrationComponent'
import { createOrchestrationComponent } from 'job-lib/builders/createComponent/createOrchestrationComponent'
import { createTransformationComponent } from 'job-lib/builders/createComponent/createTransformationComponent'
import {
  CreateComponentProps,
  InitialParameterValues
} from 'job-lib/builders/createComponent/types'
import { isModularConnector } from 'job-lib/cisIds/idType'
import { AddComponentPayload } from 'job-lib/store/jobSlice/reducers/addComponent/addComponent'
import { JobType } from 'job-lib/types/JobType'

import { removeUnsupportedParametersAndReplaceSlot } from 'utils/removeUnsupportedParametersAndReplaceSlot'

export interface MakeComponentAtPositionOptions {
  id: ComponentSummaryId
  initialValues?: InitialParameterValues
  x: number
  y: number
  componentName?: string
}

// This is a type alias which is currently the same shape as the AddComponentPayload, it was created during the refactor of this file to make it easier to understand what the return type of this hook is
export type MakeComponentPayload = AddComponentPayload
export interface MakeComponentDetails {
  isLoading: boolean
  error: Error | null | unknown
}

export type MakeComponent = (
  opts: MakeComponentAtPositionOptions
) => Promise<MakeComponentPayload>
export type MakeComponentResponse = [MakeComponent, MakeComponentDetails]

export const useMakeComponent = (): MakeComponentResponse => {
  const componentInfo = useComponentInfo()
  const project = useGetProject()
  const projectWarehouse = project.data?.warehouse as string
  const { t } = useTranslation()
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState<MakeComponentDetails['error']>(null)

  const { getComponentMetadata } = useFetchComponentMetadata()
  const getMetadata = useCallback(
    async (id: ComponentSummaryId) => {
      setLoading(true)
      setError(null)
      try {
        const response = await getComponentMetadata(id)

        return removeUnsupportedParametersAndReplaceSlot(
          projectWarehouse,
          response.metadata
        )
      } catch (err) {
        setLoading(false)
        setError(err)
        return null
      }
    },
    [getComponentMetadata, projectWarehouse]
  )

  const makeComponent: MakeComponent = useCallback(
    async ({ id, x, y, initialValues: initialParameters, componentName }) => {
      const metadata = await getMetadata(id)

      if (!metadata) {
        const err = new Error(
          `could not find metadata for component with id: ${id}`
        )
        setError(err)
        setLoading(false)
        throw err
      }

      const displayName = componentInfo.getDisplayName(id)
      const isModular = isModularConnector(id)

      const opts: CreateComponentProps = {
        t,
        metadata,
        initialValues: initialParameters,
        name: componentName || displayName,
        x,
        y
      }
      let payload: MakeComponentPayload

      if (metadata.componentType === JobType.Orchestration) {
        payload = {
          component: isModular
            ? createModularOrchestrationComponent(opts)
            : createOrchestrationComponent(opts),
          componentType: JobType.Orchestration
        }
      } else if (metadata.componentType === JobType.Transformation) {
        payload = {
          component: createTransformationComponent(opts),
          componentType: JobType.Transformation
        }
      } else {
        const err = new Error(
          `cannot create a component that has the type: ${
            metadata.componentType as string
          }`
        )
        setError(err)
        setLoading(false)
        throw err
      }

      setLoading(false)
      return payload
    },
    [t, getMetadata, componentInfo]
  )

  return [makeComponent, { isLoading, error }]
}
