import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { SearchBar } from '@matillion/component-library'

import { Loading } from 'components/Loading/Loading'
import { EmptyPanel } from 'components/Panels/EmptyPanel'

import {
  filterList,
  useAvailableComponents
} from 'hooks/useAvailableComponents'

import { ComponentRow } from './components/ComponentRow'
import classes from './ComponentsList.module.scss'

const ComponentsList = () => {
  const { components, isLoading, isError } = useAvailableComponents()
  const [searchTerm, setSearchTerm] = useState('')
  const { t } = useTranslation()

  const filteredList = useMemo(
    () => filterList(searchTerm, components),
    [searchTerm, components]
  )

  if (isLoading) {
    return <Loading className={classes.Loading} />
  }

  if (isError) {
    return (
      <EmptyPanel>
        {t('translation:sideBar.componentPanel.networkError')}
      </EmptyPanel>
    )
  }

  if (!Object.keys(components).length) {
    return (
      <EmptyPanel>
        {t('translation:sideBar.componentPanel.noJobSelected')}
      </EmptyPanel>
    )
  }

  return (
    <>
      <div className={classes.Search}>
        <SearchBar
          data-testid="add-components-search"
          value={searchTerm}
          onChange={({ target: { value } }) => setSearchTerm(value)}
          aria-label={t('sideBar.componentPanel.searchPlaceholder')}
          placeholder={t('sideBar.componentPanel.searchPlaceholder', {
            searchableItem: ''
          })}
        />
      </div>
      {!filteredList.length ? (
        <EmptyPanel>
          {t('translation:sideBar.componentPanel.noResultsFound')}
        </EmptyPanel>
      ) : (
        <ul>
          {filteredList.map((component) => (
            <ComponentRow
              key={component.componentId}
              component={component}
              displayName={component.displayName}
              searchTerm={searchTerm}
              isDraggable
            />
          ))}
        </ul>
      )}
    </>
  )
}

export { ComponentsList }
