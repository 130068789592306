import { FC } from 'react'

import { Typography } from '@matillion/component-library'
import classnames from 'classnames'

import classes from './SQLShortcuts.module.scss'

interface Props {
  updateEditor: (text: string) => void
  disabled?: boolean
}

export const shortcuts = [
  'AND',
  'OR',
  'NOT',
  '+',
  '-',
  '*',
  '/',
  '||',
  '=',
  '!=',
  '<',
  '<=',
  '=>',
  '>'
]

const SQLShortcuts: FC<Props> = ({ updateEditor, disabled = false }) => {
  return (
    <>
      <div className={classes.Shortcuts}>
        {shortcuts.map((shortcut) => {
          return (
            <Typography
              format="mc"
              weight="bold"
              key={shortcut}
              className={classnames(classes.Shortcuts__Button, {
                [classes['Shortcuts__Button--Disabled']]: disabled
              })}
              onClick={() => {
                !disabled && updateEditor(shortcut)
              }}
            >
              <span>{shortcut}</span>
            </Typography>
          )
        })}
      </div>
    </>
  )
}

export default SQLShortcuts
