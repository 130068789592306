import { Dispatch, SetStateAction, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Popover } from '@matillion/component-library'
import {
  CommitModal,
  ConflictResolutionSelection,
  DescribeMergeConflict,
  HardResetBranchModal,
  MergeModal,
  PublishEvent,
  PushChangesModal,
  useGitContext,
  usePullAction
} from '@matillion/git-component-library'

import { useProjectPermission } from 'api/external/usePermission/useProjectPermission'
import { useGetJobSummaries } from 'api/hooks/useGetJobSummaries'
import useListBranches from 'api/hooks/useListBranches'
import { useListEnvironments } from 'api/hooks/useListEnvironments'

import { useFlags } from 'hooks/useFlags'
import { useDoPublishJobs } from 'hooks/usePublishJobsAction/usePublishJobsAction'

import ConflictsModal from '../ConflictsModal/ConflictsModal'
import PullRemoteChangesModal from '../PullBranchModal/PullRemoteChangesModal'
import classes from './GitButton.module.scss'
import GitButtonAnchor from './GitButtonAnchor'
import GitButtonMenuItem from './GitButtonMenuItem'

type ModalOpenStateSetter = Dispatch<SetStateAction<boolean>>

const GitButton = () => {
  const { t } = useTranslation()
  const { hasPermission } = useProjectPermission('update_pipelines')
  const { pullChangesV2, enableHardResetBranch } = useFlags()
  const { doPublish } = useDoPublishJobs()
  const { hasUncommittedChanges } = useGitContext()
  const { data: branchesResponse } = useListBranches()
  const { data: environmentsResponse } = useListEnvironments()
  const { data: jobSummaries } = useGetJobSummaries()
  const isNativeGitEnabled = jobSummaries?.isUsingJGit ?? false

  const [pullLoading, setPullLoading] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isPushModalOpen, setIsPushModalOpen] = useState(false)
  const [isMergeModalOpen, setIsMergeModalOpen] = useState(false)
  const [isCommitModalOpen, setIsCommitModalOpen] = useState(false)
  const [isConflictModalOpen, setIsConflictModalOpen] = useState(false)
  const [isPullBranchModalOpen, setIsPullBranchModalOpen] = useState(false)
  const [isHardResetModalOpen, setIsHardResetModalOpen] = useState(false)
  const [pullConflicts, setPullConflicts] = useState<DescribeMergeConflict[]>(
    []
  )
  const onPullConflict = useCallback((conflicts: DescribeMergeConflict[]) => {
    setPullConflicts(conflicts)
    handleSelectModalItem(setIsConflictModalOpen)
  }, [])

  const onPullSuccess = useCallback(() => {
    setIsMenuOpen(false)
    setPullLoading(false)
  }, [])

  const onPullFailure = () => {
    setPullLoading(false)
  }

  const { pullRemoteChanges, resolvePullConflicts } = usePullAction({
    onConflict: onPullConflict,
    onFailure: onPullFailure,
    onSuccess: onPullSuccess
  })

  const handleButtonClick = useCallback(() => {
    setIsMenuOpen(!isMenuOpen)
  }, [isMenuOpen])

  const handleSelectModalItem = (setModalOpenState: ModalOpenStateSetter) => {
    setModalOpenState(true)
    setIsMenuOpen(false)
  }

  const handleCloseConflictsModal = () => {
    setPullLoading(false)
    setIsConflictModalOpen(false)
  }

  const handlePull = useCallback(() => {
    if (pullChangesV2) {
      setPullLoading(true)
      pullRemoteChanges()
    } else {
      handleSelectModalItem(setIsPullBranchModalOpen)
    }
  }, [pullChangesV2, pullRemoteChanges])

  const closeMenu = useCallback(() => {
    setIsMenuOpen(false)
  }, [])

  const handleResolvedPullConflicts = useCallback(
    (selections: ConflictResolutionSelection[]) => {
      resolvePullConflicts(selections)
      setIsConflictModalOpen(false)
    },
    [resolvePullConflicts]
  )

  const handlePublishPipelines = useCallback(
    async ({ branch: branchName, commitId, environment }: PublishEvent) => {
      return doPublish({
        commitId,
        branchName,
        environmentId: environment
      })
    },
    [doPublish]
  )

  const handleHardReset = () => {
    setIsHardResetModalOpen(true)
  }

  const environments =
    environmentsResponse?.results.map((response) => ({
      id: response.id,
      name: response.name
    })) ?? []

  const branches =
    branchesResponse?.map((response) => ({
      id: response.id,
      name: response.name
    })) ?? []

  const Anchor = (
    <div>
      <GitButtonAnchor
        isLoading={pullLoading}
        isMenuOpen={isMenuOpen}
        onClick={handleButtonClick}
      />
    </div>
  )

  return (
    <>
      <Popover
        align="end"
        anchor={Anchor}
        position="bottom"
        isOpen={isMenuOpen}
        onClickOutside={closeMenu}
        className={classes.PopOver}
      >
        <div
          role="menu"
          data-testid="git-actions-menu"
          aria-labelledby={t('git.button.menu.aria-label')}
        >
          <GitButtonMenuItem
            key="commit"
            className={classes.MenuItem}
            data-testid={'git-commit-item'}
            name={t('git.button.actions.commit')}
            disabled={!hasPermission || pullLoading}
            onClick={() => handleSelectModalItem(setIsCommitModalOpen)}
            info={
              hasUncommittedChanges
                ? t('git.button.info.uncommitted-changes')
                : undefined
            }
          />

          <GitButtonMenuItem
            key="merge"
            className={classes.MenuItem}
            data-testid={'git-merge-item'}
            name={t('git.button.actions.merge')}
            disabled={!hasPermission || pullLoading}
            onClick={() => handleSelectModalItem(setIsMergeModalOpen)}
          />

          <GitButtonMenuItem
            key="pull"
            onClick={handlePull}
            disabled={pullLoading}
            className={classes.MenuItem}
            data-testid={'git-pull-item'}
            name={t('git.button.actions.pull')}
            info={pullLoading ? t('git.button.info.pull-loading') : undefined}
          />

          {isNativeGitEnabled && (
            <GitButtonMenuItem
              key="push"
              disabled={pullLoading}
              className={classes.MenuItem}
              data-testid={'git-push-item'}
              name={t('git.button.actions.push')}
              onClick={() => handleSelectModalItem(setIsPushModalOpen)}
            />
          )}

          {enableHardResetBranch && (
            <GitButtonMenuItem
              key="reset"
              disabled={pullLoading}
              onClick={handleHardReset}
              className={classes.MenuItem}
              data-testid={'git-hard-reset-item'}
              name={t('git.button.actions.hard-reset')}
            />
          )}
        </div>
      </Popover>

      {isCommitModalOpen && (
        <CommitModal
          environments={environments}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onPublishPipelines={handlePublishPipelines}
          onClose={() => setIsCommitModalOpen(false)}
        />
      )}

      {isMergeModalOpen && (
        <MergeModal
          branches={branches}
          environments={environments}
          onClose={() => setIsMergeModalOpen(false)}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onPublishPipelines={handlePublishPipelines}
        />
      )}

      {isConflictModalOpen && (
        <ConflictsModal
          conflicts={pullConflicts}
          close={handleCloseConflictsModal}
          submit={t('git.pull.modal.submit')}
          onResolve={handleResolvedPullConflicts}
        />
      )}

      {isPullBranchModalOpen && (
        <PullRemoteChangesModal close={() => setIsPullBranchModalOpen(false)} />
      )}

      {isPushModalOpen && (
        <PushChangesModal
          environments={environments}
          onPublishPipelines={handlePublishPipelines}
          onClose={() => setIsPushModalOpen(false)}
        />
      )}

      {isHardResetModalOpen && (
        <HardResetBranchModal onClose={() => setIsHardResetModalOpen(false)} />
      )}
    </>
  )
}

export default GitButton
