import { ChangeEvent, FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  AutoComplete,
  AutoCompleteItem,
  AutoCompleteItemId,
  Field,
  Icon,
  Typography
} from '@matillion/component-library'
import { ServiceKey, useServiceUrl } from '@matillion/hub-client'

import {
  ParameterOverlayButton,
  ParameterOverlayFooter,
  ParameterOverlayHeader
} from 'components/ParameterOverlay'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { ParameterOptions } from 'modules/ParameterEditors/types'

import {
  getProjectExplorerLink,
  ProjectExplorerPaths
} from 'utils/getProjectExplorerLink'

import classes from './OAuthEditor.module.scss'

export interface OAuthEditorProps extends ParameterOptions {
  parameterName: string
  value: string[]
  onDone: (editedValue: string[]) => unknown
}

export const OAuthEditor: FC<OAuthEditorProps> = ({
  value,
  parameterName,
  onDone,
  editorColumns
}) => {
  const { t } = useTranslation()
  const projectExplorerOrigin = useServiceUrl(
    ServiceKey.projectExplorerFrontend,
    true
  )
  const { projectId } = useProjectInfo()
  const [profile, setProfile] = useState<AutoCompleteItemId | null>({
    name: value[0],
    id: value[0]
  })

  return (
    <>
      <ParameterOverlayHeader title={parameterName} />
      <Field
        id="profile"
        name="profile"
        data-testid="choose-oauth-profile-dropdown"
        aria-label={t('parameterEditor.OAUTH.title')}
        placeholder={t('parameterEditor.OAUTH.title')}
        title={t('parameterEditor.OAUTH.title')}
        inputComponent={AutoComplete}
        value={profile}
        availableItems={(editorColumns[0]?.options ?? []).map((option) => ({
          name: option,
          id: option
        }))}
        onChange={(e: ChangeEvent<AutoCompleteItem>) =>
          setProfile(e.target.value)
        }
      />

      <div className={classes.OAuthEditor__LinkContainer}>
        <a
          data-testid="oauth-editor-manage-link"
          className={classes.OAuthEditor__ManageLink}
          href={getProjectExplorerLink(
            projectExplorerOrigin,
            projectId,
            ProjectExplorerPaths.OAUTH
          )}
          rel="noopener noreferrer"
          target="_blank"
        >
          <Icon.Lock />
          <Typography format="bcs">
            {t('parameterEditor.OAUTH.manage')}
          </Typography>
        </a>
      </div>
      <ParameterOverlayFooter>
        <ParameterOverlayButton
          disabled={!profile?.name}
          onClick={() => profile && onDone([profile.name])}
          text={t('common.save')}
        />
      </ParameterOverlayFooter>
    </>
  )
}
