import { useEffect, useState } from 'react'

import { useAgentDetails } from 'api/hooks/useAgentDetails/useAgentDetails'

import { useOnboarded } from './useOnboarded'
import { useSessionStarted } from './useSessionStarted'

export function useOnboardingModal() {
  const [isModalVisible, setModalVisible] = useState(false)
  const [isSessionStarted, setSessionStarted] = useSessionStarted()
  const [isOnboarded, setOnboarded] = useOnboarded()

  const { data: agentStatus, isLoading: isAgentStatusLoading } =
    useAgentDetails()
  const isAgentAvailable = !!agentStatus?.isAvailable
  const shouldLoad =
    !isAgentStatusLoading &&
    isAgentAvailable &&
    !isSessionStarted &&
    !isOnboarded

  useEffect(() => {
    if (shouldLoad) {
      setSessionStarted(true)
      setModalVisible(true)
    }
  }, [isOnboarded, shouldLoad, setSessionStarted])

  const shouldReload =
    !isAgentStatusLoading && !isAgentAvailable && isModalVisible

  useEffect(() => {
    if (shouldReload) {
      setSessionStarted(false)
      setModalVisible(false)
    }
  }, [shouldReload, setSessionStarted])

  return {
    isVisible: isModalVisible,
    close: (isHiddenNextTime: boolean) => {
      setOnboarded(isHiddenNextTime)
      setModalVisible(false)
    }
  }
}
