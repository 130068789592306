import { Panel, PanelGroup } from 'react-resizable-panels'
import { Outlet } from 'react-router-dom'

import { GitLibraryProvider } from 'GitLibraryProvider'

import { Orientation, ResizeableHandler } from 'components/ResizeableHandler'

import { useFlags } from 'hooks/useFlags'

import { ValidationProvider } from 'modules/ComponentValidation/ValidationProvider'
import { JobTabs } from 'modules/JobTabs'
import { MonitorAgentStatus } from 'modules/MonitorAgentStatus/MonitorAgentStatus'
import SideBar from 'modules/SideBar/SideBar'

import { Footer } from './components/Footer/Footer'
import HeaderBar from './components/HeaderBar/HeaderBar'
import WorkingCopyProvider from './components/WorkingCopyProvider'
import classes from './EtlDesigner.module.scss'
import { UndoRedoCapture } from './UndoRedoCapture'

const EtlDesigner = () => {
  const { enableAgentAvailabilityModal } = useFlags()
  return (
    <GitLibraryProvider>
      <WorkingCopyProvider>
        <ValidationProvider>
          <HeaderBar />

          <div className={classes.ApplicationContent}>
            <PanelGroup direction="horizontal" autoSaveId="sidebar">
              <Panel
                defaultSize={15}
                minSize={5}
                className={classes.Panel__SideBar}
                id="sidebar"
              >
                <SideBar />
              </Panel>

              <ResizeableHandler
                orientation={Orientation.VERTICAL}
                id="sidebar"
              />

              <Panel>
                <PanelGroup direction="vertical" autoSaveId="canvas">
                  <Panel id="designer">
                    <UndoRedoCapture className={classes.CanvasContainer}>
                      <JobTabs />
                      <Outlet />
                    </UndoRedoCapture>
                  </Panel>

                  <ResizeableHandler
                    orientation={Orientation.HORIZONTAL}
                    id="footer"
                  />
                  <Panel defaultSize={35} maxSize={75} id="footer-container">
                    <Footer />
                  </Panel>
                </PanelGroup>
              </Panel>
            </PanelGroup>
          </div>
          {enableAgentAvailabilityModal && <MonitorAgentStatus />}
        </ValidationProvider>
      </WorkingCopyProvider>
    </GitLibraryProvider>
  )
}
export default EtlDesigner
