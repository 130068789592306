import { Cardinality, ConnectionPortType } from 'job-lib/types/Components'
import { JobType } from 'job-lib/types/JobType'

import {
  ComponentEditorTabType,
  ComponentMetadataResponse,
  EditorType,
  LookUpType,
  ParameterDataType,
  UseVariable
} from '../../types'

export const mockJoinMetadata: ComponentMetadataResponse = {
  componentId: 'join',
  metadata: {
    schemaVersion: 1,
    emeraldId: '-629958239',
    componentType: JobType.Transformation,
    inputPorts: [
      { portId: ConnectionPortType.INPUT, cardinality: Cardinality.MANY }
    ],
    outputPorts: [
      {
        portId: ConnectionPortType.UNCONDITIONAL,
        cardinality: Cardinality.MANY
      }
    ],
    deprecated: false,
    exports: [],
    editorTabs: [
      ComponentEditorTabType.PROPERTIES,
      ComponentEditorTabType.SAMPLE,
      ComponentEditorTabType.METADATA,
      ComponentEditorTabType.SQL,
      ComponentEditorTabType.PLAN,
      ComponentEditorTabType.HELP
    ],
    parameters: [
      {
        id: 'component-name',
        dplID: 'componentName',
        metlSlot: 1,
        useVariable: UseVariable.NO,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'main-table',
        dplID: 'mainTable',
        metlSlot: 2,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_SOURCE_COMPONENT_NAMES,
        lookupDependencies: ['transform.sql'],
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'main-table-alias',
        dplID: 'mainTableAlias',
        metlSlot: 3,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'joins',
        dplID: 'joins',
        metlSlot: 4,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.GRID,
        editorType: EditorType.GRID_EDITOR,
        lookupType: LookUpType.DYNAMIC_TRANSFORM_JOIN_ALIASES,
        lookupDependencies: ['transform.sql'],
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'join-expressions',
        dplID: 'joinExpressions',
        metlSlot: 5,
        useVariable: UseVariable.NO,
        dataType: ParameterDataType.GRID,
        editorType: EditorType.JOIN_EDITOR,
        lookupType: LookUpType.DYNAMIC_TRANSFORM_JOIN_EXPRESSIONS,
        lookupDependencies: [
          'param.main-table',
          'param.main-table-alias',
          'param.joins',
          'transform.sql'
        ],
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        id: 'output-columns',
        dplID: 'outputColumns',
        metlSlot: 6,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.GRID,
        editorType: EditorType.GRID_EDITOR,
        lookupType: LookUpType.DYNAMIC_TRANSFORM_JOIN_OUTPUT_COLUMNS,
        lookupDependencies: [
          'param.main-table',
          'param.main-table-alias',
          'param.joins.join-table',
          'param.joins.join-alias',
          'transform.sql'
        ],
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      }
    ]
  }
}
