import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Modal, Typography } from '@matillion/component-library'

import { ReactComponent as PlayIcon } from 'assets/play.svg'

import { RadioCard, type RadioCardProps } from 'components/RadioCard'

import classes from './PipelineRadioCard.module.scss'
import { Stack } from './Stack'

export interface PipelineRadioCardProps
  extends Omit<RadioCardProps, 'children' | 'ariaLabel'> {
  title: string
  description: string
  videoGuideURL: string
}

const VIDEO_TITLE_ID = 'video-guide-title'

export function PipelineRadioCard({
  title,
  description,
  videoGuideURL,
  ...cardProps
}: PipelineRadioCardProps) {
  const [modalOpen, setModalOpen] = useState(false)
  const { t } = useTranslation()
  return (
    <>
      <RadioCard {...cardProps} ariaLabel={title}>
        <Stack>
          <Typography format="tm">{title}</Typography>
          <Typography>{description}</Typography>
        </Stack>
        <Button
          className={classes.PipelineRadioCard__PlayButton}
          alt="text"
          as="a"
          onClick={(e) => {
            e.stopPropagation()
            setModalOpen(true)
          }}
        >
          <PlayIcon /> {t('translation:canvas.noJobSelectedWatchBtn')}
        </Button>
      </RadioCard>
      {modalOpen && (
        <Modal
          size="large"
          ariaLabelledBy={VIDEO_TITLE_ID}
          onCancel={() => setModalOpen(false)}
        >
          <div className={classes.Modal__Heading}>
            <Typography
              id={VIDEO_TITLE_ID}
              as="h2"
              format="tl"
              className={classes.Modal__Title}
            >
              {title}
            </Typography>
          </div>
          <iframe
            data-testid="pipeline-video-frame"
            allowFullScreen
            height="560px"
            width="100%"
            src={videoGuideURL}
          />
        </Modal>
      )}
    </>
  )
}
