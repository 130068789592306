import { useQueryClient, UseQueryResult } from '@tanstack/react-query'

import {
  ComponentMetadata,
  ComponentParameter,
  ParameterDataType
} from 'api/hooks/useGetComponentMetadata/types'
import { ComponentSummaryId } from 'api/hooks/useGetComponentTree'
import {
  EditorColumn,
  GridParameter
} from 'api/hooks/useGetParameterOptions/types'
import { useGetParameterOptions } from 'api/hooks/useGetParameterOptions/useGetParameterOptions'
import { ProblemDetails } from 'api/types/http-problem-details'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { JobType } from 'job-lib/types/JobType'

import { useValidationProvider } from 'modules/ComponentValidation/hooks/useValidationProvider'
import { useWorkingCopy } from 'modules/EtlDesigner/hooks/useWorkingCopy'
import { HelperData } from 'modules/ParameterEditors/components/MultiExpressionsEditor'

import { getComponentDependencies } from './getComponentDependencies'
import { getParameterDependencies } from './getParameterDependencies'
import { getParameterIdDependencies } from './getParameterIdDependencies'
import { getParameterMetadataDependencies } from './getParameterMetadataDependencies'
import { getTransformSQLDependencies } from './getTransformSQLDependencies'
import { useClientDataFiltering } from './useClientDataFiltering'

export type Parameter = ComponentParameter | GridParameter

interface UseParameterOptionsProps {
  componentSummaryId: ComponentSummaryId
  componentMetaData: ComponentMetadata
  parameter?: Parameter
  isEnabled?: boolean
}

interface UseGetParameterOptionsResponse {
  data: EditorColumn[]
  helperData?: HelperData
  isLoading: boolean
  isRefetching: boolean
  isSuccess: boolean
  isError: boolean
  refetch: (options?: {
    throwOnError: boolean
    cancelRefetch?: boolean
  }) => Promise<UseQueryResult>
  error: ProblemDetails | null
}

export const useParameterOptions = ({
  componentMetaData,
  parameter,
  componentSummaryId,
  isEnabled = false
}: UseParameterOptionsProps): UseGetParameterOptionsResponse => {
  const { job, jobType } = useWorkingCopy()
  const { projectId, branchId, componentId, jobSummaryId } = useProjectInfo()
  const { validationQueryCache } = useValidationProvider()
  const queryClient = useQueryClient()
  const { filterColumns } = useClientDataFiltering({
    componentSummaryId,
    parameterId: parameter?.id
  })

  const transformSQLDependencies =
    isEnabled && jobType === JobType.Transformation
      ? getTransformSQLDependencies({
          validationQueryCache,
          lookupDependencies: parameter?.lookupDependencies ?? [],
          componentId,
          job
        })
      : undefined

  const parameterDependencies = isEnabled
    ? getParameterDependencies({
        lookupDependencies: parameter?.lookupDependencies ?? [],
        componentMetaData,
        componentId: componentId,
        job,
        queryClient,
        projectId,
        branchId,
        jobSummaryId
      })
    : undefined

  const parameterIdDependencies = isEnabled
    ? getParameterIdDependencies(parameter?.lookupDependencies)
    : undefined

  const componentDependencies = isEnabled
    ? getComponentDependencies({
        parameter,
        componentId: componentId,
        job
      })
    : undefined

  const parameterMetadataDependencies =
    getParameterMetadataDependencies(parameter)

  const {
    isLoading,
    isRefetching,
    isSuccess,
    isError,
    error: unknownError,
    data: lookupResponseData,
    refetch
  } = useGetParameterOptions({
    lookupDefinition: {
      parameters: parameterDependencies,
      component: componentDependencies,
      parameterMetadataDependencies,
      parameterIdDependencies,
      ...transformSQLDependencies
    },
    lookupType: parameter?.lookupType ?? null,
    parameterId: parameter?.id,
    isEnabled: isEnabled && Boolean(parameter?.lookupType),
    variables: job?.variables ?? {}
  })

  const error = unknownError as ProblemDetails

  const defaultEmptyResponse: UseGetParameterOptionsResponse = {
    data: [],
    isLoading,
    isRefetching,
    isSuccess,
    isError,
    error,
    refetch
  }

  if (!isEnabled) {
    return defaultEmptyResponse
  }

  if (parameter && 'options' in parameter && parameter.options) {
    const options: EditorColumn[] = [
      {
        name: null,
        type: ParameterDataType.TEXT,
        options: parameter.options,
        columnEditorType: null,
        defaultValue: null,
        lookupType: null,
        lookupDependencies: null
      }
    ]

    return {
      data: filterColumns(options),
      isLoading: false,
      isRefetching: false,
      isSuccess: true,
      isError: false,
      error: null,
      refetch
    }
  }

  if (parameter && 'staticOptions' in parameter && parameter.staticOptions) {
    return {
      data: filterColumns(parameter.staticOptions),
      isLoading: false,
      isRefetching: false,
      isSuccess: true,
      isError: false,
      error: null,
      refetch
    }
  }

  if (lookupResponseData) {
    return {
      data: filterColumns(lookupResponseData.editorColumns),
      helperData: lookupResponseData.helperData,
      isLoading,
      isRefetching,
      isSuccess,
      isError,
      error,
      refetch
    }
  }

  return defaultEmptyResponse
}
