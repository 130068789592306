// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BxbAMt3B0JWvn4USqB6Q{margin-right:8px}", "",{"version":3,"sources":["webpack://./src/components/JobInformation/JobInformation.module.scss"],"names":[],"mappings":"AAAA,sBACE,gBAAA","sourcesContent":[".Icon {\n  margin-right: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Icon": "BxbAMt3B0JWvn4USqB6Q"
};
export default ___CSS_LOADER_EXPORT___;
