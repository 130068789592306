import React, { ReactElement } from 'react'

import {
  Tooltip,
  TreeItem,
  TreeItemProps,
  Typography,
  useTreeItem
} from '@matillion/component-library'

import Folder from 'components/Folder/Folder'
import { PopOverMenu } from 'components/PopOverMenu'

import MovePipelineModal from '../../commands/MovePipelineModal/MovePipelineModal'
import useMovePipeline from '../../hooks/useMovePipeline'
import ContextMenuButton from '../ContextMenuButton/ContextMenuButton'
import FolderItemContextMenu from '../FolderItemContextMenu/FolderItemContextMenu'
import PipelineFolderDropDestination from '../PipelineFolderDropDestination/PipelineFolderDropDestination'
import classes from './PipelineFolder.module.scss'

export interface PipelineFolderProps {
  id: string
  name: string
  children?: ReactElement<TreeItemProps> | Array<ReactElement<TreeItemProps>>
}

const PipelineFolder = ({ id, name, children }: PipelineFolderProps) => {
  const { isFocused, setHovered } = useTreeItem(id)
  const { movePipeline, onConfirmMove, showWarningModal, setShowWarningModal } =
    useMovePipeline(id)

  const handleFolderEnter = () => {
    setHovered(id)
  }

  return (
    <>
      <TreeItem
        key={id}
        nodeId={id}
        label={id}
        leaf={
          <PipelineFolderDropDestination
            id={id}
            key={id}
            onDropPipeline={movePipeline}
            onFolderEnter={handleFolderEnter}
          >
            <PopOverMenu key={id} content={<FolderItemContextMenu path={id} />}>
              {({ onContextMenu, onClick }) => {
                const conditionalContextMenuProps = {
                  ...(isFocused && { onContextMenu: onContextMenu })
                }

                return (
                  <div
                    role="link"
                    className={classes.Folder}
                    {...conditionalContextMenuProps}
                    data-testid={`pipeline-folder-${id}`}
                  >
                    <Folder className={classes.FolderIcon} />

                    <Tooltip content={name} onlyShowOnOverflow>
                      <div className={classes.TextContainer}>
                        <Typography
                          as="span"
                          format="bcs"
                          className={classes.Text}
                        >
                          {name}
                        </Typography>
                      </div>
                    </Tooltip>

                    <ContextMenuButton id={id} key={id} onClick={onClick} />
                  </div>
                )
              }}
            </PopOverMenu>
          </PipelineFolderDropDestination>
        }
      >
        {children}
      </TreeItem>

      {showWarningModal && (
        <MovePipelineModal
          onConfirm={onConfirmMove}
          onClose={() => setShowWarningModal(false)}
        />
      )}
    </>
  )
}

export default PipelineFolder
