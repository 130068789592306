import { FC } from 'react'
import { NodeProps } from 'reactflow'

import { FlowNodeWrapper } from 'modules/Canvas/components/FlowCanvas/components/FlowNodeWrapper'
import { getIdFromReactFlowId } from 'modules/Canvas/hooks/useCanvasModel/utils'

import { ComponentNode } from './ComponentNode'
import { EtlNodeProps } from './models'

export const EtlNode: FC<NodeProps<EtlNodeProps>> = ({
  id,
  selected,
  data: {
    imageUrl,
    label,
    inputPorts,
    outputPorts,
    summaryId,
    hasInputConnection,
    hasOutputConnection
  }
}) => (
  <FlowNodeWrapper
    id={getIdFromReactFlowId(id)}
    showPorts={selected}
    inputPorts={inputPorts}
    outputPorts={outputPorts}
    hasInputConnection={hasInputConnection}
    hasOutputConnection={hasOutputConnection}
  >
    <ComponentNode
      id={getIdFromReactFlowId(id)}
      isSelected={selected}
      imageUrl={imageUrl}
      summaryId={summaryId}
    >
      {label}
    </ComponentNode>
  </FlowNodeWrapper>
)
