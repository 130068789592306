import { ParameterValue } from 'modules/WorkingCopyProvider/Pipeline'

export const setParameterDPLValue = (
  parameterDPL: Record<string, unknown>,
  parameterPath: string[],
  parameterValue: ParameterValue
): string => {
  const parameterId = parameterPath.pop()
  let node = parameterDPL

  parameterPath.forEach((path) => {
    node[path] = node[path] || {}
    node = node[path] as Record<string, unknown>
  })

  node[parameterId as keyof object] = parameterValue

  return JSON.stringify(parameterDPL)
}
