import { ButtonHTMLAttributes, FC, FunctionComponent } from 'react'

import { Tooltip, Typography } from '@matillion/component-library'
import classnames from 'classnames'

import { isMacOs } from '../../utils/isMacOs'
import classes from './IconButton.module.scss'

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>

export type Props = ButtonProps & {
  label: string
  shortcut?: string
  align?: 'start' | 'center' | 'end'
  autoSize?: boolean
  hideTooltip?: boolean
}

interface ContentWithShortcutProps {
  content: string
  shortcut: string
}

const ContentWithShortcut: FunctionComponent<ContentWithShortcutProps> = ({
  content,
  shortcut
}) => {
  return (
    <Typography format="bcs">
      {content}{' '}
      <span
        className={classnames([
          classes.Shortcut_Keys,
          isMacOs() && classes.MacOs
        ])}
      >
        {shortcut}
      </span>
    </Typography>
  )
}

export const IconButton: FC<Props> = ({
  className,
  children,
  label,
  shortcut,
  align = 'center',
  autoSize = false,
  hideTooltip,
  ...props
}) => (
  <Tooltip
    content={
      hideTooltip ? null : shortcut ? (
        <ContentWithShortcut content={label} shortcut={shortcut} />
      ) : (
        label
      )
    }
    align={align}
    className={classes.Tooltip__Container}
  >
    <button
      type="button"
      aria-label={label}
      className={classnames(
        classes.Tooltip__IconButton,
        !autoSize && classes.Tooltip__IconButton__FixedSize,
        className
      )}
      {...props}
    >
      {children}
    </button>
  </Tooltip>
)
