import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'

import { getOrchestrationOutputConnector } from 'job-lib/job-functions/job-functions'
import { OutputPortType } from 'job-lib/types/Components'
import { JobType } from 'job-lib/types/JobType'

import { JobState, UpdateComponentPosition } from '../../job.types'

export const updateNodePosition: CaseReducer<
  JobState,
  PayloadAction<UpdateComponentPosition>
> = (state, action) => {
  const { id: nodeId, x, y, type } = action.payload
  if (type === 'note') {
    updateNotePosition()
  } else {
    updateComponentPosition()
  }

  return state

  function updateNotePosition() {
    if (!state.job?.notes) return

    state.job.notes[nodeId].position.x = x
    state.job.notes[nodeId].position.y = y
    return state
  }

  function updateComponentPosition() {
    if (!state.job) return

    state.job.components[nodeId].x = x
    state.job.components[nodeId].y = y

    if (state.jobType === JobType.Orchestration) {
      const component = state.job.components[nodeId]

      if (component.outputIterationConnectorIDs.length) {
        const connectors = component.outputIterationConnectorIDs.map((id) =>
          getOrchestrationOutputConnector(
            state.job,
            id,
            OutputPortType.ITERATION
          )
        )

        connectors.forEach((connector) => {
          if (connector) {
            const targetComponent = state.job.components[connector.targetID]
            if (targetComponent) {
              targetComponent.x = x
              targetComponent.y = y
            }
          }
        })
      }
    }
  }
}
