import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { XYPosition } from 'reactflow'

import { jobActions } from 'job-lib/store'

export const DEFAULT_NOTE_WIDTH = 150
export const DEFAULT_NOTE_HEIGHT = 58

export const useMakeNote = () => {
  const dispatch = useDispatch()
  const makeNote = useCallback(
    (manualPosition: XYPosition | null) => {
      if (!manualPosition) return

      dispatch(
        jobActions.addNote({
          position: {
            x: manualPosition.x,
            y: manualPosition.y
          },
          content: '',
          size: {
            width: DEFAULT_NOTE_WIDTH * 2,
            height: DEFAULT_NOTE_HEIGHT
          },
          theme: 'white'
        })
      )
    },
    [dispatch]
  )

  return { makeNote }
}
