import { useMemo } from 'react'

import useGetRunTasks from 'api/hooks/useGetRunTasks'
import { RunTaskStatus } from 'api/hooks/useGetRunTasks/types'

export const useTaskStatus = () => {
  const completed = useGetRunTasks('completed')
  const running = useGetRunTasks('running')

  const tasks = useMemo(() => {
    const combined: RunTaskStatus[] = []

    if (completed.data) {
      combined.push(...completed.data.results)
    }

    if (running.data) {
      combined.push(...running.data.results)
    }

    // Put the most recent tasks first
    combined.sort((a, b) => {
      // a enqueued later than b
      if (a.enqueuedTime > b.enqueuedTime) {
        // put a after
        return -1
      }
      // a enqueued earlier than b
      if (a.enqueuedTime < b.enqueuedTime) {
        return 1
      }

      return 0
    })

    return combined
  }, [completed.data, running.data])

  return {
    completed,
    running,
    tasks
  }
}
