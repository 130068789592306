import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { DataGrid, DataGridColumnProps } from '@matillion/component-library'

import classes from './Grid.module.scss'
import { GridProps, GridRow } from './types'
import { generateGridColumns } from './utils'

export const Grid: FunctionComponent<GridProps> = ({
  rows,
  updateRows,
  columns,
  isSelectable,
  elements,
  selectedRows,
  onSelectedChange,
  componentMetaData,
  componentInstanceId,
  componentSummaryId,
  parameter
}) => {
  const { t } = useTranslation()
  const gridColumns: Array<DataGridColumnProps<GridRow>> = generateGridColumns({
    columns,
    elements,
    onChange: updateRows,
    componentMetaData,
    componentInstanceId,
    componentSummaryId,
    parameter,
    t
  })

  const selectableDataGrid = {
    isSelectable: true,
    selectedRows: selectedRows,
    selectRowLabelName: (id: string) => `Select to delete row ${id}`,
    onSelectedChange: onSelectedChange
  }

  return (
    <DataGrid
      columns={gridColumns}
      rows={rows}
      className={classes.DataGrid_Override}
      {...(isSelectable ? selectableDataGrid : {})}
      compact
    />
  )
}
