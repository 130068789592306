import { FC, lazy, Suspense } from 'react'
import { useTranslation } from 'react-i18next'

import { CollapsiblePanel, Typography } from '@matillion/component-library'

import { useAgentStackTraces } from 'api/hooks/useAgentStackTraces/useAgentStackTraces'

import { Loading } from 'components/Loading/Loading'

import classes from './AgentStackTrace.module.scss'

const CodeEditor = lazy(async () => import('components/CodeEditor'))
export interface AgentStackTraceProps {
  workerRequests: string[]
}

export const AgentStackTrace: FC<AgentStackTraceProps> = ({
  workerRequests
}) => {
  const { t } = useTranslation()

  const { data, isLoading, isError } = useAgentStackTraces(
    workerRequests.join(',')
  )

  if (isError) {
    return null
  }

  if (isLoading) {
    return <Loading />
  }

  if (!data.messages.length) {
    return null
  }

  const stackTraceTitle = (
    <div className={classes.AgentStackTrace__Title}>
      <Typography as="h2" format="bcm">
        {t('taskExplorer.cellMessage.agentStackTraceTitle')}
      </Typography>
    </div>
  )

  return (
    <div
      className={classes.AgentStackTrace}
      data-testid="agent-stack-trace-container"
    >
      <CollapsiblePanel
        title={stackTraceTitle}
        subtitle=""
        className={classes.AgentStackTrace}
        contentClassName={classes.AgentStackTrace__Content}
      >
        {data.messages.map(({ id, detailedMessage }) => (
          <div key={id}>
            <Suspense fallback={<Loading />}>
              <CodeEditor value={detailedMessage} readOnly />
            </Suspense>
          </div>
        ))}
      </CollapsiblePanel>
    </div>
  )
}
