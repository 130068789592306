import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'

import { generateNextNoteId } from 'job-lib/job-functions/generateNextSequenceId'
import { Note } from 'job-lib/types/Job'

import { JobState } from '../../job.types'

export const addNote: CaseReducer<JobState, PayloadAction<Note>> = (
  state,
  { payload: note }
) => {
  const { job } = state

  if (job !== null) {
    if (!job.notes) {
      job.notes = { [generateNextNoteId({ notes: {} })]: note }
    } else {
      // @ts-expect-error we've ensured this exists with the above check
      job.notes[generateNextNoteId(state.job)] = note
    }
  }
  return state
}
