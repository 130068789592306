import { FC, useEffect } from 'react'

import { JobSummary } from 'api/hooks/useGetJobSummaries'

import { useFlags } from 'hooks/useFlags'
import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { FileStatus } from 'job-lib/types/FileStatus'

import BrowserTree from '../../../SideBar/components/BrowserTree/BrowserTree'
import { usePipelineBrowserCommandProvider } from '../../effects/usePipelineBrowserCommand'
import { PipelineTreeReducerType } from '../../reducers/pipelineTreeReducer/types'
import PipelineItem from '../PipelineItem/PipelineItem'
import PipelineTree from '../PipelineTree/PipelineTree'
import RootFolder from '../RootFolder/RootFolder'
import classes from './PipelineBrowserTree.module.scss'

export const pipelineComparator = (a: JobSummary, b: JobSummary): number => {
  return a.name.localeCompare(b.name)
}

export interface Props {
  jobs: JobSummary[]
}

export const PipelineBrowserTree: FC<Props> = ({ jobs }) => {
  const { pipelineFolders } = useFlags()
  const { branchId, projectId } = useProjectInfo()
  const sortedPipelines = [...jobs].sort(pipelineComparator)
  const { pipelineTree, onPipelineTreeCommand } =
    usePipelineBrowserCommandProvider()

  useEffect(() => {
    onPipelineTreeCommand({
      summaries: jobs.filter((job) => job.status !== FileStatus.DELETED),
      type: PipelineTreeReducerType.OVERWRITE_TREE,
      project: projectId,
      branch: branchId
    })
  }, [jobs, branchId, projectId, onPipelineTreeCommand])

  if (pipelineFolders) {
    return (
      <RootFolder>
        <BrowserTree className={classes.PipelineBrowserTree}>
          <PipelineTree data={pipelineTree} />
        </BrowserTree>
      </RootFolder>
    )
  }

  return (
    <BrowserTree className={classes.PipelineBrowserTree}>
      {sortedPipelines.map((job) => (
        <PipelineItem
          pipeline={job}
          isLeaf={false}
          key={`index-${job.jobId}`}
        />
      ))}
    </BrowserTree>
  )
}
