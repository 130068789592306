import { useCallback } from 'react'

import { useMutation } from '@tanstack/react-query'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { useSpringClient } from '../useSpringClient/useSpringClient'

export const useDeleteBranch = (branchName: string) => {
  const { projectId } = useProjectInfo()
  const client = useSpringClient()

  const deleteBranch = useCallback(async () => {
    return client.delete(
      `projects/${projectId}/branches/${branchName}/workingtree`
    )
  }, [client, projectId, branchName])

  return useMutation(deleteBranch)
}
