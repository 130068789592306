import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon } from '@matillion/component-library'

import { ReactComponent as ValidatingOrchestration } from 'assets/canvas/validating-orchestration.svg'
import { ReactComponent as ValidatingTransformation } from 'assets/canvas/validating-transformation.svg'

import { IconButton } from 'components/IconButton/IconButton'

import { JobType } from 'job-lib/types/JobType'

import { useValidationProvider } from 'modules/ComponentValidation/hooks/useValidationProvider'

interface ValidateButtonProps {
  jobType: JobType
  context: {
    context: string
  }
}

export const ValidateButton: FC<ValidateButtonProps> = ({
  jobType,
  context
}) => {
  const { t } = useTranslation()
  const { setValidationEnabled, isValidatingJob, hasFinishedValidating } =
    useValidationProvider()
  const [showInProgressSvg, setShowInProgressSvg] = useState(false)

  const ValidationInProgressIcon =
    jobType === JobType.Transformation
      ? ValidatingTransformation
      : ValidatingOrchestration

  const triggerValidation = () => {
    setValidationEnabled()
    setShowInProgressSvg(true)
  }

  const getValidationStatusLabel = () => {
    if (isValidatingJob) {
      return t('statuses.validatingJob')
    }

    if (hasFinishedValidating) {
      return t('statuses.validationFinished')
    }

    return t('canvasToolbox.validateJob')
  }

  useEffect(() => {
    let timeout: number

    if (showInProgressSvg) {
      timeout = window.setTimeout(() => {
        setShowInProgressSvg(false)
      }, 1000)
    }

    return () => window.clearTimeout(timeout)
  }, [showInProgressSvg])

  return (
    <div>
      <IconButton
        aria-labelledby="canvas-toolbox-validate-job-status"
        data-testid="canvas-toolbox-validate-job"
        label={
          isValidatingJob
            ? t('statuses.validatingJob')
            : t('canvasToolbox.validateJob')
        }
        shortcut={
          isValidatingJob ? undefined : t('shortcuts.validateJob', context)
        }
        disabled={isValidatingJob}
        onClick={triggerValidation}
      >
        {showInProgressSvg ? (
          <ValidationInProgressIcon title={t('statuses.loading')} />
        ) : (
          <Icon.ItemTickCircle />
        )}
      </IconButton>
      <div
        id="canvas-toolbox-validate-job-status"
        className="u-visually-hidden"
      >
        {getValidationStatusLabel()}
      </div>
    </div>
  )
}
