import { useQuery } from '@tanstack/react-query'

import { queryKeys } from 'api/queryKeys'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { useSpringClient } from '../useSpringClient/useSpringClient'
import { ListBranchesResponse } from './types'

const useListBranches = () => {
  const { projectId } = useProjectInfo()
  const client = useSpringClient()

  return useQuery([projectId, queryKeys.branches], async () => {
    const { data } = await client.get<ListBranchesResponse[]>(
      `branches?projectId=${projectId}`
    )

    return data
  })
}

export default useListBranches
