import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Toaster } from '@matillion/component-library'

import { useGetJobSummaries } from 'api/hooks/useGetJobSummaries'
import { useRenameJob } from 'api/hooks/useRenameJob/useRenameJob'

import { JobType } from 'job-lib/types/JobType'

import { useProjectInfo } from '../../../hooks/useProjectInfo/useProjectInfo'
import { PipelineDropDetails } from '../components/PipelineFolderDropDestination/PipelineFolderDropDestination'
import { usePipelineBrowserCommandProvider } from '../effects/usePipelineBrowserCommand'
import { PipelineTreeReducerType } from '../reducers/pipelineTreeReducer/types'
import { isPipelineExistingPipeline } from '../utils/validatePipelineName'

export interface UseMovePipelineResponse {
  showWarningModal: boolean
  setShowWarningModal: (show: boolean) => void
  onConfirmMove: (hideWarning: boolean) => void
  movePipeline: (details: PipelineDropDetails) => void
}

const WARNING_KEY = 'hide-move-pipeline-warning'

const useMovePipeline = (targetDirectory?: string): UseMovePipelineResponse => {
  const { makeToast } = Toaster.useToaster()
  const { projectId, branchId } = useProjectInfo()
  const { mutateAsync: renameJob } = useRenameJob()
  const { data: pipelineSummaries } = useGetJobSummaries()
  const [showWarningModal, setShowWarningModal] = useState(false)
  const { onPipelineTreeCommand } = usePipelineBrowserCommandProvider()
  const { t } = useTranslation('translation', { keyPrefix: 'move-pipeline' })
  const [pipelineDetails, setPipelineDetails] = useState<PipelineDropDetails>()

  const buildNewRuntimeName = (existingPipelineName: string) => {
    if (targetDirectory) {
      return `${targetDirectory}/${existingPipelineName}`
    }

    return existingPipelineName
  }

  const isPipelineAlreadyInFolder = (
    type: JobType,
    runtimeName: string
  ): boolean => {
    const allPipelines = pipelineSummaries?.results
    return allPipelines
      ? isPipelineExistingPipeline(runtimeName, type, allPipelines)
      : false
  }

  const buildNewPipelineDetails = (details: PipelineDropDetails) => {
    const { existingPipelineType, existingPipelineName } = details

    const isOrchestration = existingPipelineType === JobType.Orchestration
    const extension = isOrchestration ? 'orch' : 'tran'
    const newRuntimeName = buildNewRuntimeName(existingPipelineName)
    const newJobId = `${newRuntimeName}.${extension}.yaml`

    return { newJobId, newRuntimeName }
  }

  const movePipeline = (details?: PipelineDropDetails) => {
    if (!details) {
      return
    }

    const {
      existingPipelineId,
      existingPipelineType,
      existingPipelineName,
      sourceDirectory
    } = details

    const { newJobId, newRuntimeName } = buildNewPipelineDetails(details)

    const pipelineAlreadyExists = isPipelineAlreadyInFolder(
      existingPipelineType,
      newRuntimeName
    )

    if (!pipelineAlreadyExists) {
      renameJob({ jobId: existingPipelineId, newJobId }).then(() => {
        onPipelineTreeCommand({
          targetDirectory,
          branch: branchId,
          project: projectId,
          path: sourceDirectory,
          name: existingPipelineName,
          pipelineType: existingPipelineType,
          type: PipelineTreeReducerType.MOVE_PIPELINE
        })
      })
    } else {
      makeToast({
        type: 'error',
        title: t('toast.uniqueness-error.title'),
        message: t('toast.uniqueness-error.message')
      })
    }
  }

  const handleDropPipeline = (details: PipelineDropDetails) => {
    const { sourceDirectory } = details

    if (sourceDirectory === (targetDirectory ?? '')) {
      return
    }

    setPipelineDetails(details)

    const shouldHideWarningModal = localStorage.getItem(WARNING_KEY) === 'true'

    if (!shouldHideWarningModal) {
      setShowWarningModal(true)
    } else {
      movePipeline(details)
    }
  }

  const handleConfirmMove = (hideWarning: boolean) => {
    localStorage.setItem(WARNING_KEY, String(hideWarning))
    setShowWarningModal(false)
    movePipeline(pipelineDetails)
  }

  return {
    movePipeline: handleDropPipeline,
    showWarningModal,
    setShowWarningModal,
    onConfirmMove: handleConfirmMove
  }
}

export default useMovePipeline
