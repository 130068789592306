import './styles/base.scss'

import { createRoot } from 'react-dom/client'
import TagManager from 'react-gtm-module'

import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'

import App from './App'
import config from './config'
import Providers from './Providers'

import './styles/utilities.scss'

if (config.datadog) {
  const { rumService, service, ...options } = config.datadog

  datadogLogs.init({
    ...options,
    service,
    forwardErrorsToLogs: true,
    sampleRate: 100
  })
  datadogRum.init({
    ...options,
    version: config.version,
    service: rumService,
    allowedTracingOrigins: [
      // https://regexr.com/7eq8s
      /^https:\/\/.+\.(?:platform|core)(?:-.+)?\.matillion\.com$/
    ],
    // default settings from setting up the project in datadog
    sampleRate: 100,
    sessionReplaySampleRate: 20,
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input'
  })

  datadogRum.startSessionReplayRecording()
}

if (config.gtmId) {
  TagManager.initialize({ gtmId: config.gtmId })
}

const container = document.getElementById('root')
container?.setAttribute('data-app-environment', config.environment)
container?.setAttribute('data-app-version', config.version)

// @ts-expect-error container should never be empty
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container)!

root.render(
  <Providers>
    <App />
  </Providers>
)
