// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bT5EmcQbHnGXFtd8EmZL{margin-bottom:10px}.xEiPgDl8VBMxlp_h4tR3{margin-bottom:25px}.NSHgVzVVOS9P_QL0hE90{max-width:600px}.TBzm8j6jPy5zfZJUzwEA{margin-bottom:5px}", "",{"version":3,"sources":["webpack://./src/modules/BringYourOwnGit/components/GitRepositoryDropdown/GitRepositoryDropdown.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CAGF,sBACE,kBAAA,CAGF,sBACE,eAAA,CAGF,sBACE,iBAAA","sourcesContent":[".title {\n  margin-bottom: 10px;\n}\n\n.subtitle {\n  margin-bottom: 25px;\n}\n\n.dropdown {\n  max-width: 600px;\n}\n\n.label {\n  margin-bottom: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "bT5EmcQbHnGXFtd8EmZL",
	"subtitle": "xEiPgDl8VBMxlp_h4tR3",
	"dropdown": "NSHgVzVVOS9P_QL0hE90",
	"label": "TBzm8j6jPy5zfZJUzwEA"
};
export default ___CSS_LOADER_EXPORT___;
