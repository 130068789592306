import omit from 'lodash/omit'

import { OrchestrationComponentInstance } from 'job-lib/types/Job'

import { createNameParameter } from './createNameParameter'
import { createParameters } from './createParameters'
import { CreateComponentProps } from './types'

export const createEmptyOrchestrationComponent = (
  name: string
): Omit<OrchestrationComponentInstance, 'id' | 'implementationID'> => ({
  x: 0,
  y: 0,
  width: 32,
  height: 32,
  inputConnectorIDs: [],
  exportMappings: {},
  parameters: {
    1: createNameParameter(name)
  },
  expectedFailure: null,
  connectorHint: 'SUCCESS_FAIL',
  executionHint: null,
  outputSuccessConnectorIDs: [],
  outputFailureConnectorIDs: [],
  outputUnconditionalConnectorIDs: [],
  outputTrueConnectorIDs: [],
  outputFalseConnectorIDs: [],
  outputIterationConnectorIDs: [],
  inputIterationConnectorIDs: []
})

export const createOrchestrationComponent = ({
  t,
  initialValues,
  metadata,
  name,
  x,
  y
}: CreateComponentProps): Omit<OrchestrationComponentInstance, 'id'> => ({
  ...createEmptyOrchestrationComponent(name),
  implementationID: parseInt(metadata.emeraldId, 10),
  x,
  y,
  parameters: {
    ...createEmptyOrchestrationComponent(name).parameters,
    ...omit(createParameters(t, metadata, initialValues), '1')
  }
})
