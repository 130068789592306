import { useState } from 'react'

import { useAuth, useUser } from '@matillion/hub-client'
import axios, { type AxiosInstance, type AxiosRequestConfig } from 'axios'
import { useApiContext } from 'api/context/useApiContext'
import { type RestClientProps } from './types'

export const clientConfig: AxiosRequestConfig = {
  // empty by default, to be overridden by tests
}

const useClient = ({ host, contextUrl }: RestClientProps) => {
  const { getToken } = useAuth()
  const { audience } = useApiContext()
  const { organisation: account } = useUser()
  const [client] = useState<AxiosInstance>(createInstance)

  function createInstance() {
    const instance = axios.create({})

    instance.defaults.baseURL = `${host}${contextUrl}`

    instance.interceptors.request.use(async (interceptedConfig) => {
      const bearerToken = await getToken({ audience })
      const authHeader = `Bearer ${bearerToken}`

      interceptedConfig.headers = {
        ...interceptedConfig.headers,
        ...clientConfig.headers,
        'account-id': account.id,
        Authorization: authHeader
      }

      return interceptedConfig
    })

    return instance
  }

  return client
}

export default useClient
