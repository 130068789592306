import React, { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Tree,
  TreeExpandedState,
  Typography
} from '@matillion/component-library'

import { EditorColumn } from 'api/hooks/useGetParameterOptions/types'

import {
  ParameterOverlayButton,
  ParameterOverlayFooter,
  ParameterOverlayHeader
} from 'components/ParameterOverlay'

import classes from './NestedDataPickerEditor.module.scss'
import { SchemaElement } from './SchemaElement'
import { DataStructure, NestedDataPickerEditorProps } from './types'
import {
  convertFromDataStructureToRootValue,
  convertFromRootValueToDataStructure,
  flattenDataStructure,
  modifyAllElements
} from './utils'

export const NestedDataPickerEditor: FC<NestedDataPickerEditorProps> = ({
  editorColumns,
  parameterName,
  onDone,
  elements
}) => {
  const columns =
    editorColumns.find(
      (column: EditorColumn) => column.resourceID === 'sourceOfTheColumn'
    )?.options ?? []
  const { t } = useTranslation()
  const [dataStructure, setDataStructure] = useState<DataStructure[]>(() =>
    convertFromRootValueToDataStructure(elements, columns)
  )
  const flattened = useMemo(
    () => dataStructure.map((structure) => flattenDataStructure(structure)),
    [dataStructure]
  )

  const [allSelected, noneSelected] = useMemo(() => {
    const all = flattened.every((structure) => {
      const [, ...descendants] = Object.values(structure)

      return descendants.every((d) => {
        return d.selected
      })
    })

    const none = all
      ? false
      : flattened.every((structure) => {
          const [, ...descendants] = Object.values(structure)
          return descendants.every((d) => {
            return !d.selected
          })
        })
    return [all, none]
  }, [flattened])

  const [initialExpanded] = useState<TreeExpandedState>(() => {
    return flattened.reduce<Record<string, boolean>>((flattenedRoot, node) => {
      return {
        ...flattenedRoot,
        ...Object.keys(node).reduce(
          (acc, key) => ({
            ...acc,
            [key]: true
          }),
          {}
        )
      }
    }, {})
  })

  return (
    <>
      <ParameterOverlayHeader title={parameterName} />
      <div className={classes.Box}>
        <div className={classes.Box__Title}>
          <Typography format="bcs" weight="bold">
            {t('parameterEditor.NESTED_DATA_PICKER_EDITOR.tagline')}
          </Typography>
        </div>
        <div className={classes.Box__Content}>
          <Tree initialExpanded={initialExpanded}>
            {dataStructure.map((structure) => (
              <SchemaElement
                key={structure.key}
                path={[structure.key]}
                element={structure}
                root={structure}
                onChange={(newRoot) => {
                  setDataStructure((prevState) =>
                    prevState.map((rootDataStructure) => {
                      if (rootDataStructure.key === structure.key) {
                        return newRoot
                      }
                      return rootDataStructure
                    })
                  )
                }}
              />
            ))}
          </Tree>
        </div>
        <div className={classes.Box__Actions}>
          <Button
            onClick={() =>
              setDataStructure(convertFromRootValueToDataStructure({}, columns))
            }
            size="sm"
            text={t('common.reset')}
            alt="secondary"
          />
          <Button
            disabled={allSelected}
            onClick={() =>
              setDataStructure(
                modifyAllElements(dataStructure, { selected: true })
              )
            }
            size="sm"
            text={t('common.selectAll')}
            alt="secondary"
          />
          <Button
            disabled={noneSelected}
            onClick={() =>
              setDataStructure(
                modifyAllElements(dataStructure, { selected: false })
              )
            }
            size="sm"
            text={t('common.clearAll')}
            alt="secondary"
          />
        </div>
      </div>

      <ParameterOverlayFooter>
        <ParameterOverlayButton
          text={t('common.save')}
          onClick={() =>
            onDone(convertFromDataStructureToRootValue(dataStructure))
          }
        />
      </ParameterOverlayFooter>
    </>
  )
}
