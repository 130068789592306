import { rest } from 'msw'

import { AGENT_FLAKY_ID, AGENT_ID } from '__test-utils__/ids'

import config from 'config'

import { AgentStatusEnum } from '../useAgentDetails'

export const makeAgentDetailsURL = (agentId = AGENT_ID) =>
  `${config.getApiBaseUrl()}/v1/agents/${agentId}`

export const useAgentDetailsHandlers = [
  rest.get(makeAgentDetailsURL(AGENT_ID), (req, res, ctx) => {
    return res(
      ctx.json({
        agentId: AGENT_ID,
        status: AgentStatusEnum.Running,
        // This makes agent status work when using MSW to develop locally. Without it the component list doesn’t render.
        host: 'CUSTOMER'
      })
    )
  }),
  rest.get(makeAgentDetailsURL(AGENT_FLAKY_ID), (req, res, ctx) => {
    return res.once(
      ctx.json({
        agentId: AGENT_FLAKY_ID,
        status: AgentStatusEnum.Stopped
      })
    )
  })
]
