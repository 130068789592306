import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { SearchBar } from '@matillion/component-library'

import { ComponentSummaryId } from 'api/hooks/useGetComponentTree'

import { Loading } from 'components/Loading/Loading'
import { EmptyPanel } from 'components/Panels/EmptyPanel'

import {
  filterList,
  useAvailableComponents
} from 'hooks/useAvailableComponents'

import { ComponentRow } from 'modules/SideBar/components/ComponentsList/components/ComponentRow'

import classes from '../../AddNextComponent.module.scss'

interface Props {
  onSelectComponent: (id: ComponentSummaryId) => Promise<void>
}

const AddComponentList = ({ onSelectComponent }: Props) => {
  const { t } = useTranslation()
  const { components, isLoading, isError } = useAvailableComponents()
  const [searchTerm, setSearchTerm] = useState('')

  const filteredList = useMemo(
    () => filterList(searchTerm, components),
    [searchTerm, components]
  )

  if (isLoading) {
    return <Loading className={classes.Loading} />
  }

  if (isError) {
    return (
      <EmptyPanel>
        {t('translation:sideBar.componentPanel.networkError')}
      </EmptyPanel>
    )
  }

  return (
    <>
      <div className={classes.Search}>
        <SearchBar
          className={classes.Search__Input}
          data-testid="dialog-add-components-search"
          value={searchTerm}
          onChange={({ target: { value } }) => setSearchTerm(value)}
          aria-label={t('sideBar.componentPanel.searchPlaceholder')}
          placeholder={t('sideBar.componentPanel.searchPlaceholder', {
            searchableItem: ''
          })}
        />
      </div>

      {!filteredList.length ? (
        <EmptyPanel>
          {t('translation:sideBar.componentPanel.noResultsFound')}
        </EmptyPanel>
      ) : (
        <ul className={classes.ComponentsList}>
          {filteredList.map((component) => (
            <ComponentRow
              key={component.componentId}
              searchTerm={searchTerm}
              component={component}
              displayName={component.displayName}
              onSelectComponent={onSelectComponent}
            />
          ))}
        </ul>
      )}
    </>
  )
}

export { AddComponentList }
