import { ChangeEvent, FC } from 'react'

import {
  AutoComplete,
  AutoCompleteItemId,
  InputProps
} from '@matillion/component-library'

import { DataStructureType } from './types'

const ITEMS: AutoCompleteItemId[] = [
  {
    id: DataStructureType.VARCHAR,
    name: 'VARCHAR'
  },
  {
    id: DataStructureType.NUMBER,
    name: 'NUMBER'
  },
  {
    id: DataStructureType.FLOAT,
    name: 'FLOAT'
  },
  {
    id: DataStructureType.BOOLEAN,
    name: 'BOOLEAN'
  },
  {
    id: DataStructureType.DATE,
    name: 'DATE'
  },
  {
    id: DataStructureType.TIMESTAMP,
    name: 'TIMESTAMP'
  },
  {
    id: DataStructureType.TIME,
    name: 'TIME'
  },
  {
    id: DataStructureType.VARIANT,
    name: 'VARIANT'
  },
  {
    id: 'ARRAY',
    name: 'ARRAY'
  }
]

export const TypeSelector: FC<
  Pick<InputProps, 'disabled' | 'value' | 'onChange' | 'onBlur'> & {
    onBlur?: () => void
  }
> = ({ disabled, value, onChange, onBlur }) => {
  return (
    <AutoComplete
      disabled={disabled}
      value={value as string}
      onBlur={onBlur}
      onChange={(e) =>
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        onChange?.({
          target: {
            value: e.target.value?.id || ''
          }
        } as ChangeEvent<HTMLInputElement>)
      }
      availableItems={ITEMS}
    />
  )
}
