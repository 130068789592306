import { type PropsWithChildren, useEffect, useMemo, useState } from 'react'

import queryClient from 'api/queryClient'

import { FileStatus } from 'types/FileStatus'

import { GitContext } from './GitContext'
import { type GitContextBag, type GitProviderProps } from './types'
import { type FileSummary } from 'types/JobSummaries'
import { QueryClientProvider } from '@tanstack/react-query'
import { defaultFeatureFlags } from 'hooks/useFeatureFlags/useFeatureFlags'
import ApiContextProvider from 'api/context/ApiContextProvider'

const GitProvider = ({
  children,
  designerClient,
  project,
  branch,
  environment,
  featureFlags,
  fileSummaries,
  isNativeGit,
  onRefreshWorkingTreeStatus
}: PropsWithChildren<GitProviderProps>) => {
  const [hasUncommittedChanges, setHasUncommittedChanges] = useState(false)

  useEffect(() => {
    if (fileSummaries && fileSummaries.length > 0) {
      const hasChanges = fileSummaries.some((summary: FileSummary) => {
        return summary.status !== FileStatus.UNMODIFIED
      })

      setHasUncommittedChanges(hasChanges)
    }
  }, [fileSummaries])

  const providerValues: GitContextBag = useMemo(
    () => ({
      branch,
      project,
      designerClient,
      hasUncommittedChanges,
      isNativeGit: isNativeGit ?? false,
      featureFlags: featureFlags ?? defaultFeatureFlags,
      refreshWorkingTreeStatus: onRefreshWorkingTreeStatus
    }),
    [
      designerClient,
      isNativeGit,
      featureFlags,
      project,
      branch,
      hasUncommittedChanges,
      onRefreshWorkingTreeStatus
    ]
  )

  return (
    <ApiContextProvider environment={environment}>
      <QueryClientProvider client={queryClient}>
        <GitContext.Provider value={providerValues}>
          {children}
        </GitContext.Provider>
      </QueryClientProvider>
    </ApiContextProvider>
  )
}

export default GitProvider
