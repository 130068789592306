import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Alert } from '@matillion/component-library'
import classnames from 'classnames'

import config from 'config'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { RootState } from 'job-lib/store'
import { ComponentInstance } from 'job-lib/types/Job'
import { JobType } from 'job-lib/types/JobType'

import { useComponentValidationResult } from 'modules/ComponentValidation/hooks/useComponentValidationResult'
import { useValidationProvider } from 'modules/ComponentValidation/hooks/useValidationProvider'

import classes from '../../PropertiesPanel.module.scss'
import { ParameterFailures } from '../ParameterFailures'

interface ComponentStatusProps {
  componentInstance: ComponentInstance
}

export const ComponentStatus: FC<ComponentStatusProps> = ({
  componentInstance
}) => {
  const { t } = useTranslation()
  const [showAlert, setShowAlert] = useState(true)
  const jobType = useSelector<RootState>((state) => state.job.jobType)
  const { isUnvalidated, setValidationEnabled } = useValidationProvider()
  const { isError, isSuccess, failures, failureMessage } =
    useComponentValidationResult(componentInstance?.id)
  const projectInfo = useProjectInfo()

  useEffect(() => {
    setShowAlert(true)

    if (isError) {
      return
    }

    const timerId = setTimeout(
      () => setShowAlert(false),
      config.propertiesAlertTimeout
    )
    return () => {
      clearTimeout(timerId)
    }
  }, [isError, isSuccess])

  if (isSuccess && showAlert) {
    return (
      <div className={classes.ComponentOverview__StatusContainer}>
        <Alert
          data-testid="component-status"
          className={classnames(
            classes.ComponentOverview__Status,
            classes['ComponentOverview__Status--Success']
          )}
          type={'success'}
          message={null}
          title={t('translation:componentProperties.status.success')}
        />
      </div>
    )
  }

  if (isError) {
    return (
      <div className={classes.ComponentOverview__StatusContainer}>
        <ParameterFailures
          failures={failures}
          failureMessage={failureMessage}
          projectInfo={projectInfo}
        />
      </div>
    )
  }

  if (
    isUnvalidated &&
    !(isSuccess || isError) &&
    jobType === JobType.Transformation
  ) {
    return (
      <div className={classes.ComponentOverview__StatusContainer}>
        <Alert
          className={classnames(
            classes.ComponentOverview__Status,
            classes['ComponentOverview__Status--Warning']
          )}
          data-testid="component-unvalidated-status"
          type="warning"
          title={t('alerts.componentNotValidated.message')}
          message=""
          action={{
            text: t('alerts.componentNotValidated.actionText'),
            onClick: setValidationEnabled
          }}
        />
      </div>
    )
  }

  return null
}
