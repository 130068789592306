import {
  type GetRepositoriesActionProps,
  type GetRepositoriesActionResponse
} from './types'
import useGetRepositories from 'api/hooks/useGetRepositories'
import useProblemDetails from '../useProblemDetails/useProblemDetails'
import {
  type ProblemDetailRegistry,
  type TranslationKeys
} from '../useProblemDetails/types'
import { Toaster } from '@matillion/component-library'
import { useEffect } from 'react'

const authRequiredType = 'git/provider_authorisation_required'
const commonFailureTitleKey = 'git.repositories.problem.default.title'

const problemRegistry: ProblemDetailRegistry = [
  {
    type: 'git/provider_authorisation_failed',
    translation: {
      titleKey: 'git.repositories.problem.auth-failed.title',
      messageKey: 'git.repositories.problem.auth-failed.message'
    }
  }
]

const defaultTranslationKey: TranslationKeys = {
  titleKey: commonFailureTitleKey,
  messageKey: 'git.repositories.problem.default.message'
}

const useGetRepositoriesAction = ({
  provider,
  onAuthRequired
}: GetRepositoriesActionProps): GetRepositoriesActionResponse => {
  const { data, isLoading, isError, error, fetchStatus, refetch } =
    useGetRepositories({ provider })

  const { makeToast } = Toaster.useToaster()

  const { resolveProblemDetails, isProblemType } = useProblemDetails(
    problemRegistry,
    [
      {
        name: 'provider',
        value: provider?.toString()
      }
    ]
  )

  useEffect(() => {
    if (isError) {
      if (isProblemType(error, authRequiredType)) {
        onAuthRequired()
      } else {
        const { title, message } = resolveProblemDetails(
          error,
          defaultTranslationKey
        )
        makeToast({ type: 'error', title, message })
      }
    }
  }, [
    isError,
    error,
    onAuthRequired,
    makeToast,
    resolveProblemDetails,
    isProblemType
  ])

  return {
    refetch,
    repositories: data?.repositories,
    loading: isLoading && fetchStatus !== 'idle'
  }
}

export default useGetRepositoriesAction
