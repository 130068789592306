import { LoadingSpinner } from '@matillion/component-library'
import { format } from 'date-fns-tz'
import utcToZonedTime from 'date-fns-tz/utcToZonedTime'
import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc'

import useGetBranch from 'api/hooks/useGetBranch/useGetBranch'

import CommitIcon from 'icons/CommitIcon.svg'

import classes from './BranchDescription.module.scss'
import { parse } from 'date-fns'
import { type BranchDescriptionBoxProps, type BranchDescriptionProps } from './types'

const formatCommitTimeStamp = (timestamp: string): string => {
  const referenceDate = utcToZonedTime(new Date(), 'UTC')
  const parsedDate = parse(
    timestamp,
    "yyyy-MM-dd'T'HH:mm:ssXXXXX",
    referenceDate
  )
  const date = zonedTimeToUtc(parsedDate, 'UTC')
  return format(date, 'yyyy-MM-dd HH:mm')
}

const BranchDescriptionBox = ({
  description,
  timestamp
}: BranchDescriptionBoxProps) => (
  <div data-testid="branch-description-box">
    <div>
        <span
          className={classes.BranchSelector__SelectedBranchLatestCommit_Header}
        >
          <img src={CommitIcon} alt={'Commit Icon'}/>
          &nbsp;Latest commit
        </span>
    </div>
    <div
      className={
        classes.BranchSelector__SelectedBranchLatestCommit_Description
      }
      data-testid={'lastCommitDescription'}
      tabIndex={0}
    >
      [{formatCommitTimeStamp(timestamp)}] {description}
    </div>
  </div>
)

const BranchDescription = ({
  branchName
}: BranchDescriptionProps) => {
  const { data, isLoading } = useGetBranch(branchName)
  return (
    <div
      role={'branchDescriptionBox'}
      className={classes.BranchSelector__SelectedBranchLatestCommit}
    >
      {isLoading && <LoadingSpinner />}
      {data?.headCommit && (
        <BranchDescriptionBox
          description={data.headCommit.message}
          timestamp={data.headCommit.datetime}
        />
      )}
    </div>
  )
}

export default BranchDescription
