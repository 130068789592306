import { BRANCH_ID, BRANCH_NAME } from '__test-utils__/ids'

import { type BranchInformation } from 'api/hooks/useGetBranch/types'

export const MockedGetBranchInformation: Record<string, BranchInformation> = {
  [BRANCH_ID]: {
    headCommit: {
      id: '197b33fb8e170dc88d6a7674fb1dd975c68fae5c',
      message:
        'here is such a good description. \n I am good at commit messages',
      datetime: '2022-05-10T15:18:32Z'
    }
  },
  main: {
    headCommit: {
      id: '579435ab17bf62fb92df6a92c2b964b58efdba53',
      message:
        'feat(ETLD-1020): Merged feature/ETLD-1020 into main. Approved by Tom.',
      datetime: '2022-05-10T15:18:32Z'
    }
  },
  BranchNoConflicts: {
    headCommit: {
      id: 'a94a8fe5ccb19ba61c4c0873d391e987982fbbd3',
      message: 'Here is a branch that has no conflicts upon merging',
      datetime: '2022-12-25T15:18:32Z'
    }
  },
  BranchWithConflicts: {
    headCommit: {
      id: '6d3c60eeb2ddd9cce8de6c092c091ae23ffd2264',
      message: 'Here is a branch with a few conflicts upon merging',
      datetime: '2022-11-20T15:18:32Z'
    }
  },
  BranchManyConflicts: {
    headCommit: {
      id: 'da39a3ee5e6b4b0d3255bfef95601890afd80709',
      message: 'Here is a branch that has many conflicts upon merging',
      datetime: '2023-01-12T16:10:32Z'
    }
  },
  BranchFullOffset: {
    headCommit: {
      id: '1f39000fd3ba5a048c30b4252a9cc071c0c3b392',
      message:
        'feat(ETLD-1017): a commit whose timestamp has a full -hh:mm:ss offset',
      datetime: '2022-11-19T15:18:32-02:15:10' // Should add 2h 15m to our local TZ to compensate
    }
  },
  BranchPartialOffset: {
    headCommit: {
      id: 'df8abcc7d6244b112c2016a617140356c99434d3',
      message: 'feat(ETLD-1017): a commit whose timestamp has an +hh:mm offset',
      datetime: '2022-11-18T15:18:32+01:30' // Should subtract 1h 30m from our local TZ to compensate
    }
  },
  BranchNoOffset: {
    headCommit: {
      id: 'df8abcc7d6244b112c2016a617140356c99434d3',
      message: 'feat(ETLD-1017): a commit whose timestamp is UTC',
      datetime: '2022-11-18T15:18:32Z' // Has zero offset from UTC, so should not compensate
    }
  },
  [BRANCH_NAME]: {
    headCommit: {
      id: 'df8abcc7d6244b112c2016a617140356c99434d3',
      message: 'feat(ETLD-1017): an example commit message.',
      datetime: '2022-11-25T15:18:32Z'
    }
  }
}
