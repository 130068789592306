import { cloneDeep, upperCase } from 'lodash'

import { ComponentMetadata } from 'api/hooks/useGetComponentMetadata/types'

export const removeUnsupportedParametersAndReplaceSlot = (
  projectWarehouse: string,
  metadata: ComponentMetadata
) => {
  const output = cloneDeep(metadata)
  output.parameters = output.parameters.filter((parameter) => {
    if (parameter.warehouses) {
      const paramOverride = parameter.warehouses[upperCase(projectWarehouse)]
      if (paramOverride?.metlSlot) {
        parameter.metlSlot = paramOverride?.metlSlot
      }
      return !!paramOverride
    }
    return true
  })

  return output
}
