import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Grid, Section, Typography } from '@matillion/component-library'

import config from 'config'

import { JobType } from 'job-lib/types/JobType'

import {
  PipelineBrowserCommand,
  PipelineBrowserCommandProvider,
  usePipelineBrowserCommandProvider
} from 'modules/PipelineBrowser'

import {
  OnboardingModal,
  useOnboardingModal
} from './components/OnboardingModal'
import { PipelineRadioCard } from './components/PipelineRadioCard'
import classes from './GettingStarted.module.scss'

export function GettingStarted() {
  const { t } = useTranslation()
  const { isVisible, close } = useOnboardingModal()

  return (
    <div className={classes.GettingStarted}>
      <div className={classes.GettingStarted__Title}>
        <Typography as="h2" format="tl">
          {t('translation:canvas.noJobSelectedTitle')}
        </Typography>
      </div>
      <PipelineBrowserCommandProvider>
        <CreatePipelineWizard />
      </PipelineBrowserCommandProvider>
      {isVisible && <OnboardingModal onClose={close} />}
    </div>
  )
}

const sectionSize = {
  small: 12,
  medium: 10,
  large: 8
}

const sectionOffset = {
  small: 0,
  medium: 1,
  large: 2
}

function CreatePipelineWizard() {
  const [selectedPipeline, setSelectedPipeline] = useState<JobType>(
    JobType.Orchestration
  )
  const { t } = useTranslation()
  const { onCommand } = usePipelineBrowserCommandProvider()

  return (
    <Grid>
      <Section size={sectionSize} offset={sectionOffset}>
        <div className={classes.GettingStarted__CardsContainer}>
          <PipelineRadioCard
            data-testid="orchestration-card"
            videoGuideURL={config.docs.orchestrationVideoUrl}
            positiveTag={t('translation:canvas.jobRecommendation')}
            checked={selectedPipeline === JobType.Orchestration}
            onCheck={() => setSelectedPipeline(JobType.Orchestration)}
            title={t('translation:canvas.orchestrationJob')}
            description={t('translation:canvas.orchestrationDescription')}
          />
          <PipelineRadioCard
            data-testid="transformation-card"
            videoGuideURL={config.docs.transformationVideoUrl}
            checked={selectedPipeline === JobType.Transformation}
            onCheck={() => setSelectedPipeline(JobType.Transformation)}
            title={t('translation:canvas.transformationJob')}
            description={t('translation:canvas.transformationDescription')}
          />
        </div>
      </Section>
      <Section size={sectionSize} offset={sectionOffset}>
        <div className={classes.GettingStarted__CTAContainer}>
          <Button
            alt="primary"
            size="lg"
            text={t('translation:canvas.noJobSelectedCTA')}
            onClick={() =>
              onCommand({
                type: PipelineBrowserCommand.CREATE_PIPELINE,
                payload: selectedPipeline
              })
            }
          />
        </div>
      </Section>
    </Grid>
  )
}
