import { useMutation } from '@tanstack/react-query'
import axios from 'axios'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { JobType } from 'job-lib/types/JobType'

import { useSpringClient } from '../useSpringClient/useSpringClient'
import {
  SampleComponentRequest,
  SampleComponentResponse,
  SampleOrchestrationComponent,
  SampleTransformationComponent
} from './types'

const jobTypeToUrlMap = {
  [JobType.Orchestration]: '/sample/orchestration',
  [JobType.Transformation]: '/sample/transformation'
}

const isTransformationSample = (
  componentCache: SampleOrchestrationComponent | SampleTransformationComponent
): componentCache is SampleTransformationComponent => {
  return 'sql' in componentCache
}

const isOrchestrationSample = (
  componentCache: SampleOrchestrationComponent | SampleTransformationComponent
): componentCache is SampleOrchestrationComponent => {
  return 'parameters' in componentCache
}

export const SAMPLE_ROW_LIMIT = 1000

export const useSampleComponent = (jobType: JobType) => {
  const { projectId, agentId, environmentId } = useProjectInfo()
  const client = useSpringClient()

  return useMutation(async ({ componentCache }: SampleComponentRequest) => {
    if (
      jobType === JobType.Orchestration &&
      !isOrchestrationSample(componentCache)
    ) {
      throw Error(
        'Incorrect sample request body for orchestration job type. For orchestration components, send the full orchestration job.'
      )
    }

    if (
      jobType === JobType.Transformation &&
      !isTransformationSample(componentCache)
    ) {
      throw Error(
        'Incorrect sample request body for transformation job type. For transformation components, send the component cache from the validation result.'
      )
    }

    try {
      const { data } = await client.post<SampleComponentResponse>(
        jobTypeToUrlMap[jobType],
        {
          projectId,
          agentId,
          environmentId,
          componentCache,
          limit: SAMPLE_ROW_LIMIT
        }
      )

      return data
    } catch (err) {
      if (axios.isAxiosError(err)) {
        throw err.response?.data
      }
      throw Error((err as Error).message)
    }
  })
}
