import { ChangeEvent, FocusEvent, FormEvent, useReducer } from 'react'
import { useTranslation } from 'react-i18next'

import {
  AutoComplete,
  Button,
  Field,
  Radio,
  Textarea
} from '@matillion/component-library'
import classNames from 'classnames'

import { useListProjectVariables } from 'api/hooks/useListProjectVariables/useListProjectVariables'

import {
  JobVariable,
  JobVariableType,
  VariableScope
} from 'job-lib/types/Variables'

import { useWorkingCopy } from 'modules/EtlDesigner/hooks/useWorkingCopy'
import baseClasses from 'modules/ManageVariables/ManageVariables.module.scss'
import {
  Fields,
  FormState,
  ProjectVariable,
  ReducerActions
} from 'modules/ManageVariables/types'
import { getVariableName } from 'modules/ManageVariables/utils'

import { Footer } from '../Footer/Footer'
import { JobSpecificFields } from './components/JobSpecificFields'
import { ProjectSpecificFields } from './components/ProjectSpecificFields'
import classes from './Form.module.scss'
import { formReducer, getInitialState } from './formReducer/formReducer'

export const Form = ({
  selectedVariableScope = VariableScope.JOB_VARIABLE,
  onSubmit,
  onCancel,
  submitting,
  variableToEdit
}: {
  selectedVariableScope: VariableScope
  onSubmit: (state: FormState, isProjectVariable: boolean) => void
  onCancel: () => void
  submitting?: boolean
  variableToEdit?: JobVariable | ProjectVariable
}) => {
  const editing = Boolean(variableToEdit)
  const { t } = useTranslation()
  const { job } = useWorkingCopy()
  const { data: projectVariables = [] } = useListProjectVariables()
  const [state, dispatch] = useReducer(
    formReducer,
    getInitialState(selectedVariableScope, variableToEdit)
  )

  const validateJobVariable = () => {
    const isJobValid =
      state.isFormValid && state.VARIABLE_TYPE.value && state.VISIBILITY.value

    if (!isJobValid) {
      dispatch({ type: ReducerActions.VALIDATE_FORM })
    }

    return Boolean(isJobValid)
  }

  const validateProjectVariable = () => {
    const isProjectValid = state.isFormValid
    if (!isProjectValid) {
      dispatch({ type: ReducerActions.VALIDATE_FORM })
    }

    return isProjectValid
  }

  const onSubmitHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const isProjectVariable =
      state.VARIABLE_SCOPE.value === VariableScope.PROJECT_VARIABLE

    const formValid = isProjectVariable
      ? validateProjectVariable()
      : validateJobVariable()

    if (formValid) {
      onSubmit(state, isProjectVariable)
    }
  }

  return (
    <form
      className={classNames(baseClasses.Stage, classes.Form)}
      onSubmit={onSubmitHandler}
    >
      <div className={classes.Form__Content}>
        <>
          {!editing && (
            <fieldset className={classNames(classes.Form__ScopeFieldset)}>
              <legend className="u-visually-hidden">
                {t('manageVariables.jobVariables.fields.variableScopes')}
              </legend>
              <Field
                data-testid="project-variable-radio-button"
                className={classNames(classes.Field, classes['Field--Radio'])}
                inputComponent={Radio}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  dispatch({
                    type: ReducerActions.UPDATE_FIELD,
                    field: Fields.VARIABLE_SCOPE,
                    value: e.target.value as VariableScope
                  })
                }}
                text={t('manageVariables.jobVariables.fields.projectVariable')}
                supportText={t(
                  'manageVariables.jobVariables.fields.projectRadioSupportText'
                )}
                value={VariableScope.PROJECT_VARIABLE}
                checked={
                  state[Fields.VARIABLE_SCOPE].value ===
                  VariableScope.PROJECT_VARIABLE
                }
              />
              <Field
                data-testid="job-variable-radio-button"
                className={classNames(classes.Field, classes['Field--Radio'])}
                inputComponent={Radio}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  dispatch({
                    type: ReducerActions.UPDATE_FIELD,
                    field: Fields.VARIABLE_SCOPE,
                    value: e.target.value as VariableScope
                  })
                }}
                text={t('manageVariables.jobVariables.fields.jobVariable')}
                supportText={t(
                  'manageVariables.jobVariables.fields.jobRadioSupportText'
                )}
                value={VariableScope.JOB_VARIABLE}
                checked={
                  state[Fields.VARIABLE_SCOPE].value ===
                  VariableScope.JOB_VARIABLE
                }
              />
            </fieldset>
          )}

          <Field
            name={Fields.NAME}
            className={classes.Field}
            title={t('manageVariables.jobVariables.fields.variableName')}
            value={state[Fields.NAME].value}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              dispatch({
                type: ReducerActions.UPDATE_NAME,
                value: e.target.value,
                jobVariables: job?.variables,
                projectVariables
              })
            }}
            onBlur={(e: FocusEvent<HTMLInputElement>) => {
              dispatch({
                type: ReducerActions.UPDATE_NAME,
                value: e.target.value,
                originalName: getVariableName(variableToEdit),
                jobVariables: job?.variables,
                projectVariables
              })
            }}
            errorText={
              state[Fields.NAME].isValid === false &&
              t(state[Fields.NAME].error, {
                value: state[Fields.NAME].value,
                field: t('manageVariables.jobVariables.fields.variableName')
              })
            }
          />
          <Field
            name={Fields.DESCRIPTION}
            className={classes.Field}
            title={t('manageVariables.jobVariables.fields.description')}
            value={state[Fields.DESCRIPTION].value}
          >
            <Textarea
              className={classes.Form__Description}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                dispatch({
                  type: ReducerActions.UPDATE_FIELD,
                  field: Fields.DESCRIPTION,
                  value: e.target.value
                })
              }}
            />
          </Field>
          <Field
            name={Fields.VARIABLE_TYPE}
            className={classes.Field}
            title={t('manageVariables.jobVariables.fields.type')}
            errorText={
              state[Fields.VARIABLE_TYPE].isValid === false &&
              t(state[Fields.VARIABLE_TYPE].error, {
                field: t('manageVariables.jobVariables.fields.type')
              })
            }
            value={state[Fields.VARIABLE_TYPE].value}
          >
            <AutoComplete
              placeholder={t(
                'manageVariables.jobVariables.fields.dropdownPlaceholder'
              )}
              onChange={(value) => {
                dispatch({
                  type: ReducerActions.UPDATE_VARIABLE_TYPE,
                  field: Fields.VARIABLE_TYPE,
                  value: value.target.value?.id as JobVariableType
                })
              }}
              onBlur={() => dispatch({ type: ReducerActions.VALIDATE_FORM })}
              availableItems={[
                {
                  id: JobVariableType.TEXT,
                  name: t(
                    'manageVariables.jobVariables.fields.variableType.text'
                  )
                },
                {
                  id: JobVariableType.NUMBER,
                  name: t(
                    'manageVariables.jobVariables.fields.variableType.number'
                  )
                }
              ]}
              value={state[Fields.VARIABLE_TYPE].value}
            />
          </Field>

          {state.VARIABLE_SCOPE.value === VariableScope.JOB_VARIABLE && (
            <JobSpecificFields state={state} dispatch={dispatch} />
          )}
          {state.VARIABLE_SCOPE.value === VariableScope.PROJECT_VARIABLE && (
            <ProjectSpecificFields state={state} dispatch={dispatch} />
          )}
        </>
      </div>
      <Footer
        warningText={
          state.VARIABLE_SCOPE.value === VariableScope.PROJECT_VARIABLE
            ? t(getFooterWarningKey(editing))
            : undefined
        }
      >
        <Button
          disabled={Boolean(submitting)}
          alt="secondary"
          onClick={onCancel}
          text={t('common.cancel')}
        />

        <Button
          disabled={Boolean(submitting)}
          data-testid={editing ? 'edit-job-variable' : 'add-variable-to-job'}
          type="submit"
          text={
            editing
              ? t('manageVariables.edit.update')
              : t('manageVariables.create.create')
          }
          alt="positive"
        />
      </Footer>
    </form>
  )
}
function getFooterWarningKey(editing: boolean): string {
  return `manageVariables.${editing ? 'edit' : 'create'}.footerWarning`
}
