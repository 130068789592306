import { RequestHandler, rest } from 'msw'

import { BRANCH_ID, PROJECT_ID } from '__test-utils__/ids'

import config from 'config'

import { TaskState } from '../index'
import { RunTasksResponse, RunTaskStatus, State, TaskStatusId } from '../types'
import { completedRunTaskSummaries } from './mockTaskSummaries'

export let runningTasksSummaries: RunTaskStatus[] = []
const completedTasksSummariesAdditional: RunTaskStatus[] = []

export const addRunningTask = (ts: RunTaskStatus) => {
  runningTasksSummaries.push(ts)
}

export const completeRunningTask = (
  id: TaskStatusId,
  state = State.Success
) => {
  let completed: RunTaskStatus | null = null

  runningTasksSummaries = runningTasksSummaries.filter((running) => {
    if (id === running.id) {
      completed = {
        ...running,
        endTime: Date.now(),
        state
      }
      return false
    }

    return true
  })

  if (completed) {
    completedTasksSummariesAdditional.push(completed)
  }
}

export const useGetRunTasksHandlers: RequestHandler[] = [
  rest.get(
    `${config.getApiBaseUrl()}/v1/tasks`,

    (_req, res, ctx) => {
      const branchId = _req.url.searchParams.get('branchId')
      const projectId = _req.url.searchParams.get('projectId')
      const status = _req.url.searchParams.get('status')

      if (branchId !== BRANCH_ID || projectId !== PROJECT_ID) {
        return res(ctx.json<RunTasksResponse>({ results: [] }))
      }

      if (status === 'completed') {
        return res(
          ctx.json<RunTasksResponse>({
            results: completedRunTaskSummaries.concat(
              completedTasksSummariesAdditional
            )
          })
        )
      } else {
        return res(
          ctx.json<RunTasksResponse>({
            results: runningTasksSummaries
          })
        )
      }
    }
  )
]

export const useGetCombinedRunTasksHandlers = (
  completed: RunTaskStatus,
  running: RunTaskStatus
): RequestHandler[] => [
  rest.get(`${config.getApiBaseUrl()}/v1/tasks`, (_req, res, ctx) => {
    const branchId = _req.url.searchParams.get('branchId')
    const requestedState = _req.url.searchParams.get('status') as TaskState

    switch (branchId) {
      case 'branchWithOnlyCompletedTasks':
        if (requestedState === 'completed') {
          return res(ctx.json<RunTasksResponse>({ results: [completed] }))
        }
        break
      case 'branchWithOnlyRunningTasks':
        if (requestedState === 'running') {
          return res(ctx.json<RunTasksResponse>({ results: [running] }))
        }
        break
      case 'branchWithAllTaskStates':
        if (requestedState === 'completed') {
          return res(ctx.json<RunTasksResponse>({ results: [completed] }))
        }

        if (requestedState === 'running') {
          return res(ctx.json<RunTasksResponse>({ results: [running] }))
        }

        break
    }

    return res(ctx.json({ results: [] }))
  })
]
