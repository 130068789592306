import { useQuery } from '@tanstack/react-query'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { queryKeys } from '../../queryKeys'
import { useSpringClient } from '../useSpringClient/useSpringClient'
import { ListSchemaTablesResponse } from './types'

export const useSchemaTables = (warehouse: string, schemaName: string) => {
  const { projectId, environmentId } = useProjectInfo()
  const client = useSpringClient()

  return useQuery(
    [projectId, environmentId, queryKeys.listSchemaTables, schemaName],
    async () => {
      const { data } = await client.get<ListSchemaTablesResponse>(
        `projects/${projectId}/environments/${environmentId}/warehouse/${warehouse}/schemas/${schemaName}/tables`
      )
      return data.results
    },
    {
      enabled: !!warehouse
    }
  )
}
