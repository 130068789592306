import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '@matillion/component-library'

import { ChevronButton } from 'components/ChevronButton'
import { PopOverMenu } from 'components/PopOverMenu'

import classes from '../../PipelineBrowser.module.scss'
import PipelinePanelContextMenu from './PipelinePanelContextMenu'

const PipelineBrowserToolBar: FC = () => {
  const { t } = useTranslation()

  return (
    <div className={classes.PipelineBrowser__CTAContainer}>
      <PopOverMenu
        className={classes.PipelineBrowser__CTAPopoverMenu}
        position="bottom"
        content={<PipelinePanelContextMenu />}
      >
        {({ onClick, open }) => (
          <ChevronButton
            direction={open ? 'up' : 'down'}
            data-testid={'jobs-browser-actions'}
            onClick={onClick}
            size="sm"
            alt="secondary"
            aria-label={t('translation:sideBar.jobPanel.panelActionMenu')}
          >
            <Typography as="span" format="mc" weight="bold">
              {t('translation:sideBar.jobPanel.addBtnLabel')}{' '}
            </Typography>
          </ChevronButton>
        )}
      </PopOverMenu>
    </div>
  )
}

export default PipelineBrowserToolBar
