import _ from 'lodash'

import { makeConnectorId } from 'job-lib/job-functions/job-functions'
import { Cardinality } from 'job-lib/types/Components'
import { ComponentInstanceId, TransformationJob } from 'job-lib/types/Job'

import { UpdateLinks } from '../../job.types'
import { deleteTransformationLink } from '../../reducers/deleteLink/deleteTransformationLink'
import { unique } from '../../utils/unique'

/**
 * Given a target and/or a source, this function will return a list of connectors which contain either or
 * both IDs. If both target and source are passed, then what you're doing is effectively checking for an
 * already existing link.
 * @param job — Transformation job
 * @param targetID — ID of the component's input used as target
 * @param sourceID — ID of the component's output used as source
 * @returns Connector[] | undefined
 */
const getTransformationExistingConnector = (
  job: TransformationJob,
  targetID?: ComponentInstanceId,
  sourceID?: ComponentInstanceId
) => {
  return Object.values(job.connectors).filter((connector) => {
    if (!targetID || connector.targetID === targetID) {
      return sourceID === undefined || connector.sourceID === sourceID
    }
    return false
  })
}

export const updateTransformationLinks = ({
  job,
  sourceComponentId,
  targetComponentId,
  sourceCardinality,
  targetCardinality,
  sourceType
}: { job: TransformationJob } & UpdateLinks) => {
  const newJobState = _.cloneDeep(job)
  const connectorId = makeConnectorId(newJobState)

  /**
   * This enables us to check against the state of the target component, BEFORE we create a new link.
   * Important when the target has a cardinality of ONE, in which case the new link we're about to create
   * will have to remove/override this old connector against our target.
   */
  const connectorsAgainstTarget = getTransformationExistingConnector(
    newJobState,
    targetComponentId
  )
  /**
   * This enables us to know whether the link we're about to create already exists. Regardless of the
   * cardinality of both source/target, a new link will be created and this value will allow us to remove
   * the old duplicated link.
   */
  const [duplicatedConnector] = getTransformationExistingConnector(
    newJobState,
    targetComponentId,
    sourceComponentId
  )
  /**
   * This enables us to check against the state of the source component, BEFORE we create a new link.
   * Important when the source has a cardinality of ONE, in which case the new link we're about to create
   * will have to remove/override this old connector against our source.
   */
  const connectorsFromSource = getTransformationExistingConnector(
    newJobState,
    undefined,
    sourceComponentId
  )

  /**
   * This returns the current list of link-IDs against our source's output. We'll have to add our new
   * link ID here
   */
  const outputConnectors =
    newJobState.components[sourceComponentId].outputConnectorIDs
  const newSourceValue = unique(outputConnectors, connectorId)

  /**
   * This returns the current list of link-IDs against our target's input. We'll have to add our new
   * link ID here
   */
  const inputConnectors =
    newJobState.components[targetComponentId].inputConnectorIDs
  const newTargetValue = unique(inputConnectors, connectorId)

  /** Updates source output list with unique list of IDs (previous and new one) */
  newJobState.components[sourceComponentId].outputConnectorIDs = newSourceValue

  /** Updates target input list with unique list of IDs (previous and new one) */
  newJobState.components[targetComponentId].inputConnectorIDs = newTargetValue

  /** Creates a new connector under the correct source type (e.g. unconditional, success, etc) */
  newJobState.connectors[connectorId] = {
    id: connectorId,
    sourceID: sourceComponentId,
    targetID: targetComponentId
  }

  /**
   * If our target has cardinality of ONE, and before creating the new link there was already a link against it,
   * then it deletes that old link against our target
   */
  if (connectorsAgainstTarget.length && targetCardinality === Cardinality.ONE) {
    connectorsAgainstTarget.forEach((cat) => {
      deleteTransformationLink(cat.id, newJobState)
    })
  }

  /**
   * If our source has cardinality of ONE, and before creating the new link there was already a link against it,
   * then it deletes that old link against our source
   */
  if (connectorsFromSource.length && sourceCardinality === Cardinality.ONE) {
    connectorsFromSource.forEach((cat) => {
      deleteTransformationLink(cat.id, newJobState)
    })
  }

  /**
   * If there was a duplicated link already, it removes it. NB: for components with cardinality of ONE, this
   * operation might have already happened in one of the two previous IF statements. The following IF is particularly
   * useful for components without cardinality of ONE.
   */
  if (duplicatedConnector) {
    deleteTransformationLink(duplicatedConnector.id, newJobState)
  }

  return newJobState
}
