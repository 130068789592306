import { createElement, FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon, MicroCta, Tooltip } from '@matillion/component-library'
import classNames from 'classnames'

import classes from '../FlowStatsExplorer.module.scss'
import { JobTreeController } from '../types'
import { getStateIcon } from '../utils/icon-mapper'
import { DEPTH_PADDING, getDepthLevel } from '../utils/tree-depth'
import CellTypography from './CellTypography'
import ChildJobLoader from './ChildJobLoader'
import StrandLoader from './StrandLoader'

const RowTreeSelector: FC<JobTreeController> = (props) => {
  const { t } = useTranslation()
  const {
    jobName,
    status,
    depth,
    expandItems,
    collapseItem,
    isInFilter,
    loader,
    childJobId,
    ...rest
  } = props
  const taskStatusIcon = getStateIcon(status)
  const taskLabel = t(`taskExplorer.status.${status || 'UNKNOWN'}`)
  const padding = getDepthLevel(depth) - 1

  const handleClickTrigger = () => {
    if (isInFilter) {
      collapseItem?.(depth)
    } else {
      expandItems?.(depth)
    }
  }

  return (
    <>
      <div style={{ marginLeft: `${padding * DEPTH_PADDING - 10}px` }}>
        {(rest.hasChildren || childJobId) && (
          <MicroCta
            // a11y remove the button from the focus list
            tabIndex={-1}
            aria-label={isInFilter ? t('common.expand') : t('common.collapse')}
            alt="transparent"
            size="sm"
            className={classNames(classes.RowExpandTrigger, {
              [classes.expanded]: !isInFilter
            })}
            onClick={handleClickTrigger}
            data-testid={`treeGrid-trigger-${depth}`}
          >
            <Icon.ChevronDown />
          </MicroCta>
        )}
      </div>
      <Tooltip content={taskLabel}>
        <div data-testid={`treeGrid-item-${depth}`}>
          {createElement(taskStatusIcon, {
            className: classNames(classes.TaskStatus__Icon),
            'aria-label': taskLabel
          })}
        </div>
      </Tooltip>
      {!loader && <CellTypography>{jobName}</CellTypography>}
      {loader && !childJobId && <StrandLoader loader={loader} parent={props} />}
      {loader && childJobId && (
        <ChildJobLoader parent={props} loader={loader} />
      )}
    </>
  )
}

export default RowTreeSelector
