import { MoveItemVerticalProps } from './types'

export const UP = -1
export const DOWN = 1

const spliceRightColumn = (
  rightColumn: string[],
  start: number,
  deleteCount: number,
  items: string[]
) => {
  rightColumn.splice(start, deleteCount, ...items)
  return rightColumn
}
export const moveItemVertical = (props: MoveItemVerticalProps) => {
  const { rightColumn, selectedItems, direction } = props
  let rightColumnClone = [...rightColumn]
  const selectedItemsInOrder = rightColumnClone.filter(
    (item) => item !== null && selectedItems.includes(item)
  )

  if (direction === UP) {
    const topIndex =
      rightColumnClone.indexOf(selectedItemsInOrder[0]) + direction
    /* istanbul ignore next */
    if (topIndex < 0) {
      return rightColumnClone
    }
    rightColumnClone = rightColumnClone.filter(
      (item) => !selectedItemsInOrder.includes(item)
    )
    return spliceRightColumn(
      rightColumnClone,
      topIndex,
      0,
      selectedItemsInOrder
    )
  } else {
    // direction === DOWN
    const bottomIndex =
      rightColumnClone.indexOf(
        selectedItemsInOrder[selectedItemsInOrder.length - 1]
      ) + direction
    /* istanbul ignore next */
    if (bottomIndex >= rightColumnClone.length) {
      return rightColumnClone
    }
    rightColumnClone = rightColumnClone.filter(
      (item) => !selectedItemsInOrder.includes(item)
    )
    return spliceRightColumn(
      rightColumnClone,
      bottomIndex - selectedItemsInOrder.length + 1,
      0,
      selectedItemsInOrder
    )
  }
}
