import { ChangeEvent, Dispatch } from 'react'
import { useTranslation } from 'react-i18next'

import { Field, Icon, Radio, Typography } from '@matillion/component-library'
import classNames from 'classnames'

import { JobVariableVisibility } from 'job-lib/types/Variables'

import {
  Fields,
  FormState,
  ReducerActions
} from 'modules/ManageVariables/types'

import classes from '../Form.module.scss'
import { ReducerAction } from '../formReducer/formReducer'

export const JobSpecificFields = ({
  state,
  dispatch
}: {
  state: FormState
  dispatch: Dispatch<ReducerAction>
}) => {
  const { t } = useTranslation()

  return (
    <>
      <fieldset className={classes.Form__ScopeFieldset}>
        <legend className="u-visually-hidden">
          {t('manageVariables.jobVariables.fields.variableVisibilityScope')}
        </legend>

        <Typography
          format="bcs"
          weight="bold"
          className={classes.Form__CustomLabel}
        >
          {t('manageVariables.jobVariables.fields.visibility')}
        </Typography>

        <Field
          className={classNames(classes.Field, classes.RadioField)}
          inputComponent={Radio}
          onBlur={() => dispatch({ type: ReducerActions.VALIDATE_FORM })}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            dispatch({
              type: ReducerActions.UPDATE_FIELD,
              field: Fields.VISIBILITY,
              value: e.target.value as JobVariableVisibility
            })
          }}
          text={t(
            'manageVariables.jobVariables.fields.variableVisibility.public'
          )}
          hasError={state[Fields.VISIBILITY].isValid === false}
          value={JobVariableVisibility.PUBLIC}
          checked={
            state[Fields.VISIBILITY].value === JobVariableVisibility.PUBLIC
          }
        />
        <Field
          className={classes.Field}
          inputComponent={Radio}
          onBlur={() => dispatch({ type: ReducerActions.VALIDATE_FORM })}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            dispatch({
              type: ReducerActions.UPDATE_FIELD,
              field: Fields.VISIBILITY,
              value: e.target.value as JobVariableVisibility
            })
          }}
          text={t(
            'manageVariables.jobVariables.fields.variableVisibility.private'
          )}
          hasError={state[Fields.VISIBILITY].isValid === false}
          value={JobVariableVisibility.PRIVATE}
          checked={
            state[Fields.VISIBILITY].value === JobVariableVisibility.PRIVATE
          }
        />

        <div aria-live="polite">
          {state[Fields.VISIBILITY].isValid === false && (
            <div className={classes.FieldError}>
              <Icon.Error className={classes.FieldError__Icon} />
              <Typography
                className={classes.FieldError__Text}
                as="span"
                format="bcs"
              >
                {t(
                  'manageVariables.jobVariables.fields.errors.pleaseSelectAnOption'
                )}
              </Typography>
            </div>
          )}
        </div>
      </fieldset>

      <Field
        className={classes.Field}
        title={t('manageVariables.jobVariables.fields.defaultValue')}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          dispatch({
            type: ReducerActions.UPDATE_DEFAULT_VALUE,
            field: Fields.DEFAULT_VALUE,
            value: e.target.value
          })
        }}
        value={state[Fields.DEFAULT_VALUE].value}
        hasError={state[Fields.DEFAULT_VALUE].isValid === false}
        errorText={
          state[Fields.DEFAULT_VALUE].isValid === false &&
          t(state[Fields.DEFAULT_VALUE].error, {
            field: t('manageVariables.jobVariables.fields.defaultValue')
          })
        }
      />
    </>
  )
}
