import { useCallback, useEffect } from 'react'

import { FlowCanvasProps } from 'modules/Canvas/components/FlowCanvas/types'
import { useCanvasModel } from 'modules/Canvas/hooks/useCanvasModel/useCanvasModel'
import { getSelectedNodeIds } from 'modules/Canvas/hooks/useCanvasModel/utils'
import { useEtlFlow } from 'modules/Canvas/hooks/useEtlFlow'

import { useHasNodeCountChanged } from './useHasNodeCountChanged'

export const useSyncedCanvasModel = (job: FlowCanvasProps['job']) => {
  const reactFlow = useEtlFlow()
  const canvasModel = useCanvasModel(job)
  const { nodes } = canvasModel
  const hasNodeCountChanged = useHasNodeCountChanged(nodes)

  /*
   * We can't use react-flow's controlled mode, because updating
   * the job model and re-rendering nodes and edges is computationally
   * pretty expensive--doing that as components are moved around makes
   * the UI really laggy. Using the controlled flow lets us mirror changes
   * back into the job model separate from user actions, but also means that
   * we need to synchronise changes back into react-flow manually.
   *
   * In order not to deselect all nodes on change--and, therefore, close
   * the component properties panel--we also need to ensure that the selected
   * state is persisted between updates.
   */

  const syncCanvasModel = useCallback(() => {
    const selectedNodes = getSelectedNodeIds(reactFlow)

    const newNodes = [...canvasModel.nodes.values()].map((node, index, arr) => {
      const selected = hasNodeCountChanged
        ? index === arr.length - 1
        : selectedNodes.includes(node.id)

      return {
        ...node,
        selected
      }
    })

    const newEdges = [...canvasModel.edges.values()]

    reactFlow.setNodes(newNodes)
    reactFlow.setEdges(newEdges)
  }, [canvasModel, reactFlow, hasNodeCountChanged])

  useEffect(() => {
    syncCanvasModel()
  }, [canvasModel, syncCanvasModel])

  return { canvasModel, syncCanvasModel }
}
