import { useFlags as useHubFlags } from '@matillion/hub-client'

export interface EtlFlags {
  [key: string]: boolean | string[]
  pullChangesV2: boolean
  pipelineFolders: boolean
  enableExperimentalDpl: boolean
  enableAgentStackTrace: boolean
  rolloutEnableMonacoCodeEditor: boolean
  enableAgentAvailabilityModal: boolean
  enableHardResetBranch: boolean
  enableNewAddComponentsExperience: boolean
  enableAddSchedule: boolean
  enableCustomConnectors: boolean
}

export const useFlags = () => useHubFlags<EtlFlags>()
