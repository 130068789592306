export const queryKeys = {
  merge: 'merge',
  getWorkingTreeBranch: 'getWorkingTreeBranch',
  getBranch: 'getBranch',
  describeMerge: 'describeMerge',
  getBranches: 'getBranches',
  pull: 'pull',
  describePull: 'describePull',
  push: 'push',
  delete: 'delete',
  getRepositories: 'getRepositories',
  initOAuth: 'initOAuth',
  captureOAuthToken: 'captureOAuthToken',
  entitlements: 'entitlements'
}
