import {
  ComponentEditorTabType,
  ComponentMetadataResponse,
  EditorType,
  ParameterDataType,
  UseVariable
} from 'api/hooks/useGetComponentMetadata/types'

import { Cardinality, ConnectionPortType } from 'job-lib/types/Components'
import { JobType } from 'job-lib/types/JobType'

export const mockUniteMetadata: ComponentMetadataResponse = {
  componentId: 'unite',
  metadata: {
    schemaVersion: 1,
    emeraldId: '-1841822228',
    componentType: JobType.Transformation,
    inputPorts: [
      {
        portId: ConnectionPortType.INPUT,
        cardinality: Cardinality.MANY
      }
    ],
    outputPorts: [
      {
        portId: ConnectionPortType.UNCONDITIONAL,
        cardinality: Cardinality.MANY
      }
    ],
    deprecated: false,
    exports: null,
    editorTabs: [
      ComponentEditorTabType.PROPERTIES,
      ComponentEditorTabType.SAMPLE,
      ComponentEditorTabType.METADATA,
      ComponentEditorTabType.SQL,
      ComponentEditorTabType.PLAN,
      ComponentEditorTabType.HELP
    ],
    parameters: [
      {
        id: 'component-name',
        dplID: 'componentName',
        metlSlot: 1,
        useVariable: UseVariable.NO,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: null
      },
      {
        id: 'method',
        dplID: 'method',
        metlSlot: 2,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: [
          {
            name: null,
            type: 'TEXT',
            options: ['All Columns', 'Overlapping Columns'],
            columnEditorType: null,
            defaultValue: null,
            lookupType: null,
            lookupDependencies: null
          }
        ]
      },
      {
        id: 'cast-types',
        dplID: 'castTypes',
        metlSlot: 3,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: [
          {
            name: null,
            type: 'TEXT',
            options: ['Yes', 'No'],
            columnEditorType: null,
            defaultValue: null,
            lookupType: null,
            lookupDependencies: null
          }
        ]
      },
      {
        id: 'add-source-component-column',
        dplID: 'addSourceComponentColumn',
        metlSlot: 4,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: [
          {
            name: null,
            type: 'TEXT',
            options: ['Yes', 'No'],
            columnEditorType: null,
            defaultValue: null,
            lookupType: null,
            lookupDependencies: null
          }
        ]
      },
      {
        id: 'remove-duplicates',
        dplID: 'removeDuplicates',
        metlSlot: 5,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: [
          {
            name: null,
            type: 'TEXT',
            options: ['Yes', 'No'],
            columnEditorType: null,
            defaultValue: null,
            lookupType: null,
            lookupDependencies: null
          }
        ]
      }
    ]
  }
}
