import { type ConflictResolutionSelection } from 'components/ConflictResolutionTable/types'
import { type ConflictVersion } from 'components/ConflictVersionSelector/types'
import { useCallback } from 'react'

const useMapConflictSelections = (conflictMapping: Map<ConflictVersion, string>) => {
    const map = useCallback((selections: ConflictResolutionSelection[]) => {
        return selections.map((selection) => ({
            fileName: selection.fileName,
            replacementType: conflictMapping.get(selection.version) ?? 'NOT_SELECTED'
        }))
    }, [])

    return { map }
}

export default useMapConflictSelections
