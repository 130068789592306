import usePushChanges from 'api/hooks/usePushChanges'
import { type PushActionArgs, type PushActionResponse } from './types'
import { useCallback } from 'react'
import { Toaster } from '@matillion/component-library'
import useProblemDetails from '../useProblemDetails/useProblemDetails'
import {
  type ProblemDetailRegistry,
  type TranslationKeys
} from '../useProblemDetails/types'
import { useTranslation } from 'react-i18next'

const commonFailureTitleKey = 'git.push.problem.default.title'
const problemDetailRegistry: ProblemDetailRegistry = [
  {
    type: 'working-tree/push_to_remote_rejected',
    translation: {
      titleKey: 'git.push.problem.push-rejected.title',
      messageKey: 'git.push.problem.push-rejected.message'
    }
  }
]
const defaultTranslationKeys: TranslationKeys = {
  titleKey: commonFailureTitleKey,
  messageKey: 'git.push.problem.default.message'
}

const usePushAction = ({
  onSuccess,
  onFailure
}: PushActionArgs = {}): PushActionResponse => {
  const { makeToast } = Toaster.useToaster()
  const { mutateAsync: push, isLoading } = usePushChanges()
  const { t } = useTranslation('translation', { keyPrefix: 'git.push' })
  const { resolveProblemDetails } = useProblemDetails(problemDetailRegistry)

  const pushChanges = useCallback(async () => {
    try {
      const response = await push()

      onSuccess?.()

      makeToast({
        type: 'success',
        title: t('success.title'),
        message: t('success.message')
      })

      return response
    } catch (error) {
      const { title, message } = resolveProblemDetails(
        error,
        defaultTranslationKeys
      )

      makeToast({ type: 'error', title, message })

      onFailure?.()

      return undefined
    }
  }, [])

  return {
    pushChanges,
    loading: isLoading
  }
}

export default usePushAction
