import { FC } from 'react'

import { DataGrid, LoadingSpinner } from '@matillion/component-library'

import { RunTaskStatus } from 'api/hooks/useGetRunTasks/types'

import EmptyFlowExplorer from '../EmptyFlowExplorer'
import classes from '../FlowStatsExplorer.module.scss'
import { useTreeGridController } from '../hooks/useTreeGridController'

const TreeGrid: FC<{ task: RunTaskStatus }> = ({ task }) => {
  const { columnSpecs, items, isLoading, isError, getTreeGridProps } =
    useTreeGridController(task)

  if (isLoading) {
    return (
      <div className={classes.LoadingContainer}>
        <LoadingSpinner />
      </div>
    )
  }

  if (isError) return <EmptyFlowExplorer />

  return (
    <div className={classes.DatagridContainer}>
      <DataGrid
        {...getTreeGridProps()}
        columns={columnSpecs}
        rows={items}
        compact
      />
    </div>
  )
}

export default TreeGrid
