import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Field, Input } from '@matillion/component-library'

import {
  ParameterOverlayButton,
  ParameterOverlayFooter,
  ParameterOverlayHeader
} from 'components/ParameterOverlay'

import {
  StorageEditorType,
  UrlEditorProps
} from 'modules/ParameterEditors/types'

import classes from '../../UrlEditor.module.scss'
import ContainerList from '../ContainerList/ContainerList'
import { ErrorMessage } from '../ErrorMessage/ErrorMessage'
import { useGetContainers } from '../hooks/useGetContainers'
import { useGrid } from '../hooks/useGrid'

const S3PREFIX = 's3://'

export const S3UrlEditor = ({
  value,
  parameterName,
  onDone
}: UrlEditorProps) => {
  const initialS3Url = value[0]?.replace(S3PREFIX, '') || ''
  const initialFullPath = initialS3Url.split('/').filter(Boolean)

  const { t } = useTranslation()
  const [warehouseUrl, setWarehouseUrl] = useState<string>(initialS3Url)
  const [error, setError] = useState<string | null>()
  const [fullPath, setFullPath] = useState<string[]>(initialFullPath)

  const onContainerPathChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setWarehouseUrl(e.target.value)
    },
    [setWarehouseUrl]
  )

  const { isLoading, buckets, folders, foldersError, bucketsError } =
    useGetContainers(fullPath, StorageEditorType.S3)

  const { rows, columns, setSort } = useGrid({
    folders: folders ?? [],
    buckets: buckets ?? [],
    fullPath,
    setFullPath,
    setWarehouseUrl
  })

  const handleError = (errorMessage: string) => {
    setError(errorMessage)
    setFullPath([])
  }

  useEffect(() => {
    if (foldersError && foldersError.status === 403) {
      handleError(
        `${t('parameterEditor.S3_URL.error.accessDenied')} ${
          foldersError.detail
        }`
      )
    } else if (foldersError) {
      handleError(
        `${t('parameterEditor.S3_URL.error.foldersError')} ${
          foldersError.detail
        }`
      )
    } else if (bucketsError) {
      handleError(
        `${t('parameterEditor.S3_URL.error.bucketsError')} ${
          bucketsError.detail
        }`
      )
    }
  }, [foldersError, setError, bucketsError, warehouseUrl, t, error])

  useEffect(() => {
    if (warehouseUrl) {
      setError(null)
    }
  }, [warehouseUrl])

  const onSubmit = useCallback(() => {
    const newUrl = warehouseUrl.toLowerCase().includes(S3PREFIX)
      ? warehouseUrl
      : `${S3PREFIX}${warehouseUrl}`
    onDone([newUrl])
  }, [warehouseUrl, onDone])

  const handleEnterPress = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        setFullPath(warehouseUrl.split('/').filter(Boolean))
      }
    },
    [warehouseUrl]
  )

  const handleBlur = () => {
    if (isLoading) return

    if (!warehouseUrl) {
      setError(t('parameterEditor.S3_URL.error.emptyValue'))
    }
  }

  return (
    <>
      <ParameterOverlayHeader title={parameterName} />
      <Field
        id="sr3urleditor-url"
        name="sr3urleditor-url"
        title={t('parameterEditor.S3_URL.url.title')}
        aria-label={t('parameterEditor.S3_URL.url.title')}
        placeholder={t('parameterEditor.S3_URL.url.placeholder')}
        prefix={S3PREFIX.toUpperCase()}
        value={warehouseUrl}
        onChange={onContainerPathChange}
        onKeyPress={handleEnterPress}
        onBlur={handleBlur}
        hasError={!!error}
        error={!!error}
        errorText={
          error && (
            <ErrorMessage
              storageEditorType={StorageEditorType.S3}
              text={error}
            />
          )
        }
        inputComponent={Input}
        className={classes['Buckets--Input']}
      />
      <ContainerList
        isLoading={isLoading}
        columns={columns}
        rows={rows}
        setSort={setSort}
        fullPath={fullPath}
        setFullPath={setFullPath}
        setWarehouseUrl={setWarehouseUrl}
        storageEditorType={StorageEditorType.S3}
      />

      <ParameterOverlayFooter>
        <ParameterOverlayButton onClick={onSubmit} text={t('common.save')} />
      </ParameterOverlayFooter>
    </>
  )
}
