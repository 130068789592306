import { useEffect, useMemo } from 'react'

import useGetBranch from 'api/hooks/useGetBranch/useGetBranch'
import useGetWorkingTreeBranch from 'api/hooks/useGetWorkingTreeBranch/useGetWorkingTreeBranch'

import useFeatureFlags from 'hooks/useFeatureFlags'
import { type IsBehindRemoteArgs, type IsBehindRemoteResponse } from './types'

const useIsBehindRemote = ({
  branchName,
  onFailure,
  enabled
}: IsBehindRemoteArgs): IsBehindRemoteResponse => {
  const { pullChangesV2 } = useFeatureFlags()

  const queryRefetchProps = {
    refetchOnMount: true,
    staleTime: 0,
    cacheTime: 0,
    enabled: pullChangesV2 && enabled
  }

  const {
    data: remoteBranch,
    isLoading: remoteLoading,
    isError: isRemoteError,
    fetchStatus: remoteStatus
  } = useGetBranch(branchName, queryRefetchProps)

  const {
    data: localBranch,
    isLoading: localLoading,
    isError: isLocalError,
    fetchStatus: localStatus
  } = useGetWorkingTreeBranch(branchName, queryRefetchProps)

  useEffect(() => {
    if (isRemoteError || isLocalError) {
      onFailure?.()
    }
  }, [isLocalError, isRemoteError, onFailure])

  const isRemoteLoading = remoteLoading && remoteStatus !== 'idle'
  const isLocalLoading = localLoading && localStatus !== 'idle'

  const isBehind = useMemo(() => {
    if (localBranch && remoteBranch) {
      return localBranch.commitId !== remoteBranch.headCommit.id
    }
    return undefined
  }, [localBranch, remoteBranch])

  return {
    isBehindRemote: isBehind,
    isLoading: isLocalLoading || isRemoteLoading
  }
}

export default useIsBehindRemote
