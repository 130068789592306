import { useDispatch } from 'react-redux'

import { useListProjectVariables } from 'api/hooks/useListProjectVariables/useListProjectVariables'
import { UpdateProjectVariablesRequest } from 'api/hooks/useUpdateProjectVariables/types'
import { useUpdateProjectVariables } from 'api/hooks/useUpdateProjectVariables/useUpdateProjectVariables'

import { jobActions } from 'job-lib/store'
import {
  JobVariableBehaviour,
  JobVariableType,
  JobVariableVisibility,
  VariableScope
} from 'job-lib/types/Variables'

import { useWorkingCopy } from 'modules/EtlDesigner/hooks/useWorkingCopy'
import {
  FormState,
  ManageVariableStageProps,
  ProjectVariableBehaviour,
  ProjectVariableType,
  SelectedJobVariable,
  SelectedVariable,
  Stages
} from 'modules/ManageVariables/types'
import { isJobVariable } from 'modules/ManageVariables/utils'

import { Form } from '../Form/Form'

export const Edit = ({
  setStage,
  selectedVariableScope,
  selectedVariable
}: {
  setStage: ManageVariableStageProps['setStage']
  selectedVariableScope: VariableScope
  selectedVariable?: SelectedVariable
}) => {
  const jobDispatch = useDispatch()
  const { job } = useWorkingCopy()
  const { data: projectVariables, isLoading: projectVariablesLoading } =
    useListProjectVariables()
  const { mutateAsync: updateProjectVariables, isLoading } =
    useUpdateProjectVariables()
  const jobVariableSelected =
    selectedVariable?.type === VariableScope.JOB_VARIABLE

  const variableToEdit = jobVariableSelected
    ? selectedVariable?.name !== undefined &&
      job?.variables?.[selectedVariable.name]
    : projectVariables?.find((projVar) => projVar.id === selectedVariable?.id)

  if (!variableToEdit) {
    if (!projectVariablesLoading) {
      setStage(Stages.MANAGE)
    }

    return <></>
  }

  const onSubmit = (state: FormState) => {
    if (isJobVariable(variableToEdit)) {
      jobDispatch(
        jobActions.updateJobVariable({
          oldName: (selectedVariable as SelectedJobVariable).name,
          updatedVariable: {
            definition: {
              name: state.NAME.value,
              description: state.DESCRIPTION.value,
              type: state.VARIABLE_TYPE.value as JobVariableType,
              behaviour: JobVariableBehaviour.COPIED,
              visibility: state.VISIBILITY.value as JobVariableVisibility
            },
            value: state.DEFAULT_VALUE.value
          }
        })
      )
      setStage(Stages.MANAGE)
    } else {
      const patchData: UpdateProjectVariablesRequest = {
        name: state.NAME.value,
        type: state.VARIABLE_TYPE.value as ProjectVariableType,
        behaviour: ProjectVariableBehaviour.COPIED,
        description: state.DESCRIPTION.value,
        value:
          state.DEFAULT_VALUE.value !== '' ? state.DEFAULT_VALUE.value : null,
        overrides: Object.values(state.PROJECT_DEFAULT_OVERRIDES.value).map(
          ({ environmentId, value }) => ({
            environmentId,
            value: value !== '' ? value : null
          })
        )
      }

      updateProjectVariables({
        variableId: variableToEdit.id,
        updateProjectVariablesRequest: patchData
      }).then(() => {
        setStage(Stages.MANAGE)
      })
    }
  }

  const onCancel = () => {
    setStage(Stages.MANAGE)
  }

  return (
    <Form
      variableToEdit={variableToEdit}
      selectedVariableScope={selectedVariableScope}
      onCancel={onCancel}
      onSubmit={onSubmit}
      submitting={isLoading}
    />
  )
}
