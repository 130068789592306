import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'
import { groupBy } from 'lodash'

import { JobType } from 'job-lib/types/JobType'

import {
  NodeInfo,
  NodeType
} from 'modules/Canvas/hooks/useCanvasModel/useCanvasModel'

import { JobState } from '../../job.types'
import { canDeleteComponents } from './utils/canDeleteComponents'
import { removeComponent } from './utils/removeComponent'
import { removeOrchestrationLinks } from './utils/removeOrchestrationLinks'
import { removeTransformationLinks } from './utils/removeTransformationLinks'

type DeleteNodePayload = NodeInfo[]
type NodeDictionary = Record<NodeType, NodeInfo[] | undefined>

export const deleteNodes: CaseReducer<
  JobState,
  PayloadAction<DeleteNodePayload>
> = (state, { payload }) => {
  const { jobType, job } = state
  const groupedNodes = groupBy(payload, (x) => x.type) as NodeDictionary

  removeNotes(groupedNodes.note ?? [])
  removeComponents(groupedNodes.component ?? [])

  return state

  function removeNotes(notes: NodeInfo[]) {
    const noteInstanceIds = notes.map((x) => x.id)
    if (job?.notes) {
      noteInstanceIds.forEach((id) => {
        delete job.notes?.[id]
      })
    }
  }

  function removeComponents(components: NodeInfo[]) {
    const componentInstanceIds = components.map((x) => x.id)
    const invalidJob = jobType === null || job === null
    const cannotDeleteComponents =
      jobType === JobType.Orchestration &&
      !canDeleteComponents(componentInstanceIds, job)

    if (invalidJob || cannotDeleteComponents) return

    if (jobType === JobType.Orchestration) {
      removeOrchestrationLinks({ job, componentInstanceIds })
    }

    if (jobType === JobType.Transformation) {
      removeTransformationLinks({ job, componentInstanceIds })
    }

    removeComponent({
      job,
      componentInstanceIds
    })
  }
}
export default deleteNodes
