import { useState } from 'react'

import { useAuth, useUser } from '@matillion/hub-client'
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

import config from 'config'

export enum ProjectExplorerClientVersion {
  V1 = 'v1',
  V2 = 'v2'
}

export const useProjectExplorerClient = (
  version: ProjectExplorerClientVersion = ProjectExplorerClientVersion.V1,
  axiosConfig?: AxiosRequestConfig
) => {
  const { organisation: account } = useUser()
  const { getToken } = useAuth()
  const [client] = useState<AxiosInstance>(createInstance)

  function createInstance() {
    const instance = axios.create(axiosConfig ?? {})

    instance.defaults.baseURL = `${config.getProjectExplorerApiBaseUrl(
      account.region
    )}/${version}`

    instance.interceptors.request.use(async (interceptedConfig) => {
      const authHeader = `Bearer ${await getToken({
        audience: config.apiTokenAudience
      })}`

      interceptedConfig.headers = {
        ...interceptedConfig.headers,
        'account-id': account.id,
        Authorization: authHeader
      }

      return interceptedConfig
    })

    return instance
  }

  return client
}
