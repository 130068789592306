import { FunctionComponent, PropsWithChildren, StrictMode } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ReactFlowProvider } from 'reactflow'

import { Toaster } from '@matillion/component-library'
import {
  AuthProvider,
  AuthProviderEnvironment,
  FlaggedServiceRegistryProvider,
  FlagProvider,
  RegionGuard,
  ServiceKey
} from '@matillion/hub-client'
import { QueryClientProvider } from '@tanstack/react-query'

import queryClient from 'api/queryClient'

import { ShortcutProvider } from 'components/ShortcutProvider/ShortcutProvider'

import { CopyPasteProvider } from 'modules/CopyPasteComponent/CopyPasteContext'

import './config/i18n.config'

import { MOCK_USER } from '__mocks__/@matillion/hub-client'

import config from './config'

if (
  config.authEnvironment === AuthProviderEnvironment.test &&
  !('Cypress' in window)
) {
  window.AuthProvider = { mockProfile: MOCK_USER }
}

const Providers: FunctionComponent<PropsWithChildren> = ({ children }) => (
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <ReactFlowProvider>
        <AuthProvider environment={config.authEnvironment}>
          <FlagProvider
            waitForFlags
            launchDarklyClientId={config.launchDarkly.clientId}
            flags={config.launchDarkly.defaultFlags}
          >
            <RegionGuard strictAccountRegion>
              <FlaggedServiceRegistryProvider
                enableServiceRegistryFlagName="rolloutEnableServiceRegistry"
                endpoints={{
                  [ServiceKey.projectExplorerFrontend]: `https://${config.projectExplorerDomain}`
                }}
              >
                <ShortcutProvider>
                  <CopyPasteProvider>
                    <Toaster.Provider
                      withFade={3}
                      align="right"
                      position="top"
                      theme="dark"
                    >
                      <BrowserRouter>{children}</BrowserRouter>
                    </Toaster.Provider>
                  </CopyPasteProvider>
                </ShortcutProvider>
              </FlaggedServiceRegistryProvider>
            </RegionGuard>
          </FlagProvider>
        </AuthProvider>
      </ReactFlowProvider>
    </QueryClientProvider>
  </StrictMode>
)

export default Providers
