import { ComponentMetadataResponse } from '../../types'

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const mockIfMetadata = {
  componentId: 'if',
  metadata: {
    schemaVersion: 1,
    emeraldId: '-1357378929',
    componentType: 'ORCHESTRATION',
    inputPorts: [
      {
        portId: 'INPUT',
        cardinality: 'ONE'
      }
    ],
    outputPorts: [
      {
        portId: 'TRUE',
        cardinality: 'MANY'
      },
      {
        portId: 'FALSE',
        cardinality: 'MANY'
      },
      {
        portId: 'FAILURE',
        cardinality: 'MANY'
      }
    ],
    deprecated: false,
    exports: [
      'COMPLETED_AT',
      'COMPONENT_NAME',
      'DURATION',
      'MESSAGE',
      'ROW_COUNT',
      'STARTED_AT',
      'STATUS'
    ],
    editorTabs: ['PROPERTIES', 'EXPORT', 'HELP'],
    parameters: [
      {
        id: 'component-name',
        dplID: 'componentName',
        resourceID: 'componentName',
        metlSlot: 1,
        useVariable: 'NO',
        dataType: 'TEXT',
        authProvider: null,
        editorType: 'FREETEXT',
        lookupType: null,
        lookupDependencies: null,
        validationDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null,
        secretTypes: null,
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        id: 'mode',
        dplID: 'mode',
        resourceID: 'mode',
        metlSlot: 2,
        useVariable: 'YES',
        dataType: 'TEXT',
        authProvider: null,
        editorType: 'DROPDOWN',
        lookupType: null,
        lookupDependencies: null,
        validationDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: [
          {
            name: null,
            resourceID: null,
            type: 'TEXT',
            options: ['Simple', 'Advanced'],
            columnEditorType: 'DROPDOWN',
            defaultValue: 'Simple',
            lookupType: null,
            lookupDependencies: null
          }
        ],
        defaultValue: 'Simple',
        secretTypes: null,
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        id: 'condition',
        dplID: 'condition',
        resourceID: 'condition',
        metlSlot: 3,
        useVariable: 'YES',
        dataType: 'GRID',
        authProvider: null,
        editorType: 'GRID_EDITOR',
        lookupType: null,
        lookupDependencies: null,
        validationDependencies: null,
        visibleWhen: [
          {
            param: 'mode',
            value: 'Simple',
            operator: 'EQUALS'
          }
        ],
        optional: false,
        staticOptions: [
          {
            name: 'Input Variable',
            resourceID: 'inputVariable',
            type: 'TEXT',
            options: null,
            columnEditorType: 'DROPDOWN',
            defaultValue: null,
            lookupType: 'DYNAMIC_VARIABLES',
            lookupDependencies: ['param.concurrency']
          },
          {
            name: 'Qualifier',
            resourceID: 'qualifier',
            type: 'TEXT',
            options: ['Is', 'Not'],
            columnEditorType: 'DROPDOWN',
            defaultValue: 'Is',
            lookupType: null,
            lookupDependencies: null
          },
          {
            name: 'Comparator',
            resourceID: 'comparator',
            type: 'TEXT',
            options: [
              'Less than',
              'Less than or equal to',
              'Equal to',
              'Greater than or equal to',
              'Greater than',
              'Blank'
            ],
            columnEditorType: 'DROPDOWN',
            defaultValue: 'Less than',
            lookupType: null,
            lookupDependencies: null
          },
          {
            name: 'Value',
            resourceID: 'value',
            type: 'TEXT',
            options: null,
            columnEditorType: 'FREETEXT',
            defaultValue: null,
            lookupType: null,
            lookupDependencies: null
          }
        ],
        defaultValue: null,
        secretTypes: null,
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        id: 'combine-conditions',
        dplID: 'combineConditions',
        resourceID: 'combineConditions',
        metlSlot: 4,
        useVariable: 'YES',
        dataType: 'TEXT',
        authProvider: null,
        editorType: 'DROPDOWN',
        lookupType: null,
        lookupDependencies: null,
        validationDependencies: null,
        visibleWhen: [
          {
            param: 'mode',
            value: 'Simple',
            operator: 'EQUALS'
          }
        ],
        optional: false,
        staticOptions: [
          {
            name: null,
            resourceID: null,
            type: 'TEXT',
            options: ['And', 'Or'],
            columnEditorType: 'DROPDOWN',
            defaultValue: 'And',
            lookupType: null,
            lookupDependencies: null
          }
        ],
        defaultValue: 'And',
        secretTypes: null,
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        id: 'condition1',
        dplID: 'condition1',
        resourceID: 'condition',
        metlSlot: 5,
        useVariable: 'YES',
        dataType: 'TEXT',
        authProvider: null,
        editorType: 'FREETEXT_MULTI_LINE',
        lookupType: null,
        lookupDependencies: null,
        validationDependencies: null,
        visibleWhen: [
          {
            param: 'mode',
            value: 'Advanced',
            operator: 'EQUALS'
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null,
        secretTypes: null,
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      }
    ]
  }
} as ComponentMetadataResponse
