import { ReactFlowInstance, XYPosition } from 'reactflow'

interface CalculatePastedComponentsPositionsProps {
  reactFlowInstance: ReactFlowInstance
  copiedComponentOffsets: XYPosition[]
  manualPosition: XYPosition | undefined
  currentJobId: string | null | undefined
  jobSummaryId: string
}

const PASTED_COMPONENT_OFFSET = 60
const TOP_LEFT_CORNER_OFFSET = 40

export interface HasPosition {
  x: number
  y: number
}

export const calculatePastedComponentsPositions = <
  Y extends HasPosition,
  T extends Y[]
>(
  componentInstances: T,
  {
    reactFlowInstance,
    copiedComponentOffsets,
    manualPosition,
    currentJobId,
    jobSummaryId
  }: CalculatePastedComponentsPositionsProps
): T => {
  const topLeft = reactFlowInstance.project({
    x: TOP_LEFT_CORNER_OFFSET,
    y: TOP_LEFT_CORNER_OFFSET
  })

  const updatedComponentInstances = componentInstances.map(
    (componentInstance, index) => {
      let newX
      let newY
      if (copiedComponentOffsets && manualPosition) {
        newX = manualPosition.x + copiedComponentOffsets[index].x
        newY = manualPosition.y + copiedComponentOffsets[index].y
      } else if (copiedComponentOffsets && currentJobId !== jobSummaryId) {
        newX = copiedComponentOffsets[index].x + topLeft.x
        newY = copiedComponentOffsets[index].y + topLeft.y
      } else {
        newX = componentInstance.x + PASTED_COMPONENT_OFFSET
        newY = componentInstance.y + PASTED_COMPONENT_OFFSET
      }

      return {
        ...componentInstance,
        x: newX,
        y: newY
      }
    }
  )

  return updatedComponentInstances as T
}
